import axios from "axios";
const apiInstance = axios.create({
  // baseURL: "https://fra.thegrapestudio.com/api",
  baseURL: "http://frawizard-api-env-1.eba-y2cpppmj.us-east-1.elasticbeanstalk.com/api",
});

export const updateFloorPlan = async (floor, floorId, token) => {
  return apiInstance.put(`/floorplan/${floorId}`, floor, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const getFloorPlan = async (floorId, token) => {
  return apiInstance.get(`/floorplan/${floorId}`, {
    headers: { Authorization: "Bearer " + token },
  });
};
