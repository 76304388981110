import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-First-Aid-Red",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "First Aid Red",
    description: "First-Aid-Red",
    image: require("./First-Aid-Red.svg"),
    svgIcon: require("./First-Aid-Red.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.aidredcls1{fill:#d71214;}.aidredcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
             <path class="aidredcls1" d="M98.59,95.72H0V0H98.59ZM56.78,86.09v-.45q0-6-.08-12L56.58,55.2c0-.11,0-.22,0-.37H57l10.91,0L81,54.72l7.27,0,.39,0c.06-.54,0-13.73,0-14h-.4l-11.39.08-20,.12-.36,0c0-1.34,0-2.63,0-3.93s0-2.59,0-3.89,0-2.6,0-3.89,0-2.6,0-3.9l0-3.92c0-1.3,0-2.6,0-3.89s0-2.6,0-3.9,0-2.58,0-3.86c-.57-.08-14.06-.05-14.31,0Q42,25.31,42.09,41L10,41.16c-.08.38-.06,13.64,0,13.9l32.19-.15c0,5.22.07,10.41.1,15.6s.07,10.37.1,15.58Z" transform="translate(0 0)"/>
    <path class="aidredcls2" d="M56.78,86.09H42.35q0-7.82-.1-15.58c0-5.19-.07-10.38-.1-15.6L10,55.06c-.07-.26-.09-13.52,0-13.9L42.09,41Q42,25.29,41.93,9.69c.25-.08,13.74-.11,14.31,0,0,1.28,0,2.57,0,3.86s0,2.6,0,3.9,0,2.59,0,3.89l0,3.92c0,1.3,0,2.6,0,3.9s0,2.59,0,3.89,0,2.59,0,3.89,0,2.59,0,3.93l.36,0,20-.12,11.39-.08h.4c.07.27.09,13.46,0,14l-.39,0-7.27,0-13.12.06L57,54.83h-.37c0,.15,0,.26,0,.37l.12,18.47q0,6,.08,12Z" transform="translate(0 0)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
