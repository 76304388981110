import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Desk",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Desk",
    description: "Desk",
    image: require("./Desk.svg"),
    svgIcon: require("./Desk.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g
          id={`${element.type}-${element.id}`}
          transform={`translate(-47, 55) scale(1,-1)`}
        >
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M28.58,48.43H71A2.35,2.35,0,0,0,71,48c0-.74.3-1,1-1,3.23,0,6.46,0,9.69,0H96.52c1.34,0,1.34,0,1.46,1.39.87,0,1,.52,1,1.29q0,14.12,0,28.23c0,1.11-.07,1.19-1.15,1.19H2.14C1.1,79.11,1,79,1,78c0-9.44,0-18.88,0-28.32a3.94,3.94,0,0,1,.44-1.21c.05-.13.15-.25.14-.37-.08-1,.48-1.1,1.28-1.1Q15,47,27,47C28.43,47,28.43,47,28.58,48.43Zm69.51,1H1.93V78.26H94.5a4.56,4.56,0,0,0,.74,0c.16,0,.4-.12.45-.24.39-.93.76-1.87,1.1-2.82.07-.2.08-.58,0-.66a.93.93,0,0,0-.75-.07c-.51.19-1,.48-1.48.71s-1.34,1-2,.3-.08-1.38.19-2,.45-1,.68-1.55a1.71,1.71,0,0,0-.67.55,5.21,5.21,0,0,1-5.17,1.79,5.33,5.33,0,0,1,1.81-10.5A5.3,5.3,0,0,1,94,69.37c-.15,1.59-.08,3.26-1.06,4.68a1.41,1.41,0,0,0-.17.46c-.16.64.1.92.72.72a10.28,10.28,0,0,0,1.26-.59A9.92,9.92,0,0,1,96.13,74c.82-.25,1.32.2,1.12,1a15.78,15.78,0,0,1-.72,1.83c-.16.41-.3.84-.48,1.33h2ZM93.37,69a4.73,4.73,0,1,0-4.76,4.71A4.71,4.71,0,0,0,93.37,69ZM15.14,47.82H3.41a2.79,2.79,0,0,0-.65,0c-.12,0-.28.19-.28.3a.44.44,0,0,0,.27.31,1.81,1.81,0,0,0,.57,0H26.86a1.49,1.49,0,0,0,.56,0,.46.46,0,0,0,.24-.34c0-.07-.13-.23-.23-.25a2.81,2.81,0,0,0-.65,0Zm56.75,0c-.06.87.5.64.84.64H96.27a.92.92,0,0,0,.41,0,2.24,2.24,0,0,0,.42-.34,1.36,1.36,0,0,0-.41-.28,2.16,2.16,0,0,0-.57,0H71.89Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            d="M37.61,42.21c-1.78,0-1.92-.07-2.27-1.82L32.82,27.52c-.22-1.15.13-1.75,1.32-1.9a2.09,2.09,0,0,0,2-1.5,2.34,2.34,0,0,1-1-2.59,1.93,1.93,0,0,1,1.71-1.43l14.9-2.9c1.77-.35,3.55-.66,5.31-1a2.07,2.07,0,0,1,2.35.69,2.21,2.21,0,0,1,.14,2.47,1.87,1.87,0,0,0-.07.22,2.36,2.36,0,0,0,2.51.64c1.16-.29,1.69.13,1.92,1.3l2.61,13.43c.21,1.09,0,1.42-1.18,1.86a20.77,20.77,0,0,1-.81,3.39,5.21,5.21,0,0,1-4.08,3.09c-5.34,1.06-10.67,2.12-16,3.13-3.18.59-5.58-.88-6.69-4A1.22,1.22,0,0,0,37.61,42.21Zm.77-.21a11.9,11.9,0,0,0,.93,1.79,4.58,4.58,0,0,0,4.87,1.78c5.43-1,10.85-2.11,16.27-3.17a4.64,4.64,0,0,0,.64-.17,4.52,4.52,0,0,0,3-5.24l-1.6.28c-1.19.21-1.71-.1-1.94-1.28-.9-4.53-1.77-9.06-2.65-13.59a1.3,1.3,0,0,1,.9-1.62l.47-.22v-.22A6.54,6.54,0,0,0,58,20.21c-.7.08-1.39.26-2.09.4l-18,3.5c-.55.1-1,.25-1.16.91,1.38,0,1.69.19,1.9,1.23q1.38,6.83,2.72,13.66A1.31,1.31,0,0,1,40.3,41.6C39.72,41.74,39.13,41.84,38.38,42Zm27.41-6.53c0-.27,0-.35-.06-.43q-1.31-6.75-2.62-13.51c-.1-.51-.4-.66-.89-.57-1,.2-1.93.38-2.9.55-.57.11-.67.43-.56,1,.33,1.54.62,3.1.93,4.65.56,2.84,1.11,5.68,1.68,8.52.09.44.11,1,.82.84,1-.2,2-.37,3.06-.6C65.48,35.83,65.66,35.57,65.79,35.47ZM40.64,40.38a2.45,2.45,0,0,1,0-.3q-1.34-6.82-2.68-13.66c-.1-.52-.42-.65-.91-.56-.88.18-1.76.37-2.65.5-.66.1-.88.38-.72,1.06.29,1.28.51,2.57.76,3.86l1.83,9.4c.09.48.25.85.85.73,1-.19,2-.36,3-.59C40.31,40.76,40.51,40.48,40.64,40.38ZM37.17,23.44l.5-.08,11-2.15,9.1-1.77c.71-.13,1.25-.42,1.23-1.26s-.66-1.46-1.72-1.25l-10,1.94-10.39,2c-.83.17-1.15.61-1.07,1.37A1.21,1.21,0,0,0,37.17,23.44Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M98.09,49.41v28.8h-2c.18-.49.32-.92.48-1.33a15.78,15.78,0,0,0,.72-1.83c.2-.84-.3-1.29-1.12-1a9.92,9.92,0,0,0-1.42.63,10.28,10.28,0,0,1-1.26.59c-.62.2-.88-.08-.72-.72a1.41,1.41,0,0,1,.17-.46c1-1.42.91-3.09,1.06-4.68a5.3,5.3,0,0,0-4.6-5.65,5.33,5.33,0,0,0-1.81,10.5,5.21,5.21,0,0,0,5.17-1.79,1.71,1.71,0,0,1,.67-.55c-.23.51-.47,1-.68,1.55s-.82,1.42-.19,2,1.37,0,2-.3,1-.52,1.48-.71a.93.93,0,0,1,.75.07c.11.08.1.46,0,.66-.34,1-.71,1.89-1.1,2.82,0,.12-.29.21-.45.24a4.56,4.56,0,0,1-.74,0H1.93V49.41ZM49.37,62.78h8.2c1.21,0,1.52-.29,1.53-1.48q0-4,0-8c0-1.2-.33-1.53-1.51-1.53H41.1c-1.15,0-1.47.32-1.47,1.46q0,4.06,0,8.12c0,1.16.31,1.46,1.46,1.46Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M93.37,69a4.73,4.73,0,1,1-4.74-4.74A4.69,4.69,0,0,1,93.37,69ZM88.63,71a1.95,1.95,0,1,0,0-3.89,1.95,1.95,0,1,0,0,3.89Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M15.14,47.82H26.78a2.81,2.81,0,0,1,.65,0c.1,0,.25.18.23.25a.46.46,0,0,1-.24.34,1.49,1.49,0,0,1-.56,0H3.32a1.81,1.81,0,0,1-.57,0,.44.44,0,0,1-.27-.31c0-.11.16-.27.28-.3a2.79,2.79,0,0,1,.65,0Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M71.89,47.83H96.12a2.16,2.16,0,0,1,.57,0,1.36,1.36,0,0,1,.41.28,2.24,2.24,0,0,1-.42.34.92.92,0,0,1-.41,0H72.73C72.39,48.47,71.83,48.7,71.89,47.83Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M38.38,42c.75-.16,1.34-.26,1.92-.4a1.31,1.31,0,0,0,1.12-1.69q-1.34-6.82-2.72-13.66c-.21-1-.52-1.22-1.9-1.23.13-.66.61-.81,1.16-.91l18-3.5c.7-.14,1.39-.32,2.09-.4a6.54,6.54,0,0,1,1.31.13v.22l-.47.22A1.3,1.3,0,0,0,58,22.4c.88,4.53,1.75,9.06,2.65,13.59.23,1.18.75,1.49,1.94,1.28l1.6-.28a4.52,4.52,0,0,1-3,5.24,4.64,4.64,0,0,1-.64.17C55,43.46,49.61,44.54,44.18,45.57a4.58,4.58,0,0,1-4.87-1.78A11.9,11.9,0,0,1,38.38,42Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M65.79,35.47c-.13.1-.31.36-.54.41-1,.23-2,.4-3.06.6-.71.14-.73-.4-.82-.84-.57-2.84-1.12-5.68-1.68-8.52-.31-1.55-.6-3.11-.93-4.65-.11-.53,0-.85.56-1,1-.17,1.94-.35,2.9-.55.49-.09.79.06.89.57Q64.41,28.3,65.73,35C65.75,35.12,65.75,35.2,65.79,35.47Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M40.64,40.38c-.13.1-.33.38-.58.44-1,.23-2,.4-3,.59-.6.12-.76-.25-.85-.73l-1.83-9.4c-.25-1.29-.47-2.58-.76-3.86-.16-.68.06-1,.72-1.06.89-.13,1.77-.32,2.65-.5.49-.09.81,0,.91.56q1.35,6.82,2.68,13.66A2.45,2.45,0,0,0,40.64,40.38Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M37.17,23.44a1.21,1.21,0,0,1-1.3-1.17c-.08-.76.24-1.2,1.07-1.37l10.39-2,10-1.94c1.06-.21,1.71.27,1.72,1.25s-.52,1.13-1.23,1.26l-9.1,1.77-11,2.15Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            d="M49.37,62.78H41.09c-1.15,0-1.46-.3-1.46-1.46q0-4.05,0-8.12c0-1.14.32-1.46,1.47-1.46H57.59c1.18,0,1.51.33,1.51,1.53q0,4,0,8c0,1.19-.32,1.48-1.53,1.48Zm.05-10.31c-2.68,0-5.36,0-8,0-.78,0-1.07.23-1.06,1,0,2.54,0,5.09,0,7.63,0,.71.23,1,1,1q8.08,0,16.16,0c.71,0,1-.19,1-.92,0-2.57,0-5.15,0-7.72,0-.79-.28-1-1-1C54.73,52.49,52.07,52.47,49.42,52.47Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            d="M88.63,71a1.95,1.95,0,1,1,0-3.89,1.95,1.95,0,1,1,0,3.89Zm.81-3a1.48,1.48,0,0,0-2.29,1.46,1.54,1.54,0,0,0,2.93.13c.22-.6.22-1.17-.47-1.52Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M49.42,52.47c2.65,0,5.31,0,8,0,.72,0,1,.17,1,1,0,2.57,0,5.15,0,7.72,0,.73-.24.93-1,.92q-8.07,0-16.16,0c-.72,0-1-.24-1-1,0-2.54,0-5.09,0-7.63,0-.8.28-1,1.06-1C44.06,52.49,46.74,52.47,49.42,52.47Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M89.61,68c.69.35.69.92.47,1.52a1.54,1.54,0,0,1-2.93-.13,1.13,1.13,0,0,1,.71-1.51c-.7.84-.72,1.27-.1,2a1.2,1.2,0,0,0,2.08-.4A4,4,0,0,0,89.61,68Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            d="M87.76,69.85c-.62-.7-.6-1.13.1-2a1.13,1.13,0,0,1,1.58.05l-.06.17c-.95-.34-1.64-.14-1.77.6a4.33,4.33,0,0,0,.13,1.17Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            d="M89.38,68.1l.06-.17.17.07a4,4,0,0,1,.23,1.45,1.2,1.2,0,0,1-2.08.4l0,0c.61.27,1.24.51,1.76-.14A1.24,1.24,0,0,0,89.38,68.1Z"
            transform="translate(-1.01 -16.06)"
          />
          <path
            class="cls-1"
            d="M89.38,68.1a1.24,1.24,0,0,1,.12,1.63c-.52.65-1.15.41-1.76.14a4.33,4.33,0,0,1-.13-1.17C87.74,68,88.43,67.76,89.38,68.1Z"
            transform="translate(-1.01 -16.06)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
