import { Catalog } from "react-planner";

let catalog = new Catalog("m");

import * as Areas from "./areas/**/planner-element.jsx";
import * as Lines from "./lines/**/planner-element.jsx";
import * as Holes from "./holes/**/planner-element.jsx";
import * as Items from "./items/**/planner-element.jsx";
import * as Stairs from "./items/stairs/**/planner-element.jsx";
import * as Bathroom from "./items/bathroom/**/planner-element.jsx";
import * as Bedroom from "./items/bedroom/**/planner-element.jsx";
import * as Dining from "./items/dining/**/planner-element.jsx";
import * as HeatingElectrical from "./items/heating_Electrical/**/planner-element.jsx";
import * as Office from "./items/office/**/planner-element.jsx";
import * as Furniture from "./items/furniture/**/planner-element.jsx";
import * as Kitchen from "./items/kitchen/**/planner-element.jsx";
import * as LivingRoom from "./items/livingroom/**/planner-element.jsx";
import * as Outdoors from "./items/outdoor/**/planner-element.jsx";
import * as FireSafety from "./items/firesafety/**/planner-element.jsx";
// common catalog for "ADD" section
//standard-gas-nob
//toilet
import toilet from "./items/bathroom/Toilet/planner-element.jsx";
import roundSink from "./items/bathroom/round_sink/planner-element.jsx";
import cornerShower from "./items/bathroom/corner_shower/planner-element.jsx";
import bath from "./items/bathroom/Bath/planner-element.jsx";
import toilerwithcistern from "./items/bathroom/Toilet_with_cistern/planner-element.jsx";
import shower from "./items/bathroom/shower/planner-element.jsx";
import fancyBed from "./items/bedroom/fancy-bed/planner-element.jsx";
import SingleBed from "./items/bedroom/Single-Bed/planner-element.jsx";
import doubleBed from "./items/bedroom/double-bed/planner-element.jsx";
import bedsideTable from "./items/bedroom/bedside-table/planner-element.jsx";
import downArrow from "./items/down-arrow/planner-element.jsx";
import curveBasedUnit from "./items/curved-based-unit/planner-element.jsx";
import door from "./holes/door/planner-element.jsx";
import doubleDoor from "./holes/panic-door-double/planner-element.jsx";
import text from "./items/text-3d/planner-element.jsx";
import fireplace from "./items/fireplace/planner-element.jsx";
import rectangularBlock from "./items/rectangular-block/planner-element.jsx";
import measuringArrow from "./items/measuring-arrow/planner-element.jsx";
import upArrow from "./items/up-arrow/planner-element.jsx";
import transparentBaseUnit from "./items/transparent-base-unit/planner-element.jsx";
import Window from "./holes/window/planner-element.jsx";
import Hob from "./items/kitchen/hob/planner-element.jsx";
import Boiler from "./items/heating_Electrical/Boiler/planner-element.jsx";
import Freezer from "./items/heating_Electrical/Freezer/planner-element.jsx";
import Dishwasher from "./items/heating_Electrical/Dishwasher/planner-element.jsx";
import Fridge from "./items/heating_Electrical/Fridge/planner-element.jsx";
import AmericanStyleFrige from "./items/heating_Electrical/American-Style-Fridge/planner-element.jsx";
import Arrow from "./items/arrow/planner-element.jsx";
import Sink from "./items/bathroom/Sink/planner-element.jsx";
import Rug from "./items/furniture/Rug/planner-element.jsx";
import Sofa1 from "./items/furniture/Sofa1/planner-element.jsx";
import Sofa4 from "./items/furniture/Sofa 4/planner-element.jsx";

for (let x in Areas) catalog.registerElement(Areas[x]);
for (let x in Lines) catalog.registerElement(Lines[x]);
for (let x in Holes) {
  catalog.registerElement(Holes[x]);
}
for (let x in Items) catalog.registerElement(Items[x]);
for (let x in Stairs) catalog.registerElement(Stairs[x]);
for (let x in Bathroom) catalog.registerElement(Bathroom[x]);
for (let x in Bedroom) catalog.registerElement(Bedroom[x]);
for (let x in Dining) catalog.registerElement(Dining[x]);
for (let x in HeatingElectrical) catalog.registerElement(HeatingElectrical[x]);
for (let x in Office) catalog.registerElement(Office[x]);
for (let x in Furniture) catalog.registerElement(Furniture[x]);
for (let x in Kitchen) catalog.registerElement(Kitchen[x]);
for (let x in LivingRoom) catalog.registerElement(LivingRoom[x]);
for (let x in Outdoors) catalog.registerElement(Outdoors[x]);
for (let x in FireSafety) catalog.registerElement(FireSafety[x]);

catalog.registerCategory("Add", "Add", [
  ...Object.keys(Lines).map((key) => Lines[key]),
  door,
  doubleDoor,
  Window,
  // ...Object.keys(Holes).map((key) => Holes[key]),
  cornerShower,
  roundSink,
  toilet,
  bath,
  toilerwithcistern,
  shower,
  Arrow,
  downArrow,
  upArrow,
  fireplace,
  Hob,
  curveBasedUnit,
  text,
  rectangularBlock,
  measuringArrow,
  transparentBaseUnit,
  // ...Object.keys(Items).map((key) => Items[key]),
]);
catalog.registerCategory(
  "More Symbols",
  "More Symbols",
  Object.keys(Holes).map((key) => Holes[key])
);
// these categories will be used in 'More Symbols' category as sub-categories
catalog.registerCategory(
  "Bathroom",
  "Bathroom",
  Object.keys(Bathroom).map((key) => Bathroom[key])
);
catalog.registerCategory(
  "Bedroom",
  "Bedroom",
  Object.keys(Bedroom).map((key) => Bedroom[key])
);
catalog.registerCategory(
  "Dining",
  "Dining",
  Object.keys(Dining).map((key) => Dining[key])
);
catalog.registerCategory(
  "Heating & Electrical",
  "Heating & Electrical",
  Object.keys(HeatingElectrical).map((key) => HeatingElectrical[key])
);
catalog.registerCategory("Furniture", "Furniture", [
  ...Object.keys(Furniture).map((key) => Furniture[key]),
  SingleBed,
  bedsideTable,
  fancyBed,
  doubleBed,
]);
catalog.registerCategory("Kitchen", "Kitchen", [
  Sink,
  ...Object.keys(Kitchen).map((key) => Kitchen[key]),
  Boiler,
  Freezer,
  AmericanStyleFrige,
  Fridge,
  Dishwasher,
]);
catalog.registerCategory("Living Room", "Living Room", [
  Sofa1,
  Sofa4,
  Rug,
  ...Object.keys(LivingRoom).map((key) => LivingRoom[key]),
]);
catalog.registerCategory(
  "Office",
  "Office",
  Object.keys(Office).map((key) => Office[key])
);
catalog.registerCategory(
  "Outdoors",
  "Outdoors",
  Object.keys(Outdoors).map((key) => Outdoors[key])
);
catalog.registerCategory(
  "Stairs",
  "Stairs",
  Object.keys(Stairs).map((key) => Stairs[key])
);
catalog.registerCategory(
  "Firesafety",
  "Firesafety",
  Object.keys(FireSafety).map((key) => FireSafety[key])
);

catalog.registerSubCategory("More Symbols", [
  catalog.getCategory("Firesafety"),
  catalog.getCategory("Bathroom"),
  catalog.getCategory("Bedroom"),
  catalog.getCategory("Dining"),
  catalog.getCategory("Heating & Electrical"),
  catalog.getCategory("Furniture"),
  catalog.getCategory("Kitchen"),
  catalog.getCategory("Living Room"),
  catalog.getCategory("Office"),
  catalog.getCategory("Outdoors"),

]);

export default catalog;
