import React, { Fragment } from "react";
import ReactRange from "@mapbox/react-range";
import FormTextInput from "./form-text-input";

const sliderContainerStyle = {
  display: "inline-block",
  width: "90%",
  marginRight: "5%",
};
const sliderStyle = { display: "block", width: "100%", height: "30px" };
const textContainerStyle = {
  display: "inline-block",
  width: "15%",
  // border: "1px solid rgba(0,0,0,.15)",
  fontSize: "13px",
  float: "right",
};
const textStyle = { height: "34px", textAlign: "center" };

export default function FormNumberInput({
  value,
  type,
  textValue,
  onChange,
  ...rest
}) {
  return (
    <div>
      <div style={sliderContainerStyle}>
        <ReactRange
          type="range"
          style={sliderStyle}
          onChange={onChange}
          value={value}
          {...rest}
        />
      </div>

      <div style={textContainerStyle}>
        {type === "slider" && (
          <div
            style={{
              display: "flex",
              border: "1px solid rgba(0,0,0,.15)",
              fontSize: "13px",
              alignItems: "center",
            }}
          >
            <FormTextInput
              value={textValue}
              onChange={onChange}
              style={textStyle}
            />
            m
          </div>
        )}
        {/* <p>{textValue}</p> */}
        {/* <FormTextInput value={textValue} onChange={onChange} style={textStyle}/> */}
      </div>
    </div>
  );
}
