import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormLabel, FormSlider } from "../../components/style/export";
import PropertyStyle from "./shared-property-style";
import Select from "react-select";

const tableStyle = {
  width: "100%",
  borderSpacing: "2px 0",
  marginBottom: "2px",
};
const btnstyle = {
  flex: 0.5,
  margin: "5px",
  height: "30px",
  backgroundColor: "rgb(255, 93, 23)",
};
const firstTdStyle = { width: "6em", textTransform: "capitalize" };

export default function PropertyDropdown({
  value,
  onUpdate,
  configs,
  sourceElement,
  internalState,
  state,
}) {

  let update = (val) => {
    if (configs.hook) {
      return configs
        .hook(val, sourceElement, internalState, state)
        .then((_val) => {
          return onUpdate(_val);
        });
    }

    return onUpdate(val);
  };

  return (
    <Fragment>
      <table className="PropertyDropdown" style={PropertyStyle.tableStyle}>
        <tbody>
          <tr>
            <td style={PropertyStyle.firstTdStyle}>
              <FormLabel>{configs.label}</FormLabel>
            </td>
            <td>
              <div style={{ width: "200px" }}>
                <Select
                  value={{ label: value, value }}
                  defaultValue={{
                    label: configs.defaultValue,
                    value: configs.defaultValue,
                  }}
                  options={
                    Array.isArray(configs.options)
                      ? configs.options.map((p) => ({ label: p, value: p }))
                      : []
                  }
                  onChange={(e) => {
                    update(e.value);
                    //  
                  }}
                />
                {/* <select>
                  
                  <option>dummy</option>
                  <option>dummy</option>
                  <option>dummy</option>
                </select> */}
              </div>
              {/* <label style={{fontSize:"13px"}}>{parseFloat(value===configs.defaultValue?value/100:value).toFixed(2)}m</label> */}
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
}

PropertyDropdown.propTypes = {
  value: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired,
};
