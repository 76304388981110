import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Arrow",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "up arrow",
    description: "arrow",
    image: require("./arrow.svg"),
    svgIcon: require("./arrow.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-20, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="arrow">
            <path
              d="M39.57,91.26V51.08l-8.79-.78L48.22,7.39l1.15-.18c1.3,2.72,2.72,5.38,3.86,8.16,3.39,8.21,6.68,16.45,10,24.68,1,2.62,1.86,5.33,3.05,7.87,1.11,2.37-.21,2.76-2,2.84s-3.71,0-5.85,0V91.26Z"
              transform="translate(-30.78 -7.21)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
