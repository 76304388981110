import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Panel from "./panel";
import * as SharedStyle from "../../shared-style";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaPencil, FaTrash, FaTimes } from "react-icons/fa";
import { FormNumberInput } from "../../components/style/export";

const tabStyle = { margin: "1em" };

const iconStyle = {
  fontSize: "14px",
  margin: "2px",
  cursor: "pointer",
};

const addGuideStyle = {
  cursor: "pointer",
  height: "2em",
};

const tableTabStyle = {
  width: "100%",
  textAlign: "center",
};

export default class PanelGuides extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      addHGVisible: true,
      addVGVisible: true,
      addCGVisible: true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.addHGVisible !== nextState.addHGVisible ||
      this.state.addVGVisible !== nextState.addVGVisible ||
      this.state.addCGVisible !== nextState.addCGVisible ||
      this.props.state.getIn(["scene", "guides"]).hashCode() !==
        nextProps.state.getIn(["scene", "guides"]).hashCode()
    );
  }

  render() {
    let { state } = this.props;
    let { projectActions, translator } = this.context;
    let { guides } = state.scene;
    let draggingSupport = state.get("draggingSupport");

    return (
      <Fragment>
        <Tabs id="guidesTabs" style={tabStyle}>
          {/* <TabList>
            <Tab>{translator.t("Horizontal")}</Tab>
            <Tab>{translator.t("Vertical")}</Tab>
          </TabList> */}
          <p>Guides</p>
          <table style={tableTabStyle}>
              <tbody>
                {guides
                  .get("horizontal")
                  .entrySeq()
                  .map(([hgKey, hgVal], ind) => {
                    // console.log(
                    //   "guiedasdsa",
                    //   draggingSupport.get("guideID"),
                    //   hgKey
                    // );
                    return (
                      <tr
                        style={
                          draggingSupport.get("guideID") === hgKey
                            ? { background: "#e6e5e5" }
                            : {}
                        }
                        key={hgKey}
                      >
                        <td style={{ width: "2em" }}>{ind + 1}</td>
                        <td>{hgKey}</td>
                        <td style={{ width: "5em" }}>
                          {/*<FaPencil style={iconStyle} />*/}
                          <FaTrash
                            style={iconStyle}
                            onClick={(e) =>
                              projectActions.removeHorizontalGuide(hgKey)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                   {guides
                  .get("vertical")
                  .entrySeq()
                  .map(([hgKey, hgVal], ind) => {
                    return (
                      <tr
                        style={
                          draggingSupport.get("guideID") === hgKey
                            ? { background: "#e6e5e5" }
                            : {}
                        }
                        key={hgKey}
                      >
                        <td style={{ width: "2em" }}>{ind + 1}</td>
                        <td>{hgKey}</td>
                        <td style={{ width: "5em" }}>
                          {/*<FaPencil style={iconStyle} />*/}
                          <FaTrash
                            style={iconStyle}
                            onClick={(e) =>
                              projectActions.removeVerticalGuide(hgKey)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          {/* <TabPanel>
           
          </TabPanel>
          <TabPanel>
            <table style={tableTabStyle}>
              <tbody>
               
              </tbody>
            </table>
          </TabPanel> */}
          {/*<TabPanel>
            <b>TODO Circular Giudes</b>
          </TabPanel>*/}
        </Tabs>
      </Fragment>
    );
  }
}

PanelGuides.propTypes = {
  state: PropTypes.object.isRequired,
};

PanelGuides.contextTypes = {
  translator: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
};
