import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "upArrow",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "up arrow",
    description: "upArrow",
    image: require("./upArrow.svg"),
    svgIcon: require("./upArrow.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-20, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M43.34,69.26V40.05L37,39.48c4.38-10.77,8.53-21,12.69-31.21l.83-.12c.94,2,2,3.91,2.81,5.93,2.46,6,4.85,12,7.25,18,.76,1.9,1.36,3.87,2.22,5.72s-.15,2-1.44,2.07-2.7,0-4.25,0V69.26Z"
              transform="translate(-36.95 -8.15)"
            />
            <path
              d="M42.51,73.78v6.57c0,2.12,1.09,2.75,2.28,2.75,1.36,0,2.22-.75,2.22-2.75V73.78H49.4v6.45C49.4,84,47.19,85,44.76,85s-4.64-1-4.64-4.69v-6.5Z"
              transform="translate(-36.95 -8.15)"
            />
            <path
              d="M51.91,73.78h4.57c2.34,0,3.94,1.32,3.94,3.41,0,2.4-1.82,3.5-4,3.5H54.3v4.12H51.91Zm2.39,5h1.82c1.11,0,1.89-.42,1.89-1.55s-.82-1.56-1.84-1.56H54.3Z"
              transform="translate(-36.95 -8.15)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
