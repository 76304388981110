import React from "react";
import PropTypes from "prop-types";
import Scene from "./scene";
import Snap from "./snap";
import * as SharedStyle from "../../shared-style";
import { SELECT_GUIDE,MODE_IDLE } from "../../constants";

const guideStyle = {
  stroke: SharedStyle.SECONDARY_COLOR.main,
  strokewidth: "2.5px",
  margin: "10px",
};

export default function State({ state, catalog,showGuides},{projectActions}) {
  let { activeSnapElement, snapElements, scene, draggingSupport } = state;
  let { width, height } = scene;

  activeSnapElement = activeSnapElement ? (
    <Snap snap={activeSnapElement} width={scene.width} height={scene.height} />
  ) : null;
  // snapElements = snapElements.map((snap,id) => <Snap key={id} snap={snap} width={scene.width} height={scene.height}/>);
  snapElements = null; //only for debug purpose

  return (
    <g>
      <rect
        x="0"
        y="0"
        width={width}
        height={height}
        fill={SharedStyle.COLORS.white}
      />
      <g
        transform={`translate(0, ${scene.height}) scale(1, -1)`}
        id="svg-drawing-paper"
      >
        <Scene showGuides={showGuides} scene={scene} catalog={catalog} />
        
        { scene
          .getIn(["guides", "horizontal"])
          .entrySeq()
          .map(([hgKey, hgVal]) => (
            <line
             onClick={()=>{
              //  
              if(state.get("mode")===MODE_IDLE){

                projectActions.selectGuide(hgKey)
              }
             }}
              className={state.get("mode")===MODE_IDLE && "guides"}
              data-element-root
              data-prototype={"hguide"}
              data-layer={scene.get("selectedLayer")}
              data-id={"hGuide" + hgKey}
              id={"hGuide" + hgKey}
              key={hgKey}
              x1={0}
              y1={hgVal}
              x2={width}
              y2={hgVal}
              style={guideStyle}
            />
          ))}
        {scene
          .getIn(["guides", "vertical"])
          .entrySeq()
          .map(([vgKey, vgVal]) => (
            <line
            onClick={()=>{
              //  
              // projectActions.setProjectProperties({draggingSupport:{guideID:vgKey}})
             if(state.get("mode")===MODE_IDLE){

               projectActions.selectGuide(vgKey)
             }
             }}
              className={state.get("mode")===MODE_IDLE && "guides"}
              data-element-root
              data-prototype={"vGuide"}
              data-layer={scene.get("selectedLayer")}
              data-id={"vGuide" + vgKey}
              id={"vGuide" + vgKey}
              key={vgKey}
              x1={vgVal}
              y1={0}
              x2={vgVal}
              y2={height}
              style={guideStyle}
            />
          ))}
        {activeSnapElement}
        {snapElements}
      </g>
    </g>
  );
}

State.propTypes = {
  state: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  showGuides: PropTypes.bool.isRequired,
};
State.contextTypes={
  projectActions: PropTypes.object.isRequired,
}
