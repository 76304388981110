import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "outdoorchairs",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "outdoor chairs",
    description: "outdoorchairs",
    image: require("./outdoorchairs.svg"),
    svgIcon: require("./outdoorchairs.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M69.87,61.94l-.58,0A1.25,1.25,0,0,1,68,60.69c0-.22,0-.44,0-.65q0-7.82,0-15.62a3.91,3.91,0,0,1,.06-.84,1.07,1.07,0,0,1,1.11-1,.67.67,0,0,1,.2,0A2.33,2.33,0,0,0,72,41.18a2.72,2.72,0,0,1-.86-2.34,2,2,0,0,1,2.15-2c6.88,0,13.75,0,20.63,0A2,2,0,0,1,96.06,38a2.56,2.56,0,0,1-.19,2.71c-.1.14-.22.27-.34.43a13.08,13.08,0,0,0,1.13,1.32c.17.15.53.12.8.12,2,0,2.15.16,2.15,2.15V60.06c0,1.46-.06,1.51-1.49,1.84a.7.7,0,0,0-.33.51A6.38,6.38,0,0,1,95.59,67,4.6,4.6,0,0,1,93,68.18c-.43,0-.86,0-1.3,0-5.16,0-10.33-.12-15.48,0a6,6,0,0,1-6.26-5.7C69.93,62.38,69.9,62.19,69.87,61.94Zm.61,0c-.12,2.62,1.89,5.77,5.28,5.72,5.38-.08,10.76,0,16.14,0,.35,0,.69,0,1,0a5.32,5.32,0,0,0,4.21-5.68H95.68c-1.54,0-1.85-.32-1.85-1.88q0-7.84,0-15.68c0-.18,0-.35,0-.52a1.24,1.24,0,0,1,1.25-1.24c.24,0,.49,0,.73,0-.43-.61-.77-1.16-1.59-1.15q-10.38,0-20.76,0c-.85,0-1.23.54-1.81,1.19.41,0,.64,0,.87,0a1.16,1.16,0,0,1,1.2,1.12,4.79,4.79,0,0,1,0,.65V60.05a4.64,4.64,0,0,1,0,.65,1.27,1.27,0,0,1-1.42,1.21Zm28.6-9.67v-8c0-.94-.1-1-1.06-1H95.29c-.7,0-.89.19-.89.89l0,16.33c0,.57.26.84.83.84,1,0,1.91,0,2.86,0s1-.12,1-1Zm-30.59,0v8c0,.79.17,1,1,1,.94,0,1.87,0,2.8,0s1-.14,1-1V44.39c0-1.07-.11-1.18-1.21-1.18H69.74c-1.12,0-1.25.13-1.25,1.29ZM83.7,40.8c3.49,0,7,0,10.47,0A1.42,1.42,0,0,0,95.62,40a1.62,1.62,0,0,0-1.53-2.41H73.15a1.2,1.2,0,0,0-1.16.58,2,2,0,0,0,0,2c.31.6.86.64,1.45.64Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M4,62c-1.89-.34-1.92-.38-1.92-2.33q0-7.64,0-15.29a3.87,3.87,0,0,1,.1-1,1,1,0,0,1,1-.82,1.13,1.13,0,0,1,.26,0,2.35,2.35,0,0,0,2.69-1.41,2.66,2.66,0,0,1-.68-3.11,1.78,1.78,0,0,1,1.77-1.19h4.56c5.37,0,10.75,0,16.13,0,.95,0,1.8.13,2.22,1.1a2.6,2.6,0,0,1-.37,2.92,2.65,2.65,0,0,1-.17.22c.37.43.72.87,1.1,1.27a.71.71,0,0,0,.46.16,8,8,0,0,0,.85,0c1.34,0,1.73.39,1.73,1.75q0,6.84,0,13.67c0,.84,0,1.69,0,2.53s-.37,1.34-1.28,1.36c-.5,0-.55.23-.57.63A6,6,0,0,1,27.82,68a6.45,6.45,0,0,1-1.86.22c-5.29,0-10.59-.09-15.88,0a6,6,0,0,1-6-5.72C4,62.38,4,62.21,4,62Zm.62-.08a5.37,5.37,0,0,0,5.24,5.72c4.84-.09,9.68,0,14.51,0,.85,0,1.7,0,2.54,0a5.3,5.3,0,0,0,4.38-5.69h-1.6c-1.4,0-1.72-.32-1.72-1.73V44.34c0-.15,0-.3,0-.45a1.29,1.29,0,0,1,1.37-1.33l.62,0c-.42-.48-.68-1.1-1.42-1.1H7.45A1.65,1.65,0,0,0,6,42.6h.6a1.16,1.16,0,0,1,1.28,1.11,4.9,4.9,0,0,1,0,.77q0,7.23,0,14.45c0,.56,0,1.12,0,1.69a1.21,1.21,0,0,1-1.38,1.29C5.85,61.93,5.23,61.92,4.61,61.92ZM7.3,52.27h0c0-2.69,0-5.38,0-8.06,0-.84-.16-1-1-1s-1.9,0-2.86,0c-.62,0-.86.29-.86.87,0,5.44,0,10.88,0,16.33,0,.68.2.86.91.86h2.8c.85,0,1-.13,1-1Zm25.91,0V44.1c0-.73-.16-.88-.88-.89H29.4c-.73,0-.9.16-.9.87V60.35c0,.74.18.91.94.91h2.67c1,0,1.1-.15,1.1-1.09ZM17.82,37.54H7.41c-.82,0-1.33.3-1.5,1-.3,1.18.14,2.32,1.61,2.31,6.89,0,13.79,0,20.68,0a1.64,1.64,0,1,0,0-3.28C24.76,37.58,21.29,37.54,17.82,37.54Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M36.94,50.92c0-5.43,0-10.85,0-16.27a3.49,3.49,0,0,1,.28-1.44A1.74,1.74,0,0,1,39,32.16c1.69,0,3.38,0,5.08,0,5.81,0,11.62,0,17.44,0,1.76,0,2.45,1,2.44,2.52,0,6.75,0,13.49,0,20.24,0,4.08,0,8.16,0,12.23a4.76,4.76,0,0,1-.2,1.41A1.73,1.73,0,0,1,62,69.77c-1,0-2,0-2.93,0H39.39c-1.7,0-2.45-.75-2.45-2.46Zm26.47,0V34.67c0-1.43-.46-1.89-1.86-1.89H39.37c-1.38,0-1.89.5-1.89,1.88v32.6c0,1.4.47,1.86,1.89,1.86H58.89c1,0,2,0,3,0a1.34,1.34,0,0,0,1.46-1.4c0-.24,0-.48,0-.72Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M70.48,61.92h1.85a1.27,1.27,0,0,0,1.42-1.21,4.64,4.64,0,0,0,0-.65V44.37a4.79,4.79,0,0,0,0-.65,1.16,1.16,0,0,0-1.2-1.12c-.23,0-.46,0-.87,0,.58-.65,1-1.19,1.81-1.19q10.38,0,20.76,0c.82,0,1.16.54,1.59,1.15-.24,0-.49,0-.73,0a1.24,1.24,0,0,0-1.25,1.24c0,.17,0,.34,0,.52q0,7.83,0,15.68c0,1.56.31,1.88,1.85,1.88h1.47a5.32,5.32,0,0,1-4.21,5.68c-.35,0-.69,0-1,0-5.38,0-10.76,0-16.14,0C72.37,67.69,70.36,64.54,70.48,61.92ZM83.74,48h8.19a.92.92,0,0,0,.93-1.35c-.22-.45-.62-.48-1-.48H75.63c-.67,0-1,.33-1,.9S75,48,75.68,48Zm0,15.68h7.67c.26,0,.53,0,.78,0a.81.81,0,0,0,.8-.92.8.8,0,0,0-.79-.86H75.38a.87.87,0,0,0-.72,1.2c.17.49.56.59,1,.59Zm.06-3.13h8.19c.67,0,1-.34,1-.92s-.34-.85-1-.88H75.85a4.43,4.43,0,0,0-.52,0,.79.79,0,0,0-.72.86.81.81,0,0,0,.69.89,3.43,3.43,0,0,0,.64,0Zm0-6.29H92c.43,0,.83-.16.86-.6a1.58,1.58,0,0,0-.33-1c-.12-.16-.52-.16-.79-.16h-16a2.55,2.55,0,0,0-.39,0,.87.87,0,0,0-.72,1.2c.17.5.57.58,1,.58Zm0,3.16h8.13c.74,0,1.13-.43,1-1.09s-.51-.74-1-.74H75.64c-.13,0-.31,0-.38,0-.24.27-.61.56-.62.86s.33.63.59.87.41.06.63.06Zm0-8.09H81.54c-2,0-4,0-6,0-.47,0-.86.19-.9.66a1.34,1.34,0,0,0,.33,1c.16.17.56.17.85.17H92.16a.82.82,0,0,0,.79-.93c0-.57-.36-.85-1.07-.85Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M99.08,52.25v8c0,.9-.12,1-1,1s-1.91,0-2.86,0c-.57,0-.83-.27-.83-.84l0-16.33c0-.7.19-.88.89-.89H98c1,0,1.06.1,1.06,1Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M68.49,52.3V44.5c0-1.16.13-1.29,1.25-1.29H72c1.1,0,1.21.11,1.21,1.18V60.26c0,.86-.15,1-1,1s-1.86,0-2.8,0c-.78,0-1-.17-1-1Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M83.7,40.8H73.42c-.59,0-1.14,0-1.45-.64a2,2,0,0,1,0-2,1.2,1.2,0,0,1,1.16-.58H94.09A1.62,1.62,0,0,1,95.62,40a1.42,1.42,0,0,1-1.45.86C90.68,40.79,87.19,40.8,83.7,40.8Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M4.61,61.92c.62,0,1.24,0,1.86,0a1.21,1.21,0,0,0,1.38-1.29c0-.57,0-1.13,0-1.69q0-7.23,0-14.45a4.9,4.9,0,0,0,0-.77A1.16,1.16,0,0,0,6.58,42.6H6a1.65,1.65,0,0,1,1.47-1.18H28.53c.74,0,1,.62,1.42,1.1l-.62,0A1.29,1.29,0,0,0,28,43.89c0,.15,0,.3,0,.45V60.15c0,1.41.32,1.73,1.72,1.73h1.6a5.3,5.3,0,0,1-4.38,5.69c-.84.06-1.69,0-2.54,0-4.83,0-9.67-.06-14.51,0A5.37,5.37,0,0,1,4.61,61.92ZM17.86,48h8.26c.43,0,.83-.14.89-.58a1.36,1.36,0,0,0-.24-1,1.44,1.44,0,0,0-.95-.24H10c-.22,0-.51-.06-.63.05a1.91,1.91,0,0,0-.6.88.71.71,0,0,0,.62.85A2.81,2.81,0,0,0,10,48Zm0,13.88H14.51c-1.61,0-3.21,0-4.81,0-.75,0-1.17.53-1,1.18s.6.61,1.09.61H26a2.55,2.55,0,0,0,.39,0,.82.82,0,0,0,.73-.92c0-.59-.36-.86-1.12-.86Zm0-10.75h7.87a4.72,4.72,0,0,0,.65,0,.77.77,0,0,0,.7-.86.78.78,0,0,0-.7-.88,3.69,3.69,0,0,0-.58,0H10c-.22,0-.51-.06-.64.06a1.8,1.8,0,0,0-.63.85.72.72,0,0,0,.68.86,4.57,4.57,0,0,0,.64,0Zm0,9.42h7.87a3.49,3.49,0,0,0,.65,0,.79.79,0,0,0,.65-.91.77.77,0,0,0-.68-.82,3.76,3.76,0,0,0-.58,0H9.49a.81.81,0,0,0-.78.93c0,.57.36.86,1.07.86Zm0-5H9.81c-.79,0-1.16.35-1.1,1s.39.82,1.17.82h16a2.54,2.54,0,0,0,.39,0,.84.84,0,0,0,.81-1c0-.58-.35-.84-1.07-.84Zm0-1.33h7.34c.35,0,.7,0,1,0a.78.78,0,0,0,.79-.85.81.81,0,0,0-.8-.92H10a3.39,3.39,0,0,0-.45,0,.8.8,0,0,0-.79.92c0,.57.35.85,1.06.85Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M7.3,52.27v8c0,.87-.12,1-1,1H3.53c-.71,0-.91-.18-.91-.86,0-5.45,0-10.89,0-16.33,0-.58.24-.88.86-.87,1,0,1.91,0,2.86,0s1,.16,1,1c0,2.68,0,5.37,0,8.06Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M33.21,52.24v7.94c0,.94-.15,1.08-1.1,1.09H29.44c-.76,0-.94-.17-.94-.91V44.08c0-.71.17-.86.9-.87h2.93c.72,0,.87.16.88.89Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M17.82,37.54c3.47,0,6.94,0,10.41,0a1.64,1.64,0,1,1,0,3.28c-6.89,0-13.79,0-20.68,0-1.47,0-1.91-1.13-1.61-2.31.17-.67.68-1,1.5-1Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M63.41,50.93V67c0,.24,0,.48,0,.72a1.34,1.34,0,0,1-1.46,1.4c-1,0-2,0-3,0H39.37c-1.42,0-1.89-.46-1.89-1.86V34.66c0-1.38.51-1.88,1.89-1.88H61.55c1.4,0,1.86.46,1.86,1.89Zm-7-5.48a1.47,1.47,0,0,0-1.71.77c-.25.37-.7.71-.73,1.1-.1,1.16-.26,2.39.9,3.24a2.27,2.27,0,0,1,.49.76,2.78,2.78,0,0,0,3.13,1.52,3.25,3.25,0,0,0,2.33-3.1,3.35,3.35,0,0,0-2.15-3.43c-.22-.07-.52-.12-.63-.28A1.46,1.46,0,0,0,56.37,45.45ZM45.89,50.88l.19-.07a9.16,9.16,0,0,0-.43-1,9.13,9.13,0,0,0-.62-.91c-.28-.41-2.07-.92-2.42-.6-.72.65-1.75,1.09-1.68,2.36a4.69,4.69,0,0,1-.3,1.45,4.05,4.05,0,0,0,.12,2.55,3,3,0,0,0,2.67,2A3,3,0,0,0,46,54.78a3.81,3.81,0,0,0,.08-2.91A8.36,8.36,0,0,1,45.89,50.88Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M83.74,48H75.68c-.72,0-1.09-.33-1.08-.92s.36-.9,1-.9H91.82c.42,0,.82,0,1,.48A.92.92,0,0,1,91.93,48Zm0-.33h7.87c.2,0,.43.06.58,0s.45-.36.44-.52-.26-.44-.46-.59-.33,0-.5,0H75.88c-.17,0-.39,0-.51,0s-.48.37-.48.56.28.43.49.56.38,0,.58,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M83.69,63.65h-8c-.47,0-.86-.1-1-.59a.87.87,0,0,1,.72-1.2H92.15a.8.8,0,0,1,.79.86.81.81,0,0,1-.8.92c-.25,0-.52,0-.78,0Zm.14-1.51h-8c-.46,0-1,0-1,.61s.5.6.95.6H91.63a1.22,1.22,0,0,0,.58,0c.19-.12.46-.36.45-.52a1.06,1.06,0,0,0-.45-.61c-.13-.09-.38,0-.57,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M83.75,60.52H75.94a3.43,3.43,0,0,1-.64,0,.81.81,0,0,1-.69-.89.79.79,0,0,1,.72-.86,4.43,4.43,0,0,1,.52,0H92c.64,0,1,.33,1,.88s-.34.91-1,.92H83.75Zm0-.33h7a5.59,5.59,0,0,0,1.23,0,.93.93,0,0,0,.64-.53c.11-.43-.25-.58-.64-.61H76a1.63,1.63,0,0,0-.7.07c-.17.09-.38.35-.36.52a.87.87,0,0,0,.43.54c.14.09.38,0,.57,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M83.76,54.23H75.7c-.47,0-.87-.08-1-.58a.87.87,0,0,1,.72-1.2,2.55,2.55,0,0,1,.39,0h16c.27,0,.67,0,.79.16a1.58,1.58,0,0,1,.33,1c0,.44-.43.61-.86.6H83.76Zm0-.3h7.81c.19,0,.43.06.57,0s.47-.39.46-.58-.29-.4-.49-.54-.3,0-.45,0H75.84c-.15,0-.34,0-.45,0s-.47.34-.5.55.23.43.42.55a1.3,1.3,0,0,0,.63,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M83.79,57.39H75.86c-.22,0-.51.06-.63-.06s-.59-.58-.59-.87.38-.59.62-.86c.07-.07.25,0,.38,0H91.89c.54,0,.94.16,1,.74s-.28,1.08-1,1.09H83.79Zm0-1.5H75.91c-.2,0-.43-.06-.58,0s-.45.36-.44.53.26.43.46.58.33,0,.51,0H91.71c.18,0,.39,0,.51,0s.45-.36.44-.53a1,1,0,0,0-.45-.58c-.14-.09-.38,0-.58,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M83.82,49.3h8.06c.71,0,1.06.28,1.07.85a.82.82,0,0,1-.79.93H75.84c-.29,0-.69,0-.85-.17a1.34,1.34,0,0,1-.33-1c0-.47.43-.66.9-.66,2,0,4,0,6,0Zm0,.3H77a9,9,0,0,0-1.56,0,.79.79,0,0,0-.54.55c0,.21.32.44.54.59s.34,0,.51,0H91.63c.2,0,.44.06.57,0a1,1,0,0,0,.46-.6c0-.17-.25-.41-.45-.53s-.38,0-.57,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M17.86,48H10a2.81,2.81,0,0,1-.65,0,.71.71,0,0,1-.62-.85,1.91,1.91,0,0,1,.6-.88c.12-.11.41-.05.63-.05H25.82a1.44,1.44,0,0,1,.95.24,1.36,1.36,0,0,1,.24,1c-.06.44-.46.58-.89.58H17.86Zm0-1.5H10.08c-.19,0-.43-.06-.57,0s-.49.35-.49.55.26.42.46.56.34,0,.51,0H25.8a1.23,1.23,0,0,0,.58,0c.17-.1.39-.33.39-.49a.88.88,0,0,0-.37-.59,1.33,1.33,0,0,0-.64-.05Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M17.82,61.85H26c.76,0,1.11.27,1.12.86a.82.82,0,0,1-.73.92,2.55,2.55,0,0,1-.39,0H9.83c-.49,0-.92-.09-1.09-.61s.21-1.18,1-1.18c1.6,0,3.2,0,4.81,0Zm0,1.5h7.87a1.49,1.49,0,0,0,.64,0c.2-.11.45-.37.43-.54a1,1,0,0,0-.45-.59c-.14-.09-.38,0-.58,0H10.06a1.34,1.34,0,0,0-.64.06c-.19.12-.41.37-.4.56s.23.43.43.54a1.44,1.44,0,0,0,.63.05Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M17.85,51.1h-7.8a4.57,4.57,0,0,1-.64,0,.72.72,0,0,1-.68-.86,1.8,1.8,0,0,1,.63-.85c.13-.12.42,0,.64-.06H25.79a3.69,3.69,0,0,1,.58,0,.78.78,0,0,1,.7.88.77.77,0,0,1-.7.86,4.72,4.72,0,0,1-.65,0Zm0-1.5H11.51a15.67,15.67,0,0,0-1.94,0,.78.78,0,0,0-.55.55c0,.2.31.44.53.59s.34,0,.51,0c5.23,0,10.45,0,15.68,0a1.3,1.3,0,0,0,.63,0,.91.91,0,0,0,.4-.57.74.74,0,0,0-.38-.52,1.31,1.31,0,0,0-.63-.06Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M17.9,60.52H9.78c-.71,0-1.06-.29-1.07-.86a.81.81,0,0,1,.78-.93H25.81a3.76,3.76,0,0,1,.58,0,.77.77,0,0,1,.68.82.79.79,0,0,1-.65.91,3.49,3.49,0,0,1-.65,0Zm0-.33h6.64a11.75,11.75,0,0,0,1.68,0,.81.81,0,0,0,.55-.54c0-.2-.31-.43-.52-.58s-.34,0-.51,0H10.05a2.76,2.76,0,0,0-.64,0,.53.53,0,0,0-.32.86,1.19,1.19,0,0,0,.74.26Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M17.94,55.56H26c.72,0,1,.26,1.07.84a.84.84,0,0,1-.81,1,2.54,2.54,0,0,1-.39,0h-16c-.78,0-1.12-.24-1.17-.82s.31-1,1.1-1Zm0,1.5h7.81c.19,0,.43.06.57,0s.47-.37.47-.56-.27-.42-.47-.56-.34,0-.51,0H10c-.21,0-.48-.06-.64,0a.9.9,0,0,0-.38.58c0,.17.21.41.39.51a1.49,1.49,0,0,0,.64,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M17.9,54.23H9.77c-.71,0-1-.28-1.06-.85a.8.8,0,0,1,.79-.92,3.39,3.39,0,0,1,.45,0H26.27a.81.81,0,0,1,.8.92.78.78,0,0,1-.79.85c-.34,0-.69,0-1,0Zm0-1.47H12.6c-1,0-2,0-3,0-.22,0-.42.32-.62.5a6.88,6.88,0,0,0,.59.62c.06.06.21,0,.32,0h16c.42,0,.89,0,.9-.57s-.49-.6-.93-.6Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M56.37,45.45A1.46,1.46,0,0,1,58,46c.11.16.41.21.63.28a3.35,3.35,0,0,1,2.15,3.43,3.25,3.25,0,0,1-2.33,3.1,2.78,2.78,0,0,1-3.13-1.52,2.27,2.27,0,0,0-.49-.76c-1.16-.85-1-2.08-.9-3.24,0-.39.48-.73.73-1.1A1.47,1.47,0,0,1,56.37,45.45Zm-2.18,4c.24-.82,1.2-.66,1.69-1.26l-1.69-.84v2.1c.28.3.59.56.84.88a1.45,1.45,0,0,0,1.06.5c1.28.23,1.85-.61,2.43-1.43l-1.83-.83.66,2-.2.07-.76-1.93-.75,1.94-.22-.07.68-2Zm1.42,1.6a2.41,2.41,0,0,0,2.77,1.48,3.15,3.15,0,0,0,.4-5.79c.07,1.05.45,2.11-.23,3.13A2.39,2.39,0,0,1,55.61,51.07Zm.79-3.22.73-2.07H55.65Zm-1-2L54.3,47.09l1.83.87ZM56.66,48l1.82-.88-1.06-1.25Zm1.93-.58-1.84.87,1.84.87Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              d="M45.89,50.88a8.36,8.36,0,0,0,.23,1A3.81,3.81,0,0,1,46,54.78a3,3,0,0,1-2.62,1.83,3,3,0,0,1-2.67-2,4.05,4.05,0,0,1-.12-2.55,4.69,4.69,0,0,0,.3-1.45c-.07-1.27,1-1.71,1.68-2.36.35-.32,2.14.19,2.42.6a9.13,9.13,0,0,1,.62.91,9.16,9.16,0,0,1,.43,1Zm-.38,1.73-1.84-1.1.51,2.12,1.32-1,.2-2-1.9.61a9.26,9.26,0,0,1,.84.56C45,52,45.22,52.31,45.51,52.61Zm.38-.45a3.2,3.2,0,0,1-2.45,1.9c-1.35.07-1.87-.92-2.5-1.88a3.27,3.27,0,0,0,1.15,3.69,2.36,2.36,0,0,0,2.79-.1A3.17,3.17,0,0,0,45.89,52.16Zm-2,1.51-.51-2.09-1,1.83-.21-.1.88-1.89-1.8.51-.07-.22L43,51.06,41.41,50l.14-.2,1.65,1-.53-2.12c-1.2.86-1.86,2.37-1.5,3.34A2.36,2.36,0,0,0,43.9,53.67Zm.79-4.8-1,2,1.89-.62Zm-1.21,1.82,1-2-1.51-.19Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M83.77,47.64H76c-.2,0-.43.06-.58,0s-.48-.37-.49-.56.28-.42.48-.56.34,0,.51,0H91.7c.17,0,.39-.06.5,0s.44.38.46.59-.25.41-.44.52-.38,0-.58,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M83.83,62.14h7.81c.19,0,.44,0,.57,0a1.06,1.06,0,0,1,.45.61c0,.16-.26.4-.45.52a1.22,1.22,0,0,1-.58,0H75.82c-.45,0-.95,0-.95-.6s.5-.61,1-.61Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M83.76,60.19H75.89c-.19,0-.43.05-.57,0a.87.87,0,0,1-.43-.54c0-.17.19-.43.36-.52A1.63,1.63,0,0,1,76,59H92c.39,0,.75.18.64.61a.93.93,0,0,1-.64.53,5.59,5.59,0,0,1-1.23,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M83.81,53.93H75.94a1.3,1.3,0,0,1-.63,0c-.19-.12-.44-.39-.42-.55s.3-.41.5-.55.3,0,.45,0H91.71c.15,0,.34,0,.45,0s.49.35.49.54-.25.44-.46.58-.38,0-.57,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M83.83,55.89h7.8c.2,0,.44-.06.58,0a1,1,0,0,1,.45.58c0,.17-.25.4-.44.53s-.33,0-.51,0H75.86c-.18,0-.4,0-.51,0s-.44-.37-.46-.58.25-.41.44-.53.38,0,.58,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M83.83,49.6h7.81c.19,0,.43-.05.57,0s.46.36.45.53a1,1,0,0,1-.46.6c-.13.09-.37,0-.57,0H76c-.17,0-.39.06-.51,0s-.54-.38-.54-.59a.79.79,0,0,1,.54-.55,9,9,0,0,1,1.56,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M17.89,46.47h7.87a1.33,1.33,0,0,1,.64.05.88.88,0,0,1,.37.59c0,.16-.22.39-.39.49a1.23,1.23,0,0,1-.58,0H10c-.17,0-.39.06-.51,0S9,47.24,9,47.05s.28-.43.49-.55.38,0,.57,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M17.82,63.35H10.08a1.44,1.44,0,0,1-.63-.05C9.25,63.19,9,63,9,62.76s.21-.44.4-.56a1.34,1.34,0,0,1,.64-.06H25.73c.2,0,.44,0,.58,0a1,1,0,0,1,.45.59c0,.17-.23.43-.43.54a1.49,1.49,0,0,1-.64,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M17.89,49.6h7.87a1.31,1.31,0,0,1,.63.06.74.74,0,0,1,.38.52.91.91,0,0,1-.4.57,1.3,1.3,0,0,1-.63,0c-5.23,0-10.45,0-15.68,0-.17,0-.39.06-.51,0S9,50.39,9,50.19a.78.78,0,0,1,.55-.55,15.67,15.67,0,0,1,1.94,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M17.89,60.19H9.83a1.19,1.19,0,0,1-.74-.26.53.53,0,0,1,.32-.86,2.76,2.76,0,0,1,.64,0H25.73c.17,0,.39,0,.51,0s.52.38.52.58a.81.81,0,0,1-.55.54,11.75,11.75,0,0,1-1.68,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M17.91,57.06H10a1.49,1.49,0,0,1-.64,0c-.18-.1-.4-.34-.39-.51a.9.9,0,0,1,.38-.58c.16-.1.43,0,.64,0H25.78c.17,0,.39-.06.51,0s.47.37.47.56-.26.43-.47.56-.38,0-.57,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M17.93,52.76h7.93c.44,0,.94,0,.93.6s-.48.57-.9.57H9.9c-.11,0-.26,0-.32,0A6.88,6.88,0,0,1,9,53.29c.2-.18.4-.49.62-.5,1-.06,2,0,3,0Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M55.61,51.07a2.39,2.39,0,0,0,2.94-1.18c.68-1,.3-2.08.23-3.13a3.15,3.15,0,0,1-.4,5.79A2.41,2.41,0,0,1,55.61,51.07Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M54.19,49.48l1.91-.9-.68,2,.22.07.75-1.94.76,1.93.2-.07-.66-2,1.83.83c-.58.82-1.15,1.66-2.43,1.43a1.45,1.45,0,0,1-1.06-.5c-.25-.32-.56-.58-.84-.88Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M54.19,49.47v-2.1l1.69.84c-.49.6-1.45.44-1.69,1.27Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M56.4,47.85l-.75-2.07h1.48Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M55.38,45.86l.75,2.1-1.83-.87Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M56.66,48l.76-2.13,1.06,1.25Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M58.59,47.39v1.74l-1.84-.87Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M45.89,52.16a3.17,3.17,0,0,1-1,3.61,2.36,2.36,0,0,1-2.79.1,3.27,3.27,0,0,1-1.15-3.69c.63,1,1.15,2,2.5,1.88A3.2,3.2,0,0,0,45.89,52.16Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M43.9,53.67A2.36,2.36,0,0,1,41.17,52c-.36-1,.3-2.48,1.5-3.34l.53,2.12-1.65-1-.14.2L43,51.06l-1.76.65.07.22,1.8-.51-.88,1.89.21.1,1-1.83Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M45.51,52.6l-1.33,1-.51-2.12,1.84,1.1Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M45.51,52.61c-.29-.3-.56-.61-.87-.88a9.26,9.26,0,0,0-.84-.56l1.9-.61c-.07.73-.13,1.38-.19,2Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M44.69,48.87l.93,1.38-1.89.62Z"
              transform="translate(-2.07 -32.15)"
            />
            <path
              class="cls-1"
              d="M43.48,50.69l-.54-2.15,1.51.19Z"
              transform="translate(-2.07 -32.15)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
