import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ContentTitle,
  ContentContainer,
  FormLabel,
  FormBlock,
  FormNumberInput,
  FormSubmitButton,
  CancelButton,
} from "../style/export";
import { browserDownload, browserUpload } from "../../utils/browser";
import State  from "../viewer2d/state";

export default class ConfigurationModal extends Component {
  constructor(props, context) {
    super(props, context);

    let scene = props.state.scene;
     
    this.state = {
      dataWidth: scene.width,
      dataHeight: scene.height,
      errorMessage: null,
      settings : [
        { title: "Save to disk", onclick: () => {
          browserDownload(scene);
          this.props.close();
        } },
        { title: "Open from disk", onclick: () => {
          browserUpload().then((data) => {
            this.context.projectActions.loadProject(JSON.parse(data));
            this.props.close();
          });
        } },
      ]
    };
  }

  onSubmit(event) {
    event.preventDefault();

    let { projectActions } = this.context;
    let { close } = this.props;
    let { dataWidth, dataHeight } = this.state;
    dataWidth = parseInt(dataWidth);
    dataHeight = parseInt(dataHeight);
    if (dataWidth <= 100 || dataHeight <= 100) {
      this.setState({ ...this.state, errorMessage: "Scene size too small" });
    } else {
      projectActions.setProjectProperties({
        width: dataWidth,
        height: dataHeight,
      });
      close;
    }
  }

  render() {
    let { dataWidth, dataHeight } = this.state;
    let { projectActions } = this.context;

    return (
      <ContentContainer width={"250px"}>
        <ContentTitle>{"Settings"}</ContentTitle>
        {/* <h3>Canvas Size</h3>
        <form onSubmit={(e) => this.onSubmit(e)}>
          <FormBlock>
            <FormLabel htmlFor="width">width</FormLabel>
            <FormNumberInput
              id="width"
              placeholder="width"
              value={dataWidth}
              onChange={(e) => this.setState({ dataWidth: e.target.value })}
            />
          </FormBlock>

          <FormBlock>
            <FormLabel htmlFor="height">height</FormLabel>
            <FormNumberInput
              id="height"
              placeholder="height"
              value={dataHeight}
              onChange={(e) => this.setState({ dataHeight: e.target.value })}
            />
          </FormBlock>

          <table style={{ float: "right" }}>
            <tbody>
              {this.state.errorMessage && <tr><td><span color="red">{this.state.errorMessage}</span></td></tr>}
              <tr>
                <td>
                  <CancelButton
                    size="large"
                    onClick={(e) => projectActions.rollback()}
                  >
                    Cancel
                  </CancelButton>
                </td>
                <td>
                  <FormSubmitButton size="large">
                    Save
                  </FormSubmitButton>
                </td>
              </tr>
            </tbody>
          </table>
        </form> */}
        <div style={{ paddingTop: "10px" }}>
          {this.state.settings.map(({title, onclick})=><SettingList key={title} title={title} onclick={()=>{onclick()}}/>)}
         
        </div>
      </ContentContainer>
    );
  }
}

const SettingList = ({ title, onclick }) => {
  return <div className="setting-list" style={{ padding: "10px 0px", marginBottom:"3px", cursor:"pointer" }} onClick={onclick}>{title}</div>;
};

ConfigurationModal.propTypes = {
  state: PropTypes.object.isRequired,

};

ConfigurationModal.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,

};
