import React, { Component } from "react";
import PropTypes from "prop-types";
import * as SharedStyle from "../../shared-style";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const STYLE = {
  borderTop: "1px solid black",
  borderBottom: "1px solid black",
  userSelect: "none",
};
const STYLE_TITLE = {
  fontSize: "13px",
  color: SharedStyle.COLORS.black,
  padding: "5px 15px 8px 15px",
  backgroundColor: SharedStyle.COLORS.white,
  // textShadow: '-1px -1px 2px rgba(0, 0, 0, 1)',
  // boxShadow: 'inset 0px -3px 19px 0px rgba(0,0,0,0.5)',
  margin: "0px",
  cursor: "pointer",
};
const STYLE_CONTENT = {
  fontSize: "13px",
  color: SharedStyle.COLORS.black,
  // border: "1px solid #222",
  padding: "0px",
  backgroundColor: SharedStyle.PRIMARY_COLOR.alt,
  // textShadow: '-1px -1px 2px rgba(0, 0, 0, 1)'
  transition: "height 0.3s ease-in-out", // Smooth transition on height change
  overflow: "hidden", // Ensure content doesn't overflow when closed
};
const STYLE_ARROW = {
  float: "right",
};

export default class AreaPanel extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      opened: props.hasOwnProperty("opened") ? props.opened : false,
      hover: false,
    };
  }

  toggleOpen() {
    this.setState({ opened: !this.state.opened });
  }

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  render() {
    let { name, headComponents, children, style, headingStyles, onclickcb } = this.props;
    let { opened, hover } = this.state;
    let additionalStyles = this.props.hasOwnProperty("style") ? style : {};
    let additionalHeadingStyles = this.props.hasOwnProperty("headingStyles")
      ? headingStyles
      : {};
    return (
      <div style={{ ...STYLE, ...additionalStyles }}>
        <h3
          style={{
            ...STYLE_TITLE,
            color: SharedStyle.COLORS.black,
            ...additionalHeadingStyles,
          }}
          onMouseEnter={() => this.toggleHover()}
          onMouseLeave={() => this.toggleHover()}
          onClick={() => this.toggleOpen()}
        >
          {name}
          {headComponents}
          {opened ? (
            <FaAngleUp style={STYLE_ARROW} />
          ) : (
            <FaAngleDown style={STYLE_ARROW} />
          )}
        </h3>

        <div
          style={{
            ...STYLE_CONTENT,
            ...additionalStyles,
            display: opened ? "block" : "none",
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

AreaPanel.propTypes = {
  name: PropTypes.string.isRequired,
  headComponents: PropTypes.array,
  opened: PropTypes.bool,
};
