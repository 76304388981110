import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "rectangular-block",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "rectangular block",
    description: "rectangular-block",
    image: require("./rectangular-block.svg"),
    svgIcon: require("./rectangular-block.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 950,
      defaultValue: 200,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 950,
      defaultValue: 200,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-25, 55) scale(1, -1)`}>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
          <path d="M20.35,95.69V4.31H75.09V95.69Z" transform="translate(-20.35 -4.31)"/>
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
