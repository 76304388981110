import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Square-Boiler",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Square Boiler",
    description: "Square-Boiler",
    image: require("./Square-Boiler.svg"),
    svgIcon: require("./Square-Boiler.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g id={`${element.type}-${element.id}`} transform={`translate(-47, 55) scale(1,-1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            class="cls-1"
            d="M94.26,5.22H5.74V95.3H94.26Z"
            transform="translate(-5.74 -5.22)"
          />
          <path
            d="M94.26,5.22V95.3H5.74V5.22ZM87,90.82l.31-.54L49.43,52.9l-37,37.92Zm.28-80.56-.34-.44h-74l-.22.45L49.88,47Zm2,3.16c-12.14,12-24.7,24.31-37.5,36.89l37.5,37Zm-41,36.82L10.7,13.12v74Z"
            transform="translate(-5.74 -5.22)"
          />
          <path
            class="cls-1"
            d="M87,90.82H12.47l37-37.92L87.31,90.28Z"
            transform="translate(-5.74 -5.22)"
          />
          <path
            class="cls-1"
            d="M87.28,10.26,49.88,47,12.7,10.27l.22-.45h74Z"
            transform="translate(-5.74 -5.22)"
          />
          <path
            class="cls-1"
            d="M89.31,13.42v73.9l-37.5-37C64.61,37.73,77.17,25.37,89.31,13.42Z"
            transform="translate(-5.74 -5.22)"
          />
          <path
            class="cls-1"
            d="M48.29,50.24,10.7,87.15v-74Z"
            transform="translate(-5.74 -5.22)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
