import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Sink",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Sink",
    description: "Sink",
    image: require("./Sink.svg"),
    svgIcon: require("./Sink.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M49.29,2Q71.94,2,94.58,2a3.27,3.27,0,0,1,2.2.93c1.53,1.22,2,2.91,2.64,4.57a4.61,4.61,0,0,1,.26,1.61q0,39.07,0,78.14a9,9,0,0,1-1.88,5.44c-.14.2-.34.37-.46.58-1.63,2.94-4.6,3.43-7.4,3.45-16.34.13-32.68.06-49,.06q-15.18,0-30.36,0A25.43,25.43,0,0,1,5,96a6,6,0,0,1-4.23-3,3.79,3.79,0,0,1-.55-1.74q0-42.91,0-85.85a1.91,1.91,0,0,1,.88-1.9.49.49,0,0,0,.18-.17C2.12,2,3.37,2,4.75,2Q27,2,49.29,2Zm.13,2.92c-13.77,0-27.54,0-41.31-.05-2.32,0-5.2,2.67-5.19,5.22q.06,38.11,0,76.24A5.4,5.4,0,0,0,4.1,89.9c.31.38.59.95,1,1.06a18.15,18.15,0,0,0,4.59,1q39.78.08,79.56,0a10.2,10.2,0,0,0,3-.51A4.73,4.73,0,0,0,95.39,87q0-38.35,0-76.73a7.27,7.27,0,0,0-.58-2.62c-.2-.52-.78-.89-1.14-1.37a3.3,3.3,0,0,0-2.83-1.39Q70.12,4.93,49.42,4.9Z"
              transform="translate(-0.22 -1.96)"
            />
            <path
              class="cls-1"
              d="M49.42,4.9q20.7,0,41.4,0a3.3,3.3,0,0,1,2.83,1.39c.36.48.94.85,1.14,1.37a7.27,7.27,0,0,1,.58,2.62q0,38.37,0,76.73a4.73,4.73,0,0,1-3.17,4.43,10.2,10.2,0,0,1-3,.51q-39.78,0-79.56,0a18.15,18.15,0,0,1-4.59-1c-.39-.11-.67-.68-1-1.06a5.4,5.4,0,0,1-1.18-3.59q.06-38.12,0-76.24c0-2.55,2.87-5.23,5.19-5.22C21.88,4.94,35.65,4.9,49.42,4.9ZM43.21,27.75H10.84A6,6,0,0,0,6.72,29.6c-1.41,1.33-1.6,3.23-1.6,5Q5,57.1,5.1,79.62a17.29,17.29,0,0,0,.37,5.49,6.34,6.34,0,0,0,1.64,3.08,6.39,6.39,0,0,0,4.55,1.72q35.25.06,70.51,0c1.9,0,3.81.31,5.72-.38,1.06-.38,2.28-.2,2.79-1.57.12-.34.75-.51.83-.84a18.39,18.39,0,0,0,.89-4.4q.09-23.94,0-47.87a13.18,13.18,0,0,0-.62-3.72A4.79,4.79,0,0,0,86.9,27.8c-10.4-.1-20.8-.05-31.19-.05-.35,0-.69,0-1.1-.05V26.62c0-4.81,0-9.62,0-14.43,0-.68-.17-.91-.88-.9-3,0-5.91,0-8.87,0-.24,0-.48,0-.71,0l-.27,1c-.43-.6-.74-1.11-1.12-1.57A4.38,4.38,0,0,0,36,10.43a8.6,8.6,0,0,0-.07,11,4.37,4.37,0,0,0,6.72,0l.6-.63ZM56.34,15.64a7.88,7.88,0,0,0,1.53,4.89,4.51,4.51,0,0,0,7.53,0,8.54,8.54,0,0,0,.07-9.72,4.53,4.53,0,0,0-7.73.11A7.89,7.89,0,0,0,56.34,15.64Z"
              transform="translate(-0.22 -1.96)"
            />
            <path
              d="M43.21,27.75v-7l-.6.63a4.37,4.37,0,0,1-6.72,0,8.6,8.6,0,0,1,.07-11,4.38,4.38,0,0,1,6.81.32c.38.46.69,1,1.12,1.57l.27-1c.23,0,.47,0,.71,0,3,0,5.92,0,8.87,0,.71,0,.89.22.88.9,0,4.81,0,9.62,0,14.43V27.7c.41,0,.75.05,1.1.05,10.39,0,20.79-.05,31.19.05a4.79,4.79,0,0,1,4.89,3.33,13.18,13.18,0,0,1,.62,3.72q.06,23.94,0,47.87a18.39,18.39,0,0,1-.89,4.4c-.08.33-.71.5-.83.84-.51,1.37-1.73,1.19-2.79,1.57-1.91.69-3.82.38-5.72.38q-35.27.06-70.51,0a6.39,6.39,0,0,1-4.55-1.72,6.34,6.34,0,0,1-1.64-3.08,17.29,17.29,0,0,1-.37-5.49Q5,57.09,5.12,34.57c0-1.74.19-3.64,1.6-5a6,6,0,0,1,4.12-1.84H43.21Zm0,1.86c-.32,0-.5-.06-.69-.06-10.62,0-21.24,0-31.86,0a3.53,3.53,0,0,0-3.4,2.11,8.87,8.87,0,0,0-.63,3.13q0,23.94,0,47.87a15.55,15.55,0,0,0,.64,3.19,1.09,1.09,0,0,0,.27.41,5.65,5.65,0,0,0,4.54,1.82c19.22,0,38.44.08,57.66,0,6,0,12,.51,17.92-.35a3.21,3.21,0,0,0,2.45-1.93,7.15,7.15,0,0,0,.66-3.33q.06-23.73,0-47.45a18.18,18.18,0,0,0-.58-3.4,1.41,1.41,0,0,0-.33-.56,4.11,4.11,0,0,0-3.33-1.52q-15.51,0-31,0c-.29,0-.58,0-1,.05V40.27H43.26Zm1.57-3.78c0,4,0,7.9,0,11.85,0,.63.13.88.82.87,2.21,0,4.42,0,6.63,0,.56,0,.78-.13.78-.74q0-12,0-23.95c0-.59-.21-.76-.77-.75-2.16,0-4.32,0-6.47,0-.75,0-1,.19-1,1C44.85,18,44.83,21.91,44.83,25.83ZM36.05,16a5.65,5.65,0,0,0,1.22,3.61,2.42,2.42,0,0,0,3.93,0,6.06,6.06,0,0,0-.1-7.37,2.38,2.38,0,0,0-3.7,0A5.55,5.55,0,0,0,36.05,16Z"
              transform="translate(-0.22 -1.96)"
            />
            <path
              d="M56.34,15.64A7.89,7.89,0,0,1,57.74,11a4.53,4.53,0,0,1,7.73-.11,8.54,8.54,0,0,1-.07,9.72,4.51,4.51,0,0,1-7.53,0A7.88,7.88,0,0,1,56.34,15.64Zm2.13,0a5.26,5.26,0,0,0,1.2,3.53,2.47,2.47,0,0,0,3.89.06,5.84,5.84,0,0,0,0-7.18,2.48,2.48,0,0,0-4,.12A5.37,5.37,0,0,0,58.47,15.67Z"
              transform="translate(-0.22 -1.96)"
            />
            <path
              class="cls-1"
              d="M43.26,29.61V40.27H54.58V29.6c.4,0,.69-.05,1-.05q15.51,0,31,0a4.11,4.11,0,0,1,3.33,1.52,1.41,1.41,0,0,1,.33.56,18.18,18.18,0,0,1,.58,3.4q.06,23.72,0,47.45a7.15,7.15,0,0,1-.66,3.33,3.21,3.21,0,0,1-2.45,1.93c-6,.86-12,.32-17.92.35-19.22.1-38.44,0-57.66,0A5.65,5.65,0,0,1,7.6,86.3a1.09,1.09,0,0,1-.27-.41,15.55,15.55,0,0,1-.64-3.19q-.06-23.94,0-47.87a8.87,8.87,0,0,1,.63-3.13,3.53,3.53,0,0,1,3.4-2.11c10.62-.08,21.24-.05,31.86,0C42.76,29.55,42.94,29.58,43.26,29.61Z"
              transform="translate(-0.22 -1.96)"
            />
            <path
              class="cls-1"
              d="M44.83,25.83c0-3.92,0-7.85,0-11.77,0-.78.24-1,1-1,2.15.05,4.31,0,6.47,0,.56,0,.77.16.77.75q0,12,0,23.95c0,.61-.22.75-.78.74-2.21,0-4.42,0-6.63,0-.69,0-.83-.24-.82-.87C44.84,33.73,44.83,29.78,44.83,25.83Z"
              transform="translate(-0.22 -1.96)"
            />
            <path
              class="cls-1"
              d="M36.05,16A5.55,5.55,0,0,1,37.4,12.2a2.38,2.38,0,0,1,3.7,0,6.06,6.06,0,0,1,.1,7.37,2.42,2.42,0,0,1-3.93,0A5.65,5.65,0,0,1,36.05,16Z"
              transform="translate(-0.22 -1.96)"
            />
            <path
              class="cls-1"
              d="M58.47,15.67a5.37,5.37,0,0,1,1.12-3.47,2.48,2.48,0,0,1,4-.12,5.84,5.84,0,0,1,0,7.18,2.47,2.47,0,0,1-3.89-.06A5.26,5.26,0,0,1,58.47,15.67Z"
              transform="translate(-0.22 -1.96)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
