import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Dryer",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Dryer",
    description: "Dryer",
    image: require("./Dryer.svg"),
    svgIcon: require("./Dryer.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g id={`${element.type}-${element.id}`} transform={`translate(-47, 55) scale(1,-1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M50.06,3.39q21.73,0,43.49,0c2.75,0,3,.3,3,3V89.91c0,2.69-.34,3-3,3-5.64,0-11.28,0-16.92,0-1.09,0-1.84.08-2.34,1.36-.74,1.93-2.5,2.44-4.47,2.43-5.64,0-11.28,0-16.92,0-7.52,0-15,0-22.57,0-2.33,0-4.11-.68-5.06-3-.17-.42-1.13-.72-1.73-.72-5.82-.06-11.64,0-17.47,0-2.23,0-2.68-.44-2.68-2.63V6.07c0-2.33.38-2.68,2.81-2.68Q28.14,3.38,50.06,3.39ZM5.68,90.68h88.6c0-.68.09-1.21.09-1.75q0-40.77,0-81.54c0-1.74-.65-1.94-2.12-1.94Q50,5.51,7.66,5.48h-2ZM27.59,93.1c.47,1.61,1.7,1.5,2.81,1.5q12.17,0,24.35,0h14.9c1.08,0,2.15,0,2.76-1.51Z"
            transform="translate(-3.4 -3.38)"
          />
          <path
            class="cls-1"
            d="M5.68,90.68V5.48h2q42.32,0,84.63,0c1.47,0,2.12.2,2.12,1.94q-.09,40.77,0,81.54c0,.54-.05,1.07-.09,1.75Zm44.2-19.6q10.56,0,21.11,0c2.41,0,2.8-.37,2.8-2.69q0-20.3,0-40.59c0-2.12-.42-2.55-2.56-2.55q-21.3,0-42.59,0c-2.06,0-2.55.51-2.55,2.59q0,20.29,0,40.59c0,2.17.48,2.65,2.68,2.65Q39.32,71.1,49.88,71.08Z"
            transform="translate(-3.4 -3.38)"
          />
          <path
            class="cls-1"
            d="M27.59,93.1H72.41c-.61,1.46-1.68,1.51-2.76,1.51H54.75q-12.18,0-24.35,0C29.29,94.6,28.06,94.71,27.59,93.1Z"
            transform="translate(-3.4 -3.38)"
          />
          <path
            d="M49.88,71.08q-10.56,0-21.11,0c-2.2,0-2.68-.48-2.68-2.65q0-20.3,0-40.59c0-2.08.49-2.59,2.55-2.59q21.28,0,42.59,0c2.14,0,2.56.43,2.56,2.55q0,20.29,0,40.59c0,2.32-.39,2.69-2.8,2.69Q60.43,71.1,49.88,71.08ZM28.46,27.43V68.84h43V27.43Z"
            transform="translate(-3.4 -3.38)"
          />
          <path
            class="cls-1"
            d="M28.46,27.43h43V68.84h-43ZM69,48.19a40,40,0,0,0-1.26-5.81C63.9,31.89,50.61,27.24,40.62,32.79,34.1,36.41,30.37,43.33,31.3,50.11A18.47,18.47,0,0,0,50.21,66C60.58,65.86,68.79,58,69,48.19Z"
            transform="translate(-3.4 -3.38)"
          />
          <path
            d="M69,48.19C68.79,58,60.58,65.86,50.21,66A18.47,18.47,0,0,1,31.3,50.11c-.93-6.78,2.8-13.7,9.32-17.32,10-5.55,23.28-.9,27.13,9.59A40,40,0,0,1,69,48.19ZM50.42,32.61a17.29,17.29,0,0,0-8.73,2.1A15.07,15.07,0,0,0,39.11,60a17.19,17.19,0,0,0,14.17,3.52c7.17-1.15,15.5-8.27,12.77-19.21C64.39,37.62,58,32.91,50.42,32.61Z"
            transform="translate(-3.4 -3.38)"
          />
          <path
            class="cls-1"
            d="M50.42,32.61c7.54.3,14,5,15.63,11.65,2.73,10.94-5.6,18.06-12.77,19.21A17.19,17.19,0,0,1,39.11,60a15.07,15.07,0,0,1,2.58-25.24A17.29,17.29,0,0,1,50.42,32.61Z"
            transform="translate(-3.4 -3.38)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
