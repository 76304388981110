import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Boiler",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Boiler",
    description: "Boiler",
    image: require("./Boiler.svg"),
    svgIcon: require("./Boiler.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g id={`${element.type}-${element.id}`} transform={`translate(-47, 55) scale(1,-1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M48,1.29c25.55,0,45.64,16,50.69,38.81C103.15,60.26,93.5,81.53,75.14,92a50.52,50.52,0,0,1-62.64-10,48,48,0,0,1-.35-63.67C22.22,7.06,35,1.62,48,1.29Zm4.08,49.49,29.64,30.4c18.61-18.1,16.46-46.6.77-61.43ZM80.35,17.4A45,45,0,0,0,47.33,6a44.5,44.5,0,0,0-28,11.68l30.4,31ZM17.56,80.54,46.85,50.67,16.94,20.17C1.16,36.14.46,63.51,17.56,80.54Zm2.35,2.21c17.18,15.76,44.6,14.44,59.59.39l-30-30.59Z"
            transform="translate(-0.23 -1.29)"
          />
          <path
            class="cls-1"
            d="M52.08,50.78l30.41-31c15.69,14.83,17.84,43.33-.77,61.43Z"
            transform="translate(-0.23 -1.29)"
          />
          <path
            class="cls-1"
            d="M80.35,17.4,49.68,48.74l-30.4-31A44.5,44.5,0,0,1,47.33,6,45,45,0,0,1,80.35,17.4Z"
            transform="translate(-0.23 -1.29)"
          />
          <path
            class="cls-1"
            d="M17.56,80.54c-17.1-17-16.4-44.4-.62-60.37l29.91,30.5Z"
            transform="translate(-0.23 -1.29)"
          />
          <path
            class="cls-1"
            d="M19.91,82.75,49.5,52.55l30,30.59C64.51,97.19,37.09,98.51,19.91,82.75Z"
            transform="translate(-0.23 -1.29)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
