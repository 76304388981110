import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Exit",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Exit",
    description: "Exit",
    image: require("./Exit.svg"),
    svgIcon: require("./Exit.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.exitcls1{fill:#009f4d;}.exitcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="exitcls1"
              d="M86.13,20.39v43H0v-43H.34q42.72,0,85.45,0ZM85,21.52a1.13,1.13,0,0,0-.15,0H1.29a.65.65,0,0,0-.13,0,.37.37,0,0,0,0,.09q0,20.18,0,40.36a1.64,1.64,0,0,0,0,.22s0,0,0,0l0,0c.42,0,83.64,0,83.86,0Z"
              transform="translate(0 -20.32)"
            />
            <path
              class="exitcls1"
              d="M86.13,20.39h-.34q-42.72,0-85.45,0H0s0-.05,0-.05H85.87C86,20.34,86.07,20.27,86.13,20.39Z"
              transform="translate(0 -20.32)"
            />
            <path
              class="exitcls2"
              d="M85,21.52V62.21c-.22.06-83.44.08-83.86,0l0,0s0,0,0,0a1.64,1.64,0,0,1,0-.22q0-20.19,0-40.36a.37.37,0,0,1,0-.09.65.65,0,0,1,.13,0h83.6A1.13,1.13,0,0,1,85,21.52ZM83.74,61V22.82s0,0-.06,0H2.49l0,0,0,0v.26q0,18.9,0,37.81l0,0,0,0Z"
              transform="translate(0 -20.32)"
            />
            <path
              class="exitcls1"
              d="M83.74,61H2.48l0,0,0,0q0-18.9,0-37.81v-.26l0,0,0,0H83.68s0,0,.06,0ZM14.21,49.14c-.09-.32-.07-4.88,0-5.11h8.57c.57,0,1.14,0,1.7,0a39.24,39.24,0,0,0,0-4.06H14.21a44.8,44.8,0,0,1,0-4.78H25.43l.44,0c.06-.32.05-3.92,0-4.16H9c-.08.27-.08,22,0,22.29.29.06,17.07,0,17.29,0,.06-.38,0-3.9,0-4.13ZM39,38.15l-.25-.33q-2.34-3.28-4.67-6.59a.53.53,0,0,0-.49-.26H28l7.84,11-1.2,1.65-1.16,1.6L32.3,46.82c-.39.53-.78,1.06-1.16,1.6L30,50l-1.16,1.6c-.39.54-.8,1.06-1.19,1.66l.3,0h5.42a.41.41,0,0,0,.4-.21l1.09-1.58,3.87-5.66c.05-.08.12-.15.19-.25.11.15.19.27.28.38,1.63,2.36,3.27,4.71,4.89,7.07a.48.48,0,0,0,.47.25h5.4l.34,0-4.17-5.77-4.16-5.76,3.88-5.37c1.29-1.79,2.59-3.56,3.91-5.41H44.39a.56.56,0,0,0-.54.28l-4.63,6.66ZM79.7,31H60.19c0,.13,0,.25,0,.38v3.41c0,.45,0,.45.46.45h6.31l.39,0v16c0,.66,0,1.33,0,2,.37.06,5,0,5.22,0V35.66c0-.13,0-.26,0-.42h6.72l.39,0ZM57.43,53.3c.07-.27.07-22,0-22.29,0,0,0,0-.08,0H52.47l-.17,0h0l0,0V53.3Z"
              transform="translate(0 -20.32)"
            />
            <path
              class="exitcls2"
              d="M14.21,49.14H26.26c.07.23.09,3.75,0,4.13-.22.06-17,.07-17.29,0C8.93,53,8.93,31.26,9,31H25.86c.06.24.07,3.84,0,4.16l-.44,0H14.22a44.8,44.8,0,0,0,0,4.78H24.48a39.24,39.24,0,0,1,0,4.06c-.56,0-1.13,0-1.7,0H14.22C14.14,44.26,14.12,48.82,14.21,49.14Z"
              transform="translate(0 -20.32)"
            />
            <path
              class="exitcls2"
              d="M39,38.15l.18-.24,4.63-6.66a.56.56,0,0,1,.54-.28h5.33c-1.32,1.85-2.62,3.62-3.91,5.41l-3.88,5.37,4.16,5.76,4.17,5.77-.34,0h-5.4a.48.48,0,0,1-.47-.25c-1.62-2.36-3.26-4.71-4.89-7.07-.09-.11-.17-.23-.28-.38-.07.1-.14.17-.19.25l-3.87,5.66-1.09,1.58a.41.41,0,0,1-.4.21H27.91l-.3,0c.39-.6.8-1.12,1.19-1.66L30,50l1.18-1.62c.38-.54.77-1.07,1.16-1.6l1.18-1.63,1.16-1.6,1.2-1.65L28,31h5.63a.53.53,0,0,1,.49.26q2.33,3.3,4.67,6.59Z"
              transform="translate(0 -20.32)"
            />
            <path
              class="exitcls2"
              d="M79.7,31v4.21l-.39,0H72.59c0,.16,0,.29,0,.42v17.6c-.24.07-4.85.08-5.22,0,0-.65,0-1.32,0-2v-16l-.39,0H60.63c-.46,0-.46,0-.46-.45V31.38c0-.13,0-.25,0-.38Z"
              transform="translate(0 -20.32)"
            />
            <path
              class="exitcls2"
              d="M57.43,53.3H52.26V31l0,0h0l.17,0h4.88s0,0,.08,0C57.5,31.25,57.5,53,57.43,53.3Z"
              transform="translate(0 -20.32)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
