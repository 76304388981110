import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Computer",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Computer",
    description: "Computer",
    image: require("./Computer.svg"),
    svgIcon: require("./Computer.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g id={`${element.type}-${element.id}`} transform={`translate(-47, 20) scale(1,-1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M28.92,39.62c.5-3.38,1.69-4.42,5.06-4.42q16,0,32,0a7.8,7.8,0,0,1,1.93.18,3.67,3.67,0,0,1,3,4.23H97.19c1.6,0,1.77.18,1.77,1.8,0,1.42,0,2.85,0,4.27A1.14,1.14,0,0,1,97.65,47H3.11C1.07,47,1,47,1,45V41.28c0-1.46.19-1.65,1.67-1.65H28.92ZM2.49,45.82H97.42v-5H2.49Zm66.9-6.26c.18-2-.49-2.9-2.26-3.18a9.13,9.13,0,0,0-1.38-.08H34.22a7.35,7.35,0,0,0-1.82.18c-1.53.39-2.13,1.45-1.77,3.08Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            d="M50.05,64.8H24.13c-1.66,0-1.79-.13-1.79-1.75,0-3,0-5.93,0-8.89,0-1.1.4-1.53,1.48-1.52H76.21c1.06,0,1.5.44,1.48,1.53,0,2.93,0,5.85,0,8.78,0,1.7-.14,1.85-1.82,1.85ZM76.18,53.85H23.84v9.73H76.18Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            d="M81.3,58.46c0-.77,0-1.54,0-2.31a4,4,0,0,1,.08-.92c.36-1.59,2.13-2.52,4.48-2.39C88,53,89.4,54,89.49,55.67c.1,1.84,0,3.69,0,5.54a1.86,1.86,0,0,1,0,.34c-.36,1.67-2.22,2.63-4.67,2.43-2.1-.18-3.38-1.27-3.47-3,0-.85,0-1.7,0-2.54Zm1.43-.09c0,.85,0,1.69,0,2.54.07,1.23,1.06,2,2.61,2s2.67-.74,2.71-2A37,37,0,0,0,88,56a2.34,2.34,0,0,0-1.24-1.68,4.49,4.49,0,0,0-2.76,0A2.7,2.7,0,0,0,82.78,56,9.13,9.13,0,0,0,82.73,58.37Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            class="cls-1"
            d="M2.49,45.82v-5H97.42v5Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            class="cls-1"
            d="M69.39,39.56H30.63c-.36-1.63.24-2.69,1.77-3.08a7.35,7.35,0,0,1,1.82-.18H65.75a9.13,9.13,0,0,1,1.38.08C68.9,36.66,69.57,37.57,69.39,39.56Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            class="cls-1"
            d="M76.18,53.85v9.73H23.84V53.85ZM42.74,62.73c5.55,0,11.09,0,16.64,0,.89,0,1.34-.25,1.3-1.25,0-1.8,0-3.62,0-5.42,0-1-.38-1.27-1.29-1.27q-16.64,0-33.26,0c-1,0-1.27.37-1.24,1.3.05,1.73.08,3.47,0,5.2-.05,1.15.38,1.45,1.46,1.44C31.81,62.7,37.28,62.73,42.74,62.73Zm28.36,0c1,0,1.91,0,2.87,0s1.24-.28,1.22-1.2c0-1.84,0-3.69,0-5.53,0-.82-.26-1.21-1.11-1.19q-3,0-6.07,0c-.76,0-1.12.26-1.11,1.08q.06,2.88,0,5.76c0,.81.35,1.1,1.11,1.08C69,62.71,70.07,62.72,71.1,62.72Zm-4.66-4c0-.92,0-1.85,0-2.77,0-.71-.24-1.1-1-1.1H62.14c-.66,0-1,.28-1,1,0,2,0,3.92,0,5.88,0,.75.32,1,1,1,1.07,0,2.14,0,3.21,0,.72,0,1.06-.3,1-1C66.42,60.68,66.44,59.68,66.44,58.68Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            class="cls-1"
            d="M82.73,58.37a9.13,9.13,0,0,1,0-2.4A2.7,2.7,0,0,1,84,54.28a4.49,4.49,0,0,1,2.76,0A2.34,2.34,0,0,1,88,56a37,37,0,0,1,0,4.94c0,1.27-1.12,2-2.71,2s-2.54-.77-2.61-2C82.7,60.06,82.73,59.22,82.73,58.37Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            d="M42.74,62.73c-5.46,0-10.93,0-16.4,0-1.08,0-1.51-.29-1.46-1.44.09-1.73.06-3.47,0-5.2,0-.93.27-1.3,1.24-1.3q16.64,0,33.26,0c.91,0,1.32.28,1.29,1.27,0,1.8,0,3.62,0,5.42,0,1-.41,1.25-1.3,1.25C53.83,62.71,48.29,62.73,42.74,62.73ZM25.9,61.89H59.61V55.63H25.9Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            d="M71.1,62.72c-1,0-2.06,0-3.09,0-.76,0-1.12-.27-1.11-1.08q.06-2.88,0-5.76c0-.82.35-1.09,1.11-1.08q3,0,6.07,0c.85,0,1.13.37,1.11,1.19,0,1.84,0,3.69,0,5.53,0,.92-.36,1.24-1.22,1.2S72.06,62.72,71.1,62.72Zm-3.21-.82h6.25V55.64H67.89Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            d="M66.44,58.68c0,1,0,2,0,3,0,.75-.32,1-1,1-1.07,0-2.14,0-3.21,0-.71,0-1-.3-1-1,0-2,0-3.92,0-5.88,0-.7.31-1,1-1h3.33c.74,0,1,.39,1,1.1C66.42,56.83,66.44,57.76,66.44,58.68Zm-1,3.25V55.64H62.2v6.29Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            class="cls-1"
            d="M25.9,61.89V55.63H59.61v6.26Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            class="cls-1"
            d="M67.89,61.9V55.64h6.25V61.9Z"
            transform="translate(-1.03 -35.2)"
          />
          <path
            class="cls-1"
            d="M65.42,61.93H62.2V55.64h3.22Z"
            transform="translate(-1.03 -35.2)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
