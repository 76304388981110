import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import polylabel from "polylabel";
import areapolygon from "area-polygon";
import { connect } from "react-redux";

const STYLE_TEXT = {
  textAnchor: "middle",
  fontFamily: '"Courier New", Courier, monospace',
  pointerEvents: "none",
  fontWeight: "bold",

  // http://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting-using-css
  WebkitTouchCallout: "none" /* iOS Safari */,
  WebkitUserSelect: "none" /* Chrome/Safari/Opera */,
  MozUserSelect: "none" /* Firefox */,
  MsUserSelect: "none" /* Internet Explorer/Edge */,
  userSelect: "none",
};

const Area = function ({ layer, area, catalog, state }) {
  const [splitRoomName, setSplitRoomName] = useState([]);
  // console.log(area);
  // useEffect(() => {
  //   // Function to split roomName into substrings based on available width
  //   const splitRoomNameIntoArray = (roomName, availableWidth) => {
  //     const words = roomName.split(" ");
  //     const maxCharsPerLine = Math.floor(availableWidth / 12); // Assuming font-size of 12px per character
  //     let lines = [];
  //     let currentLine = "";

  //     words.forEach((word, index) => {
  //       if (currentLine.length === 0) {
  //         currentLine = word;
  //       } else {
  //         const potentialLine = currentLine + " " + word;
  //         if (potentialLine.length <= maxCharsPerLine) {
  //           currentLine = potentialLine;
  //         } else {
  //           lines.push(currentLine);
  //           currentLine = word;
  //         }
  //       }

  //       // Handle the last word separately
  //       if (index === words.length - 1) {
  //         lines.push(currentLine);
  //       }
  //     });
  //
  //     // Split lines longer than maxCharsPerLine into chunks
  //     let chunkedLines = [];
  //     lines.forEach(line => {
  //       if (line.length <= maxCharsPerLine) {
  //         chunkedLines.push(line);
  //       } else {
  //         let i = 0;
  //         while (i < line.length) {
  //           chunkedLines.push(line.slice(i, i + maxCharsPerLine));
  //           i += maxCharsPerLine;
  //         }
  //       }
  //     });

  //     return chunkedLines;
  //   };

  //   // Calculate available width for displaying the room name
  //   let polygon = area.vertices.toArray().map((vertexID) => {
  //     let { x, y } = layer.vertices.get(vertexID);
  //     return [x, y];
  //   });

  //   let polygonWithHoles = polygon;
  //   area.holes.forEach((holeID) => {
  //     let polygonHole = layer.areas
  //       .get(holeID)
  //       .vertices.toArray()
  //       .map((vertexID) => {
  //         let { x, y } = layer.vertices.get(vertexID);
  //         return [x / 100, y / 100];
  //       });

  //     polygonWithHoles = polygonWithHoles.concat(polygonHole.reverse());
  //   });

  //   let { width } = calculateBoundingBox(polygonWithHoles, 1);

  //   const availableWidth = width / 100; // Convert to meters if necessary
  //   const lines = splitRoomNameIntoArray(area.properties.get("roomName"), availableWidth);

  //   setSplitRoomName(lines);
  // }, [area, catalog, layer]);

  let rendered = catalog.getElement(area.type).render2D(area, layer);
  let { x: centeredX, y: centeredY } = getPolygonCentroid(rendered.props.d);
  let renderedAreaSize = null;

  let polygon = area.vertices.toArray().map((vertexID) => {
    let { x, y } = layer.vertices.get(vertexID);
    return [x, y];
  });

  let polygonWithHoles = polygon;
  area.holes.forEach((holeID) => {
    let polygonHole = layer.areas
      .get(holeID)
      .vertices.toArray()
      .map((vertexID) => {
        let { x, y } = layer.vertices.get(vertexID);
        return [x / 100, y / 100];
      });

    polygonWithHoles = polygonWithHoles.concat(polygonHole.reverse());
  });

  // const centerPoint = findPolygonCenter(polygonWithHoles);
  let areaSize = areapolygon(polygon, false);

  area.holes.forEach((areaID) => {
    let hole = layer.areas.get(areaID);
    let holePolygon = hole.vertices.toArray().map((vertexID) => {
      let { x, y } = layer.vertices.get(vertexID);
      return [x, y];
    });
    areaSize -= areapolygon(holePolygon, false);
  });
  let { width, height } = calculateBoundingBox(polygonWithHoles, 1);

  //

  useEffect(() => {
    // Function to split roomName into chunks of up to 8 characters each
    const splitRoomNameIntoChunks = (roomName) => {
      const maxCharsPerChunk = width / 20 <= 0 ? width / 20 : width / 20;
      const chunks = [];
      let startIndex = 0;

      while (startIndex < roomName.length) {
        const chunk = roomName.slice(startIndex, startIndex + maxCharsPerChunk);
        chunks.push(chunk);
        startIndex += maxCharsPerChunk;
      }

      return chunks;
    };
    const roomName = area.properties.get("roomName");
    const chunks = splitRoomNameIntoChunks(roomName);

    // Replace with your state handling logic
    setSplitRoomName(chunks);
  }, [area, width]);
  renderedAreaSize = (
    <Fragment>
      {splitRoomName.map((line, index) => (
        <text
          key={index}
          display={area.visible ? "block" : "none"}
          x="0"
          y={index === 0 ? 0 : -index * -20} // Adjust spacing between lines
          // transform={`translate(${centerPoint[0]} ${centerPoint[1]}) scale(1, -1)`}
          transform={`translate(${centeredX} ${centeredY + 20}) scale(1, -1)`}
          style={{
            ...STYLE_TEXT,
            fontSize: `${
              12 / state.getIn(["react-planner"]).viewer2D.toJS().a < 12
                ? 12
                : 12 /
                  (state.getIn(["react-planner"]).viewer2D.toJS().a < 0.3
                    ? state.getIn(["react-planner"]).viewer2D.toJS().a * 1.5
                    : state.getIn(["react-planner"]).viewer2D.toJS().a)
            }px`,
            whiteSpace: "pre-line", // Allow text to break into multiple lines
            textAlign: "center", // Center-align text
            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
            fontWeight: 100,
          }}
        >
          {line}
        </text>
      ))}
      {state.getIn(["react-planner"]).dimension && (
        <text
          display={area.visible ? "block" : "none"}
          x="0"
          y={splitRoomName.length * 20}
          // transform={`translate(${centerPoint[0]} ${centerPoint[1]}) scale(1, -1)`}
          transform={`translate(${centeredX} ${centeredY}) scale(1, -1)`}
          style={{
            ...STYLE_TEXT,
            fontSize: `${
              12 / state.getIn(["react-planner"]).viewer2D.toJS().a < 12
                ? 12
                : 12 /
                  (state.getIn(["react-planner"]).viewer2D.toJS().a < 0.3
                    ? state.getIn(["react-planner"]).viewer2D.toJS().a * 1.5
                    : state.getIn(["react-planner"]).viewer2D.toJS().a)
            }px`,
            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          }}
        >
          {(width / 100).toFixed(2)}m x {(height / 100).toFixed(2)}m
        </text>
      )}
    </Fragment>
  );

  return (
    <g
      data-element-root
      data-prototype={area.prototype}
      data-id={area.id}
      data-selected={area.selected}
      data-layer={layer.id}
    >
      {rendered}
      {renderedAreaSize}
    </g>
  );
};

Area.propTypes = {
  area: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};

function mapStateToProps(reduxState) {
  return {
    state: reduxState,
  };
}

export default connect(mapStateToProps)(Area);

function getPolygonCentroid(pathData) {
  // Split the SVG path data into individual commands
  var commands = pathData.split(/(?=[A-Z])/);

  // Initialize variables for centroid calculation
  var area = 0;
  var cx = 0;
  var cy = 0;

  // Iterate over the commands to calculate the centroid
  for (var i = 0; i < commands.length; i++) {
    var command = commands[i];
    var params = command.substring(1).trim().split(/[ ,]+/);

    // Extract the coordinates of the vertices
    var x = parseFloat(params[params.length - 2]);
    var y = parseFloat(params[params.length - 1]);

    // Calculate the area of the polygon using the shoelace formula
    var nextParams = commands[(i + 1) % commands.length]
      .substring(1)
      .trim()
      .split(/[ ,]+/);
    var nextX = parseFloat(nextParams[nextParams.length - 2]);
    var nextY = parseFloat(nextParams[nextParams.length - 1]);
    area += x * nextY - nextX * y;

    // Calculate the centroid using the centroid formula
    cx += (x + nextX) * (x * nextY - nextX * y);
    cy += (y + nextY) * (x * nextY - nextX * y);
  }

  // Divide by 6 times the polygon's area to get the centroid coordinates
  area /= 2;
  cx /= 6 * area;
  cy /= 6 * area;

  return { x: cx, y: cy };
}

function findPolygonCenter(vertices) {
  // Initialize sum for x and y coordinates
  let sumX = 0;
  let sumY = 0;

  // Calculate sum of x and y coordinates
  for (let i = 0; i < vertices.length; i++) {
    sumX += vertices[i][0];
    sumY += vertices[i][1];
  }

  // Calculate average for x and y coordinates
  const centerX = sumX / vertices.length;
  const centerY = sumY / vertices.length;

  return [centerX, centerY];
}

function calculateBoundingBox(vertices, conversionFactor) {
  if (vertices.length === 0) {
    return { width: 0, height: 0 };
  }

  // Initialize min and max values with the coordinates of the first vertex
  let minX = vertices[0][0];
  let minY = vertices[0][1];
  let maxX = vertices[0][0];
  let maxY = vertices[0][1];

  // Iterate through the vertices to find the minimum and maximum coordinates
  for (let i = 1; i < vertices.length; i++) {
    const x = vertices[i][0];
    const y = vertices[i][1];

    minX = Math.min(minX, x);
    minY = Math.min(minY, y);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);
  }

  // Calculate width and height in meters
  const width = (maxX - minX) * conversionFactor;
  const height = (maxY - minY) * conversionFactor;

  return { width, height };
}
