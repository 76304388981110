import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Running-Man-Door-Symbol-Left",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Running Man Door Symbol Left",
    description: "Running-Man-Door-Symbol-Left",
    image: require("./Running-Man-Door-Symbol-Left.svg"),
    svgIcon: require("./Running-Man-Door-Symbol-Left.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.doorsymbolleftcls1{fill:none;}.doorsymbolleftcls2{fill:#fff;}.doorsymbolleftcls3{fill:#009f4d;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <rect class="doorsymbolleftcls1" x="11.9" y="4.58" width="77.92" height="90.7"/>
    <path class="doorsymbolleftcls2" d="M86.2,100.07H22.87c0-.11-.07-.21-.12-.32q-2-4.95-4-9.9a2.76,2.76,0,0,1-.21-1.1V8.53a2.38,2.38,0,0,1,.05-.26H81.64c0,.2,0,.38,0,.56V62.36c0,.14,0,.28,0,.45l.46,0h1a3.63,3.63,0,0,1,1.52.28,4.07,4.07,0,0,1,2,1.88,6.27,6.27,0,0,1,.65,4,5.63,5.63,0,0,1-1.35,3,3.55,3.55,0,0,1-2.37,1.22c-.49,0-1,0-1.47,0l-.4,0c0,.16,0,.28,0,.4v14.9a2,2,0,0,0,.11.58c.13.36.29.72.44,1.08Zm-54-53.62-.34-.33-6.58-6.57a2.68,2.68,0,0,0-2.62-.79,2.84,2.84,0,0,0-1.47,4.69q4.22,4.27,8.46,8.51a5.25,5.25,0,0,0,.94.75,2.87,2.87,0,0,0,2.23.41,3.12,3.12,0,0,0,1.51-.87l4.89-4.91.32-.3,8.23,9.53-.33.34Q43.89,60.45,40.34,64a3.45,3.45,0,0,0-.87,1.44,4.7,4.7,0,0,0-.08,2.47c.3,1.34.62,2.68.92,4Q41.66,77.94,43,84c.26,1.16.5,2.32.79,3.47a3.53,3.53,0,0,0,6.87-1.65c-.46-2.08-.94-4.15-1.4-6.23-.68-3-1.35-6-2-9A.52.52,0,0,1,47.4,70l8.14-7.89a1.55,1.55,0,0,1,.23-.19l.29.31L63,69.92a4,4,0,0,0,1.45,1,7.06,7.06,0,0,0,2.73.49H82.37a3.63,3.63,0,0,0,.57,0,3.53,3.53,0,0,0,3-3.57,3.44,3.44,0,0,0-2.47-3.28,4.38,4.38,0,0,0-1.25-.16c-4.1,0-8.2,0-12.3.06a.85.85,0,0,1-.71-.33q-5.55-6.47-11.12-12.9Q54.44,47,50.78,42.77l-.26-.33.46,0,4.17,0a.72.72,0,0,1,.62.29c1.21,1.41,2.44,2.82,3.66,4.23l4.74,5.47a2.65,2.65,0,0,0,2.67.91,2.56,2.56,0,0,0,2-1.82,2.68,2.68,0,0,0-.57-2.7l-.48-.52q-4.47-5.16-8.95-10.32a2.87,2.87,0,0,0-2.28-1.05q-6.6,0-13.2,0a4,4,0,0,0-1.28.18,3.8,3.8,0,0,0-1.49,1l-8.13,8.13Zm4.3-19.13A5.49,5.49,0,1,0,42,32.79,5.47,5.47,0,0,0,36.47,27.32Z" transform="translate(-0.07 -0.07)"/>
    <path class="doorsymbolleftcls3" d="M86.2,100.07l-4-9.88c-.15-.36-.31-.72-.44-1.08a2,2,0,0,1-.11-.58V73.63c0-.12,0-.24,0-.4l.4,0c.49,0,1,0,1.47,0A3.55,3.55,0,0,0,85.92,72a5.63,5.63,0,0,0,1.35-3,6.27,6.27,0,0,0-.65-4,4.07,4.07,0,0,0-2-1.88,3.63,3.63,0,0,0-1.52-.28h-1l-.46,0c0-.17,0-.31,0-.45V8.83c0-.18,0-.36,0-.56h-63a2.38,2.38,0,0,0-.05.26V88.75a2.76,2.76,0,0,0,.21,1.1q2,5,4,9.9c.05.11.08.21.12.32H.07V.07h100v100Z" transform="translate(-0.07 -0.07)"/>
    <path class="doorsymbolleftcls3" d="M32.17,46.45l.32-.3L40.62,38a3.8,3.8,0,0,1,1.49-1,4,4,0,0,1,1.28-.18q6.6,0,13.2,0a2.87,2.87,0,0,1,2.28,1.05q4.47,5.17,8.95,10.32l.48.52a2.68,2.68,0,0,1,.57,2.7,2.56,2.56,0,0,1-2,1.82,2.65,2.65,0,0,1-2.67-.91L59.43,46.9c-1.22-1.41-2.45-2.82-3.66-4.23a.72.72,0,0,0-.62-.29l-4.17,0-.46,0,.26.33q3.66,4.23,7.31,8.48,5.56,6.45,11.12,12.9a.85.85,0,0,0,.71.33c4.1,0,8.2,0,12.3-.06a4.38,4.38,0,0,1,1.25.16,3.44,3.44,0,0,1,2.47,3.28,3.53,3.53,0,0,1-3,3.57,3.63,3.63,0,0,1-.57,0H67.14A7.06,7.06,0,0,1,64.41,71a4,4,0,0,1-1.45-1l-6.9-7.69-.29-.31a1.55,1.55,0,0,0-.23.19L47.4,70a.52.52,0,0,0-.16.55c.69,3,1.36,6,2,9,.46,2.08.94,4.15,1.4,6.23a3.53,3.53,0,0,1-6.87,1.65c-.29-1.15-.53-2.31-.79-3.47q-1.35-6-2.71-12.08c-.3-1.34-.62-2.68-.92-4a4.7,4.7,0,0,1,.08-2.47A3.45,3.45,0,0,1,40.34,64q3.54-3.52,7.07-7.07l.33-.34L39.51,47l-.32.3L34.3,52.25a3.12,3.12,0,0,1-1.51.87,2.87,2.87,0,0,1-2.23-.41,5.25,5.25,0,0,1-.94-.75q-4.25-4.24-8.46-8.51a2.84,2.84,0,0,1,1.47-4.69,2.68,2.68,0,0,1,2.62.79l6.58,6.57Z" transform="translate(-0.07 -0.07)"/>
    <path class="doorsymbolleftcls3" d="M36.47,27.32a5.49,5.49,0,1,1,0,11,5.49,5.49,0,0,1,0-11Z" transform="translate(-0.07 -0.07)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
