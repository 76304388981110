import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "shower",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Shower",
    description: "shower",
    image: require("./shower.svg"),
    svgIcon: require("./shower.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M50.45,97.76H6.6a3.69,3.69,0,0,1-3.71-3.5c0-.31,0-.62,0-.94q0-43,0-86.1a5,5,0,0,1,.35-2A3.65,3.65,0,0,1,7,2.84q43.65.09,87.3,0a3.76,3.76,0,0,1,3.92,4V32.45q0,30.52,0,61A6,6,0,0,1,97.51,96,3.14,3.14,0,0,1,94.3,97.8C79.68,97.73,65.07,97.76,50.45,97.76Zm-8.3-87.31a2.8,2.8,0,0,0-.44-.11H13.19A3,3,0,0,0,10.35,12a9,9,0,0,0-.93,3.52q-.08,34.44,0,68.89c0,3.74,1.51,5.24,5.21,5.24h72c.36,0,.71,0,1.07,0a3.62,3.62,0,0,0,3.7-2.85,10.82,10.82,0,0,0,.38-2.89q0-33.91,0-67.82a10.71,10.71,0,0,0-.32-2.77,4.67,4.67,0,0,0-6.72-2.62,7.28,7.28,0,0,1-3.27.89c-5.51.09-11,.1-16.52,0a13.68,13.68,0,0,1-3.41-.91c-.25-.07-.47-.31-.71-.31-2,0-4.06,0-6.22,0,0,3.51,0,6.88,0,10.24,0,1.67-.18,3.31,1.79,4.07.11.05.18.19.27.29a12.31,12.31,0,0,1,.72,15.66c-2.64,3.27-6.63,3.94-9.77,1.55a10.42,10.42,0,0,1-4.08-8.57c-.07-3.91,1.11-7.23,4.35-9.65a1.89,1.89,0,0,0,.55-1.35c0-3.73,0-7.46,0-11.19a7,7,0,0,0-.11-1h-5c0,1,0,1.95,0,2.87,0,.23-.3.43-.46.65-.19-.22-.54-.43-.56-.67C42.12,12.35,42.15,11.42,42.15,10.45Zm0-5.87c-.36,0-.62-.07-.88-.07H8.7A4.33,4.33,0,0,0,4.32,8.36,13.87,13.87,0,0,0,4.11,11q0,39.32,0,78.64a14.49,14.49,0,0,0,.1,1.86c.43,3.28,2,4.66,5.32,4.66h83a3.91,3.91,0,0,0,3.32-1.89A7.76,7.76,0,0,0,97,89.82q0-39.45,0-78.9a10.08,10.08,0,0,0-.13-2.13c-.38-1.79-1-3.71-3-4a46.39,46.39,0,0,0-7.19-.09V8.21A5.76,5.76,0,0,1,93,12.76a12.15,12.15,0,0,1,.35,3.3q0,33.92,0,67.84a19,19,0,0,1-.11,2c-.42,3.8-2.64,5.79-6.45,5.79H14c-3,0-4.86-1.31-5.73-4.19a11.28,11.28,0,0,1-.45-3.27q0-34.24,0-68.51c0-.48,0-1,0-1.46.27-3,2.2-6.1,6.44-6.05,8.75.11,17.5,0,26.25,0H42.1ZM44.21,33.2a34.62,34.62,0,0,0,1.45,4.9,6.43,6.43,0,0,0,5.76,4.1c2.57.08,4.41-1.34,5.76-3.45a11,11,0,0,0-.53-11.91c-2.7-3.44-6.73-3.64-9.71-.43A11.25,11.25,0,0,0,44.21,33.2ZM60.7,4c0,.51-.06.95-.06,1.38,0,3.91.92,4.84,4.88,4.84H77.63c1.59,0,3.19,0,4.78-.08a3.17,3.17,0,0,0,3.1-2.77c.11-1.09,0-2.19,0-3.37ZM53.41,4H49.52V22.81h3.89Zm6.13,4.19V4.58H54.66v2.2l1.81.09.23,1.32ZM48.29,6.75V4.58h-5V6.75Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              class="cls-1"
              d="M42.15,10.45c0,1,0,1.9,0,2.82,0,.24.37.45.56.67.16-.22.44-.42.46-.65,0-.92,0-1.85,0-2.87h5a7,7,0,0,1,.11,1c0,3.73,0,7.46,0,11.19a1.89,1.89,0,0,1-.55,1.35c-3.24,2.42-4.42,5.74-4.35,9.65a10.42,10.42,0,0,0,4.08,8.57c3.14,2.39,7.13,1.72,9.77-1.55a12.31,12.31,0,0,0-.72-15.66c-.09-.1-.16-.24-.27-.29-2-.76-1.82-2.4-1.79-4.07,0-3.36,0-6.73,0-10.24,2.16,0,4.19,0,6.22,0,.24,0,.46.24.71.31a13.68,13.68,0,0,0,3.41.91c5.5.1,11,.09,16.52,0a7.28,7.28,0,0,0,3.27-.89,4.67,4.67,0,0,1,6.72,2.62,10.71,10.71,0,0,1,.32,2.77q0,33.92,0,67.82a10.82,10.82,0,0,1-.38,2.89,3.62,3.62,0,0,1-3.7,2.85c-.36,0-.71,0-1.07,0H14.6c-3.7,0-5.21-1.5-5.21-5.24q0-34.44,0-68.89A9,9,0,0,1,10.35,12a3,3,0,0,1,2.84-1.65H41.71A2.8,2.8,0,0,1,42.15,10.45ZM57.94,76.23a8.94,8.94,0,0,0-1.67-5.48c-2.65-3.81-7.36-3.79-10,0a10.3,10.3,0,0,0,.07,11.41c2.6,3.69,7.17,3.73,9.83.08A9.7,9.7,0,0,0,57.94,76.23Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              class="cls-1"
              d="M42.1,4.58V8.24H40.52c-8.75,0-17.5.07-26.25,0-4.24,0-6.17,3.06-6.44,6.05,0,.49,0,1,0,1.46q0,34.26,0,68.51a11.28,11.28,0,0,0,.45,3.27C9.12,90.37,11,91.67,14,91.68H86.75c3.81,0,6-2,6.45-5.79a19,19,0,0,0,.11-2q0-33.92,0-67.84a12.15,12.15,0,0,0-.35-3.3,5.76,5.76,0,0,0-6.32-4.55V4.69a46.39,46.39,0,0,1,7.19.09c2,.3,2.62,2.22,3,4A10.08,10.08,0,0,1,97,10.92q0,39.45,0,78.9a7.76,7.76,0,0,1-1.08,4.45,3.91,3.91,0,0,1-3.32,1.89h-83c-3.31,0-4.89-1.38-5.32-4.66a14.49,14.49,0,0,1-.1-1.86q0-39.32,0-78.64a13.87,13.87,0,0,1,.21-2.65A4.33,4.33,0,0,1,8.7,4.51H41.22C41.48,4.51,41.74,4.55,42.1,4.58Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              class="cls-1"
              d="M44.21,33.2a11.25,11.25,0,0,1,2.73-6.79c3-3.21,7-3,9.71.43a11,11,0,0,1,.53,11.91c-1.35,2.11-3.19,3.53-5.76,3.45a6.43,6.43,0,0,1-5.76-4.1A34.62,34.62,0,0,1,44.21,33.2Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              class="cls-1"
              d="M60.7,4H85.53c0,1.18.09,2.28,0,3.37a3.17,3.17,0,0,1-3.1,2.77c-1.59.12-3.19.07-4.78.08H65.52c-4,0-4.9-.93-4.88-4.84C60.64,4.92,60.68,4.48,60.7,4Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              class="cls-1"
              d="M53.41,4V22.81H49.52V4Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              class="cls-1"
              d="M59.54,8.19H56.7l-.23-1.32-1.81-.09V4.58h4.88Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              class="cls-1"
              d="M48.29,6.75h-5V4.58h5Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              d="M57.94,76.23a9.7,9.7,0,0,1-1.77,6c-2.66,3.65-7.23,3.61-9.83-.08a10.3,10.3,0,0,1-.07-11.41c2.64-3.82,7.35-3.84,10,0A8.94,8.94,0,0,1,57.94,76.23Zm-1.06.85c0-3-.82-5.07-2.43-6.56a4.58,4.58,0,0,0-5.77-.45c-4.1,2.84-4,10,.13,12.79a4.33,4.33,0,0,0,5,0A7.09,7.09,0,0,0,56.88,77.08Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              class="cls-1"
              d="M56.88,77.08a7.09,7.09,0,0,1-3.07,5.73,4.33,4.33,0,0,1-5,0c-4.16-2.78-4.23-10-.13-12.79a4.58,4.58,0,0,1,5.77.45C56.06,72,56.9,74.06,56.88,77.08Zm-9.39-.24a2.76,2.76,0,0,0,0,.73,4.41,4.41,0,0,0,2.7,3.54,3.08,3.08,0,0,0,3.22-.66,5.7,5.7,0,0,0,0-8,3.14,3.14,0,0,0-4.35,0A5.23,5.23,0,0,0,47.49,76.84Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              d="M47.49,76.84a5.23,5.23,0,0,1,1.62-4.4,3.14,3.14,0,0,1,4.35,0,5.7,5.7,0,0,1,0,8,3.08,3.08,0,0,1-3.22.66,4.41,4.41,0,0,1-2.7-3.54A2.76,2.76,0,0,1,47.49,76.84Zm6.68-.15a12.74,12.74,0,0,0-.54-2,2.56,2.56,0,0,0-2.3-1.73,2.5,2.5,0,0,0-2.42,1.75,4,4,0,0,0,.46,4.25,2.22,2.22,0,0,0,3.72.06A11.14,11.14,0,0,0,54.17,76.69Z"
              transform="translate(-2.88 -2.84)"
            />
            <path
              class="cls-1"
              d="M54.17,76.69A11.14,11.14,0,0,1,53.09,79a2.22,2.22,0,0,1-3.72-.06,4,4,0,0,1-.46-4.25,2.5,2.5,0,0,1,2.42-1.75,2.56,2.56,0,0,1,2.3,1.73A12.74,12.74,0,0,1,54.17,76.69Z"
              transform="translate(-2.88 -2.84)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
