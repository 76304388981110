export function objectsMap(object, func) {
  let mappedObject = {};
  for (let key in object) {
    mappedObject[key] = func(key, mappedObject[key]);
  }
  return mappedObject;
}

export function objectsCompare(x, y) {
  if (x === y) return true;
  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  if (x.constructor !== y.constructor) return false;

  for (let p in x) {
    if (!x.hasOwnProperty(p)) continue;
    if (!y.hasOwnProperty(p)) return false;
    if (x[p] === y[p]) continue;
    if (typeof x[p] !== "object") return false;
    if (!objectsCompare(x[p], y[p])) return false;
  }

  for (let p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  }

  return true;
}

export function capitalizeWords(str) {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

export function numberToOrdinal(currentFloor = "0th floor") {
  if (!currentFloor.includes("floor")) {
    currentFloor += " floor";
  }
  if (currentFloor === "ground floor") {
    return "1st floor";
  }
  const currentNumber = parseInt(currentFloor, 10);

  if (!isNaN(currentNumber) && currentNumber >= 0 && currentNumber < 1000) {
    const nextNumber = currentNumber + 1;
    let suffix;

    if (10 <= nextNumber % 100 && nextNumber % 100 <= 20) {
      suffix = "th";
    } else {
      const lastDigit = nextNumber % 10;
      suffix = { 1: "st", 2: "nd", 3: "rd" }[lastDigit] || "th";
    }

    if (nextNumber < 10 || nextNumber > 20) {
      suffix = suffix.charAt(0) + suffix.slice(1);
    }

    const nextFloorName = `${nextNumber}${suffix} floor`;
    return nextFloorName;
  } else {
    return "Invalid floor number";
  }
}

export function extractElementData(node) {
  while (
    !node.attributes.getNamedItem("data-element-root") &&
    node.tagName !== "svg"
  ) {
    node = node.parentNode;
  }
  if (node.tagName === "svg") return null;

  return {
    part: node.attributes.getNamedItem("data-part")
      ? node.attributes.getNamedItem("data-part").value
      : undefined,
    layer: node.attributes.getNamedItem("data-layer").value,
    prototype: node.attributes.getNamedItem("data-prototype").value,
    selected: node.attributes.getNamedItem("data-selected")
      ? node.attributes.getNamedItem("data-selected").value === "true"
      : true,
    id: node.attributes.getNamedItem("data-id").value,
  };
}

const hasData = (layer) => {
  return (
    Object.keys(layer.vertices).length > 0 ||
    Object.keys(layer.lines).length > 0 ||
    Object.keys(layer.holes).length > 0 ||
    Object.keys(layer.areas).length > 0 ||
    Object.keys(layer.items).length > 0
  );
};

// Function to check if any of the layers contain data
export const hasDataInLayers = (data) => {
  return Object.values(data.layers).some((layer) => hasData(layer));
};

export const areaProperties = [
  {
    name: "Hallway",
    children: [
      "Entrance Hall",
      "Hall",
      "Hallway",
      "Landing",
      "Lobby",
      "Porch",
      "Reception Hall",
      "Stairs",
      "Storm Porch",
      "Vestibule",
    ],
  },
  {
    name: "Reception",
    children: [
      "Reception Room",
      "Sitting Room",
      "Sitting/Dining Room",
      "Lounge",
      "Lounge/Diner",
      "Living Room",
      "Family Room",
      "Drawing Room",
      "Dining Room",
      "Dining Hall",
      "Breakfast Room",
    ],
  },
  {
    name: "Kitchen",
    children: [
      "Kitchen/Breakfast Room",
      "Kitchen/Diner",
      "Kitchen/Dining Room",
    ],
  },
  {
    name: "Bedroom",
    children: [
      "Bedroom/Study",
      "Box Room",
      "Guest Bedroom",
      "Master Bedroom",
      "Bedroom 1",
      "Bedroom 2",
      "Bedroom 3",
      "Bedroom 4",
      "Bedroom 5",
    ],
  },
  {
    name: "Bathroom",
    children: ["Bathroom", "Ensuite", "Shower Room", "WC", "Cloakroom"],
  },
  {
    name: "Balcony",
    children: ["Balcony", "Roof Garden", "Sun Deck", "Sun Terrace", "Garden"],
  },
];
