import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "rectangular-swimming-pool",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "rectangular swimming pool",
    description: "rectangular-swimming-pool",
    image: require("./rectangular-swimming-pool.svg"),
    svgIcon: require("./rectangular-swimming-pool.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 300,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 300,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1,.cls-2{fill:#fff;}.cls-2,.cls-3{stroke:#000;stroke-miterlimit:10;stroke-width:0.1px;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <rect
              class="cls-1"
              x="4.91"
              y="4.55"
              width="47.18"
              height="82.45"
            />
            <rect class="cls-1" x="0.05" y="0.05" width="56.9" height="91.46" />
            <path
              class="cls-2"
              d="M74.27,50.12V95c0,.83,0,.83-.83.83H18.24c-.82,0-.82,0-.82-.84V5.23c0-.87,0-.87.87-.87H73.42c.85,0,.85,0,.85.88Zm-.16,0q0-22.41,0-44.82c0-.61-.1-.83-.79-.83q-27.49,0-55,0c-.64,0-.76.19-.76.79q0,44.82,0,89.63c0,.67.2.79.82.79q27.45,0,54.88,0c1,0,.85.12.85-.86Z"
              transform="translate(-17.37 -4.31)"
            />
            <path
              class="cls-3"
              d="M71.48,17c-.44,0-.89,0-1.34,0s-.52-.13-.51-.52c0-.87,0-1.75,0-2.63,0-.43.12-.59.57-.59a3.23,3.23,0,0,0,1.48-.09c-1.34,0-2.68,0-4,0-.48,0-.63-.18-.57-.61-.27.25-.26.56.06.67s.35.35.35.63c0,.87,0,1.75,0,2.62,0,.26,0,.49-.34.58a.37.37,0,0,0-.18.61.79.79,0,0,1,.15.12.4.4,0,0,1,.15,0h.11c.42.05.83,0,1.25,0l.08,0h.06a.66.66,0,0,1,.15,0l.3,0c.24,0,.22.23.22.39s0,.35,0,.52V90.28c0,1,.08.91-.94.91q-22.75,0-45.51,0c-.62,0-.82-.12-.82-.79q0-40.32,0-80.64c0-.62.12-.86.8-.82C24,9,25,9,26.06,9c.2,0,.47-.09.55.2a.39.39,0,0,1-.13.39.5.5,0,0,1,0,.12c0,.28,0,.56,0,.84,0,.07,0,.13,0,.2a.35.35,0,0,1,.13.11c.46.62.58.64,1,0,.13-.21.28-.17.44-.17a27.64,27.64,0,0,0,2.88,0c.5-.05.43.52.79.52a.69.69,0,0,1-.13-.51c0-1.36,0-2.71,0-4.07-.24.12-.17.35-.18.55,0,.4,0,.81,0,1.22s-.13.49-.48.49q-1.41,0-2.82,0c-.37,0-.54-.12-.51-.52s0-.94,0-1.41a.43.43,0,0,0-.47-.48c-.35,0-.45.19-.46.48,0,.47,0,.94,0,1.41s-.14.52-.52.52c-1.07,0-2.14,0-3.2,0s-.86,0-.86.85V90.48c0,.85,0,.85.86.85H68.7c1,0,.93.14.93-.91V18.87a5.42,5.42,0,0,0,0-.57c0-.36.1-.51.47-.49s.94,0,1.41,0a.42.42,0,1,0,0-.84ZM28,9h3c.19,0,.38,0,.4.29a.34.34,0,0,1-.38.4h-3q-.39,0-.36-.42C27.59,9,27.78,9,28,9Zm0,.74H31c.31,0,.39.15.4.43s-.09.43-.4.42c-.51,0-1,0-1.53,0s-1,0-1.53,0c-.28,0-.36-.12-.35-.38S27.58,9.72,27.92,9.72Zm-.84-3.13c.36,0,.31.29.32.51,0,.62,0,1.23,0,1.85v1.72c0,.21,0,.45-.3.47s-.29-.23-.29-.45q0-1.81,0-3.63C26.81,6.86,26.76,6.6,27.08,6.59Zm42,6.63c.29,0,.43.1.43.41q0,1.47,0,2.94c0,.32-.19.38-.46.38s-.38-.1-.38-.38c0-.49,0-1,0-1.47s0-1,0-1.47C68.61,13.33,68.73,13.22,69,13.22Zm-1.43.41c0-.37.21-.4.5-.41s.41.11.4.41c0,.49,0,1,0,1.47s0,.94,0,1.41-.11.45-.44.44-.47-.11-.46-.45C67.61,15.54,67.61,14.58,67.6,13.63Zm3.67,4c-.64,0-1.28,0-1.92,0s-1.2,0-1.79,0c-.2,0-.47.07-.48-.27s.22-.3.44-.3h3.77c.22,0,.46,0,.45.3S71.47,17.65,71.27,17.65Z"
              transform="translate(-17.37 -4.31)"
            />
            <path
              class="cls-3"
              d="M71.91,12.67a.42.42,0,0,0-.43-.33c-.47,0-.94,0-1.41,0s-.45-.11-.44-.44c0-.75,0-1.5,0-2.25s-.17-.83-.81-.83c-3.87,0-7.74,0-11.61,0-.85,0-.85,0-.85-.82,0-.3,0-.6,0-.9,0-.69-.11-.82-.8-.83H51.2c-1.44,0-1.44,0-1.42,1.47V8c0,.89,0,.89-.92.89H35.27c-.77,0-1.54,0-2.31,0-.47,0-.71-.1-.65-.64a6.09,6.09,0,0,0,0-1.28c0-.44-.27-.65-.7-.36.4,0,.56.15.55.58,0,1.14,0,2.29,0,3.43,0,.33-.07.57-.42.61.42.22.5-.1.63-.37h0a4.78,4.78,0,0,1,.07-.65V10s0,0,0,0v0a.68.68,0,0,1,0-.42.38.38,0,0,1-.07-.42c.09-.24.33-.15.51-.15,5.45,0,10.9,0,16.35,0,.6,0,.57.32.57.72V33.28c0,.86,0,.86.83.86,1.75,0,3.5,0,5.25,0,.4,0,.53-.12.52-.5,0-.68,0-1.37,0-2V9.8c0-.82,0-.82.84-.82,3.85,0,7.69,0,11.54,0,.58,0,.74.16.71.72,0,.72,0,1.45,0,2.18,0,.2,0,.47-.28.46s-.42.11-.57-.23a.54.54,0,0,0-.14-.18h-.73l-.12,0h0l0,0h0a1.18,1.18,0,0,1-.48.51c1.34,0,2.68,0,4,0,.5,0,.69.15.57.63C72,13.05,72,12.84,71.91,12.67ZM50.42,6.43h2.69c.85,0,1.7,0,2.56,0,.35,0,.56.08.55.5s-.21.44-.53.44q-2.62,0-5.25,0c-.36,0-.54-.08-.54-.49S50.1,6.42,50.42,6.43ZM55.7,34.07c-1.73,0-3.46,0-5.19,0-.5,0-.64-.14-.64-.64,0-4.23,0-8.46,0-12.69s0-8.29,0-12.43c0-.91-.11-.8.83-.81,1.66,0,3.33,0,5,0,.42,0,.54.12.54.54q0,12.75,0,25.5C56.26,34,56.11,34.07,55.7,34.07ZM68.27,12.33c-.28,0-.67.14-.66-.37h.67c.12,0,.21.06.2.19S68.38,12.34,68.27,12.33Z"
              transform="translate(-17.37 -4.31)"
            />
            <path d="M69.22,81.47" transform="translate(-17.37 -4.31)" />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
