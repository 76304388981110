import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "bathroom-sink",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "bathroom sink",
    description: "bathroom-sink",
    image: require("./bathroom-sink.svg"),
    svgIcon: require("./bathroom-sink.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
          <path d="M49.84,3.64H85.92c1.73,0,2.48.77,2.48,2.55,0,9.76,0,19.52,0,29.29a2.72,2.72,0,0,0,.87,2.09c6.87,7,10.49,15.38,10,25.32A30.84,30.84,0,0,1,95,76.79a40.84,40.84,0,0,1-15,14.73A56.11,56.11,0,0,1,56.45,99a62,62,0,0,1-24.78-2.39c-9.18-2.85-17.2-7.59-23.32-15.09C2.56,74.48-.19,66.33,1,57.12a33.15,33.15,0,0,1,9.47-19.18,2.71,2.71,0,0,0,.82-2q0-14.83,0-29.68A5.39,5.39,0,0,1,11.8,4.4c.26-.64.79-.77,1.46-.77,6.83,0,13.66,0,20.49,0ZM13.43,6.76V8.08q0,14.43,0,28.88a3.75,3.75,0,0,1-1.17,2.85A31.66,31.66,0,0,0,6,48.4c-4.39,8.91-4.16,17.72.73,26.36,4,7,9.87,11.85,16.88,15.42,11.79,6,24.3,7.4,37.23,5.2a49.76,49.76,0,0,0,24.9-11.49c5.79-5,9.87-11.15,11.08-18.89a26.32,26.32,0,0,0-1.53-13.93,32.22,32.22,0,0,0-7.77-11.5,4.13,4.13,0,0,1-1.31-3.2q0-14.13,0-28.28V6.76Z" transform="translate(-0.7 -3.63)"/>
    <path class="cls-1" d="M13.43,6.76H86.2V8.09q0,14.15,0,28.28a4.13,4.13,0,0,0,1.31,3.2,32.22,32.22,0,0,1,7.77,11.5A26.32,26.32,0,0,1,96.8,65c-1.21,7.74-5.29,13.85-11.08,18.89a49.76,49.76,0,0,1-24.9,11.49c-12.93,2.2-25.44.8-37.23-5.2-7-3.57-12.93-8.46-16.88-15.42C1.82,66.12,1.59,57.31,6,48.4a31.66,31.66,0,0,1,6.29-8.59A3.75,3.75,0,0,0,13.44,37q0-14.45,0-28.88ZM46.86,27.94c-.17,0-.29.08-.42.09a52.21,52.21,0,0,0-15.83,3.56c-8,3.24-14.73,8.12-19.2,15.71A26,26,0,0,0,8.25,66.44C9.89,74.09,14.34,80,20.49,84.6A47.23,47.23,0,0,0,46,93.75,51.15,51.15,0,0,0,72.33,89c6.82-3.28,12.47-7.89,16.33-14.5a26.12,26.12,0,0,0,3.1-19.15c-1.39-6.53-5-11.79-9.89-16.17a44.32,44.32,0,0,0-20.72-10c-2.1-.44-4.24-.73-6.39-1.1,2.75-2.08,3-2.95,1.94-6.23-1.1-3.44-2.12-6.92-3.19-10.37a6.89,6.89,0,0,0-.7-1.54c-1.65-2.68-4.65-2.73-6.27,0a7.39,7.39,0,0,0-.19,7.44,3.16,3.16,0,0,1,.49,1.18C46.88,21.58,46.86,24.71,46.86,27.94ZM58.59,14.39H78.73c2.06,0,2.88.83,2.88,2.82V24.8c0,.35,0,.7.07,1h1.48c0-3.46.13-6.85,0-10.22a3.85,3.85,0,0,0-4-3.51H59.39a6.12,6.12,0,0,0-.8.09ZM17.9,23.93V22.88c0-2,0-4.06,0-6.09a2.15,2.15,0,0,1,2-2.33,9.32,9.32,0,0,1,1.4-.07H40.35c.38,0,.76,0,1.11-.05V12.16A3.75,3.75,0,0,0,41,12.1c-6.92,0-13.84,0-20.77,0a3.83,3.83,0,0,0-3.84,3.4c-.17,2.78-.05,5.57-.05,8.42Z" transform="translate(-0.7 -3.63)"/>
    <path d="M46.86,27.94c0-3.23,0-6.36,0-9.48a3.16,3.16,0,0,0-.49-1.18,7.39,7.39,0,0,1,.19-7.44c1.62-2.69,4.62-2.64,6.27,0a6.89,6.89,0,0,1,.7,1.54c1.07,3.45,2.09,6.93,3.19,10.37,1,3.28.81,4.15-1.94,6.23,2.15.37,4.29.66,6.39,1.1a44.32,44.32,0,0,1,20.72,10c4.92,4.38,8.5,9.64,9.89,16.17a26.12,26.12,0,0,1-3.1,19.15C84.8,81.06,79.15,85.67,72.33,89A51.15,51.15,0,0,1,46,93.75,47.23,47.23,0,0,1,20.49,84.6C14.34,80,9.89,74.09,8.25,66.44A26,26,0,0,1,11.41,47.3c4.47-7.59,11.18-12.47,19.2-15.71A52.21,52.21,0,0,1,46.44,28C46.57,28,46.69,28,46.86,27.94Zm.21,2.42c-.6,0-.93,0-1.26,0a48.39,48.39,0,0,0-21,6.52c-5.95,3.56-10.7,8.23-13.54,14.65a22.55,22.55,0,0,0-1.15,15.53A28.86,28.86,0,0,0,21.2,82.54,45.12,45.12,0,0,0,43,91.13c10.22,1.27,20.08,0,29.36-4.62,7.23-3.62,13.07-8.69,16.42-16.24a22.49,22.49,0,0,0,1.1-15.63C88,48.09,83.92,43,78.47,39.05a46.92,46.92,0,0,0-24.24-8.68c-.58-.05-1.17,0-1.91,0a34.25,34.25,0,0,1,0,4.07,8.26,8.26,0,0,1-1,3.08,1.89,1.89,0,0,1-3.44-.2,9.15,9.15,0,0,1-.75-3C47,33.06,47.07,31.76,47.07,30.36Zm7.35-11.53c-.71-2.16-1.42-4.33-2.17-6.49a3.8,3.8,0,0,0-.71-1.19,2,2,0,0,0-3.12-.06,3.62,3.62,0,0,0-1,3.31,14.14,14.14,0,0,0,1.25,3c.68,1.42,1.43,2.82,2.14,4.21A14,14,0,0,1,54.42,18.83Zm-6.2.36L48,19.3c0,.13,0,.26,0,.39.06,4.78.11,9.57.19,14.36a4,4,0,0,0,.31,1.65c.24.47.78,1.18,1.11,1.14.49-.05,1-.64,1.35-1.08a1.39,1.39,0,0,0,.23-.85c.07-1.69,0-3.39.2-5.07A13,13,0,0,0,49.18,21,19,19,0,0,1,48.22,19.19ZM54,20.71c-2,1.37-2,1.38-.84,3.28.11.17.17.4.32.5a10,10,0,0,0,1,.55c.18-.42.58-.89.5-1.25A29.7,29.7,0,0,0,54,20.71Z" transform="translate(-0.7 -3.63)"/>
    <path d="M58.59,14.39v-2.2a6.12,6.12,0,0,1,.8-.09H79.06a3.85,3.85,0,0,1,4,3.51c.18,3.37,0,6.76,0,10.22H81.68c0-.33-.07-.68-.07-1V17.21c0-2-.82-2.81-2.88-2.82H58.59Z" transform="translate(-0.7 -3.63)"/>
    <path d="M17.9,23.93H16.33c0-2.85-.12-5.64.05-8.42a3.83,3.83,0,0,1,3.84-3.4c6.93,0,13.85,0,20.77,0a3.75,3.75,0,0,1,.47.06v2.17c-.35,0-.73.05-1.11.05H21.28a9.32,9.32,0,0,0-1.4.07,2.15,2.15,0,0,0-2,2.33c0,2,0,4.06,0,6.09Z" transform="translate(-0.7 -3.63)"/>
    <path class="cls-1" d="M47.07,30.36c0,1.4-.1,2.7,0,4a9.15,9.15,0,0,0,.75,3,1.89,1.89,0,0,0,3.44.2,8.26,8.26,0,0,0,1-3.08,34.25,34.25,0,0,0,0-4.07c.74,0,1.33,0,1.91,0a46.92,46.92,0,0,1,24.24,8.68c5.45,4,9.53,9,11.39,15.59a22.49,22.49,0,0,1-1.1,15.63c-3.35,7.55-9.19,12.62-16.42,16.24C63.06,91.15,53.2,92.4,43,91.13A45.12,45.12,0,0,1,21.2,82.54,28.86,28.86,0,0,1,10.13,67.06a22.55,22.55,0,0,1,1.15-15.53c2.84-6.42,7.59-11.09,13.54-14.65a48.39,48.39,0,0,1,21-6.52C46.14,30.34,46.47,30.36,47.07,30.36Zm7.43,28a7.93,7.93,0,0,0-2-5.35,3.71,3.71,0,0,0-5.77,0,8.47,8.47,0,0,0,.08,10.83,3.73,3.73,0,0,0,5.64,0A8.1,8.1,0,0,0,54.5,58.38Z" transform="translate(-0.7 -3.63)"/>
    <path class="cls-1" d="M54.42,18.83a14,14,0,0,0-3.57,2.77c-.71-1.39-1.46-2.79-2.14-4.21a14.14,14.14,0,0,1-1.25-3,3.62,3.62,0,0,1,1-3.31,2,2,0,0,1,3.12.06,3.8,3.8,0,0,1,.71,1.19C53,14.5,53.71,16.67,54.42,18.83Z" transform="translate(-0.7 -3.63)"/>
    <path class="cls-1" d="M48.22,19.19a19,19,0,0,0,1,1.78,13,13,0,0,1,2.15,8.87c-.17,1.68-.13,3.38-.2,5.07a1.39,1.39,0,0,1-.23.85c-.39.44-.86,1-1.35,1.08-.33,0-.87-.67-1.11-1.14a4,4,0,0,1-.31-1.65c-.08-4.79-.13-9.58-.19-14.36,0-.13,0-.26,0-.39Z" transform="translate(-0.7 -3.63)"/>
    <path class="cls-1" d="M54,20.71a29.7,29.7,0,0,1,1,3.08c.08.36-.32.83-.5,1.25a10,10,0,0,1-1-.55c-.15-.1-.21-.33-.32-.5C52,22.09,52,22.08,54,20.71Z" transform="translate(-0.7 -3.63)"/>
    <path d="M54.5,58.38a8.1,8.1,0,0,1-2,5.55,3.73,3.73,0,0,1-5.64,0,8.47,8.47,0,0,1-.08-10.83,3.71,3.71,0,0,1,5.77,0A7.93,7.93,0,0,1,54.5,58.38Zm-1.13.4a6.09,6.09,0,0,0-1.59-4.64,2.88,2.88,0,0,0-4.05-.2c-2.47,2.14-2.4,7.07.15,9.12a2.8,2.8,0,0,0,3.5,0A5.5,5.5,0,0,0,53.37,58.78Z" transform="translate(-0.7 -3.63)"/>
    <path class="cls-1" d="M53.37,58.78a5.5,5.5,0,0,1-2,4.33,2.8,2.8,0,0,1-3.5,0c-2.55-2.05-2.62-7-.15-9.12a2.88,2.88,0,0,1,4.05.2A6.09,6.09,0,0,1,53.37,58.78Z" transform="translate(-0.7 -3.63)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
