import { Area } from "../class/export";
import { REMOVE_AREA, SELECT_AREA } from "../constants";
import { history } from "../utils/export";

export default function (state, action) {
  switch (action.type) {
    case SELECT_AREA:
      return Area.select(state, action.layerID, action.areaID).updatedState;
    case REMOVE_AREA:
      state = state.merge({
        sceneHistory: history.historyPush(state.sceneHistory, state.scene),
      });

      return Area.remove(state, action.layerID, action.areaID).updatedState;
    default:
      return state;
  }
}
