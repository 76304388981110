import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import html2canvas from "html2canvas";

import Translator from "./translator/translator";
import Catalog from "./catalog/catalog";
import actions from "./actions/export";
import {
  capitalizeWords,
  hasDataInLayers,
  objectsMap,
} from "./utils/objects-utils";
import AppLogo from "./assets/app-logo.png";
import {
  ToolbarComponents,
  Content,
  SidebarComponents,
  FooterBarComponents,
} from "./components/export";
import { VERSION } from "./version";
import "./styles/export";
import Catalogbar from "./components/catalog-bar/catalogbar";
import { Seq } from "immutable";
import "./app.css";
import { API_REQUEST_START, MODE_FINISHING_PLAN, VIEW_ONLY } from "./constants";
const { Toolbar } = ToolbarComponents;
const { Sidebar } = SidebarComponents;
const { FooterBar } = FooterBarComponents;

const toolbarW = 200;
const sidebarW = 300;
const footerBarH = 20;
const logoH = 50;
const wrapperStyle = {
  display: "flex",
  flexFlow: "column nowrap",
};
// const handleBeforeUnload = (event,state) => {
//   // Cancel the event (if needed)
//   event.preventDefault();
//
//   // Chrome requires returnValue to be set
//   const searchParams = new URLSearchParams(window.location.search);
//   const floorId = searchParams.get("floorId");
//   const token = searchParams.get("token");
//   localStorage.setItem(
//     "react-planner_v0",
//     JSON.stringify({ ...state.scene.toJS(), floorId, token })
//   );
//
//   event.returnValue = "";
//   // Your custom logic here
// };

class ReactPlanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonClicked: false,
    };
    this.componentRef = React.createRef();
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only update if the relevant props or state have changed
    if (
      nextProps.width !== this.props.width ||
      nextProps.height !== this.props.height ||
      nextProps.state !== this.props.state ||
      nextProps.stateExtractor !== this.props.stateExtractor ||
      nextState.buttonClicked !== this.state.buttonClicked
    ) {
      return true;
    }
    return false;
  }

  getChildContext() {
    return {
      ...objectsMap(actions, (actionNamespace) => this.props[actionNamespace]),
      translator: this.props.translator,
      catalog: this.props.catalog,
    };
  }

  componentWillMount() {
    let { store } = this.context;
    let { projectActions, catalog, stateExtractor, plugins } = this.props;
    plugins.forEach((plugin) => plugin(store, stateExtractor));
    projectActions.initCatalog(catalog);
    const searchParams = new URLSearchParams(window.location.search);
    const viewOnly = searchParams.get("viewOnly");
    if (viewOnly) {
      projectActions.setMode(VIEW_ONLY);
    }
    window.addEventListener("beforeunload", (e) =>
      this.handleBeforeUnload(e, stateExtractor, store)
    );
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", (e) =>
      this.handleBeforeUnload(e, stateExtractor, store)
    );
  }

  componentWillReceiveProps(nextProps) {
    let { stateExtractor, state, projectActions, catalog } = nextProps;
    let plannerState = stateExtractor(state);
    let catalogReady = plannerState.getIn(["catalog", "ready"]);
    if (!catalogReady) {
      projectActions.initCatalog(catalog);
    }
  }

  handleBeforeUnload(event, stateExtractor, store) {
    event.preventDefault();
    let state = stateExtractor(store.getState());
    //
    // Chrome requires returnValue to be set
    const searchParams = new URLSearchParams(window.location.search);
    const floorId = searchParams.get("floorId");
    const token = searchParams.get("token");
    if (hasDataInLayers(state.scene.toJS())) {
      localStorage.setItem(
        "react-planner_v0",
        JSON.stringify({ ...state.scene.toJS(), floorId, token })
      );
    }
    event.returnValue =
      "Are you sure you want to leave? Your changes have been temporary saved just in case.";
  }
  eventOnButtonClick(fn) {
    return fn;
  }
  handleButtonClick() {
    this.setState({ buttonClicked: !this.state.buttonClicked });
    this.handleDownload();
  }

  handleDownload() {
    html2canvas(this.componentRef.current).then((canvas) => {
      canvas.toBlob((blob) => {
        // console.log();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${this.componentRef.current.getAttribute(
          "data-bs-floorId"
        )}.png`;
        link.click();
      });
    });
  }
  render() {
    let { width, height, state, stateExtractor, ...props } = this.props;
    let contentW = width - toolbarW;
    let toolbarH = height - footerBarH;
    let contentH = height - footerBarH;
    let sidebarH = height - logoH;

    let extractedState = stateExtractor(state);
    let selectedLayer = extractedState.getIn(["scene", "selectedLayer"]);

    //TODO change in multi-layer check
    let selected = extractedState.getIn([
      "scene",
      "layers",
      selectedLayer,
      "selected",
    ]);

    let multiselected =
      selected.lines.size > 1 ||
      selected.items.size > 1 ||
      selected.holes.size > 1 ||
      selected.areas.size > 1 ||
      selected.lines.size +
        selected.items.size +
        selected.holes.size +
        selected.areas.size >
        1;
    return (
      <div style={{ ...wrapperStyle }}>
        {extractedState.get("mode") !== MODE_FINISHING_PLAN && (
          <p
            style={{
              position: "absolute",
              zIndex: 99999,
              left: "55.5%",
              top: "6%",
            }}
          >
            <b>
              {capitalizeWords(
                extractedState.getIn([
                  "scene",
                  "layers",
                  selectedLayer,
                  "visibleName",
                ])
              )}
            </b>
          </p>
        )}
        {extractedState.get("mode") === VIEW_ONLY ? (
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              overflow: "hidden",
            }}
          >
            <div style={{ height: logoH, textAlign: "center" }}>
              <a href="http://frawizard-portal.s3-website-us-east-1.amazonaws.com/">
                {" "}
                <img src={AppLogo} alt="" height={logoH} />
              </a>
              <Catalogbar
                width={230}
                height={sidebarH}
                state={extractedState}
                downloadpng={this.handleButtonClick}
                {...props}
              />
            </div>
            <Content
              width={contentW}
              height={contentH}
              state={extractedState}
              buttonClicked={this.state.buttonClicked}
              {...props}
              onWheel={(e) => {
                e.stopPropagation();
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              overflow: "hidden",
            }}
          >
            <div style={{ height: logoH, textAlign: "center" }}>
              <a href="http://frawizard-portal.s3-website-us-east-1.amazonaws.com/">
                {" "}
                <img src={AppLogo} alt="" height={logoH} />
              </a>
              <Catalogbar
                width={230}
                modeFinish={extractedState.get("mode") === MODE_FINISHING_PLAN}
                height={sidebarH}
                downloadpng={this.handleButtonClick}
                state={extractedState}
                {...props}
              />
            </div>

            <div
              style={{ height: "100vh", width: "85vw", position: "relative" }}
            >
              <Toolbar
                width={"100%"}
                height={toolbarH}
                state={extractedState}
                {...props}
              />
              <Content
                width={contentW}
                height={contentH}
                state={extractedState}
                {...props}
                componentRef={this.componentRef}
                onWheel={(e) => {
                  e.stopPropagation();
                }}
              />
              <Sidebar
                width={sidebarW}
                opened={
                  (!multiselected &&
                    extractedState.mode === "MODE_IDLE" &&
                    extractedState.scene.layers
                      .get(selectedLayer)
                      .selected.valueSeq()
                      .some((layer) => layer.size > 0)) ||
                  (!multiselected && extractedState.mode === "SELECT_GUIDE")
                }
                height={contentH}
                state={extractedState}
                {...props}
              />
            </div>

            <FooterBar
              width={width}
              height={footerBarH}
              state={extractedState}
              {...props}
            />
          </div>
        )}
      </div>
    );
  }
}

ReactPlanner.propTypes = {
  translator: PropTypes.instanceOf(Translator),
  catalog: PropTypes.instanceOf(Catalog),
  allowProjectFileSupport: PropTypes.bool,
  plugins: PropTypes.arrayOf(PropTypes.func),
  autosaveKey: PropTypes.string,
  autosaveDelay: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  stateExtractor: PropTypes.func.isRequired,
  toolbarButtons: PropTypes.array,
  sidebarComponents: PropTypes.array,
  footerbarComponents: PropTypes.array,
  customContents: PropTypes.object,
  softwareSignature: PropTypes.string,
};

ReactPlanner.contextTypes = {
  store: PropTypes.object.isRequired,
};

ReactPlanner.childContextTypes = {
  ...objectsMap(actions, () => PropTypes.object),
  translator: PropTypes.object,
  catalog: PropTypes.object,
};

ReactPlanner.defaultProps = {
  translator: new Translator(),
  catalog: new Catalog(),
  plugins: [],
  allowProjectFileSupport: true,
  softwareSignature: `React-Planner ${VERSION}`,
  toolbarButtons: [],
  sidebarComponents: [],
  footerbarComponents: [],
  customContents: {},
};

//redux connect
function mapStateToProps(reduxState) {
  return {
    state: reduxState,
  };
}

function mapDispatchToProps(dispatch) {
  return objectsMap(actions, (actionNamespace) =>
    bindActionCreators(actions[actionNamespace], dispatch)
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactPlanner);
