import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Square-Boiler-with-B",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Square Boiler with B",
    description: "Square-Boiler-with-B",
    image: require("./Square-Boiler-with-B.svg"),
    svgIcon: require("./Square-Boiler-with-B.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g id={`${element.type}-${element.id}`} transform={`translate(-47, 55) scale(1,-1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M3.22,3.67H95.77V96.33H3.22Zm3.1,89.77H92.61V6.64H6.32Z"
            transform="translate(-3.22 -3.67)"
          />
          <path
            class="cls-1"
            d="M6.32,93.44V6.64H92.61v86.8Zm20.84-73v61a5.09,5.09,0,0,0,.83.17c9.9-.08,19.8,0,29.7-.32A25.06,25.06,0,0,0,71,77c5.63-3.73,8.33-9,7.75-15.74s-4.59-11.08-10.85-13.4c-1.23-.46-2.51-.8-3.61-1.15a56.32,56.32,0,0,0,5.86-5,11.94,11.94,0,0,0-1.19-16.8,18.27,18.27,0,0,0-11.4-4.15c-9.77-.31-19.54-.33-29.31-.46A7.73,7.73,0,0,0,27.16,20.39Z"
            transform="translate(-3.22 -3.67)"
          />
          <path
            d="M27.16,20.39a7.73,7.73,0,0,1,1-.12c9.77.13,19.54.15,29.31.46a18.27,18.27,0,0,1,11.4,4.15,11.94,11.94,0,0,1,1.19,16.8,56.32,56.32,0,0,1-5.86,5c1.1.35,2.38.69,3.61,1.15,6.26,2.32,10.26,6.58,10.85,13.4S76.58,73.23,71,77A25.06,25.06,0,0,1,57.69,81.2c-9.9.28-19.8.24-29.7.32a5.09,5.09,0,0,1-.83-.17Zm9.27,31.37a5.65,5.65,0,0,0-.23,1c0,6.81,0,13.63,0,20.44,0,1.31.66,1.44,1.69,1.43,5.08-.06,10.17.15,15.23-.2a38.71,38.71,0,0,0,10.12-2,8.7,8.7,0,0,0,5.9-7.06c1.17-7.19-2.16-12.22-9.42-12.94-6.76-.67-13.6-.47-20.41-.64C38.32,51.74,37.37,51.76,36.43,51.76Zm-.12-7.18c6,0,11.93,0,17.83,0A8.07,8.07,0,0,0,56.78,44c2.26-.81,4.44-1.78,5.6-4.09,2.92-5.81.42-11.31-6-12-6-.67-12.19-.49-18.29-.67-.56,0-1.12.08-1.8.13Z"
            transform="translate(-3.22 -3.67)"
          />
          <path
            class="cls-1"
            d="M36.43,51.76c.94,0,1.89,0,2.84,0,6.81.17,13.65,0,20.41.64,7.26.72,10.59,5.75,9.42,12.94a8.7,8.7,0,0,1-5.9,7.06,38.71,38.71,0,0,1-10.12,2c-5.06.35-10.15.14-15.23.2-1,0-1.7-.12-1.69-1.43.05-6.81,0-13.63,0-20.44A5.65,5.65,0,0,1,36.43,51.76Z"
            transform="translate(-3.22 -3.67)"
          />
          <path
            class="cls-1"
            d="M36.31,44.58V27.35c.68-.05,1.24-.14,1.8-.13,6.1.18,12.24,0,18.29.67,6.4.72,8.9,6.22,6,12C61.22,42.23,59,43.2,56.78,44a8.07,8.07,0,0,1-2.64.54C48.24,44.6,42.34,44.58,36.31,44.58Z"
            transform="translate(-3.22 -3.67)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
