import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "bedside-table",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Bedside Table",
    description: "bedside-table",
    image: require("./bedside-table.svg"),
    svgIcon: require("./bedside-table.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 50,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 50,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g >
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g transform={`translate(-47, 55) scale(1, -1)`} id={`${element.type}-${element.id}`} data-name="corner shower">
          <path d="M98.65,50.1V95.45a3.29,3.29,0,0,1-3.2,3.22q-45.46,0-90.93,0a3.31,3.31,0,0,1-3.16-3.14q0-45.52,0-91A3.32,3.32,0,0,1,4.5,1.31h91a3.32,3.32,0,0,1,3.1,3.09Q98.66,27.24,98.65,50.1ZM5.46,94.63h77.4c2.13,0,2.12,0,2.76-2.06a8,8,0,0,1,.34-1c1.18-2.57,2.42-5.11,3.51-7.71A24.79,24.79,0,0,0,91,79.23c.4-2-.89-3.07-2.85-2.61a12.6,12.6,0,0,0-2.81,1c-2.53,1.25-5,2.67-7.53,3.88A21.2,21.2,0,0,1,73,83.21c-3.37.68-5.65-1.67-4.83-5A43.88,43.88,0,0,1,70.37,72c1-2.47,2.14-4.89,3.15-7.36A13.91,13.91,0,0,0,74,62.24l-.28-.08c-.2.31-.4.61-.58.93A25.64,25.64,0,0,1,60.3,74.51c-9.71,3.86-18.94,2.68-27-3.92s-11.1-15.25-9.35-25.44A26.24,26.24,0,0,1,47,23.58c9.59-1,17.67,2.27,23.69,9.78s7.38,16.29,4.36,25.58a1.46,1.46,0,0,0,0,1.05,4.7,4.7,0,0,1,.44,4.29C74.44,67.17,73.15,70,72,72.84a42.18,42.18,0,0,0-2,5.46c-.64,2.54.79,3.84,3.26,3a54,54,0,0,0,6.46-2.9c2.81-1.44,5.44-3.28,8.7-3.65,2.67-.3,4.6,1.36,4.45,4.06a13,13,0,0,1-.91,4c-1.06,2.62-2.34,5.14-3.47,7.73-.56,1.28-1,2.61-1.55,4.07h7.57V5.47h-89Zm44.6-21a23.58,23.58,0,1,0-23.58-23.5A23.57,23.57,0,0,0,50.06,73.58Z" transform="translate(-1.35 -1.31)"/>
    <path class="cls-1" d="M5.46,94.63V5.47h89V94.55H86.91c.55-1.46,1-2.79,1.55-4.07,1.13-2.59,2.41-5.11,3.47-7.73a13,13,0,0,0,.91-4c.15-2.7-1.78-4.36-4.45-4.06-3.26.37-5.89,2.21-8.7,3.65a54,54,0,0,1-6.46,2.9c-2.47.88-3.9-.42-3.26-3a42.18,42.18,0,0,1,2-5.46c1.16-2.86,2.45-5.67,3.51-8.56A4.7,4.7,0,0,0,75.06,60a1.46,1.46,0,0,1,0-1.05c3-9.29,1.75-17.95-4.36-25.58S56.59,22.58,47,23.58A26.24,26.24,0,0,0,23.91,45.15c-1.75,10.19,1.34,18.91,9.35,25.44s17.33,7.78,27,3.92A25.64,25.64,0,0,0,73.15,63.09c.18-.32.38-.62.58-.93l.28.08a13.91,13.91,0,0,1-.49,2.36c-1,2.47-2.15,4.89-3.15,7.36a43.88,43.88,0,0,0-2.24,6.21c-.82,3.37,1.46,5.72,4.83,5a21.2,21.2,0,0,0,4.81-1.75c2.55-1.21,5-2.63,7.53-3.88a12.6,12.6,0,0,1,2.81-1c2-.46,3.25.65,2.85,2.61a24.79,24.79,0,0,1-1.49,4.66C88.38,86.49,87.14,89,86,91.6a8,8,0,0,0-.34,1c-.64,2.06-.63,2.06-2.76,2.06H5.46Z" transform="translate(-1.35 -1.31)"/>
    <path class="cls-1" d="M50.06,73.58A23.58,23.58,0,1,1,73.53,50,23.57,23.57,0,0,1,50.06,73.58ZM59.76,50A9.76,9.76,0,1,0,50,59.77,9.78,9.78,0,0,0,59.76,50Z" transform="translate(-1.35 -1.31)"/>
    <path d="M59.76,50A9.76,9.76,0,1,1,50,40.19,9.73,9.73,0,0,1,59.76,50Zm-2,0a7.73,7.73,0,1,0-15.46-.09A7.73,7.73,0,1,0,57.73,50Z" transform="translate(-1.35 -1.31)"/>
    <path class="cls-1" d="M57.73,50a7.73,7.73,0,1,1-15.46-.09A7.73,7.73,0,1,1,57.73,50ZM50,43.34A6.65,6.65,0,1,0,56.65,50,6.65,6.65,0,0,0,50,43.34Z" transform="translate(-1.35 -1.31)"/>
    <path d="M50,43.34a6.65,6.65,0,1,1-.1,13.3,6.65,6.65,0,0,1,.1-13.3ZM55.61,50A5.62,5.62,0,1,0,50,55.62,5.62,5.62,0,0,0,55.61,50Z" transform="translate(-1.35 -1.31)"/>
    <path class="cls-1" d="M55.61,50a5.62,5.62,0,1,1-5.56-5.64A5.63,5.63,0,0,1,55.61,50Z" transform="translate(-1.35 -1.31)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
