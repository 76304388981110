import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-fire-extinguisher-CO2",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "no smoking",
    description: "fire-extinguisher-CO2",
    image: require("./fire-extinguisher-CO2.svg"),
    svgIcon: require("./fire-extinguisher-CO2.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.co2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              d="M2,1.83H84.19V84h-82L2,84V1.83ZM30.62,81.64H56.75V30.89c0-1.61,0-3.23,0-4.84a5.5,5.5,0,0,0-.49-2.26,7.54,7.54,0,0,0-2.15-2.67A12.77,12.77,0,0,0,50.19,19c-.35-.13-.71-.24-1.07-.36l-.66-5.27h.3l1.4,0a8.17,8.17,0,0,0,1.77-.17,2.93,2.93,0,0,0,1-.38.72.72,0,0,0,.35-.52c.05-.33.12-.67.18-1,.22-1.18.43-2.37.65-3.55A.4.4,0,0,0,54,7.33,1.8,1.8,0,0,0,53.56,7a5.21,5.21,0,0,0-.7-.23,7.72,7.72,0,0,0-2.15-.17l-2.46.1h-.61L47.31,4.2H39.82l-.37,2.91-.3,0c-1,0-2.1.08-3.15.14a13.49,13.49,0,0,0-1.41.15,2.9,2.9,0,0,0-1.1.39c-.16.1-.32.22-.33.42a.48.48,0,0,1-.19.3l-7.86,7a2.43,2.43,0,0,0-.76,1.2,2.86,2.86,0,0,0,0,1.2,7.74,7.74,0,0,1,.12,1.17c0,1.54,0,3.08,0,4.63V24l.16,0,1.73-.58a2.23,2.23,0,0,0,.48-.26.75.75,0,0,0,.32-.58c.11-1.16.24-2.31.34-3.46a.68.68,0,0,1,.34-.61,1.86,1.86,0,0,0,.28-.21l7.47-5.85.55-.41c.07.09.14.15.19.22a1.57,1.57,0,0,0,.84.59,3.62,3.62,0,0,0,.47.13l1.07.19L38,18.69l-.24.09a15.48,15.48,0,0,0-2.28.91A10.31,10.31,0,0,0,32,22.36a5.8,5.8,0,0,0-1.39,3.8c0,2.81,0,5.63,0,8.44v47Z"
              transform="translate(-1.94 -1.83)"
            />
            <path
              class="co2"
              d="M2,1.83V84l.2,0h82s0,0,0,0H2v-82C2,2,1.9,1.89,2,1.83Z"
              transform="translate(-1.94 -1.83)"
            />
            <path
              class="co2"
              d="M30.62,81.64v-47c0-2.81,0-5.63,0-8.44A5.8,5.8,0,0,1,32,22.36a10.31,10.31,0,0,1,3.5-2.67,15.48,15.48,0,0,1,2.28-.91l.24-.09.7-5.55L37.63,13a3.62,3.62,0,0,1-.47-.13,1.57,1.57,0,0,1-.84-.59c0-.07-.12-.13-.19-.22l-.55.41-7.47,5.85a1.86,1.86,0,0,1-.28.21.68.68,0,0,0-.34.61c-.1,1.15-.23,2.3-.34,3.46a.75.75,0,0,1-.32.58,2.23,2.23,0,0,1-.48.26L24.62,24l-.16,0v-.31c0-1.55,0-3.09,0-4.63a7.74,7.74,0,0,0-.12-1.17,2.86,2.86,0,0,1,0-1.2,2.43,2.43,0,0,1,.76-1.2l7.86-7a.48.48,0,0,0,.19-.3c0-.2.17-.32.33-.42a2.9,2.9,0,0,1,1.1-.39A13.49,13.49,0,0,1,36,7.27c1-.06,2.1-.09,3.15-.14l.3,0,.37-2.91h7.49l.33,2.54h.61l2.46-.1a7.72,7.72,0,0,1,2.15.17,5.21,5.21,0,0,1,.7.23,1.8,1.8,0,0,1,.43.29.4.4,0,0,1,.14.44c-.22,1.18-.43,2.37-.65,3.55-.06.33-.13.67-.18,1a.72.72,0,0,1-.35.52,2.93,2.93,0,0,1-1,.38,8.17,8.17,0,0,1-1.77.17l-1.4,0h-.3l.66,5.27c.36.12.72.23,1.07.36a12.77,12.77,0,0,1,3.92,2.13,7.54,7.54,0,0,1,2.15,2.67,5.5,5.5,0,0,1,.49,2.26c0,1.61,0,3.23,0,4.84V81.64ZM54.31,33.05H33c-.06.3,0,12.9,0,13.09.74.06,21.09,0,21.29,0Z"
              transform="translate(-1.94 -1.83)"
            />
            <path
              d="M54.31,33.05V46.11c-.2.06-20.55.09-21.29,0C33,46,33,33.35,33,33.05ZM44,37.7c-.18,0-.36,0-.54.08a1.89,1.89,0,0,0-.12,3.67,2.47,2.47,0,0,0,1.08,0A1.89,1.89,0,0,0,46,39.07,2,2,0,0,0,44,37.7Zm-2.3.67a1.73,1.73,0,0,0-.79-.54,2.25,2.25,0,0,0-1.26,0,1.89,1.89,0,0,0-.34,3.58,2.17,2.17,0,0,0,1.68,0,1.47,1.47,0,0,0,.7-.52l-.53-.49c-.08.07-.15.14-.22.19a1.2,1.2,0,0,1-1.71-.28A1.19,1.19,0,0,1,41,38.71l.19.15Zm4.65,3.08a22.67,22.67,0,0,0,2.76,0v-.65H47.6a2.11,2.11,0,0,1,.18-.2c.23-.22.47-.43.69-.66a2.62,2.62,0,0,0,.4-.48,1.07,1.07,0,0,0-.63-1.64,2,2,0,0,0-1.42.09,1.27,1.27,0,0,0-.61.52l.6.38a2.32,2.32,0,0,1,.18-.16.94.94,0,0,1,.85-.16.43.43,0,0,1,.32.55,1,1,0,0,1-.31.5c-.4.38-.78.77-1.2,1.12a.73.73,0,0,0-.28.69A.43.43,0,0,1,46.39,41.45Z"
              transform="translate(-1.94 -1.83)"
            />
            <path
              class="co2"
              d="M44,37.7A2,2,0,0,1,46,39.07a1.89,1.89,0,0,1-1.5,2.43,2.47,2.47,0,0,1-1.08,0,1.89,1.89,0,0,1,.12-3.67C43.68,37.74,43.86,37.73,44,37.7Zm0,3.1a1.12,1.12,0,0,0,1.17-1.17,1.16,1.16,0,1,0-2.31,0A1.11,1.11,0,0,0,44,40.8Z"
              transform="translate(-1.94 -1.83)"
            />
            <path
              class="co2"
              d="M41.74,38.37l-.53.49L41,38.71a1.19,1.19,0,0,0-1.75,1.58,1.2,1.2,0,0,0,1.71.28c.07,0,.14-.12.22-.19l.53.49a1.47,1.47,0,0,1-.7.52,2.17,2.17,0,0,1-1.68,0,1.89,1.89,0,0,1,.34-3.58,2.25,2.25,0,0,1,1.26,0A1.73,1.73,0,0,1,41.74,38.37Z"
              transform="translate(-1.94 -1.83)"
            />
            <path
              class="co2"
              d="M46.39,41.45a.43.43,0,0,0,0-.12.73.73,0,0,1,.28-.69c.42-.35.8-.74,1.2-1.12a1,1,0,0,0,.31-.5.43.43,0,0,0-.32-.55.94.94,0,0,0-.85.16,2.32,2.32,0,0,0-.18.16l-.6-.38a1.27,1.27,0,0,1,.61-.52,2,2,0,0,1,1.42-.09,1.07,1.07,0,0,1,.63,1.64,2.62,2.62,0,0,1-.4.48c-.22.23-.46.44-.69.66a2.11,2.11,0,0,0-.18.2h1.55v.65A22.67,22.67,0,0,1,46.39,41.45Z"
              transform="translate(-1.94 -1.83)"
            />
            <path
              d="M44,40.8a1.11,1.11,0,0,1-1.14-1.19,1.16,1.16,0,1,1,2.31,0A1.12,1.12,0,0,1,44,40.8Z"
              transform="translate(-1.94 -1.83)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
