import React from "react";
import { buildWall, updatedWall } from "./wall-factory-3d";
import * as SharedStyle from "../../shared-style";
import * as Geometry from "../../utils/geometry";
import Translator from "../../translator/translator";
import "../../app.css";
import { MODE_DRAWING_SQUARE, MODE_FINISHING_PLAN } from "../../constants";
const epsilon = 20;
const STYLE_TEXT = { textAnchor: "middle" };
const STYLE_LINE = { stroke: SharedStyle.LINE_MESH_COLOR.selected };
const STYLE_RECT = { fill: "black" };
const STYLE_RECT_SELECTED = {
  ...STYLE_RECT,
  stroke: SharedStyle.LINE_MESH_COLOR.selected,
};

let translator = new Translator();

export default function WallFactory(name, info, textures) {
  let wallElement = {
    name,
    prototype: "lines",
    info,
    properties: {
      // height: {
      //   label: translator.t('height'),
      //   type: 'length-measure',
      //   defaultValue: {
      //     length: 300,
      //   }
      // },
      // thickness: {
      //   label: "Wall Thickness",
      //   type: 'length-measure',
      //   defaultValue: {
      //     length: 18,
      //     maxLimit:60,
      //     minLimit:10
      //   }
      // },
      thickness: {
        label: "Wall Thickness",
        type: "slider",
        min: 10,
        max: 60,
        defaultValue: 40,
      },
      wallColor: {
        label: translator.t("color"),
        type: "color",
        defaultValue: "#000000",
      },
      style: {
        label: "Wall Style",
        type: "dropdown",
        defaultValue: "Solid",
        options: ["Solid", "Hidden"],
      },
    },

    render2D: function (element, layer, mode) {
      let { x: x1, y: y1 } = layer.vertices.get(element.vertices.get(0));
      let { x: x2, y: y2 } = layer.vertices.get(element.vertices.get(1));
      let orientation = getLineOrientation(x1, y1, x2, y2);
      let length = Geometry.pointsDistance(x1, y1, x2, y2);
      let length_5 = length / 2;
      // Number.isInteger(element.properties.get('width')) && element.properties.get('width')>5
      // let thickness = element.getIn(['properties', 'thickness']);
      let thickness =
        Number.isInteger(element.getIn(["properties", "thickness"])) &&
        element.getIn(["properties", "thickness"]) > 5
          ? element.getIn(["properties", "thickness"])
          : element.getIn(["properties", "thickness"]) * 100;
      let half_thickness = thickness / 2;
      let half_thickness_eps = half_thickness + epsilon;
      let char_height = 11;
      let extra_epsilon = 5;
      let textDistance = half_thickness + epsilon + extra_epsilon;

      return element.selected ? (
        <g>
          <rect
            onDoubleClick={(e) => {}}
            x="0"
            y={0}
            width={length}
            height={1}
            style={{
              ...STYLE_RECT_SELECTED,
              stroke: "red",
              strokeWidth:
                mode === MODE_DRAWING_SQUARE
                  ? half_thickness / 4
                  : half_thickness,
              opacity:
                element.getIn(["properties", "style"]) === "Hidden" ? 0.1 : 1,
            }}
          />
          <line
            x1={length_5}
            y1={-half_thickness_eps}
            x2={length_5}
            y2={half_thickness_eps}
            style={STYLE_LINE}
          />
          <text x={length_5} y={textDistance + char_height} style={STYLE_TEXT}>
            A
          </text>
          <text x={length_5} y={-textDistance} style={STYLE_TEXT}>
            B
          </text>
        </g>
      ) : (
        <rect
          className={`${mode !== MODE_FINISHING_PLAN && "wall-class"} ${
            mode === MODE_DRAWING_SQUARE && "force-color"
          } ${
            mode !== MODE_DRAWING_SQUARE &&
            (orientation === "vertical" ? "vertical" : "horizontal")
          } `}
          x="0"
          y={0}
          width={length}
          height={1}
          style={{
            ...STYLE_RECT,
            stroke: element.getIn(["properties", "wallColor"]),
            strokeWidth:  mode === MODE_DRAWING_SQUARE
            ? half_thickness / 4
            : half_thickness,
            opacity:
              element.getIn(["properties", "style"]) === "Hidden" ? 0.1 : 1,
          }}
        />
      );
    },

    render3D: function (element, layer, scene) {
      return buildWall(element, layer, scene, textures);
    },

    updateRender3D: (
      element,
      layer,
      scene,
      mesh,
      oldElement,
      differences,
      selfDestroy,
      selfBuild
    ) => {
      return updatedWall(
        element,
        layer,
        scene,
        textures,
        mesh,
        oldElement,
        differences,
        selfDestroy,
        selfBuild
      );
    },
  };

  if (textures && textures !== {}) {
    let textureValues = { none: "None" };

    for (let textureName in textures) {
      textureValues[textureName] = textures[textureName].name;
    }

    // wallElement.properties.textureA = {
    //   label: translator.t('texture') + ' A',
    //   type: 'enum',
    //   defaultValue: textureValues.bricks ? 'bricks' : 'none',
    //   values: textureValues
    // };

    // wallElement.properties.textureB = {
    //   label: translator.t('texture') + ' B',
    //   type: 'enum',
    //   defaultValue: textureValues.bricks ? 'bricks' : 'none',
    //   values: textureValues
    // };
  }

  return wallElement;
}
function getLineOrientation(x1, y1, x2, y2) {
  var deltaX = Math.abs(x2 - x1);
  var deltaY = Math.abs(y2 - y1);

  if (deltaX > deltaY) {
    return "horizontal";
  } else if (deltaY > deltaX) {
    return "vertical";
  } else {
    return "diagonal";
  }
}

// WallFactory.propTypes={}
