import React, { Component, Fragment, memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Panel from "../sidebar/panel";
import CatalogList from "../catalog-view/catalog-list";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { capitalizeWords, numberToOrdinal } from "../../utils/objects-utils";
import "./styles.css";
import { MdArrowDownward, MdArrowUpward, MdCheck } from "react-icons/md";
import { ElementsFactories } from "react-planner";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Line from "../../class/line";
import {
  BEGIN_DRAWING_SQUARE,
  MODE_DRAGGING_SQUARE,
  MODE_DRAWING_SQUARE,
  MODE_IDLE,
  MODE_WAITING_DRAWING_LINE,
  MODE_WAITING_DRAWING_SQUARE,
} from "../../constants";
// import * as ScenesActions from "../../actions/scene-actions";
const tabStyle = { margin: "1em" };

const iconStyle = {
  fontSize: "14px",
  margin: "2px",
  cursor: "pointer",
};

const addGuideStyle = {
  cursor: "pointer",
  height: "2em",
};

const tableTabStyle = {
  width: "100%",
  textAlign: "center",
};

export default class CatalogPanel extends Component {
  constructor(props, context) {
    super(props);
    let page = props.state.catalog.page;
    let currentCategory = context.catalog.getCategory(page);

    let elementsToDisplay = currentCategory.elements.filter((element) =>
      element.info.visibility ? element.info.visibility.catalog : true
    );
    this.state = {
      selectedElement: "",
      categories: currentCategory.categories,
      elements: elementsToDisplay,
      matchString: "",
      matchedElements: [],
    };
  }

  flattenCategories(categories) {
    let toRet = [];

    for (let x = 0; x < categories.length; x++) {
      let curr = categories[x];
      toRet = toRet.concat(curr.elements);
      if (curr.categories.length)
        toRet = toRet.concat(this.flattenCategories(curr.categories));
    }

    return toRet;
  }

  matcharray(text) {
    let array = this.state.elements.concat(
      this.flattenCategories(this.state.categories)
    );

    let filtered = [];

    if (text != "") {
      let regexp = new RegExp(text, "i");
      for (let i = 0; i < array.length; i++) {
        if (regexp.test(array[i].info.title)) {
          filtered.push(array[i]);
        }
      }
    }

    this.setState({
      matchString: text,
      matchedElements: filtered,
    });
  }

  // select( element ) {
  //    
  //   switch (element.prototype) {
  //     case 'lines':
  //       this.context.linesActions.selectToolDrawingLine(element.name);
  //       break;
  //     case 'items':
  //       this.context.itemsActions.selectToolDrawingItem(element.name);
  //       break;
  //     case 'holes':
  //       this.context.holesActions.selectToolDrawingHole(element.name);
  //       break;
  //   }

  //   this.context.projectActions.pushLastSelectedCatalogElementToHistory(element);
  // }

  select(elem, isSquare) {
    let element = elem;
    this.context.projectActions.unselectAll(this.props.state);
    switch (element.prototype) {
      case "lines":
        if (isSquare) {
          this.context.linesActions.selectToolDrawingSquare(element.name);
          return;
        }
        this.context.linesActions.selectToolDrawingLine(element.name);
        break;
      case "items":
        this.context.itemsActions.selectToolDrawingItem(element.name);
        break;
      case "holes":
        this.context.holesActions.selectToolDrawingHole(element.name);
        break;
    }
    this.context.projectActions.pushLastSelectedCatalogElementToHistory(
      element
    );
  }

  render() {
    //FOR SQUARE
    const info = {
      title: "wall",
      tag: ["wall"],
      description: "Wall with bricks or painted",
      image: require("./wall.png"),
      svgicon: require("./wall-svg.svg"),
      visibility: {
        catalog: true,
        layerElementsVisible: true,
      },
    };

    const textures = {
      bricks: {
        name: "Bricks",
        uri: require("./textures/bricks.jpg"),
        lengthRepeatScale: 0.01,
        heightRepeatScale: 0.01,
        normal: {
          uri: require("./textures/bricks-normal.jpg"),
          lengthRepeatScale: 0.01,
          heightRepeatScale: 0.01,
          normalScaleX: 0.8,
          normalScaleY: 0.8,
        },
      },
      painted: {
        name: "Painted",
        uri: require("./textures/painted.jpg"),
        lengthRepeatScale: 0.01,
        heightRepeatScale: 0.01,
        normal: {
          uri: require("./textures/painted-normal.jpg"),
          lengthRepeatScale: 0.01,
          heightRepeatScale: 0.01,
          normalScaleX: 0.4,
          normalScaleY: 0.4,
        },
      },
    };
    //================

    const panels = ["Add", "Floors", "Stairs", "More Symbols"];
    let page = this.props.state.catalog.page;
    let currentCategory = this.context.catalog.getCategory(page);
    let categoriesToDisplay = currentCategory.categories;
    let elementsToDisplay = currentCategory.elements.filter((element) =>
      element.info.visibility ? element.info.visibility.catalog : true
    );
    if (page !== "root") {
      let breadcrumbsNames = [];

      this.props.state.catalog.path.forEach((pathName) => {
        breadcrumbsNames.push({
          name: this.context.catalog.getCategory(pathName).label,
          action: () => projectActions.goBackToCatalogPage(pathName),
        });
      });

      breadcrumbsNames.push({ name: currentCategory.label, action: "" });

      breadcrumbComponent = <CatalogBreadcrumb names={breadcrumbsNames} />;
    }

    let pathSize = this.props.state.catalog.path.size;
    let breadcrumbComponent = null;
    let { state } = this.props;
    let { projectActions, translator } = this.context;
    // let { guides } = state.scene;

    return (
      <Fragment>
        {panels.map((panel) => {
          if (panel === "Floors") {
            return (
              <PanelView
                divStyle={true}
                panelStyle={true}
                STYLES={{ paddingTop: "0 !important" }}
                key={panel}
                name={panel}
              >
                <FloorPanel actions={this.context} state={this.props.state} />
              </PanelView>
            );
          }
          let panel_var = this.context.catalog.getCategory(panel);
          if (panel_var.categories.length) {
            return (
              <PanelView
                panelStyle={panel_var.name === "More Symbols"}
                divStyle={false}
                name={panel_var.name}
                key={panel_var.name}
              >
                {panel_var.categories.map((sub) => (
                  <PanelView
                    panelStyle={false}
                    divStyle={true}
                    name={sub.name}
                    key={sub.name}
                  >
                    {sub.elements.map((elem) => (
                      <CatalogItemView
                        item={elem}
                        selectOnClick={() => this.select(elem)}
                        key={elem.name}
                      />
                    ))}
                  </PanelView>
                ))}
              </PanelView>
            );
          } else {
            return (
              <PanelView
                divStyle={true}
                panelStyle={true}
                name={panel_var.name}
                key={panel_var.name}
                state={state}
                projectActions={projectActions}
              >
                {panel === "Add" && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "50px",
                        // cursor: "pointer",
                        height: "50px",
                        border: `3px solid  ${
                          this.props.state.mode ===
                            MODE_WAITING_DRAWING_SQUARE ||
                          this.props.state.mode === MODE_DRAWING_SQUARE
                            ? "rgb(255, 93, 23)"
                            : "black"
                        }`,
                        borderStyle: "groove",
                      }}
                      onClick={() => {
                        this.select(
                          ElementsFactories.WallFactory("wall", info, textures),
                          true
                        );
                      }}
                    ></div>
                    <p style={{ width: "60px", textAlign: "center" }}>
                      Room or Cupboard
                    </p>
                  </div>
                )}

                {panel_var.elements.map((elem) => (
                  <CatalogItemView
                    item={elem}
                    selectOnClick={() => this.select(elem)}
                    key={elem.name}
                  />
                ))}
              </PanelView>
            );
          }
        })}
        {/* <Panel style={{backgroundColor:"white !important", color:"black !important"}} opened={true} name={translator.t('Catalog')}>
        <div style={{width:"100%", height:"100%", display:"flex", paddingTop:"30px", justifyContent:"center", flexWrap:"wrap"}}>
       {elementsToDisplay.map(elem => <CatalogItemView item={elem} selectOnClick={()=>this.select(elem)} key={elem.name}/>)}
        </div>
      </Panel> */}
      </Fragment>
    );
  }
}

CatalogPanel.propTypes = {
  state: PropTypes.object.isRequired,
};

CatalogPanel.contextTypes = {
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  sceneActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
};

const CatalogItemView = ({ item, selectOnClick }) => {
  return (
    <div
      // onClick={selectOnClick}
      onMouseDown={(e) => {
        selectOnClick();
      }}
      style={{
        border: "2px solid silver",
        margin: "3px",
        boxSizing: "border-box",
        width: "50px",
        height: "50px",
      }}
    >
      <img
        title={capitalizeWords(item.info.title)}
        width={"100%"}
        height={"100%"}
        draggable="false"
        // onDragStart={(e)=>{ }}
        style={{ objectFit: "contain", padding: "2px" }}
        src={item.info.svgicon ? item.info.svgicon : item.info.image}
        alt={item.info.title}
      />
      {/* <label htmlFor="">{item.info.title}</label> */}
    </div>
  );
};
const changeLayerProperties = (state, layerID, actions, opacity, visible) => {
  let layer = state.getIn(["scene", "layers", layerID]);
  layer = layer.set("opacity", opacity);
  if (layer.items.size) {
    layer = layer.set(
      "items",
      layer.items.map((item) => item.set("visible", visible))
    );
  }
  if (layer.holes.size) {
    layer = layer.set(
      "holes",
      layer.holes.map((hole) => hole.set("visible", visible))
    );
  }
  if (layer.areas.size) {
    layer = layer.set(
      "areas",
      layer.areas.map((area) => area.set("visible", visible))
    );
  }
  actions.sceneActions.setLayerProperties(layerID, layer);
  return { updatedLayer: layer };
};
const PanelView = ({
  name,
  children,
  opened,
  panelStyle,
  divStyle,
  STYLES,
  projectActions,
  state,
}) => {
  return (
    <Panel
      style={
        panelStyle
          ? {
              backgroundColor: "white !important",
              color: "black !important",
              textAlign: "left",
            }
          : { backgroundColor: "white !important", color: "black !important" }
      }
      opened={name === "Add"}
      name={name}
    >
      <div
        onMouseUp={() => {
          if (
            state.get("mode") !== MODE_WAITING_DRAWING_SQUARE &&
            state.get("mode") !== MODE_WAITING_DRAWING_LINE &&
            state.get("mode") !== MODE_IDLE
          )
            projectActions.rollback();
        }}
        onMouseEnter={(e) => e.stopPropagation()}
        style={
          divStyle
            ? {
                width: "100%",
                height: "100%",
                display: "flex",
                paddingTop: "30px",
                justifyContent: "center",
                flexWrap: "wrap",
                ...STYLES,
              }
            : { width: "100%", height: "100%" }
        }
      >
        {children}
      </div>
    </Panel>
  );
};
// margin: 10px;
// width: calc(33.33% - 25px);
// height: calc(33.33% - 25px);
const FloorListItems = memo(
  ({ open, floor, selected, actions, selectLayer }) => {
    const { visibleName, id } = floor.toJS();
    const { sceneActions } = actions;
    return (
      <div
        onClick={selectLayer}
        style={{
          cursor: "pointer",
          display: "flex",
          padding: "0px 5px",
          borderBottom: "1px solid gray",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <MdCheck visibility={selected !== id && "hidden"} />
          {capitalizeWords(visibleName)}
        </div>
        <button
          style={{ width: "30%" }}
          onClick={() => {
            open(floor);
          }}
          className="floor-btns"
        >
          Edit
        </button>
      </div>
    );
  }
);
const FloorPanel = ({ shouldDelete, state, actions }) => {
  let layers = state.getIn(["scene", "layers"]);
  const [modal, setModal] = useState(false);
  const [layer, setLayer] = useState(null);
  const [prevLayer, setPrevLayer] = useState(null);

  let selectedLayer = state.getIn(["scene", "selectedLayer"]);
  return (
    <Fragment>
      <div
        className="d-flex"
        style={{ width: "100%", flexDirection: "column" }}
      >
        <button
          className="floor-btns"
          onClick={() => {
            actions.sceneActions.addLayer(
              numberToOrdinal(layers.entrySeq().last()[1].toJS().name)
            );
            changeLayerProperties(state, selectedLayer, actions, 0.3, false);
            // actions.sceneActions.setLayerProperties(selectedLayer,{opacity:0.5});
          }}
        >
          <MdArrowUpward /> Add Floor Above
        </button>
        <button disabled={true} className="floor-btns">
          <MdArrowDownward /> Add Floor Below
        </button>
      </div>
      <div
        style={{
          padding: "10px 0px 7px 0px",
          marginBottom: "5px",
          width: "100%",
        }}
      >
        {layers
          .entrySeq()
          .reverse()
          .map((l, ind) => (
            <FloorListItems
              selectLayer={() => {
                if (selectedLayer !== l[1].id) {
                  actions.sceneActions.selectLayer(l[1].id);
                  changeLayerProperties(state, l[1].id, actions, 1, true);
                  changeLayerProperties(
                    state,
                    selectedLayer,
                    actions,
                    0.3,
                    false
                  );
                }
              }}
              state={state}
              open={(l) => {
                setLayer(l);
                setModal(true);
              }}
              actions={actions}
              key={ind}
              floor={l[1]}
              selected={selectedLayer}
            />
          ))}
      </div>
      <Modal
        center
        open={modal}
        onClose={() => {
          setModal(false);
          setLayer(null);
          // this.setState((prev) => ({
          //   ...prev,
          //   modal: { type: "configure", open: false },
          // }));
        }}
      >
        <FloorModal
          canDelete={layers.entrySeq().count() > 1}
          actions={actions}
          state={state}
          floor={layer}
          close={() => {
            setModal(false);
            setLayer(null);
          }}
        />
      </Modal>
    </Fragment>
  );
};

const FloorModal = ({ actions, state, floor, close, canDelete }) => {
  const [formdata, setFormData] = useState({
    visibleName: "",
  });
  useEffect(() => {
    if (floor) {
      const { visibleName } = floor.toJS();
      setFormData({ visibleName });
    }
  }, []);
  return (
    <form
      style={{
        display: "flex",
        flexFlow: "column",
        paddingTop: "20px",
        width: "300px",
        height: "300px",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        confirmAlert({
          overlayClassName: "overlay-dialog",
          title: "Confirm to submit",
          message: "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                actions.sceneActions.setLayerProperties(floor.id, {
                  visibleName: formdata.visibleName,
                });
                close();
              },
            },
            {
              label: "No",
              onClick: () => {},
            },
          ],
        });
        // actions.
      }}
      onReset={(e) => {
        e.preventDefault();
        close();
      }}
    >
      <label style={{ fontSize: "15px" }} htmlFor="visiblename-input">
        Floor Name
      </label>
      <input
        style={{ padding: "5px" }}
        id="visiblename-input"
        onChange={(e) => {
          setFormData({ visibleName: e.target.value });
        }}
        value={formdata.visibleName}
      ></input>
      <div style={{ textAlign: "center", padding: "20px 0px" }}>
        <button
          onClick={() => {
            confirmAlert({
              overlayClassName: "overlay-dialog",
              title: "Confirm to submit",
              message: "Are you sure to do this.",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    let floors = state.getIn(["scene", "layers"]);
                    let layerKeys = floors.keySeq().toArray();
                    const indexToDelete = layerKeys.indexOf(floor.id);
                    // console.log(
                    //   layerKeys,
                    //   indexToDelete,
                    //   layerKeys[indexToDelete - 1],
                    //   layerKeys[indexToDelete + 1]
                    // );
                    actions.sceneActions.removeLayer(floor.id);
                    if (layerKeys[indexToDelete - 1]) {
                      changeLayerProperties(
                        state,
                        layerKeys[indexToDelete - 1],
                        actions,
                        1,
                        true
                      );
                      actions.sceneActions.selectLayer(
                        layerKeys[indexToDelete - 1]
                      );
                    } else if (layerKeys[indexToDelete + 1]) {
                      changeLayerProperties(
                        state,
                        layerKeys[indexToDelete + 1],
                        actions,
                        1,
                        true
                      );
                      actions.sceneActions.selectLayer(
                        layerKeys[indexToDelete + 1]
                      );
                    }
                    close();
                  },
                },
                {
                  label: "No",
                  onClick: () => {},
                },
              ],
            });
          }}
          disabled={!canDelete}
          style={{ backgroundColor: !canDelete ? "rgb(255 169 126)" : "" }}
          type="button"
          className="floor-btns"
        >
          Delete
        </button>
      </div>
      <div style={{ textAlign: "right" }}>
        <button
          className="floor-btns"
          style={{ width: "20%", marginRight: "5px" }}
          type="reset"
        >
          Cancel
        </button>
        <button className="floor-btns" style={{ width: "20%" }} type="submit">
          Ok
        </button>
      </div>
    </form>
  );
};
