import React from "react";
import * as Three from "three";
import { loadObjWithMaterial } from "../../utils/load-obj";
import path from "path";

let cached3DWindow = null;

export default {
  name: "window",
  prototype: "holes",

  info: {
    title: "window",
    tag: ["window"],
    description: "Window",
    image: require("./window.png"),
    svgicon: require("./window.svg"),
  },

  properties: {
    width: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 350,
      defaultValue: 100,
    },
    // height: {
    //   label: "Height",
    //   type: "length-measure",
    //   defaultValue: {
    //     length: 100
    //   }
    // },
    // altitude: {
    //   label: "Altitude",
    //   type: "length-measure",
    //   defaultValue: {
    //     length: 90
    //   }
    // },
    // thickness: {
    //   label: "Thickness",
    //   type: "length-measure",
    //   defaultValue: {
    //     length: 10,
    //   },
    // },
  },

  render2D: function (element, layer, scene) {
    const STYLE_HOLE_BASE = {
      stroke: "white",
      strokeWidth: "3px",
      fill: "white",
    };
    const STYLE_HOLE_SELECTED = {
      stroke: "rgb(220 220 220)",
      strokeWidth: "3px",
      fill: "rgb(239 239 239)",
      cursor: "move",
    };
    let line = layer.lines.get(element.line);
    let lineThickness = line.properties.get("thickness");
    let epsilon =
      (Number.isInteger(lineThickness) && lineThickness > 5
        ? lineThickness
        : lineThickness * 100) / 2;

    // let epsilon = 3;
    let lineStyle = {
      strokeLineJoin: "miter !important",
    };
    // let lineLength = scene.getIn(["layers",layer.id,'lines',element.get("line")])
    let holeWidth =
      element.properties.get("width") === 100
        ? element.properties.get("width")
        : element.properties.get("width") * 100;
    let holePath = `M${0} ${-epsilon}  L${holeWidth} ${-epsilon}  L${holeWidth} ${epsilon}  L${0} ${epsilon}`;
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
    let length =
      element.properties.get("width") === 100
        ? element.properties.get("width")
        : element.properties.get("width") * 100;
    return (
      <g
        style={{ strokeLinejoin: "miter !important" }}
        transform={`translate(${-length / 2}, 0)`}
      >
        <style>
          {` .clswindow-1{fill:#f4f4f5;}.clswindow-2{fill:none;stroke:#727373;stroke-miterlimit:10; stroke-linejoin:miter !important;}`}
        </style>
        <path key="1" d={holePath} style={holeStyle} />
        {element.selected && (
          <text transform="translate(0, 10) scale(1,-1)" x={holeWidth / 2 - 15}>
            {Number.isInteger(element.properties.get("width")) &&
            element.properties.get("width") > 5
              ? parseFloat(element.properties.get("width") / 100).toFixed(2)
              : element.properties.get("width").toFixed(2)}
            m
          </text>
        )}
        <line
          style={lineStyle}
          class="clswindow-2"
          y1={(Number.isInteger(lineThickness) && lineThickness > 5?lineThickness/10:lineThickness*10) + 3}
          x2={holeWidth+1.5}
          y2={(Number.isInteger(lineThickness) && lineThickness > 5?lineThickness/10:lineThickness*10) + 3}
        />
        <line
          style={lineStyle}
          class="clswindow-2"
          y1={0}
          x2={holeWidth +1.5}
          y2={0}
        />
        <line
          style={lineStyle}
          class="clswindow-2"
          y1={-((Number.isInteger(lineThickness) && lineThickness > 5?lineThickness/10:lineThickness*10) + 3)}
          x2={holeWidth +1.5}
          y2={-((Number.isInteger(lineThickness) && lineThickness > 5?lineThickness/10:lineThickness*10) + 3)}
        />
      </g>
    );
  },

  render3D: function (element, layer, scene) {
    let onLoadItem = (object) => {
      let boundingBox = new Three.Box3().setFromObject(object);

      let initialWidth = boundingBox.max.x - boundingBox.min.x;
      let initialHeight = boundingBox.max.y - boundingBox.min.y;
      let initialThickness = boundingBox.max.z - boundingBox.min.z;

      if (element.selected) {
        let box = new Three.BoxHelper(object, 0x99c3fb);
        box.material.linewidth = 2;
        box.material.depthTest = false;
        box.renderOrder = 1000;
        object.add(box);
      }

      let width = element.properties.get("width");
      let height = element.properties.get("height");
      let thickness = element.properties.get("thickness");

      object.scale.set(
        width / initialWidth,
        height / initialHeight,
        thickness / initialThickness
      );

      return object;
    };

    if (cached3DWindow) {
      return Promise.resolve(onLoadItem(cached3DWindow.clone()));
    }

    let mtl = require("./window.mtl");
    let obj = require("./window.obj");
    let img = require("./texture.png");

    return loadObjWithMaterial(mtl, obj, path.dirname(img) + "/").then(
      (object) => {
        cached3DWindow = object;
        return onLoadItem(cached3DWindow.clone());
      }
    );
  },
};
