import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Double-sink-with-drainer",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Double sink with drainer",
    description: "Double-sink-with-drainer",
    image: require("./Double-sink-with-drainer.svg"),
    svgIcon: require("./Double-sink-with-drainer.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              id="black-border"
              d="M50,68.18H2.91c-.94,0-1.08-.14-1.08-1.07V30.82c0-.91.14-1.06,1-1.06H97.14c.89,0,1,.15,1,1V67.16c0,.9-.12,1-1,1ZM3,66.82H97V31.11H3Z"
              transform="translate(-1.83 -29.76)"
            />
            <path
              id="white-background"
              class="cls-1"
              d="M3,66.82V31.11H97V66.82Zm92.09-2V32.32H69.39v2.22c-.09,0-.14,0-.17,0l-1.75-1.12a2,2,0,0,0-2.2,0,1.64,1.64,0,0,0-.5,1.85A4.83,4.83,0,0,1,65,37.34a1.06,1.06,0,0,0,.66,1.2,1.7,1.7,0,0,0,1.78-.14,1.15,1.15,0,0,0,.41-1.29,3.45,3.45,0,0,1-.07-.39c.38,0,.73,0,1.08,0,.6,0,.6.06.6.68V64.8ZM37.55,32.3V64.79H63.22V32.3Zm-19,5.24h7.73c1.7,0,3.39,0,5.09,0,.41,0,.55-.14.53-.54,0-.56,0-1.11,0-1.66s0-.68-.68-.68H5.69c-.29,0-.4.12-.4.4,0,.61,0,1.22,0,1.83s0,.64.64.64Zm.06,12.58H5.82c-.41,0-.55.13-.53.54s0,1.1,0,1.65,0,.69.69.69H31.31c.54,0,.56,0,.57-.59V50.7c0-.58,0-.58-.6-.58Zm0-5.17H5.85c-.52,0-.56,0-.56.56v1.65c0,.68,0,.68.69.68H31.47c.29,0,.41-.12.41-.4,0-.63,0-1.25,0-1.87s0-.62-.62-.62Zm0,18.32H31.33c.53,0,.54,0,.55-.55V61.11c0-.71,0-.71-.69-.71H5.69c-.29,0-.4.12-.4.4,0,.63,0,1.25,0,1.87s0,.6.59.6Zm-.06-20.59H31.33c.53,0,.54,0,.55-.55V40.52c0-.71,0-.71-.7-.71H5.72c-.31,0-.43.13-.43.43,0,.61,0,1.22,0,1.83s0,.61.62.61Zm0,15.45h9.89c1,0,2,0,3,0,.37,0,.51-.14.49-.5,0-.57,0-1.14,0-1.7s0-.68-.68-.68H6a2.26,2.26,0,0,1-.26,0c-.29,0-.4.12-.4.41,0,.6,0,1.21,0,1.82s0,.64.64.64Z"
              transform="translate(-1.83 -29.76)"
            />
            <g id="drainer">
              <path
                d="M18.53,37.54H5.93c-.64,0-.64,0-.64-.64s0-1.22,0-1.83c0-.28.11-.42.4-.4H31.2c.68,0,.68,0,.68.68s0,1.1,0,1.66c0,.4-.12.54-.53.54-1.7,0-3.39,0-5.09,0ZM5.73,37H31.42V35.2H5.73Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M18.59,50.12H31.28c.59,0,.59,0,.6.58v1.7c0,.56,0,.58-.57.59H6c-.69,0-.69,0-.69-.69s0-1.1,0-1.65.12-.54.53-.54H18.59ZM5.73,52.46H31.42V50.62H5.73Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M18.57,45H31.26c.6,0,.62,0,.62.62s0,1.24,0,1.87c0,.28-.12.42-.41.4H6c-.69,0-.69,0-.69-.68V45.51c0-.52,0-.56.56-.56H18.57ZM5.74,47.33H31.42V45.47H5.74Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M18.61,63.27H5.88c-.58,0-.59,0-.59-.6s0-1.24,0-1.87c0-.28.11-.42.4-.4h25.5c.69,0,.69,0,.69.71v1.61c0,.53,0,.55-.55.55H18.61ZM5.73,62.78H31.42V60.9H5.73Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M18.55,42.68H5.91c-.62,0-.62,0-.62-.61s0-1.22,0-1.83c0-.3.12-.45.43-.43H31.18c.7,0,.7,0,.7.71v1.61c0,.53,0,.55-.55.55H18.55Zm-12.81-.5H31.43V40.32H5.74Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M18.53,58.13H5.93c-.64,0-.64,0-.64-.64s0-1.22,0-1.82c0-.29.11-.43.4-.41a2.26,2.26,0,0,0,.26,0H31.2c.68,0,.68,0,.68.68s0,1.13,0,1.7c0,.36-.12.5-.49.5-1,0-2,0-3,0Zm12.9-2.37H5.74v1.86H31.43Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M5.73,37V35.2H31.42V37Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M5.73,52.46V50.62H31.42v1.84Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M5.74,47.33V45.47H31.42v1.86Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M5.73,62.78V60.9H31.42v1.88Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M5.74,42.18V40.32H31.43v1.86Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M31.43,55.76v1.86H5.74V55.76Z"
                transform="translate(-1.83 -29.76)"
              />
            </g>
            <g id="double_sink" data-name="double sink">
              <path
                d="M95.13,64.8H69.41V37.41c0-.62,0-.64-.6-.68-.35,0-.7,0-1.08,0a3.45,3.45,0,0,0,.07.39,1.15,1.15,0,0,1-.41,1.29,1.7,1.7,0,0,1-1.78.14,1.06,1.06,0,0,1-.66-1.2,4.83,4.83,0,0,0-.18-2.17,1.64,1.64,0,0,1,.5-1.85,2,2,0,0,1,2.2,0l1.75,1.12s.08,0,.17,0V32.32H95.13ZM70.09,37V64.08h24.4V33H70.06c0,.52,0,1,0,1.51a.5.5,0,0,0,.39.59c.88.26,1.77.53,2.64.82a6.11,6.11,0,0,1,1.31.57A1.61,1.61,0,0,1,74.83,39a1.07,1.07,0,0,1-1.63.13c-.19-.18-.37-.39-.56-.57A6.14,6.14,0,0,0,70.09,37Zm-3.81-3.25c-.64,0-.9.25-.91.9a1.61,1.61,0,0,0,.09.62,3.69,3.69,0,0,1,.14,1.92c-.08.53.09.79.61.78a1.27,1.27,0,0,0,.88-.27c.12-.16,0-.56-.09-.85a.67.67,0,0,0-.06-.12c-.21-.42-.12-.6.33-.68a6.51,6.51,0,0,1,3.3.35,7.19,7.19,0,0,1,3,2.06c.36.39.79.29,1-.21a.75.75,0,0,0-.25-1,6.33,6.33,0,0,0-1.36-.66c-.69-.25-1.42-.39-2.11-.63a11.37,11.37,0,0,1-3.13-1.67A2.27,2.27,0,0,0,66.28,33.74Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M37.55,32.3H63.22V64.79H37.55Zm25,.72H38.19V64.08H62.58Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M70.09,37a6.14,6.14,0,0,1,2.55,1.53c.19.18.37.39.56.57A1.07,1.07,0,0,0,74.83,39a1.61,1.61,0,0,0-.43-2.45,6.11,6.11,0,0,0-1.31-.57c-.87-.29-1.76-.56-2.64-.82a.5.5,0,0,1-.39-.59c0-.49,0-1,0-1.51H94.49V64.08H70.09Zm2,11.2a4.07,4.07,0,0,0,.69,2.32,3.33,3.33,0,0,0,5.26.57,4.24,4.24,0,0,0,.12-5.65,3.3,3.3,0,0,0-5-.09A4,4,0,0,0,72.07,48.19Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M66.28,33.74a2.27,2.27,0,0,1,1.38.59A11.37,11.37,0,0,0,70.79,36c.69.24,1.42.38,2.11.63a6.33,6.33,0,0,1,1.36.66.75.75,0,0,1,.25,1c-.19.5-.62.6-1,.21a7.19,7.19,0,0,0-3-2.06,6.51,6.51,0,0,0-3.3-.35c-.45.08-.54.26-.33.68a.67.67,0,0,1,.06.12c0,.29.21.69.09.85a1.27,1.27,0,0,1-.88.27c-.52,0-.69-.25-.61-.78a3.69,3.69,0,0,0-.14-1.92,1.61,1.61,0,0,1-.09-.62C65.38,34,65.64,33.75,66.28,33.74Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M62.58,33V64.08H38.19V33Zm-.51,15.52V34.07c0-.59,0-.59-.58-.59H39.17c-.59,0-.59,0-.59.59V63c0,.62,0,.62.61.62H61.43c.64,0,.64,0,.64-.67Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M72.07,48.19a4,4,0,0,1,1.11-2.85,3.3,3.3,0,0,1,5,.09A4.24,4.24,0,0,1,78,51.08a3.33,3.33,0,0,1-5.26-.57A4.07,4.07,0,0,1,72.07,48.19ZM72.7,48a3.84,3.84,0,0,0,.6,2.08,2.74,2.74,0,0,0,4.61,0,3.45,3.45,0,0,0,.55-2.59A3,3,0,0,0,75.85,45a2.9,2.9,0,0,0-2.95,2.09C72.79,47.44,72.75,47.82,72.7,48Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M62.07,48.54V63c0,.67,0,.67-.64.67H39.19c-.61,0-.61,0-.61-.62V34.07c0-.59,0-.59.59-.59H61.49c.58,0,.58,0,.58.59Zm-.35-14.68H39V63.24H61.72Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M72.7,48c0-.22.09-.6.2-1A2.9,2.9,0,0,1,75.85,45a3,3,0,0,1,2.61,2.57,3.45,3.45,0,0,1-.55,2.59,2.74,2.74,0,0,1-4.61,0A3.84,3.84,0,0,1,72.7,48Zm1,.11a2,2,0,0,0,1.94,2.13,2.12,2.12,0,0,0,0-4.22A2,2,0,0,0,73.7,48.15Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M61.72,33.86V63.24H39V33.86ZM60.13,48.58a4.45,4.45,0,0,0-.78-2.73,3.33,3.33,0,0,0-5.26-.34,4.2,4.2,0,0,0,.27,5.85,3.26,3.26,0,0,0,4.21.2A3.88,3.88,0,0,0,60.13,48.58Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M73.7,48.15a2,2,0,0,1,1.94-2.09,2.12,2.12,0,0,1,0,4.22A2,2,0,0,1,73.7,48.15Zm3.24,0a1.3,1.3,0,1,0-2.6,0,1.3,1.3,0,1,0,2.6,0Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M60.13,48.58a3.88,3.88,0,0,1-1.56,3,3.26,3.26,0,0,1-4.21-.2,4.2,4.2,0,0,1-.27-5.85,3.33,3.33,0,0,1,5.26.34A4.45,4.45,0,0,1,60.13,48.58Zm-.65-.28a3.33,3.33,0,0,0-.68-2,2.71,2.71,0,0,0-4.32-.14,3.45,3.45,0,0,0-.21,4.14,2.73,2.73,0,0,0,4.54.11A3.28,3.28,0,0,0,59.48,48.3Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M76.94,48.19a1.3,1.3,0,1,1-1.28-1.41A1.35,1.35,0,0,1,76.94,48.19Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M59.48,48.3a3.28,3.28,0,0,1-.67,2.06,2.73,2.73,0,0,1-4.54-.11,3.45,3.45,0,0,1,.21-4.14,2.71,2.71,0,0,1,4.32.14A3.33,3.33,0,0,1,59.48,48.3Zm-.92,0a2,2,0,1,0-1.92,2.14A2,2,0,0,0,58.56,48.27Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                d="M58.56,48.27a2,2,0,1,1-3.9,0,2,2,0,1,1,3.9,0Zm-.65,0a1.31,1.31,0,1,0-2.61,0,1.31,1.31,0,1,0,2.61,0Z"
                transform="translate(-1.83 -29.76)"
              />
              <path
                class="cls-1"
                d="M57.91,48.28A1.31,1.31,0,1,1,56.6,46.9,1.34,1.34,0,0,1,57.91,48.28Z"
                transform="translate(-1.83 -29.76)"
              />
            </g>
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
