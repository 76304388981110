import {
  SELECT_ITEM,
  SELECT_TOOL_DRAWING_ITEM,
  UPDATE_DRAWING_ITEM,
  END_DRAWING_ITEM,
  BEGIN_DRAGGING_ITEM,
  UPDATE_DRAGGING_ITEM,
  END_DRAGGING_ITEM,
  BEGIN_ROTATING_ITEM,
  UPDATE_ROTATING_ITEM,
  END_ROTATING_ITEM,
  RESIZE_ITEM,
  SELECT_RESIZE_ITEM,
  END_RESIZING_ITEM,
  DUPLICATE_ITEM,
} from "../constants";

export function selectItem(layerID, itemID) {
  return {
    type: SELECT_ITEM,
    layerID,
    itemID,
  };
}

export function selectToolDrawingItem(sceneComponentType, layerID, properties) {
  return {
    type: SELECT_TOOL_DRAWING_ITEM,
    sceneComponentType,
    layerID,
    properties,
  };
}

export function updateDrawingItem(layerID, x, y) {
  return {
    type: UPDATE_DRAWING_ITEM,
    layerID,
    x,
    y,
  };
}

export function endDrawingItem(layerID, x, y) {
  return {
    type: END_DRAWING_ITEM,
    layerID,
    x,
    y,
  };
}

export function beginDraggingItem(layerID, itemID, x, y) {
  return {
    type: BEGIN_DRAGGING_ITEM,
    layerID,
    itemID,
    x,
    y,
  };
}

export function updateDraggingItem(x, y) {
  return {
    type: UPDATE_DRAGGING_ITEM,
    x,
    y,
  };
}

export function endDraggingItem(x, y) {
  return {
    type: END_DRAGGING_ITEM,
    x,
    y,
  };
}

export function beginRotatingItem(layerID, itemID, x, y) {
  return {
    type: BEGIN_ROTATING_ITEM,
    layerID,
    itemID,
    x,
    y,
  };
}

export function updateRotatingItem(x, y) {
  return {
    type: UPDATE_ROTATING_ITEM,
    x,
    y,
  };
}

export function endRotatingItem(x, y) {
  return {
    type: END_ROTATING_ITEM,
    x,
    y,
  };
}
export function selectResizeItem(layerID, itemID) {
  return {
    type: SELECT_RESIZE_ITEM,
    layerID,
    itemID,
  };
}
export function resizeItem(x, y) {
  return {
    type: RESIZE_ITEM,
    x,
    y,
  };
}
export function endResizingItem(x, y) {
  return {
    type: END_RESIZING_ITEM,
    x,
    y,
  };
}

export function duplicate(layerID, itemID) {
  return {
    type: DUPLICATE_ITEM,
    layerID,
    itemID,
  };
}
