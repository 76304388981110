import React from "react";
import PropTypes from "prop-types";
import { FormLabel, FormSlider } from "../../components/style/export";
import PropertyStyle from "./shared-property-style";

const tableStyle = {
  width: "100%",
  borderSpacing: "2px 0",
  marginBottom: "2px",
};
const firstTdStyle = { width: "6em", textTransform: "capitalize" };

export default function PropertyRange({
  value,
  onUpdate,
  configs,
  sourceElement,
  internalState,
  state,
}) {

  let update = (val) => {
    if (configs.hook) {
      return configs
        .hook(val, sourceElement, internalState, state)
        .then((_val) => {
          return onUpdate(_val);
        });
    }

    return onUpdate(val);
  };

  return (
    <table className="PropertyRange" style={PropertyStyle.tableStyle}>
      <tbody>
        <tr>
          <td style={PropertyStyle.firstTdStyle}>
            <FormLabel>{configs.label}</FormLabel>
          </td>
          <td>
            <FormSlider
              min={configs.min || 1}
              max={configs.max || 25}
              type={"resize-slider"}
              textValue={value}
              value={value}
              onChange={(e) => {
                update(parseInt(e.target.value));
              }}
            />
            <button onClick={()=>{update(configs.defaultValue)}}>Reset</button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

PropertyRange.propTypes = {
  value: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired,
};
