import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "toilet",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Toilet",
    description: "Toilet",
    image: require("./Toilet.svg"),
    svgIcon: require("./Toilet.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 50,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 50,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M49.53,0C57,0,64.45.05,71.9,0c1.15,0,1.5.29,1.47,1.47-.24,8-.4,16-.63,24.05a2.33,2.33,0,0,0,.82,2A42,42,0,0,1,86,48.79a12,12,0,0,1,.3,2.74c0,4.53-.11,9.06.06,13.59a26.37,26.37,0,0,1-2.18,10.75,40.67,40.67,0,0,1-13.2,17.7A34.56,34.56,0,0,1,58,99.82a5.2,5.2,0,0,1-1.31.11c-4,0-7.95-.15-11.9,0a20.43,20.43,0,0,1-10.48-2.64C22.77,91.42,16,81.81,13,69.33a6.75,6.75,0,0,1-.11-1.56c0-4.69.14-9.39-.05-14.07a26.2,26.2,0,0,1,2.06-10.53A41.19,41.19,0,0,1,25.55,27.49a2.3,2.3,0,0,0,.79-2Q26,13.84,25.7,2.18C25.65-.15,25.32,0,27.75,0Zm-22,1.21c0,.46,0,.78,0,1.1q.32,12.79.64,25.61a3.48,3.48,0,0,1-.83,2.53,38.41,38.41,0,0,0-9,20.52c-1.65,13.18,1.47,24.94,10.81,34.66a27.22,27.22,0,0,0,14.71,8.17C55.51,96,64.86,91.88,72.25,83c5.91-7.1,8.58-15.47,8.84-24.65C81.37,48,78.48,38.6,71.77,30.52a3.65,3.65,0,0,1-.88-2.62q.36-12.63.67-25.26c0-.46,0-.92,0-1.41ZM19.4,39.47c-.2.36-.5.81-.73,1.3a41.67,41.67,0,0,0-3.29,27.87c2.28,10.28,7.51,18.76,16.54,24.44,11.35,7.13,23.11,7.36,34.57.46,11.24-6.78,16.74-17.38,18.11-30.2a41,41,0,0,0-3.84-22c-.44-.94-1-1.84-1.44-2.76a22.42,22.42,0,0,0,1,3,44.85,44.85,0,0,1-1,33.32c-3.86,9.05-10.08,15.94-19.41,19.45-10.71,4-20.6,2-29.46-5.06A36.56,36.56,0,0,1,19,73.05a44.16,44.16,0,0,1-2-24.94C17.61,45.15,18.61,42.28,19.4,39.47Z"
              transform="translate(-12.84 0)"
            />
            <path
              class="cls-1"
              d="M27.52,1.23h44c0,.49,0,1,0,1.41q-.33,12.63-.67,25.26a3.65,3.65,0,0,0,.88,2.62c6.71,8.08,9.6,17.44,9.32,27.85-.26,9.18-2.93,17.55-8.84,24.65-7.39,8.86-16.74,13-28.36,10.8a27.22,27.22,0,0,1-14.71-8.17C19.84,75.93,16.72,64.17,18.37,51a38.41,38.41,0,0,1,9-20.52,3.48,3.48,0,0,0,.83-2.53q-.35-12.81-.64-25.61C27.51,2,27.52,1.69,27.52,1.23Zm40.64,8C68.55,8,67.89,8,67.12,8H31.64a5.51,5.51,0,0,0-.88.13l.1,1.06Z"
              transform="translate(-12.84 0)"
            />
            <path
              class="cls-1"
              d="M19.4,39.47c-.79,2.81-1.79,5.68-2.41,8.64a44.16,44.16,0,0,0,2,24.94A36.56,36.56,0,0,0,30.43,89.27c8.86,7,18.75,9.09,29.46,5.06,9.33-3.51,15.55-10.4,19.41-19.45a44.85,44.85,0,0,0,1-33.32,22.42,22.42,0,0,1-1-3c.49.92,1,1.82,1.44,2.76a41,41,0,0,1,3.84,22c-1.37,12.82-6.87,23.42-18.11,30.2-11.46,6.9-23.22,6.67-34.57-.46-9-5.68-14.26-14.16-16.54-24.44a41.67,41.67,0,0,1,3.29-27.87C18.9,40.28,19.2,39.83,19.4,39.47Z"
              transform="translate(-12.84 0)"
            />
            <path
              d="M68.16,9.2H30.86l-.1-1.06A5.51,5.51,0,0,1,31.64,8H67.12C67.89,8,68.55,8,68.16,9.2Z"
              transform="translate(-12.84 0)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
