import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Single-Bed",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Single-Bed",
    description: "Single-Bed",
    image: require("./Single-Bed.svg"),
    svgIcon: require("./Single-Bed.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
         <g id={`${element.type}-${element.id}`} data-name="corner shower">
         <path class="cls-1" d="M26.62,52.35V97.42c0,1,0,1.08,1.06,1.08h41.1c1,0,1.06,0,1.06-1.08q0-35.46,0-70.93V7.24c0-.35-.08-.72.35-1,.12-.06.16-.36.17-.56,0-1.13,0-2.26,0-3.39,0-.93-.07-1-1-1H26.84c-.47,0-.71.2-.71.69,0,1.26,0,2.52,0,3.79a1.54,1.54,0,0,0,.18.52,2.46,2.46,0,0,1,.3.77c0,5,0,10,0,15Z" transform="translate(-26.13 -1.34)"/>
            <path d="M26.62,52.35V22.12c0-5,0-10,0-15a2.46,2.46,0,0,0-.3-.77,1.54,1.54,0,0,1-.18-.52c0-1.27,0-2.53,0-3.79,0-.49.24-.7.71-.69H69.32c1,0,1,.07,1,1,0,1.13,0,2.26,0,3.39,0,.2,0,.5-.17.56-.43.23-.35.6-.35,1V26.49q0,35.48,0,70.93c0,1,0,1.08-1.06,1.08H27.68c-1,0-1.06,0-1.06-1.08ZM69.1,29.9a1.68,1.68,0,0,0-.09.29q-.42,3.06-.82,6.1c-.09.68-.39.84-1,.48A82.27,82.27,0,0,0,48.52,28a62.51,62.51,0,0,0-17.45-3.2c-1.22,0-2.45,0-3.69,0v73H69.1ZM30.52,7.9V19.81c12.17-2,23.65.45,34.85,5.09V7.9ZM68.68,27.11l-1-.44A76.56,76.56,0,0,0,51,21a52.17,52.17,0,0,0-18.26-.86c-1.61.23-3.18.66-4.76,1-.23.05-.58.26-.59.42-.06.79,0,1.59,0,2.4,14.69-.23,27.8,4.62,40.2,12.05ZM26.84,5.79H69.62V2.06H26.84Zm.51,14.86c.67-.17,1.27-.36,1.89-.48.44-.08.58-.26.57-.71q0-5.61,0-11.21c0-1,.06-1,1.05-1h34.2c1,0,1,.07,1,1q0,8.19,0,16.39c0,.21,0,.55.15.61.92.46,1.86.87,2.85,1.32v-20H27.35Z" transform="translate(-26.13 -1.34)"/>
            <path class="cls-1" d="M69.1,29.9V97.75H27.38v-73c1.24,0,2.47,0,3.69,0A62.51,62.51,0,0,1,48.52,28a82.27,82.27,0,0,1,18.72,8.8c.56.36.86.2,1-.48q.41-3,.82-6.1A1.68,1.68,0,0,1,69.1,29.9Z" transform="translate(-26.13 -1.34)"/>
            <path class="cls-1" d="M30.52,7.9H65.37v17c-11.2-4.64-22.68-7.13-34.85-5.09Z" transform="translate(-26.13 -1.34)"/>
            <path class="cls-1" d="M68.68,27.11l-1.14,9C55.14,28.66,42,23.81,27.34,24c0-.81,0-1.61,0-2.4,0-.16.36-.37.59-.42,1.58-.38,3.15-.81,4.76-1A52.17,52.17,0,0,1,51,21a76.56,76.56,0,0,1,16.72,5.63Z" transform="translate(-26.13 -1.34)"/>
            <path class="cls-1" d="M26.84,5.79V2.06H69.62V5.79Z" transform="translate(-26.13 -1.34)"/>
            <path class="cls-1" d="M27.35,20.65V6.57H69.1v20c-1-.45-1.93-.86-2.85-1.32-.12-.06-.15-.4-.15-.61q0-8.2,0-16.39c0-1-.07-1-1-1H30.85c-1,0-1.05.06-1.05,1q0,5.6,0,11.21c0,.45-.13.63-.57.71C28.62,20.29,28,20.48,27.35,20.65Z" transform="translate(-26.13 -1.34)"/>
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
