import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "corner-shower",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Corner Shower",
    description: "corner shower",
    image: require("./corner-shower.svg"),
    svgIcon: require("./corner-shower.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue:100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(${-WIDTH / 2},${-DEPTH / 2})`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path d="M96.57,97.05c-5,0-9.74.08-14.5,0a100.05,100.05,0,0,1-26.73-4A70,70,0,0,1,18,67,71.41,71.41,0,0,1,6.73,42.66,129.81,129.81,0,0,1,2.66,17.82c-.29-4.22-.48-8.44-.72-12.67-.1-1.72.34-2.21,2-2.21q45.5,0,91,0c1.38,0,1.68.61,1.68,1.79,0,5.76,0,11.52,0,17.28q0,36.64-.05,73.3ZM88.5,88.5V10.92H64.94c0,.63,0,1.21,0,1.79,0,.79-.18,1.23-1.12.83l-.12-2.69h-6c0,3,0,5.9,0,8.82a1.87,1.87,0,0,0,1.09,1.89A9.1,9.1,0,0,1,62.41,33,9,9,0,0,1,51.6,38.08a8.5,8.5,0,0,1-6.34-7.43c-.5-4,1.17-7,4.48-9.12.42-.27,1-.68,1.06-1,.08-3.18.05-6.36.05-9.71H49.39c-2.61,0-5.22,0-7.83,0a6.35,6.35,0,0,0-3.87.79,3.85,3.85,0,0,1-2,.5c-6.57,0-13.14.06-19.71,0a9.37,9.37,0,0,1-2.78-.85c-.73-.25-1.43-.57-2.37-.95,0,.83,0,1.31,0,1.8A133.22,133.22,0,0,0,14,36.48C16.8,48.21,21.38,59,29.76,68A60.78,60.78,0,0,0,53.69,83.85a84.15,84.15,0,0,0,26.73,4.64C83.11,88.56,85.8,88.5,88.5,88.5ZM65,8.62c.49,0,.88.08,1.28.08l22.14.14c2,0,2.12.13,2.13,2.16q.09,39,.16,77.89a13.49,13.49,0,0,1-.14,1.61c-8.1.19-16,.13-23.93-1.18-13.23-2.2-25.07-7.26-35-16.49A61.2,61.2,0,0,1,15.81,49.35C11.17,37,9.25,24.07,8.64,10.94c-.11-2.4-.07-2.4,2.28-2.53.07,0,.15-.08.32-.19L11,6.29H5.2c.42,5.22.75,10.34,1.24,15.44a109.66,109.66,0,0,0,6.5,29,63,63,0,0,0,24,30c10.3,7,21.87,10.55,34.11,12.17a145.1,145.1,0,0,0,21.56.78,3.18,3.18,0,0,0,.63-.13V6.07H65ZM54.33,21.5a7.91,7.91,0,0,0-8,8,7.76,7.76,0,0,0,7.76,7.8,8.06,8.06,0,0,0,8-7.83A7.77,7.77,0,0,0,54.33,21.5Zm-15-16.6H12.1c.11,1.28.13,2.45.34,3.59.24,1.4,2,2.52,3.88,2.53q9,.06,18.06,0C38.38,11,40.05,8.94,39.31,4.9ZM52.09,20.4h4.35V6H52.09ZM50.84,6.32c-.19-.09-.27-.16-.35-.16l-7.82,0c-2.08,0-2.09,0-2.26,2a1.43,1.43,0,0,0,.06.36C43,9,50,8.78,50.75,8.29,49.45,7.75,49.45,7.75,50.84,6.32ZM64,6.24H57.62C58.43,7.1,62.88,7.14,64,6.24Zm-6.17,1.9v.45a28.38,28.38,0,0,0,5.9,0V8.14Z" />
            <path
              class="cls-1"
              d="M88.5,88.5c-2.7,0-5.39.06-8.08,0a84.15,84.15,0,0,1-26.73-4.64A60.78,60.78,0,0,1,29.76,68C21.38,59,16.8,48.21,14,36.48a133.22,133.22,0,0,1-3.26-24.43c0-.49,0-1,0-1.8.94.38,1.64.7,2.37.95a9.37,9.37,0,0,0,2.78.85c6.57.07,13.14,0,19.71,0a3.85,3.85,0,0,0,2-.5,6.35,6.35,0,0,1,3.87-.79c2.61,0,5.22,0,7.83,0h1.46c0,3.35,0,6.53-.05,9.71,0,.36-.64.77-1.06,1-3.31,2.16-5,5.11-4.48,9.12a8.5,8.5,0,0,0,6.34,7.43A9,9,0,0,0,62.41,33a9.1,9.1,0,0,0-3.64-11.4,1.87,1.87,0,0,1-1.09-1.89c.06-2.92,0-5.83,0-8.82h6l.12,2.69c.94.4,1.15,0,1.12-.83,0-.58,0-1.16,0-1.79H88.5ZM77.74,27.32A6.34,6.34,0,1,0,71.25,21,6.37,6.37,0,0,0,77.74,27.32Z"
            />
            <path
              class="cls-1"
              d="M65,8.62V6.07H93.21V93.5a3.18,3.18,0,0,1-.63.13A145.1,145.1,0,0,1,71,92.85c-12.24-1.62-23.81-5.13-34.11-12.17a63,63,0,0,1-24-30,109.66,109.66,0,0,1-6.5-29C6,16.63,5.62,11.51,5.2,6.29H11l.21,1.93c-.17.11-.25.19-.32.19-2.35.13-2.39.13-2.28,2.53.61,13.13,2.53,26,7.17,38.41A61.2,61.2,0,0,0,31.68,72.83c9.91,9.23,21.75,14.29,35,16.49,7.89,1.31,15.83,1.37,23.93,1.18a13.49,13.49,0,0,0,.14-1.61Q90.66,50,90.57,11c0-2-.14-2.15-2.13-2.16L66.3,8.7C65.9,8.7,65.51,8.66,65,8.62Z"
            />
            <path
              class="cls-1"
              d="M39.31,4.9c.74,4-.93,6.13-4.93,6.14q-9,0-18.06,0c-1.9,0-3.64-1.13-3.88-2.53-.21-1.14-.23-2.31-.34-3.59Z"
            />
            <path class="cls-1" d="M52.09,20.4V6h4.35V20.4Z" />
            <path
              class="cls-1"
              d="M50.84,6.32c-1.39,1.43-1.39,1.43-.09,2C50,8.78,43,9,40.47,8.56a1.43,1.43,0,0,1-.06-.36c.17-2,.18-2,2.26-2l7.82,0C50.57,6.16,50.65,6.23,50.84,6.32Z"
            />
            <path class="cls-1" d="M64,6.24c-1.07.9-5.52.86-6.33,0Z" />
            <path
              class="cls-1"
              d="M57.78,8.14h5.9v.43a28.38,28.38,0,0,1-5.9,0Z"
            />
            <path d="M77.74,27.32A6.34,6.34,0,1,1,84.22,21,6.38,6.38,0,0,1,77.74,27.32Zm0-1.15a5.19,5.19,0,1,0-5.4-5.2A5.27,5.27,0,0,0,77.76,26.17Z" />
            <path
              class="cls-1"
              d="M77.76,26.17a5.19,5.19,0,1,1,5.36-5.24A5.27,5.27,0,0,1,77.76,26.17Zm.06-1.42a3.74,3.74,0,1,0-.06-7.47,3.74,3.74,0,1,0,.06,7.47Z"
            />
            <path d="M77.82,24.75a3.74,3.74,0,1,1-.06-7.47,3.74,3.74,0,1,1,.06,7.47Zm0-1.14A2.63,2.63,0,0,0,80.45,21a2.59,2.59,0,0,0-2.75-2.51A2.64,2.64,0,0,0,75.11,21,2.7,2.7,0,0,0,77.82,23.61Z" />
            <path
              class="cls-1"
              d="M77.82,23.61A2.7,2.7,0,0,1,75.11,21a2.64,2.64,0,0,1,2.59-2.56A2.59,2.59,0,0,1,80.45,21,2.63,2.63,0,0,1,77.82,23.61Z"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
