import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormLabel, FormSlider } from "../../components/style/export";
import PropertyStyle from "./shared-property-style";

const tableStyle = {
  width: "100%",
  borderSpacing: "2px 0",
  marginBottom: "2px",
};
const btnstyle = {
  flex: 0.5,
  margin: "5px",
  height: "30px",
  backgroundColor: "rgb(255, 93, 23)",
};
const firstTdStyle = { width: "6em", textTransform: "capitalize" };

export default function PropertyRange({
  value,
  onUpdate,
  configs,
  sourceElement,
  internalState,
  state,
}) {

  let update = (val) => {
    if (configs.hook) {
      return configs
        .hook(val, sourceElement, internalState, state)
        .then((_val) => {
          return onUpdate(_val);
        });
    }

    return onUpdate(val);
  };
  return (
    <Fragment>
      <table className="PropertyRange" style={PropertyStyle.tableStyle}>
        <tbody>
          <tr>
            <td style={PropertyStyle.firstTdStyle}>
              <FormLabel>{configs.label}</FormLabel>
            </td>
            <td>
              <label style={{fontSize:"13px"}}>{parseFloat(value===configs.defaultValue?value/100:value).toFixed(2)}m</label>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "13px",
        }}
      >
        <Fragment>
          <button
            disabled={
              (value === configs.defaultValue ? value : value * 100) ===
              configs.min
            }
            style={btnstyle}
            onClick={() => {
              update(
                (value == configs.defaultValue ? value - 1 : value * 100 - 1) /
                  100
              );
            }}
          >
            -
          </button>
          {configs.min/100}m
          <FormSlider
            min={configs.min || 0.5}
            max={configs.max || 2.5}
            type={"resize-slider"}
            textValue={value == configs.defaultValue ? value / 100 : value}
            value={value == configs.defaultValue ? value : value * 100}
            onChange={(e) => {
              update(parseFloat(e.target.value / 100));
            }}
          />
          {configs.max/100}m
        </Fragment>
        <button
          disabled={
            (value === configs.defaultValue ? value : value * 100) ===
            configs.max
          }
          style={btnstyle}
          onClick={() => {
            update(
              (value == configs.defaultValue ? value + 1 : value * 100 + 1) /
                100
            );
          }}
        >
          +
        </button>
      </div>

      <button
        onClick={() => {
          update(configs.defaultValue);
        }}
      >
        Reset
      </button>
    </Fragment>
  );
}

PropertyRange.propTypes = {
  value: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired,
};
