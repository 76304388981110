import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Running-Man-Symbol",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Running Man Symbol",
    description: "Running-Man-Symbol",
    image: require("./Running-Man-Symbol.svg"),
    svgIcon: require("./Running-Man-Symbol.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.runningmancls1{fill:#009f4d;}.runningmancls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
           <path class="runningmancls1" d="M.07,100.07V.07h100v100ZM46.73,54.68l.21.19L58.45,66a.55.55,0,0,1,.17.61l-1.77,7.83q-1.49,6.61-3,13.25a5.86,5.86,0,0,0-.09,2.5,5.06,5.06,0,0,0,9.91,0l2-8.73q1.56-6.92,3.11-13.84c.31-1.37.61-2.75,1-4.12a8.09,8.09,0,0,0,.26-2.9,5.27,5.27,0,0,0-1.48-3.19l-10.05-10-.31-.33L69.58,33.8l.26.25L71,35.21q2.94,3,5.9,5.93a4.22,4.22,0,0,0,3.15,1.27,4.42,4.42,0,0,0,2.17-.7,8.48,8.48,0,0,0,1.54-1.26L95.33,28.78a4.06,4.06,0,0,0-1.61-6.71,3.79,3.79,0,0,0-4.13,1q-4.74,4.69-9.45,9.41a3.38,3.38,0,0,1-.27.26l-.26-.25L76.17,29,68,20.83a5.09,5.09,0,0,0-3.71-1.56l-18.57,0a5.43,5.43,0,0,0-1,.07,4.23,4.23,0,0,0-2.45,1.49Q36.66,27.33,31,33.79c-.6.7-1.19,1.41-1.83,2.07a3.94,3.94,0,0,0-.4,5.15,4,4,0,0,0,6.19.22l6-6.93,5.91-6.84a.56.56,0,0,1,.47-.24l6.3.05a1.81,1.81,0,0,1,.25.05c-.16.18-.28.34-.41.49L45,37.65,27.68,57.74a.82.82,0,0,1-.71.32L9.31,58a5.89,5.89,0,0,0-1,.1,5,5,0,0,0,1,10c2.91,0,5.82,0,8.73,0H30.68a10.52,10.52,0,0,0,3.25-.47,6,6,0,0,0,2.75-1.73q4.83-5.41,9.68-10.8Zm34.9-41a7.81,7.81,0,1,0-7.78,7.82A7.79,7.79,0,0,0,81.63,13.64Z" transform="translate(-0.07 -0.07)"/>
    <path class="runningmancls2" d="M46.73,54.68l-.37.4q-4.84,5.4-9.68,10.8a6,6,0,0,1-2.75,1.73,10.52,10.52,0,0,1-3.25.47H18c-2.91,0-5.82,0-8.73,0a5,5,0,0,1-1-10,5.89,5.89,0,0,1,1-.1L27,58.06a.82.82,0,0,0,.71-.32L45,37.65l8.5-9.84c.13-.15.25-.31.41-.49a1.81,1.81,0,0,0-.25-.05l-6.3-.05a.56.56,0,0,0-.47.24L41,34.3l-6,6.93A4,4,0,0,1,28.79,41a3.94,3.94,0,0,1,.4-5.15c.64-.66,1.23-1.37,1.83-2.07q5.63-6.46,11.23-12.94a4.23,4.23,0,0,1,2.45-1.49,5.43,5.43,0,0,1,1-.07l18.57,0A5.09,5.09,0,0,1,68,20.83l8.2,8.2,3.44,3.44.26.25a3.38,3.38,0,0,0,.27-.26q4.73-4.71,9.45-9.41a3.79,3.79,0,0,1,4.13-1,4.06,4.06,0,0,1,1.61,6.71L83.76,40.45a8.48,8.48,0,0,1-1.54,1.26,4.42,4.42,0,0,1-2.17.7,4.22,4.22,0,0,1-3.15-1.27q-3-3-5.9-5.93l-1.16-1.16-.26-.25L58.12,47.07l.31.33,10.05,10A5.27,5.27,0,0,1,70,60.63a8.09,8.09,0,0,1-.26,2.9c-.34,1.37-.64,2.75-1,4.12q-1.56,6.91-3.11,13.84l-2,8.73a5.06,5.06,0,0,1-9.91,0,5.86,5.86,0,0,1,.09-2.5q1.5-6.63,3-13.25l1.77-7.83a.55.55,0,0,0-.17-.61L46.94,54.87Z" transform="translate(-0.07 -0.07)"/>
    <path class="runningmancls2" d="M81.63,13.64a7.81,7.81,0,1,1-15.61,0,7.81,7.81,0,0,1,15.61,0Z" transform="translate(-0.07 -0.07)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
