const localStorage = window.hasOwnProperty("localStorage")
  ? window.localStorage
  : false;
import { loadProject, setMode } from "../actions/project-actions";
import { getFloorPlan, updateFloorPlan } from "../api/service";
import { confirmAlert } from "react-confirm-alert";
import { API_REQUEST_FINISH, API_REQUEST_START, MODE_IDLE } from "../constants";
// import { MODE_IDLE } from "react-svg-pan-zoom";

const TIMEOUT_DELAY = 10000;

let timeout = null;
const confirmAlertfn = (autosaveKey,store) =>
  confirmAlert({
    closeOnClickOutside:false,
    overlayClassName: "overlay-dialog",
    title: "Confirm to continue",
    message: "Are you sure you want to continue from your last checkpoint?",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          let data = localStorage.getItem(autosaveKey);
          let json = JSON.parse(data);
          store.dispatch(loadProject(json));
        },
      },
      {
        label: "No",
        onClick: () => {
          localStorage.removeItem(autosaveKey);
        },
      },
    ],
  });
export default function autosave(autosaveKey, delay) {
  return (store, stateExtractor) => {
    delay = delay || TIMEOUT_DELAY;
    // store.dispatch({ type: 'API_REQUEST_START' });
    store.dispatch(setMode(API_REQUEST_START));

    if (!autosaveKey) return;
    if (!localStorage) return;
    const searchParams = new URLSearchParams(window.location.search);
    const floorId = searchParams.get("floorId");
    const token = searchParams.get("token");
    //revert
    if (!floorId && !token) {
      alert("You are not authorized to access the app.");
      // return;
      // window.location.href = "http://frawizard.thegrapestudio.com/";
      // window.location.href = "http://frawizard-portal.s3-website-us-east-1.amazonaws.com/";
      store.dispatch(setMode(MODE_IDLE));

    }
    if (localStorage.getItem(autosaveKey) !== null) {
      if (JSON.parse(localStorage.getItem(autosaveKey)).floorId !== floorId) {
        localStorage.removeItem(autosaveKey);
      }
    }
  
    getFloorPlan(floorId, token)
      .then((res) => {
        if (res.data.data && res.data.data.floorplan) {
          store.dispatch(loadProject(res.data.data.floorplan));
        } else {
          if (localStorage.getItem(autosaveKey) !== null) {
            if (JSON.parse(localStorage.getItem(autosaveKey)).floorId === floorId) {
              // localStorage.removeItem(autosaveKey);
              confirmAlertfn(autosaveKey,store);
            }
          }
          // confirmAlertfn();
          // let data = localStorage.getItem(autosaveKey);
          // let json = JSON.parse(data);
          // store.dispatch(loadProject(json));
        }
      })
      .catch(() => {
        store.dispatch(setMode(MODE_IDLE));
        if (localStorage.getItem(autosaveKey) !== null) {
          if (JSON.parse(localStorage.getItem(autosaveKey)).floorId === floorId) {
            // localStorage.removeItem(autosaveKey);
            confirmAlertfn(autosaveKey,store);
          }
        }
      }).finally(() => {
        // Set loading state to false when API request is finished
        store.dispatch(setMode(MODE_IDLE));
      });




    // } catch (error) {
    //   confirmAlertfn()
    //   // let data = localStorage.getItem(autosaveKey);
    //   // let json = JSON.parse(data);
    //   // store.dispatch(loadProject(json));
    // }

    //update
    store.subscribe(() => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        let state = stateExtractor(store.getState());
        localStorage.setItem(
          autosaveKey,
          JSON.stringify({ ...state.scene.toJS(), floorId, token })
        );
        try {
          updateFloorPlan(
            { floorplan: JSON.stringify(state.scene.toJS()) },
            floorId,
            token
          )
            .then((res) => {
              if (res.data.data) {
              }
            })
            .catch((err) => {
              // alert("Error saving to the server.");
            });
        } catch (error) {
          // alert("Error saving to the server.");
        }

        /*let scene = state.sceneHistory.last;
        if (scene) {
          let json = JSON.stringify(scene.toJS());
          localStorage.setItem(autosaveKey, json);
        }*/
      }, delay);
    });
  };
}
