import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Evacuation-Bed",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Evacuation Bed",
    description: "Evacuation-Bed",
    image: require("./Evacuation-Bed.svg"),
    svgIcon: require("./Evacuation-Bed.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.evacbedcls1{fill:#da2727;}.evacbedcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="evacbedcls1"
              d="M0,95.72V0H98.59V95.72Zm12.68-33.4H86.46V68a4.7,4.7,0,0,0,1.17,3.23A5.07,5.07,0,0,0,93,72.85a4.87,4.87,0,0,0,3.35-2.8,5.07,5.07,0,0,0,.44-2.24q0-15,0-30.05a6.19,6.19,0,0,0-.06-1,4.72,4.72,0,0,0-1.78-3.06,5,5,0,0,0-2.74-1.11,5.27,5.27,0,0,0-3.36.76,4.9,4.9,0,0,0-2.34,4.3V50c-.06,0-.07.05-.09.05l-.13,0H12.93l-.16,0,0,0L12.68,50V28.27a10.14,10.14,0,0,0-.1-1.46,4.52,4.52,0,0,0-2-3.19,5.71,5.71,0,0,0-5.09-.68,5,5,0,0,0-2.94,2.41A4.66,4.66,0,0,0,2,27.61V68.23a4.66,4.66,0,0,0,.73,2.41,5,5,0,0,0,3,2.18,6,6,0,0,0,3.79-.18,4.87,4.87,0,0,0,2.46-2,5.18,5.18,0,0,0,.73-2.77c0-1.69,0-3.38,0-5.07ZM58,46.38h2.49c5,0,9.93,0,14.9,0A6.68,6.68,0,0,0,80.69,44a6.25,6.25,0,0,0,.67-7.18,6,6,0,0,0-1.75-2,6.71,6.71,0,0,0-4-1.27H40.42a6.91,6.91,0,0,0-1.11.08,6.49,6.49,0,0,0-4.38,2.7c-.11.16-.21.32-.31.49a6,6,0,0,0,.08,6.31,6.84,6.84,0,0,0,1.91,2,8,8,0,0,0,1.25.69,6.55,6.55,0,0,0,2.8.52C46.43,46.37,52.21,46.38,58,46.38ZM23.93,32h-.67c-.24,0-.47.07-.7.11a7.17,7.17,0,0,0-5.63,4.73,6.58,6.58,0,0,0,.16,5.06,7.18,7.18,0,0,0,2.79,3.27,7.42,7.42,0,0,0,4.55,1.16A7.52,7.52,0,0,0,28.31,45a6.64,6.64,0,0,0,2-2.13,6.78,6.78,0,0,0,1-4.48,6.89,6.89,0,0,0-1.22-3.21A7.41,7.41,0,0,0,23.93,32Z"
              transform="translate(0 0)"
            />
            <path
              class="evacbedcls2"
              d="M12.68,62.32v.44c0,1.69,0,3.38,0,5.07A5.18,5.18,0,0,1,12,70.6a4.87,4.87,0,0,1-2.46,2,6,6,0,0,1-3.79.18,5,5,0,0,1-3-2.18A4.66,4.66,0,0,1,2,68.23V27.61a4.66,4.66,0,0,1,.55-2.26,5,5,0,0,1,2.94-2.41,5.71,5.71,0,0,1,5.09.68,4.52,4.52,0,0,1,2,3.19,10.14,10.14,0,0,1,.1,1.46V50l.06.06,0,0,.16,0H86.24l.13,0s0,0,.09-.05V37.69a4.9,4.9,0,0,1,2.34-4.3,5.27,5.27,0,0,1,3.36-.76,5,5,0,0,1,2.74,1.11,4.72,4.72,0,0,1,1.78,3.06,6.19,6.19,0,0,1,.06,1q0,15,0,30.05a5.07,5.07,0,0,1-.44,2.24A4.87,4.87,0,0,1,93,72.85a5.07,5.07,0,0,1-5.33-1.65A4.7,4.7,0,0,1,86.46,68V62.32Z"
              transform="translate(0 0)"
            />
            <path
              class="evacbedcls2"
              d="M58,46.38c-5.77,0-11.55,0-17.32,0a6.55,6.55,0,0,1-2.8-.52,8,8,0,0,1-1.25-.69,6.84,6.84,0,0,1-1.91-2,6,6,0,0,1-.08-6.31c.1-.17.2-.33.31-.49a6.49,6.49,0,0,1,4.38-2.7,6.91,6.91,0,0,1,1.11-.08H75.58a6.71,6.71,0,0,1,4,1.27,6,6,0,0,1,1.75,2A6.25,6.25,0,0,1,80.69,44a6.68,6.68,0,0,1-5.32,2.37c-5,0-9.93,0-14.9,0Z"
              transform="translate(0 0)"
            />
            <path
              class="evacbedcls2"
              d="M23.93,32a7.41,7.41,0,0,1,6.12,3.14,6.89,6.89,0,0,1,1.22,3.21,6.78,6.78,0,0,1-1,4.48,6.64,6.64,0,0,1-2,2.13,7.52,7.52,0,0,1-3.88,1.37,7.42,7.42,0,0,1-4.55-1.16,7.18,7.18,0,0,1-2.79-3.27,6.58,6.58,0,0,1-.16-5.06,7.17,7.17,0,0,1,5.63-4.73c.23,0,.46-.09.7-.11Z"
              transform="translate(0 0)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
