import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {FaSave as IconSave} from 'react-icons/fa';
import ToolbarButton from './toolbar-button';
import {browserDownload}  from '../../utils/browser';
import { Project } from '../../class/export';
import { updateFloorPlan } from '../../api/service';
import { GiSave } from "react-icons/gi";

export default function ToolbarSaveButton({state,rtl}, {translator}) {
  const [savechange, setSaveChange] = useState(null)
  let saveProjectToFile = e => {
    e.preventDefault();
    const searchParams = new URLSearchParams(window.location.search);
    const floorId = searchParams.get("floorId");
    const token = searchParams.get("token");
    state = Project.unselectAll( state ).updatedState;
    try {
      setSaveChange(true)
      updateFloorPlan(
        { floorplan: JSON.stringify(state.get('scene').toJS()) },
        floorId,
        token
      )
        .then((res) => {
          setSaveChange(null)
          if (res.data.data) {
            //  
          }
        })
        .catch((err) => {
          setSaveChange(null)
          // alert("Error saving to the server.");
        });
    } catch (error) {
      // alert("Error saving to the server.");
    }
    // browserDownload(state.get('scene').toJS());
  };

  return (
    <ToolbarButton rtl={rtl} active={false} tooltip={translator.t('Save project')} onClick={saveProjectToFile}>
      {savechange?<GiSave/>:<IconSave />}
    </ToolbarButton>
  );
}

ToolbarSaveButton.propTypes = {
  state: PropTypes.object.isRequired,
  rtl: PropTypes.bool.isRequired

};

ToolbarSaveButton.contextTypes = {
  translator: PropTypes.object.isRequired,
};
