import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "corner-sink",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Corner Sink",
    description: "corner-sink",
    image: require("./corner-sink.svg"),
    svgIcon: require("./corner-sink.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-34, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              class="cls-1"
              d="M9.91,45.61q0,20.24,0,40.46a4.44,4.44,0,0,0,.18,1.51,22,22,0,0,0,8,10.5,7,7,0,0,0,4.3,1.33c5.86-.1,11.73-.08,17.59,0a12.46,12.46,0,0,0,5.19-1.17A68.66,68.66,0,0,0,64.81,85.07a93.22,93.22,0,0,0,25.9-41,36,36,0,0,0,1.67-8.58c.26-5.19.08-10.4.07-15.61a8,8,0,0,0-.14-2c-1.42-5.36-4-10-8.58-13.3a12.71,12.71,0,0,0-8-2.84C69.83,2,63.91,2.21,58,2.33c-15.21.31-30.42.1-45.63.1-1.84,0-2.46.63-2.46,2.5Z"
              transform="translate(-9.91 -1.81)"
            />
            <path
              d="M9.91,45.61V4.93c0-1.87.62-2.5,2.46-2.5,15.21,0,30.42.21,45.63-.1,5.91-.12,11.83-.34,17.74-.51a12.71,12.71,0,0,1,8,2.84C88.31,8,90.89,12.6,92.31,18a8,8,0,0,1,.14,2c0,5.21.19,10.42-.07,15.61a36,36,0,0,1-1.67,8.58,93.22,93.22,0,0,1-25.9,41A68.66,68.66,0,0,1,45.18,98.23,12.46,12.46,0,0,1,40,99.4c-5.86-.07-11.73-.09-17.59,0a7,7,0,0,1-4.3-1.33,22,22,0,0,1-8-10.5,4.44,4.44,0,0,1-.18-1.51Q9.91,65.85,9.91,45.61ZM12.15,5.13V6.75c0,17.33,0,34.67,0,52a24.91,24.91,0,0,1-.59,5.09c-1.46,7-2,14,.1,21,1.5,5,4.27,9.06,8.95,11.63,4.47,2.45,9.28,2.81,14.2,2A46,46,0,0,0,52,91.66,83.76,83.76,0,0,0,75.4,69.31c7.36-10.15,12.72-21.22,14.91-33.66,1.1-6.24,1.22-12.46-.74-18.58A18.64,18.64,0,0,0,80.66,5.81,20.42,20.42,0,0,0,66.13,4a54.78,54.78,0,0,1-12.3,1.15c-13.37-.05-26.75,0-40.13,0Z"
              transform="translate(-9.91 -1.81)"
            />
            <path
              class="cls-1"
              d="M12.15,5.13H13.7c13.38,0,26.76,0,40.13,0A54.78,54.78,0,0,0,66.13,4,20.42,20.42,0,0,1,80.66,5.81a18.64,18.64,0,0,1,8.91,11.26c2,6.12,1.84,12.34.74,18.58C88.12,48.09,82.76,59.16,75.4,69.31A83.76,83.76,0,0,1,52,91.66a46,46,0,0,1-17.25,6.83c-4.92.78-9.73.42-14.2-2-4.68-2.57-7.45-6.66-8.95-11.63-2.1-7-1.56-14-.1-21a24.91,24.91,0,0,0,.59-5.09c.05-17.34,0-34.68,0-52Zm2.23,67.39c.28,2.43.39,4.9.85,7.29,1.86,9.62,9.2,14.91,18.9,13.81a37.69,37.69,0,0,0,16.15-6C66.74,77.09,77.94,62.34,84.45,44c2.42-6.8,3.63-13.82,2.45-21.07a18.19,18.19,0,0,0-5.25-10.76c-4-3.84-9-4.69-14.3-4.06-6.37.76-12,3.4-17.3,6.91-13.21,8.81-22.8,20.73-29.56,35A61.75,61.75,0,0,0,14.38,72.52Z"
              transform="translate(-9.91 -1.81)"
            />
            <path
              d="M14.38,72.52A61.75,61.75,0,0,1,20.49,50c6.76-14.26,16.35-26.18,29.56-35C55.31,11.52,61,8.88,67.35,8.12c5.3-.63,10.26.22,14.3,4.06A18.19,18.19,0,0,1,86.9,22.94c1.18,7.25,0,14.27-2.45,21.07C77.94,62.34,66.74,77.09,50.28,87.63a37.69,37.69,0,0,1-16.15,6c-9.7,1.1-17-4.19-18.9-13.81C14.77,77.42,14.66,75,14.38,72.52ZM85.67,27.75c-.06-.8-.13-2.3-.31-3.79-1.14-9.31-7.5-14.61-16.83-14-5.35.36-10.17,2.38-14.76,5a74,74,0,0,0-21,18.64c-6.9,8.7-12.24,18.24-15.05,29.06-1.47,5.65-2.14,11.39-.74,17.17C18.46,86,22,90.27,28.36,91.4a23,23,0,0,0,7.31,0c5.88-.86,11.09-3.51,15.92-6.85A83.89,83.89,0,0,0,81,48.42,51.74,51.74,0,0,0,85.67,27.75Z"
              transform="translate(-9.91 -1.81)"
            />
            <path
              class="cls-1"
              d="M85.67,27.75A51.74,51.74,0,0,1,81,48.42,83.89,83.89,0,0,1,51.59,84.53c-4.83,3.34-10,6-15.92,6.85a23,23,0,0,1-7.31,0C22,90.27,18.46,86,17,79.87c-1.4-5.78-.73-11.52.74-17.17,2.81-10.82,8.15-20.36,15.05-29.06A74,74,0,0,1,53.77,15c4.59-2.64,9.41-4.66,14.76-5,9.33-.63,15.69,4.67,16.83,14C85.54,25.45,85.61,27,85.67,27.75ZM76,37.63c1.57-.43,2.54-2.06,1.07-3.52a86.65,86.65,0,0,1-5.72-6.67A2.36,2.36,0,0,1,70.89,26a7.64,7.64,0,0,0-3.09-6.81A6.66,6.66,0,0,0,64.69,18C63.26,17.8,61,19.6,61,20.92a11.85,11.85,0,0,0,.51,3.33,6.85,6.85,0,0,0,6.31,5.17.94.94,0,0,1,.57.25c2,2.3,4,4.59,5.94,6.93a1.83,1.83,0,0,1,0,2.35c-.28.42-.55.86-.86,1.27-1.88,2.4-3,2.42-4.92.08-2.54-3-5.07-6.08-7.61-9.12l-4.12-4.94-.78.89a4.55,4.55,0,0,0,.34.49q5.7,6.84,11.41,13.67A12,12,0,0,0,69.67,43a2.07,2.07,0,0,0,2.48.11A8.28,8.28,0,0,0,76,37.63ZM47.41,60.39c-.12-.68-.17-1.38-.35-2a5.22,5.22,0,0,0-4.49-4,4.84,4.84,0,0,0-4.9,3.35,6.61,6.61,0,0,0,.82,6.76,4.58,4.58,0,0,0,7.51,0A7,7,0,0,0,47.41,60.39Z"
              transform="translate(-9.91 -1.81)"
            />
            <path
              d="M76,37.63a8.28,8.28,0,0,1-3.88,5.48A2.07,2.07,0,0,1,69.67,43a12,12,0,0,1-1.85-1.71q-5.73-6.81-11.41-13.67a4.55,4.55,0,0,1-.34-.49l.78-.89L61,31.18c2.54,3,5.07,6.09,7.61,9.12,2,2.34,3,2.32,4.92-.08.31-.41.58-.85.86-1.27a1.83,1.83,0,0,0,0-2.35c-2-2.34-3.94-4.63-5.94-6.93a.94.94,0,0,0-.57-.25,6.85,6.85,0,0,1-6.31-5.17A11.85,11.85,0,0,1,61,20.92c0-1.32,2.25-3.12,3.68-2.92a6.66,6.66,0,0,1,3.11,1.17A7.64,7.64,0,0,1,70.89,26a2.36,2.36,0,0,0,.49,1.46,86.65,86.65,0,0,0,5.72,6.67C78.57,35.57,77.6,37.2,76,37.63ZM76.26,36l.34-.27c-.19-.26-.37-.54-.58-.79-1.93-2.3-3.91-4.56-5.78-6.91a2.69,2.69,0,0,1-.5-1.85,6.76,6.76,0,0,0-3.39-6.42,2.73,2.73,0,0,0-2.92,0,2.81,2.81,0,0,0-1.1,2.82,6.56,6.56,0,0,0,2.78,4.79,3.55,3.55,0,0,0,3.31.65,1.66,1.66,0,0,1,1.38.47c1.76,2,3.43,4,5.14,6Z"
              transform="translate(-9.91 -1.81)"
            />
            <path
              d="M47.41,60.39A7,7,0,0,1,46,64.5a4.58,4.58,0,0,1-7.51,0,6.61,6.61,0,0,1-.82-6.76,4.84,4.84,0,0,1,4.9-3.35,5.22,5.22,0,0,1,4.49,4C47.24,59,47.29,59.71,47.41,60.39Zm-9.12,0A4.44,4.44,0,0,0,41.59,65a3.83,3.83,0,0,0,4.26-2.66,5.07,5.07,0,0,0-.57-4.9A3.56,3.56,0,0,0,41.06,56,4.63,4.63,0,0,0,38.29,60.4Z"
              transform="translate(-9.91 -1.81)"
            />
            <path
              class="cls-1"
              d="M76.26,36l-1.32-1.54c-1.71-2-3.38-4-5.14-6a1.66,1.66,0,0,0-1.38-.47,3.55,3.55,0,0,1-3.31-.65,6.56,6.56,0,0,1-2.78-4.79,2.81,2.81,0,0,1,1.1-2.82,2.73,2.73,0,0,1,2.92,0,6.76,6.76,0,0,1,3.39,6.42,2.69,2.69,0,0,0,.5,1.85c1.87,2.35,3.85,4.61,5.78,6.91.21.25.39.53.58.79Z"
              transform="translate(-9.91 -1.81)"
            />
            <path
              class="cls-1"
              d="M38.29,60.4A4.63,4.63,0,0,1,41.06,56a3.56,3.56,0,0,1,4.22,1.47,5.07,5.07,0,0,1,.57,4.9A3.83,3.83,0,0,1,41.59,65,4.44,4.44,0,0,1,38.29,60.4Z"
              transform="translate(-9.91 -1.81)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
