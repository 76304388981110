import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-direction-diagonal-top-right",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "direction diagonal top right",
    description: "direction-diagonal-top-right",
    image: require("./direction-diagonal-top-right.svg"),
    svgIcon: require("./direction-diagonal-top-right.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.directiontrcls1{fill:#0ea04b;}.directiontrcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="directiontrcls1"
              d="M6.23,90.77v-86H92.3c0,.16,0,.32,0,.48q0,42.54,0,85.07c0,.52,0,.47-.47.47H6.66C6.51,90.74,6.37,90.76,6.23,90.77Zm50.29-58.5,0,.06-.25.27-4.73,4.72-33,33.05c-.08.07-.15.15-.22.22a.14.14,0,0,0,0,.21l.14.14,7.65,7.65.28.26L64.8,40.43v.4q0,14.36,0,28.69a2.62,2.62,0,0,0,0,.28,1.12,1.12,0,0,0,.06.23l.3-.27q5.48-5.48,11-11a.83.83,0,0,0,.25-.63v-37c0-.49,0-.45-.46-.45h-37a.92.92,0,0,0-.7.3l-5.11,5.12c-1.93,1.93-3.87,3.86-5.8,5.8l-.25.27a.64.64,0,0,0,.47.09h29Z"
              transform="translate(-6.23 -4.72)"
            />
            <path
              class="directiontrcls2"
              d="M6.23,90.77c.14,0,.28,0,.43,0H91.8c.52,0,.47.05.47-.47q0-42.54,0-85.07c0-.16,0-.32,0-.48l0,0a1.69,1.69,0,0,1,0,.23V90.83H6.49C6.4,90.82,6.29,90.89,6.23,90.77Z"
              transform="translate(-6.23 -4.72)"
            />
            <path
              class="directiontrcls2"
              d="M56.52,32.27h-29a.64.64,0,0,1-.47-.09l.25-.27c1.93-1.94,3.87-3.87,5.8-5.8L38.2,21a.92.92,0,0,1,.7-.3h37c.46,0,.46,0,.46.45v37a.83.83,0,0,1-.25.63q-5.49,5.46-11,11l-.3.27a1.12,1.12,0,0,1-.06-.23,2.62,2.62,0,0,1,0-.28q0-14.34,0-28.69v-.4L26.36,78.85l-.28-.26-7.65-7.65-.14-.14a.14.14,0,0,1,0-.21c.07-.07.14-.15.22-.22L51.57,37.32,56.3,32.6l.25-.27Z"
              transform="translate(-6.23 -4.72)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
