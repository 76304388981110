import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Fire-Door-FD60",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Fire Door FD60",
    description: "Fire-Door-FD60",
    image: require("./Fire-Door-FD60.svg"),
    svgIcon: require("./Fire-Door-FD60.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.fd60cls1{fill:none;}.fd60cls2{fill:#3f51b5;}.fd60cls3{fill:#f6fe00;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          > <rect class="fd60cls1" x="10.31" y="3.02" width="77.92" height="90.7"/>
          <path class="fd60cls2" d="M1.66,50A48.44,48.44,0,1,1,50.07,98.5,48.36,48.36,0,0,1,1.66,50ZM50.1,64.46H89.5c.48,0,.43.06.43-.43V36.1c0-.5.07-.45-.44-.45H10.69c-.51,0-.44-.07-.44.44V64c0,.51-.05.44.45.44Z" transform="translate(-1.66 -1.63)"/>
          <path class="fd60cls3" d="M50.1,64.46H10.7c-.5,0-.45.07-.45-.44V36.09c0-.51-.07-.44.44-.44h78.8c.51,0,.43,0,.44.45V64c0,.49,0,.43-.43.43ZM31.21,50.29v9.46a1.76,1.76,0,0,1,0,.23c0,.17.06.22.22.21h8.23c.75,0,1.51,0,2.26-.11a11.07,11.07,0,0,0,5-1.75,8.83,8.83,0,0,0,3.75-5,11,11,0,0,0,.36-4,9,9,0,0,0-.79-3.18,9.5,9.5,0,0,0-5.83-5,13.5,13.5,0,0,0-4.33-.65H31.56c-.39,0-.35,0-.35.37Zm57-.15a14,14,0,0,0-.13-2,11,11,0,0,0-1.17-3.8,7.74,7.74,0,0,0-4.25-3.8,8.49,8.49,0,0,0-4.33-.38,7.59,7.59,0,0,0-5.08,3.12,10.4,10.4,0,0,0-1.84,4.91,16.63,16.63,0,0,0,0,4.05,10.91,10.91,0,0,0,1.12,3.84,7.82,7.82,0,0,0,3.71,3.65,8.47,8.47,0,0,0,4.42.7A7.56,7.56,0,0,0,85,58.67a8.91,8.91,0,0,0,2.69-4.29A14.25,14.25,0,0,0,88.2,50.14Zm-30.27-.59v-.26a7.44,7.44,0,0,1,.21-1.48A4.83,4.83,0,0,1,61.91,44a7.54,7.54,0,0,1,2.72-.1,5.29,5.29,0,0,1,2.4.82.16.16,0,0,0,.18,0l1.66-3.29A7.33,7.33,0,0,0,67,40.61a12.77,12.77,0,0,0-6.26-.26,9.16,9.16,0,0,0-3.87,1.79A9.33,9.33,0,0,0,53.54,48a15.29,15.29,0,0,0-.14,4.58A10.32,10.32,0,0,0,54.32,56a7.32,7.32,0,0,0,3.79,3.72,10,10,0,0,0,6.39.51,7,7,0,0,0,3.45-2,6,6,0,0,0,1.64-4.47,5.66,5.66,0,0,0-3-5,7.93,7.93,0,0,0-5.94-.71A5.8,5.8,0,0,0,57.93,49.55Zm-44.75.75v9.6c0,.28,0,.28.26.28h4c.25,0,.25,0,.26-.26V53.4c0-.46-.06-.41.4-.41h8.43c.31,0,.31,0,.31-.31v-3c0-.44.05-.38-.39-.38H18.06c-.32,0-.32,0-.32-.3V44.39c0-.3,0-.31.31-.31h9.76c.29,0,.3,0,.3-.29v-3.1c0-.26,0-.26-.26-.27H13.35c-.12,0-.18.06-.17.18v9.7Z" transform="translate(-1.66 -1.63)"/>
          <path d="M31.21,50.29v-9.5c0-.38,0-.37.35-.37h8.53a13.5,13.5,0,0,1,4.33.65,9.5,9.5,0,0,1,5.83,5A9,9,0,0,1,51,49.29a11,11,0,0,1-.36,4,8.83,8.83,0,0,1-3.75,5,11.07,11.07,0,0,1-5,1.75c-.75.07-1.51.11-2.26.11H31.43c-.16,0-.24,0-.22-.21a1.76,1.76,0,0,0,0-.23Zm4.58,0v5.83c0,.29,0,.29.29.29h4.16a7.11,7.11,0,0,0,2-.29,5.5,5.5,0,0,0,3.88-3.62,7,7,0,0,0,.28-3.22,5.53,5.53,0,0,0-1.94-3.65,6.24,6.24,0,0,0-3.92-1.44c-1.5,0-3,0-4.5,0h-.1c-.12,0-.16,0-.15.16v6Z" transform="translate(-1.66 -1.63)"/>
          <path d="M88.2,50.14a14.25,14.25,0,0,1-.54,4.24A8.91,8.91,0,0,1,85,58.67a7.56,7.56,0,0,1-4.32,1.8,8.47,8.47,0,0,1-4.42-.7,7.82,7.82,0,0,1-3.71-3.65,10.91,10.91,0,0,1-1.12-3.84,16.63,16.63,0,0,1,0-4.05,10.4,10.4,0,0,1,1.84-4.91,7.59,7.59,0,0,1,5.08-3.12,8.49,8.49,0,0,1,4.33.38,7.74,7.74,0,0,1,4.25,3.8,11,11,0,0,1,1.17,3.8A14,14,0,0,1,88.2,50.14Zm-12.31.15a16.32,16.32,0,0,0,.17,2.31,7.27,7.27,0,0,0,.86,2.47,3.32,3.32,0,0,0,5.68,0,6.69,6.69,0,0,0,.8-2.2,15.52,15.52,0,0,0,.06-4.81,6.78,6.78,0,0,0-.73-2.27A3.22,3.22,0,0,0,80.15,44a3.18,3.18,0,0,0-3,1.21,5,5,0,0,0-.84,1.68A12,12,0,0,0,75.89,50.29Z" transform="translate(-1.66 -1.63)"/>
          <path d="M57.93,49.55a5.8,5.8,0,0,1,2.72-1.5,7.93,7.93,0,0,1,5.94.71,5.66,5.66,0,0,1,3,5A6,6,0,0,1,68,58.24a7,7,0,0,1-3.45,2,10,10,0,0,1-6.39-.51A7.32,7.32,0,0,1,54.32,56a10.32,10.32,0,0,1-.92-3.45A15.29,15.29,0,0,1,53.54,48a9.33,9.33,0,0,1,3.37-5.83,9.16,9.16,0,0,1,3.87-1.79,12.77,12.77,0,0,1,6.26.26,7.33,7.33,0,0,1,1.83.83l-1.66,3.29a.16.16,0,0,1-.18,0,5.29,5.29,0,0,0-2.4-.82,7.54,7.54,0,0,0-2.72.1,4.83,4.83,0,0,0-3.77,3.83,7.44,7.44,0,0,0-.21,1.48Zm4,7.57.62-.06a2.86,2.86,0,0,0,2.62-2.69,2.76,2.76,0,0,0-1.52-2.77,3.34,3.34,0,0,0-1.3-.37,4,4,0,0,0-3,.76,2.86,2.86,0,0,0,0,4.35A4,4,0,0,0,61.93,57.12Z" transform="translate(-1.66 -1.63)"/>
          <path d="M13.18,50.3V40.6c0-.12.05-.18.17-.18h14.5c.25,0,.26,0,.26.27v3.1c0,.29,0,.29-.3.29H18.05c-.31,0-.31,0-.31.31V49c0,.29,0,.3.32.3h8.43c.44,0,.39-.06.39.38v3c0,.3,0,.31-.31.31H18.14c-.46,0-.4-.05-.4.41v6.53c0,.25,0,.25-.26.26h-4c-.26,0-.26,0-.26-.28V50.3Z" transform="translate(-1.66 -1.63)"/>
          <path class="fd60cls3" d="M35.79,50.3v-6c0-.11,0-.17.15-.16H36c1.5,0,3,0,4.5,0a6.24,6.24,0,0,1,3.92,1.44,5.53,5.53,0,0,1,1.94,3.65,7,7,0,0,1-.28,3.22,5.5,5.5,0,0,1-3.88,3.62,7.11,7.11,0,0,1-2,.29H36.08c-.29,0-.29,0-.29-.29Z" transform="translate(-1.66 -1.63)"/>
          <path class="fd60cls3" d="M75.89,50.29a12,12,0,0,1,.44-3.43,5,5,0,0,1,.84-1.68,3.18,3.18,0,0,1,3-1.21,3.22,3.22,0,0,1,2.58,1.81,6.78,6.78,0,0,1,.73,2.27,15.52,15.52,0,0,1-.06,4.81,6.69,6.69,0,0,1-.8,2.2,3.32,3.32,0,0,1-5.68,0,7.27,7.27,0,0,1-.86-2.47A16.32,16.32,0,0,1,75.89,50.29Z" transform="translate(-1.66 -1.63)"/>
          <path class="fd60cls3" d="M61.93,57.12a4,4,0,0,1-2.53-.78,2.86,2.86,0,0,1,0-4.35,4,4,0,0,1,3-.76,3.34,3.34,0,0,1,1.3.37,2.76,2.76,0,0,1,1.52,2.77,2.86,2.86,0,0,1-2.62,2.69Z" transform="translate(-1.66 -1.63)"/>
      
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
