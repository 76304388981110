import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "transparent-base-unit",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Transparent base unit",
    description: "transparent-base-unit",
    image: require("./Transparent-base-unit.svg"),
    svgIcon: require("./Transparent-base-unit.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 950,
      defaultValue: 200,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 950,
      defaultValue: 200,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-42, 34) scale(1, -1)`}>
            <defs>
        <style>{".tbu-1{fill:none;stroke:#6c6c6c;stroke-miterlimit:10;stroke-width:1.25px;}"}</style>
    </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
        
    <rect class="tbu-1" x="0.13" y="0.13" width="91.66" height="55.15"/>          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
