import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-warning",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "warning",
    description: "warning",
    image: require("./warning.svg"),
    svgIcon: require("./warning.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.warningcls1{fill:#f7f700;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="warningcls1"
              d="M0,0H86.13V85.9H0ZM43.15,78.86H80.06a4.84,4.84,0,0,0,1.41-.23,3.55,3.55,0,0,0,2.41-3.92A6.22,6.22,0,0,0,83,72.42c-.92-1.59-1.85-3.18-2.76-4.78Q74.9,58.29,69.55,49T58.85,30.29Q56.47,26.15,54.1,22L47.47,10.46c-.2-.34-.42-.68-.65-1A6,6,0,0,0,45,7.69a4.15,4.15,0,0,0-4.5,0,5.39,5.39,0,0,0-1.71,1.65c-.49.73-.94,1.49-1.38,2.24-.85,1.47-1.68,2.95-2.52,4.43q-4.09,7.22-8.19,14.43Q21.23,40,15.81,49.62,10.51,59,5.23,68.31c-.48.84-1,1.68-1.42,2.52-.39.69-.8,1.37-1.16,2.07a4.23,4.23,0,0,0-.4,2.84,4.13,4.13,0,0,0,3.94,3.12Z"
            />
            <path d="M43.15,78.86h-37a4.13,4.13,0,0,1-3.94-3.12,4.23,4.23,0,0,1,.4-2.84c.36-.7.77-1.38,1.16-2.07.47-.84.94-1.68,1.42-2.52Q10.51,59,15.81,49.62,21.24,40,26.67,30.45,30.75,23.24,34.86,16c.84-1.48,1.67-3,2.52-4.43.44-.75.89-1.51,1.38-2.24A5.39,5.39,0,0,1,40.47,7.7a4.15,4.15,0,0,1,4.5,0,6,6,0,0,1,1.85,1.76c.23.33.45.67.65,1L54.1,22q2.37,4.14,4.75,8.29Q64.19,39.62,69.55,49T80.27,67.64c.91,1.6,1.84,3.19,2.76,4.78a6.22,6.22,0,0,1,.85,2.29,3.55,3.55,0,0,1-2.41,3.92,4.84,4.84,0,0,1-1.41.23H43.15Zm34.4-5.35c-.07-.12-.11-.21-.15-.29L69.52,59.5,58.75,40.71,57.1,37.83l-9-15.74-5.13-9c0-.09-.11-.17-.17-.26-.34.41-34.29,60.34-34.36,60.64Z" />
            <path
              class="warningcls1"
              d="M77.55,73.51H8.41c.07-.3,34-60.23,34.36-60.64.06.09.12.17.17.26l5.13,9,9,15.74,1.65,2.88L69.52,59.5,77.4,73.22C77.44,73.3,77.48,73.39,77.55,73.51ZM37.11,34.15c0,.19,0,.39,0,.57.08.64.16,1.27.25,1.9.14,1,.29,2,.42,3,.22,1.67.43,3.34.65,5L39.18,50c.26,1.93.5,3.86.76,5.79a4.6,4.6,0,0,0,.67,1.84,2.86,2.86,0,0,0,4.73.17A3.9,3.9,0,0,0,46,56.39c.11-.52.18-1,.25-1.58.15-1.1.29-2.19.44-3.29l.42-3.26c.15-1.11.29-2.21.44-3.32s.28-2.17.43-3.26c.12-.85.25-1.7.36-2.54.18-1.28.34-2.56.51-3.83a8.09,8.09,0,0,0,.1-1.2,4.81,4.81,0,0,0-.79-2.53,6.59,6.59,0,0,0-2.09-2.08,5.46,5.46,0,0,0-4.36-.75A6.22,6.22,0,0,0,38,31.38,4.75,4.75,0,0,0,37.11,34.15Zm10,31.39a4.35,4.35,0,0,0-.52-2.09A4,4,0,0,0,40.86,62a4.31,4.31,0,0,0-1.23,5.87,4,4,0,0,0,4.16,1.83A4.16,4.16,0,0,0,47.11,65.54Z"
            />
            <path d="M37.11,34.15A4.75,4.75,0,0,1,38,31.38a6.22,6.22,0,0,1,3.69-2.63,5.46,5.46,0,0,1,4.36.75,6.59,6.59,0,0,1,2.09,2.08A4.81,4.81,0,0,1,49,34.11a8.09,8.09,0,0,1-.1,1.2c-.17,1.27-.33,2.55-.51,3.83-.11.84-.24,1.69-.36,2.54-.15,1.09-.29,2.18-.43,3.26s-.29,2.21-.44,3.32l-.42,3.26c-.15,1.1-.29,2.19-.44,3.29-.07.53-.14,1.06-.25,1.58a3.9,3.9,0,0,1-.68,1.44,2.86,2.86,0,0,1-4.73-.17,4.6,4.6,0,0,1-.67-1.84c-.26-1.93-.5-3.86-.76-5.79l-.72-5.44c-.22-1.68-.43-3.35-.65-5-.13-1-.28-2-.42-3-.09-.63-.17-1.26-.25-1.9C37.12,34.54,37.12,34.34,37.11,34.15Z" />
            <path d="M47.11,65.54a4.16,4.16,0,0,1-3.32,4.13,4,4,0,0,1-4.16-1.83A4.31,4.31,0,0,1,40.86,62a4,4,0,0,1,5.73,1.48A4.35,4.35,0,0,1,47.11,65.54Z" />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
