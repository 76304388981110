import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Fire-Door-FD30",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Fire Door FD30",
    description: "Fire-Door-FD30",
    image: require("./Fire-Door-FD30.svg"),
    svgIcon: require("./Fire-Door-FD30.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.fd30cls1{fill:#3f51b5;}.fd30cls2{fill:#f6fe00;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="fd30cls1"
              d="M49.08,1.48a51.44,51.44,0,0,1,6.38.39,48.14,48.14,0,0,1,6.31,1.22,49.35,49.35,0,0,1,5.48,1.78c1.47.57,2.9,1.23,4.3,1.94A45.62,45.62,0,0,1,76,9.42c1.14.74,2.24,1.54,3.31,2.38s2.26,1.86,3.33,2.87c.73.7,1.44,1.41,2.13,2.15s1.53,1.71,2.25,2.61,1.51,2,2.2,3,1.51,2.36,2.2,3.59c.4.71.74,1.45,1.12,2.18s.73,1.62,1.09,2.44.7,1.87,1,2.8a42.44,42.44,0,0,1,1.17,4.1,38.37,38.37,0,0,1,.87,4.86c.12,1,.18,2,.26,3.05a30.82,30.82,0,0,1,.09,3.19c0,1.12-.13,2.25-.22,3.37-.11,1.33-.29,2.66-.53,4a44.49,44.49,0,0,1-1.42,5.68c-.31,1-.67,1.95-1,2.91s-.72,1.72-1.12,2.57-.69,1.41-1.06,2.1q-1,1.92-2.22,3.72c-.67,1-1.38,2-2.12,3s-1.49,1.83-2.29,2.7S83.72,80.05,83,80.74a44.56,44.56,0,0,1-3.34,3c-1.06.85-2.15,1.64-3.27,2.39a51.25,51.25,0,0,1-5.52,3.18,46.05,46.05,0,0,1-4.39,1.89A51.52,51.52,0,0,1,60.17,93a49,49,0,0,1-5,.85,48.59,48.59,0,0,1-6.71.35,46.52,46.52,0,0,1-5.28-.37A52,52,0,0,1,37,92.67a51.14,51.14,0,0,1-5.54-1.8q-2.22-.87-4.33-2a45,45,0,0,1-4.46-2.59c-1.13-.73-2.24-1.51-3.3-2.35s-2.23-1.83-3.28-2.82a46.08,46.08,0,0,1-3.29-3.42c-.78-.9-1.51-1.81-2.22-2.76s-1.48-2.1-2.16-3.19c-.4-.62-.74-1.26-1.12-1.89s-.73-1.42-1.1-2.12-.75-1.64-1.12-2.47-.76-2-1.12-3c-.46-1.23-.76-2.5-1.1-3.76a39.07,39.07,0,0,1-.93-5c-.08-.61-.13-1.23-.19-1.84a35.43,35.43,0,0,1-.16-5.25c0-.62,0-1.24.08-1.85s.13-1.31.21-2c.15-1.31.35-2.63.62-3.93A44,44,0,0,1,3.8,33.81c.32-1,.65-2,1-2.9S5.59,29.19,6,28.34q.5-1.05,1-2.07C7.73,25,8.45,23.8,9.25,22.61c.68-1,1.41-2,2.17-3s1.41-1.73,2.17-2.55,1.43-1.52,2.18-2.24c1-1,2.12-1.94,3.24-2.83s2.18-1.66,3.32-2.42,2.17-1.38,3.3-2A47.07,47.07,0,0,1,31.09,5a48,48,0,0,1,5.47-1.82A49.61,49.61,0,0,1,42,2a45,45,0,0,1,5.9-.52Zm.21,60.16H88.24c.36,0,.34,0,.35-.35v-.06q0-13.38,0-26.77c0-.46.07-.4-.41-.4H10.45c-.49,0-.42-.06-.42.42V61.16c0,.48,0,.48.46.48Z"
              transform="translate(-1.55 -1.48)"
            />
            <path
              class="fd30cls2"
              d="M49.29,61.64H10.49c-.46,0-.46,0-.46-.48V34.48c0-.48-.07-.42.42-.42H88.17c.48,0,.41-.06.41.4q0,13.38,0,26.77v.06c0,.39,0,.35-.35.35H49.29ZM31.52,48.08v9.15c0,.34,0,.32.31.32h8.43a15.79,15.79,0,0,0,2.82-.24,10.86,10.86,0,0,0,4.73-2.09A8.47,8.47,0,0,0,50,52.57a10,10,0,0,0,.88-6.47,8.51,8.51,0,0,0-3.72-5.57,10.49,10.49,0,0,0-2.81-1.33,13.75,13.75,0,0,0-4.18-.57H31.88c-.4,0-.36,0-.36.36Zm37.86,0A14.2,14.2,0,0,0,69.69,51a10.23,10.23,0,0,0,1.18,3.07,7.77,7.77,0,0,0,2.51,2.59,6.58,6.58,0,0,0,1.7.79,9.15,9.15,0,0,0,4.13.29A7.65,7.65,0,0,0,83,56a8.61,8.61,0,0,0,2.11-2.85,9.36,9.36,0,0,0,.73-2.36A15.28,15.28,0,0,0,86,46.66a10.16,10.16,0,0,0-1.18-4.17,10,10,0,0,0-1.12-1.62,7.43,7.43,0,0,0-2.75-2,8.62,8.62,0,0,0-4.35-.53,7.73,7.73,0,0,0-3.78,1.5,8,8,0,0,0-2.2,2.61,10.79,10.79,0,0,0-1,3.16A13.5,13.5,0,0,0,69.38,48.12Zm-7.32-1.81c.1-.12.16-.21.23-.28l2.43-2.7c.52-.58,1-1.16,1.56-1.73a.51.51,0,0,0,.15-.36c0-.78,0-1.56,0-2.33,0-.28,0-.28-.28-.28H53.06c-.28,0-.29,0-.29.3v2.84c0,.39-.05.36.36.36h7.53a.91.91,0,0,1,.33,0s0,.07,0,.08c-.39.39-.73.82-1.11,1.23-.88,1-1.76,1.93-2.65,2.89a.58.58,0,0,0-.17.45v2.33c0,.32,0,.3.32.3.81,0,1.62,0,2.42,0a4.14,4.14,0,0,1,1.59.32,2.12,2.12,0,0,1,.47,3.72,4,4,0,0,1-2,.66,9.88,9.88,0,0,1-5.31-1.08c-.32-.17-.64-.37-1-.57-.62,1.05-1.11,2.15-1.7,3.19-.1.17,0,.21.12.27A9.34,9.34,0,0,0,54.22,57a15.59,15.59,0,0,0,6.06.78,9.36,9.36,0,0,0,3.85-1,6,6,0,0,0,2.48-2.37A5.48,5.48,0,0,0,66,48.26a5.81,5.81,0,0,0-1.87-1.35A8.48,8.48,0,0,0,62.06,46.31ZM13.72,48.05v9.06a1.61,1.61,0,0,1,0,.22c0,.18.06.22.22.22H18c.22,0,.22,0,.23-.23v-.2c0-2,0-4,0-6.06,0-.45-.07-.39.41-.39H27c.3,0,.3,0,.3-.29V47.43c0-.3,0-.28-.28-.28H18.39c-.11,0-.15,0-.14-.16v-.22c0-1.41,0-2.83,0-4.24,0-.44-.06-.4.38-.4H28a1.77,1.77,0,0,1,.23,0c.16,0,.22-.05.21-.22V38.85c0-.16,0-.24-.21-.22H13.89c-.13,0-.18,0-.17.17v9.25Z"
              transform="translate(-1.55 -1.48)"
            />
            <path
              d="M31.52,48.08V39c0-.39,0-.36.36-.36h8.33a13.75,13.75,0,0,1,4.18.57,10.49,10.49,0,0,1,2.81,1.33,8.51,8.51,0,0,1,3.72,5.57A10,10,0,0,1,50,52.57a8.47,8.47,0,0,1-2.23,2.64,10.86,10.86,0,0,1-4.73,2.09,15.79,15.79,0,0,1-2.82.24H31.83c-.32,0-.31,0-.31-.32Zm4.51,0v5.61c0,.28,0,.28.28.28,1.39,0,2.78,0,4.18,0A6.87,6.87,0,0,0,43,53.47a5.28,5.28,0,0,0,3.12-3,6.58,6.58,0,0,0,.37-3.29A5.14,5.14,0,0,0,43.67,43a6.67,6.67,0,0,0-3.15-.8c-1.4,0-2.81,0-4.21,0-.28,0-.28,0-.28.28v5.55Z"
              transform="translate(-1.55 -1.48)"
            />
            <path
              d="M69.38,48.12a13.5,13.5,0,0,1,.2-2.47,10.79,10.79,0,0,1,1-3.16,8,8,0,0,1,2.2-2.61,7.73,7.73,0,0,1,3.78-1.5,8.62,8.62,0,0,1,4.35.53,7.43,7.43,0,0,1,2.75,2,10,10,0,0,1,1.12,1.62A10.16,10.16,0,0,1,86,46.66a15.28,15.28,0,0,1-.18,4.16,9.36,9.36,0,0,1-.73,2.36A8.61,8.61,0,0,1,83,56a7.65,7.65,0,0,1-3.78,1.72,9.15,9.15,0,0,1-4.13-.29,6.58,6.58,0,0,1-1.7-.79,7.77,7.77,0,0,1-2.51-2.59A10.23,10.23,0,0,1,69.69,51,14.2,14.2,0,0,1,69.38,48.12ZM74,48.05a9.91,9.91,0,0,0,.14,2,8.33,8.33,0,0,0,.49,1.84A3.29,3.29,0,0,0,77,54.08,3.2,3.2,0,0,0,80.31,53a4.57,4.57,0,0,0,.8-1.51A9.74,9.74,0,0,0,81.52,49a13.52,13.52,0,0,0-.23-3.65,5,5,0,0,0-1.07-2.28,2.53,2.53,0,0,0-.59-.54,3.35,3.35,0,0,0-4.88,1.33A5,5,0,0,0,74.33,45,10.84,10.84,0,0,0,74,48.05Z"
              transform="translate(-1.55 -1.48)"
            />
            <path
              d="M62.06,46.31a8.48,8.48,0,0,1,2.06.6A5.81,5.81,0,0,1,66,48.26a5.48,5.48,0,0,1,.62,6.19,6,6,0,0,1-2.48,2.37,9.36,9.36,0,0,1-3.85,1A15.59,15.59,0,0,1,54.22,57,9.34,9.34,0,0,1,52,56c-.1-.06-.22-.1-.12-.27.59-1,1.08-2.14,1.7-3.19.31.2.63.4,1,.57a9.88,9.88,0,0,0,5.31,1.08,4,4,0,0,0,2-.66,2.12,2.12,0,0,0-.47-3.72,4.14,4.14,0,0,0-1.59-.32c-.8,0-1.61,0-2.42,0-.34,0-.32,0-.32-.3V46.8a.58.58,0,0,1,.17-.45c.89-1,1.77-1.93,2.65-2.89.38-.41.72-.84,1.11-1.23,0,0,0,0,0-.08a.91.91,0,0,0-.33,0H53.13c-.41,0-.35,0-.36-.36V38.93c0-.3,0-.3.29-.3H66.14c.28,0,.28,0,.28.28,0,.77,0,1.55,0,2.33a.51.51,0,0,1-.15.36c-.53.57-1,1.15-1.56,1.73L62.29,46C62.22,46.1,62.16,46.19,62.06,46.31Z"
              transform="translate(-1.55 -1.48)"
            />
            <path
              d="M13.72,48.05V38.8c0-.12,0-.18.17-.17H28.24c.17,0,.21.06.21.22v3.06c0,.17-.05.24-.21.22a1.77,1.77,0,0,0-.23,0H18.63c-.44,0-.38,0-.38.4,0,1.41,0,2.83,0,4.24V47c0,.11,0,.16.14.16H27c.3,0,.28,0,.28.28v2.93c0,.29,0,.29-.3.29H18.66c-.48,0-.41-.06-.41.39,0,2,0,4,0,6.06v.2c0,.23,0,.23-.23.23H13.94c-.16,0-.24,0-.22-.22a1.61,1.61,0,0,0,0-.22Z"
              transform="translate(-1.55 -1.48)"
            />
            <path
              class="fd30cls2"
              d="M36,48.05V42.5c0-.27,0-.28.28-.28,1.4,0,2.81,0,4.21,0a6.67,6.67,0,0,1,3.15.8,5.14,5.14,0,0,1,2.8,4.15,6.58,6.58,0,0,1-.37,3.29,5.28,5.28,0,0,1-3.12,3,6.87,6.87,0,0,1-2.49.46c-1.4,0-2.79,0-4.18,0-.28,0-.28,0-.28-.28Z"
              transform="translate(-1.55 -1.48)"
            />
            <path
              class="fd30cls2"
              d="M74,48.05a10.84,10.84,0,0,1,.38-3.1,5,5,0,0,1,.42-1.07,3.35,3.35,0,0,1,4.88-1.33,2.53,2.53,0,0,1,.59.54,5,5,0,0,1,1.07,2.28A13.52,13.52,0,0,1,81.52,49a9.74,9.74,0,0,1-.41,2.44,4.57,4.57,0,0,1-.8,1.51A3.2,3.2,0,0,1,77,54.08a3.29,3.29,0,0,1-2.39-2.15,8.33,8.33,0,0,1-.49-1.84A9.91,9.91,0,0,1,74,48.05Z"
              transform="translate(-1.55 -1.48)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
