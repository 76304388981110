import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-exit-left",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "exit left",
    description: "exit-left",
    image: require("./exit-left.svg"),
    svgIcon: require("./exit-left.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.exitleftcls1{fill:#009f4d;}.exitleftcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="exitleftcls1"
              d="M0,64.41v-43H86.13v43H58.67l-.13-.29q-.87-2.16-1.73-4.3a1.4,1.4,0,0,1-.11-.53V53c0-.12.06-.17.18-.17h.57a1.35,1.35,0,0,0,.78-.26,1.93,1.93,0,0,0,.64-.75,2.75,2.75,0,0,0,.18-2,1.77,1.77,0,0,0-1.91-1.45c-.47,0-.44,0-.44-.43v-23l-.49,0H30a2.26,2.26,0,0,0-.26,0l-.24,0v34.6a1.23,1.23,0,0,0,.09.47c.53,1.33,1.07,2.66,1.61,4,.05.11.08.22.14.39H0ZM27.55,44.94a33.36,33.36,0,0,0,0-4H9l.2-.2L14,35.92,15.9,34c.07-.07.13-.15.23-.27l-.38,0h-4.9a.66.66,0,0,0-.51.21L1.56,42.72l-.19.22.23.24L6,47.58l4.32,4.32a.54.54,0,0,0,.42.21H15.9l.24,0L9.05,45l0-.06Zm38.6-4.29V39.24c-.28-.07-5.5-.07-5.74,0-.06.29,0,7.36,0,7.57.39.06,5.65,0,5.9,0,0-.38,0-.77,0-1.16,0-.22,0-.24-.25-.25H62.54l-.35,0c0-.57,0-1.11,0-1.67H64c.57,0,1.14,0,1.7,0a4.17,4.17,0,0,0,0-1.42H62.21V40.7l.36,0h3.21Zm.69-1.43L69.51,43l-2.8,3.86.31,0h1.52a.4.4,0,0,0,.39-.21l1.44-2.11.2-.27.2.28,1.47,2.12a.37.37,0,0,0,.32.19h1.69l.2,0-2.82-3.9.54-.74.52-.72.54-.74.52-.72a6.69,6.69,0,0,0,.51-.76l-.3,0c-.45,0-.9,0-1.35,0a.46.46,0,0,0-.45.23c-.44.65-.89,1.29-1.34,1.93l-.2.26-.23-.3c-.44-.63-.9-1.26-1.34-1.9a.47.47,0,0,0-.42-.23c-.49,0-1,0-1.46,0ZM82,46.82c0-.15,0-.28,0-.41,0-1.78,0-3.55,0-5.33,0-.12,0-.24,0-.38h2.38V39.22l-.3,0h-6c-.1,0-.2,0-.31,0,0,.5,0,1,0,1.46.81,0,1.6,0,2.41,0v4.58c0,.51,0,1,0,1.53ZM75.11,39.2v7.6a8.06,8.06,0,0,0,1.78,0V39.2C76.29,39.18,75.72,39.18,75.11,39.2Z"
              transform="translate(0 -21.42)"
            />
            <path
              class="exitleftcls2"
              d="M0,64.41H31.34c-.06-.17-.09-.28-.14-.39-.54-1.33-1.08-2.66-1.61-4a1.23,1.23,0,0,1-.09-.47V25l.24,0a2.26,2.26,0,0,1,.26,0H56.21l.49,0V48c0,.46,0,.43.44.43a1.77,1.77,0,0,1,1.91,1.45,2.75,2.75,0,0,1-.18,2,1.93,1.93,0,0,1-.64.75,1.35,1.35,0,0,1-.78.26h-.57c-.12,0-.18,0-.18.17v6.25a1.4,1.4,0,0,0,.11.53q.86,2.14,1.73,4.3l.13.29H86.13a.33.33,0,0,1,0,.06H.26C.17,64.46.06,64.53,0,64.41ZM43.26,39.63l.35,0c.51,0,1,0,1.52,0a.63.63,0,0,1,.51.24L48.42,43c.26.3.51.6.78.89A1.16,1.16,0,0,0,51,42.4c-.1-.12-.21-.23-.31-.34-1.24-1.43-2.49-2.85-3.72-4.29a1.42,1.42,0,0,0-1.15-.52H40.32a1.81,1.81,0,0,0-1.38.57c-1.1,1.12-2.21,2.22-3.32,3.33l-.24.21-.23-.21-.91-.91-1.87-1.86a1.2,1.2,0,0,0-1.76.09,1.18,1.18,0,0,0,.07,1.61q1.8,1.83,3.62,3.64a1.54,1.54,0,0,0,.43.33,1.8,1.8,0,0,0,.51.17,1.21,1.21,0,0,0,1.06-.39c.66-.67,1.32-1.33,2-2a3.2,3.2,0,0,1,.26-.24l3.55,4.11-.26.27-2.9,2.9a1.6,1.6,0,0,0-.47.93,3.09,3.09,0,0,0,.12,1.13c.51,2.23,1,4.47,1.51,6.7.1.46.19.91.32,1.37a1.5,1.5,0,0,0,2.88,0,1.87,1.87,0,0,0,0-.88c-.48-2.12-1-4.23-1.44-6.34a.39.39,0,0,1,.14-.43c1.11-1.06,2.21-2.14,3.31-3.21l.23-.19a3.45,3.45,0,0,0,.25.27c.94,1.05,1.88,2.09,2.81,3.14a2,2,0,0,0,.91.57,3.11,3.11,0,0,0,.91.11h6.72a1.49,1.49,0,0,0,.46-2.86,1.73,1.73,0,0,0-.71-.11c-1.69,0-3.38,0-5.08,0a.77.77,0,0,1-.63-.3c-2.55-3-5.12-5.93-7.68-8.9Zm-3.68-4.16a2.35,2.35,0,1,0-2.37,2.36A2.33,2.33,0,0,0,39.58,35.47Z"
              transform="translate(0 -21.42)"
            />
            <path
              class="exitleftcls2"
              d="M27.55,44.94H9.08l0,.06,7.09,7.07-.24,0H10.74a.54.54,0,0,1-.42-.21L6,47.58l-4.4-4.4-.23-.24.19-.22,8.78-8.78a.66.66,0,0,1,.51-.21h4.9l.38,0c-.1.12-.16.2-.23.27L14,35.92,9.19,40.74l-.2.2H27.55A33.36,33.36,0,0,1,27.55,44.94Z"
              transform="translate(0 -21.42)"
            />
            <path
              class="exitleftcls2"
              d="M66.15,40.65l-.37,0H62.57l-.36,0v1.55h3.43a4.17,4.17,0,0,1,0,1.42c-.56,0-1.13,0-1.7,0H62.22c-.06.56,0,1.1,0,1.67l.35,0h3.53c.22,0,.25,0,.25.25,0,.39,0,.78,0,1.16-.25.07-5.51.09-5.9,0-.06-.21-.08-7.28,0-7.57.24-.07,5.46-.07,5.74,0Z"
              transform="translate(0 -21.42)"
            />
            <path
              class="exitleftcls2"
              d="M66.84,39.22l.33,0c.49,0,1,0,1.46,0a.47.47,0,0,1,.42.23c.44.64.9,1.27,1.34,1.9l.23.3.2-.26c.45-.64.9-1.28,1.34-1.93a.46.46,0,0,1,.45-.23c.45,0,.9,0,1.35,0l.3,0a6.69,6.69,0,0,1-.51.76l-.52.72-.54.74-.52.72-.54.74,2.82,3.9-.2,0H72.56a.37.37,0,0,1-.32-.19l-1.47-2.12-.2-.28-.2.27-1.44,2.11a.4.4,0,0,1-.39.21H67l-.31,0L69.51,43Z"
              transform="translate(0 -21.42)"
            />
            <path
              class="exitleftcls2"
              d="M82,46.82H80.25c-.06-.52,0-1,0-1.53V40.71c-.81,0-1.6,0-2.41,0,0-.5,0-1,0-1.46.11,0,.21,0,.31,0h6l.3,0v1.47H82.08c0,.14,0,.26,0,.38,0,1.78,0,3.55,0,5.33C82.05,46.54,82,46.67,82,46.82Z"
              transform="translate(0 -21.42)"
            />
            <path
              class="exitleftcls2"
              d="M75.11,39.2c.61,0,1.18,0,1.78,0v7.58a8.06,8.06,0,0,1-1.78,0Z"
              transform="translate(0 -21.42)"
            />
            <path
              class="exitleftcls1"
              d="M43.26,39.63l.25.3c2.56,3,5.13,5.93,7.68,8.9a.77.77,0,0,0,.63.3c1.7,0,3.39,0,5.08,0a1.73,1.73,0,0,1,.71.11,1.49,1.49,0,0,1-.46,2.86H50.43a3.11,3.11,0,0,1-.91-.11,2,2,0,0,1-.91-.57c-.93-1-1.87-2.09-2.81-3.14a3.45,3.45,0,0,1-.25-.27l-.23.19c-1.1,1.07-2.2,2.15-3.31,3.21a.39.39,0,0,0-.14.43c.49,2.11,1,4.22,1.44,6.34a1.87,1.87,0,0,1,0,.88,1.5,1.5,0,0,1-2.88,0c-.13-.46-.22-.91-.32-1.37-.5-2.23-1-4.47-1.51-6.7a3.09,3.09,0,0,1-.12-1.13,1.6,1.6,0,0,1,.47-.93l2.9-2.9.26-.27L38.55,41.6a3.2,3.2,0,0,0-.26.24c-.67.66-1.33,1.32-2,2a1.21,1.21,0,0,1-1.06.39,1.8,1.8,0,0,1-.51-.17,1.54,1.54,0,0,1-.43-.33q-1.81-1.81-3.62-3.64a1.18,1.18,0,0,1-.07-1.61,1.2,1.2,0,0,1,1.76-.09l1.87,1.86.91.91.23.21.24-.21c1.11-1.11,2.22-2.21,3.32-3.33a1.81,1.81,0,0,1,1.38-.57h5.46a1.42,1.42,0,0,1,1.15.52c1.23,1.44,2.48,2.86,3.72,4.29.1.11.21.22.31.34a1.16,1.16,0,0,1-1.76,1.52c-.27-.29-.52-.59-.78-.89l-2.78-3.21a.63.63,0,0,0-.51-.24c-.51,0-1,0-1.52,0Z"
              transform="translate(0 -21.42)"
            />
            <path
              class="exitleftcls1"
              d="M39.58,35.47a2.35,2.35,0,1,1-2.36-2.33A2.34,2.34,0,0,1,39.58,35.47Z"
              transform="translate(0 -21.42)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
