import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Sofa",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Sofa",
    description: "Sofa",
    image: require("./sofa.svg"),
    svgIcon: require("./sofa.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              class="cls-1"
              d="M96.72,66.54c1,0,1,0,1-1V32c0-.58,0-1.17,0-1.75a1.91,1.91,0,0,0-2-1.93c-8.92,0-17.84,0-26.76,0H5.09a1.7,1.7,0,0,0-1.73,1,3.48,3.48,0,0,0-.23,1.33q0,17.46,0,34.92c0,.93,0,.93.88,1.11,0,.59,0,1.19,0,1.79a1.8,1.8,0,0,0,1.92,1.92q12.72,0,25.45,0a4.82,4.82,0,0,0,4.12-2c0-.07.12-.13.2-.22A5.1,5.1,0,0,0,40,70.36q10.52,0,21,0a5.21,5.21,0,0,0,3.75-1.24c.32-.29.6-.63.91-1l.47.54a4.64,4.64,0,0,0,3.57,1.66q12.56,0,25.12,0a1.79,1.79,0,0,0,1.91-1.92C96.73,67.81,96.72,67.17,96.72,66.54Z"
              transform="translate(-3.12 -28.31)"
            />
            <path
              d="M96.72,66.54c0,.63,0,1.27,0,1.91a1.79,1.79,0,0,1-1.91,1.92q-12.57,0-25.12,0a4.64,4.64,0,0,1-3.57-1.66l-.47-.54c-.31.33-.59.67-.91,1A5.21,5.21,0,0,1,61,70.36q-10.52,0-21,0a5.1,5.1,0,0,1-4.27-2.22c-.08.09-.15.15-.2.22a4.82,4.82,0,0,1-4.12,2q-12.72,0-25.45,0A1.8,1.8,0,0,1,4,68.45c0-.6,0-1.2,0-1.79-.88-.18-.88-.18-.88-1.11q0-17.46,0-34.92a3.48,3.48,0,0,1,.23-1.33,1.7,1.7,0,0,1,1.73-1H69c8.92,0,17.84,0,26.76,0a1.91,1.91,0,0,1,2,1.93c0,.58,0,1.17,0,1.75V65.54C97.71,66.51,97.68,66.54,96.72,66.54Zm-4.82-31c-.74.39-1.41.8-2.12,1.11A24.08,24.08,0,0,1,74.15,38a15.61,15.61,0,0,1-7.48-3.79c-.52-.49-1-1.07-1.44-1.62a12.06,12.06,0,0,1-5.65,4.35,24,24,0,0,1-15.35,1c-3.42-.84-6.53-2.33-8.67-5.38a10.68,10.68,0,0,1-2.94,2.9,19.69,19.69,0,0,1-9.41,3.13A23.31,23.31,0,0,1,9.42,35.81s-.09,0-.14,0c.18,1.43.39,2.84.52,4.25A67.56,67.56,0,0,1,9.9,50.6a42,42,0,0,1-2,10.83,8.87,8.87,0,0,1-2.36,4.2,2.34,2.34,0,0,0-.9,2.28,1.62,1.62,0,0,1,0,.22c0,1.19.38,1.57,1.57,1.57H31a4.51,4.51,0,0,0,4.2-2.17.49.49,0,0,1,.9,0,4.31,4.31,0,0,0,3.95,2.14c7,0,13.94,0,20.91,0a4.47,4.47,0,0,0,4.12-2c.36-.58.69-.56,1.08,0a4.19,4.19,0,0,0,3.63,2q12.45,0,24.9,0a1.18,1.18,0,0,0,1.34-1.3c0-.66,0-1.32,0-2A.63.63,0,0,0,95.9,66a10.06,10.06,0,0,1-2.69-4.56A37.83,37.83,0,0,1,91.44,53a65.4,65.4,0,0,1,.1-14.8C91.65,37.29,91.77,36.43,91.9,35.54Zm5.15,30.27V30.64c0-1.3-.35-1.66-1.65-1.66h-90c-1.26,0-1.63.37-1.63,1.63V65.83a2.28,2.28,0,0,0,1.61-1,11,11,0,0,0,1.89-3.61,39.75,39.75,0,0,0,1.83-9,58.79,58.79,0,0,0,.16-9.84c-.17-2.32-.51-4.63-.75-7a.78.78,0,0,1,.23-.58c.11-.08.4,0,.56.06.73.38,1.41.86,2.16,1.17a23.52,23.52,0,0,0,17.21.51,11.49,11.49,0,0,0,6.47-4.77.47.47,0,0,1,.83,0,10.06,10.06,0,0,0,4.63,4,23.13,23.13,0,0,0,18.15.64,11.12,11.12,0,0,0,6.12-4.64.47.47,0,0,1,.83.07,10.33,10.33,0,0,0,4.53,3.9A22.24,22.24,0,0,0,82,37.87a19.56,19.56,0,0,0,9.92-3.22c.12-.09.43-.12.5,0a.82.82,0,0,1,.22.59c-.25,2.17-.61,4.34-.76,6.51A56,56,0,0,0,93,58.35a18.36,18.36,0,0,0,2.17,5.76A3.71,3.71,0,0,0,97.05,65.81Z"
              transform="translate(-3.12 -28.31)"
            />
            <path
              class="cls-1"
              d="M91.9,35.54c-.13.89-.25,1.75-.36,2.61a65.4,65.4,0,0,0-.1,14.8,37.83,37.83,0,0,0,1.77,8.49A10.06,10.06,0,0,0,95.9,66a.63.63,0,0,1,.16.43c0,.65,0,1.31,0,2a1.18,1.18,0,0,1-1.34,1.3q-12.45,0-24.9,0a4.19,4.19,0,0,1-3.63-2c-.39-.59-.72-.61-1.08,0a4.47,4.47,0,0,1-4.12,2c-7,0-13.94,0-20.91,0a4.31,4.31,0,0,1-3.95-2.14.49.49,0,0,0-.9,0A4.51,4.51,0,0,1,31,69.7H6.23c-1.19,0-1.56-.38-1.57-1.57a1.62,1.62,0,0,0,0-.22,2.34,2.34,0,0,1,.9-2.28,8.87,8.87,0,0,0,2.36-4.2,42,42,0,0,0,2-10.83,67.56,67.56,0,0,0-.1-10.55c-.13-1.41-.34-2.82-.52-4.25.05,0,.11,0,.14,0a23.31,23.31,0,0,0,13.79,2.73,19.69,19.69,0,0,0,9.41-3.13,10.68,10.68,0,0,0,2.94-2.9c2.14,3.05,5.25,4.54,8.67,5.38a24,24,0,0,0,15.35-1,12.06,12.06,0,0,0,5.65-4.35c.49.55.92,1.13,1.44,1.62A15.61,15.61,0,0,0,74.15,38a24.08,24.08,0,0,0,15.63-1.3C90.49,36.34,91.16,35.93,91.9,35.54Z"
              transform="translate(-3.12 -28.31)"
            />
            <path
              class="cls-1"
              d="M97.05,65.81a3.71,3.71,0,0,1-1.87-1.7A18.36,18.36,0,0,1,93,58.35a56,56,0,0,1-1.12-16.64c.15-2.17.51-4.34.76-6.51a.82.82,0,0,0-.22-.59c-.07-.08-.38,0-.5,0A19.56,19.56,0,0,1,82,37.87a22.24,22.24,0,0,1-11.77-2.09,10.33,10.33,0,0,1-4.53-3.9.47.47,0,0,0-.83-.07,11.12,11.12,0,0,1-6.12,4.64,23.13,23.13,0,0,1-18.15-.64,10.06,10.06,0,0,1-4.63-4,.47.47,0,0,0-.83,0,11.49,11.49,0,0,1-6.47,4.77,23.52,23.52,0,0,1-17.21-.51c-.75-.31-1.43-.79-2.16-1.17-.16-.08-.45-.14-.56-.06a.78.78,0,0,0-.23.58c.24,2.32.58,4.63.75,7a58.79,58.79,0,0,1-.16,9.84,39.75,39.75,0,0,1-1.83,9,11,11,0,0,1-1.89,3.61,2.28,2.28,0,0,1-1.61,1V30.61c0-1.26.37-1.63,1.63-1.63h90c1.3,0,1.65.36,1.65,1.66V65.81Z"
              transform="translate(-3.12 -28.31)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
