import React, { Fragment, useEffect, useState } from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "stairs-with-a-turn",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Stairs with a Turn",
    description: "",
    image: require("./stairs-with-turn-icon.svg"),
    svgIcon: require("./stairs-with-turn-icon.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    arrow: {
      label: "Arrow Text",
      type: "dropdown",
      defaultValue: "up",
      options: ["up", "down"],
    },
    steps: {
      label: "Number Of Steps",
      type: "stairs-slider",
      min: 1,
      max: 25,
      defaultValue: 10,
    },
    arrowDirection: {
      label: "Arrow Direction",
      type: "dropdown",
      defaultValue: "upward",
      options: ["upward", "downward"],
    },
  },

  render2D: function (element, layer, scene, properties) {
    //  
    let ArrowDirection = element.properties.get("arrowDirection");
    let angle = element.rotation + 90;
    let textRotation = 0;
    if (Math.sin((angle * Math.PI) / 180) < 0) {
      textRotation = 180;
    }
    let { steps } = element.properties.toJS();
    const rect = (
      <rect class="rectcls-2" x="0.81" y="1.03" width="43.34" height="8.95" />
    );
    const [rectangles, setRectangles] = useState([rect]);
    useEffect(() => {
      if (steps) {
        setRectangles(replicateRect(steps));
      }
    }, [steps]);

    return {
      element: (
        <g
          transform={`translate(-22.6,${
            -parseFloat(rectangles[rectangles.length - 1].props.y) + 30
          })`}
        >
          <defs>
            <style>{`.rectcls-1{fill:#050505;}.rectcls-2{fill:#fff;}`}</style>
          </defs>
          <title>straight-stairs</title>
          <g id="Stairs">
            <g
              id={`${element.type}-${element.id}_Group_`}
              data-name="&lt;Group&gt;"
            >
              <rect
                class="rectcls-1"
                width="45.28"
                transform={`translate(0,-10)`}
                height={Math.abs(
                  parseFloat(rectangles[rectangles.length - 1].props.y) + 68
                )}
              />
              <g
                id="turn"

                transform={`translate(0,${
                  parseFloat(rectangles[rectangles.length - 1].props.y) + 9.0 + 49
                }) scale(1, -1)`}
                // transform={`translate(0,${
                //   parseFloat(rectangles[rectangles.length - 1].props.y) + 9.0
                // })`}
              >
                <rect
                  class="rectcls-2"
                  x="0.82"
                  y="1.06"
                  width="43.4"
                  height="46.83"
                />
                <polygon  points="0.51 47.98 0.9 1.04 0.9 46.02 18.59 1.04 19.96 1.04 2.12 45.39 44.73 0.99 45.68 0.98 45.56 2.45 2.9 46.71 45.68 28.25 45.51 29.46 3.13 47.81 45.51 47.98 0.51 47.98" />
              </g>
              {/* <polygon
               transform={ `translate(0,${
                parseFloat(rectangles[rectangles.length - 1].props.y) + 9.00
              })`}
                class="rectcls-2"
                points="44.2 1.06 44.2  10.44 44.2  10.44 44.2  47.91 0.81 47.91 0.81 10.44 0.84 10.44 0.84 1.06 44.2  1.06"
              /> */}
              {rectangles.map((rect) => rect)}
            </g>

            {ArrowDirection === "downward" && (
              <Fragment>
                <path
                  d="M49,.89H45.93c0,4.59,0,16.35,0,20.94a26.15,26.15,0,0,1-2.76,0s4.06,10.71,5.91,15.39C50.91,32.54,55,21.83,55,21.83a26,26,0,0,1-2.75,0c0-4.59,0-16.35,0-20.94H49Z"
                  style={{
                    transform:
                      rectangles.length <= 3
                        ? `translate(-2px, -10px) scale(0.5,0.5)`
                        : `translate(-26.61px,-10px) `,
                  }}
                />
                <text
                  key="5"
                  x="0"
                  y="0"
                  transform={`translate(22, -22) scale(1,-1) rotate(${textRotation})`}
                  style={{ textAnchor: "middle", fontSize: "11px" }}
                >
                  {element.properties.get("arrow")}
                </text>
              </Fragment>
            )}

            {ArrowDirection === "upward" && (
              <Fragment>
                <text
                  key="5"
                  x="0"
                  y="0"
                  transform={`translate(-15, ${
                    parseFloat(rectangles[rectangles.length - 1].props.y) + 28
                  }) scale(1,-1) rotate(${textRotation})`}
                  style={{ textAnchor: "middle", fontSize: "11px" }}
                >
                  {element.properties.get("arrow")}
                </text>
                <path style={{
                    transform:
                     `translate(-25.61px,${ parseFloat(
                      rectangles[rectangles.length - 1].props.y + 5
                    )}px) `,
                  }} d="M26.06,25.24v2.63L43.7,28a22.59,22.59,0,0,1,0,2.32s9-3.38,13-4.92c-3.94-1.58-13-5-13-5a21.77,21.77,0,0,1,0,2.32l-17.65-.06v2.65Z" transform="translate(-25.23 0.03)"/>

                {/* <path
                  d="M49.07,98.9h3.12c0-4.59,0-16.35,0-20.94A26,26,0,0,1,55,78s-4.05-10.71-5.9-15.39C47.21,67.25,43.15,78,43.15,78a26.15,26.15,0,0,1,2.76,0c0,4.59,0,16.35,0,20.94h3.14Z"
                  style={{
                    transform:
                      rectangles.length <= 3
                        ? `translate(-2px, ${
                            parseFloat(
                              rectangles[rectangles.length - 1].props.y
                            ) + 8
                          }px) scale(0.5,0.5)`
                        : `translate(-26.61px, ${
                            parseFloat(
                              rectangles[rectangles.length - 1].props.y
                            ) - 42
                          }px) `,
                  }}
                /> */}
              </Fragment>
            )}

            {/* <path
              d="M49,.89H45.93c0,4.59,0,16.35,0,20.94a26.15,26.15,0,0,1-2.76,0s4.06,10.71,5.91,15.39C50.91,32.54,55,21.83,55,21.83a26,26,0,0,1-2.75,0c0-4.59,0-16.35,0-20.94H49Z"
              // transform={`translate(-43.17px,${
              //   parseFloat(rectangles[rectangles.length - 1].props.y) + 10 + 100
              // }px)) `}
            /> */}
            {/* <path
              d="M49.07,98.9h3.12c0-4.59,0-16.35,0-20.94A26,26,0,0,1,55,78s-4.05-10.71-5.9-15.39C47.21,67.25,43.15,78,43.15,78a26.15,26.15,0,0,1,2.76,0c0,4.59,0,16.35,0,20.94h3.14Z"
              // style={{
              //   transform: `translate(${-25}px,${
              //     parseFloat(rectangles[rectangles.length - 1].props.y) +
              //     10 -
              //     100
              //   }px)`,
              // }}
            /> */}
          </g>
        </g>
      ),
      transform: { width: -WIDTH / 2, depth: -DEPTH / 2 },
    };
  },

  render3D: function (element, layer, scene) {},
};

function replicateRect(countingState) {
  let elements = [];

  for (let i = 0; i < countingState; i++) {
    let newRect = (
      <rect
        class="rectcls-2"
        x="0.81"
        y={i === 0 ? "-9.0" : parseFloat(elements[i - 1].props.y) + 9.74}
        width="43.34"
        height="8.95"
      />
    );
    elements.push(newRect);
  }

  return elements;
}
