import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Boiler-with-B",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Boiler with B",
    description: "Boiler-with-B",
    image: require("./Boiler-with-B.svg"),
    svgIcon: require("./Boiler-with-B.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g id={`${element.type}-${element.id}`} transform={`translate(-47, 55) scale(1,-1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M94.58,52.2A45.18,45.18,0,1,1,49.65,7,45.12,45.12,0,0,1,94.58,52.2Zm-45-40.95a40.82,40.82,0,1,0,40.79,41A40.84,40.84,0,0,0,49.62,11.25Z"
            transform="translate(-4.21 -6.98)"
          />
          <path
            class="cls-1"
            d="M49.62,11.25A40.82,40.82,0,1,1,8.5,51.87,41,41,0,0,1,49.62,11.25ZM32.94,24.78V80c1.44,0,2.77,0,4.1,0,7.21-.13,14.45.08,21.62-.51,9.11-.76,15.2-7.4,15.45-15.94.21-7.25-3.49-12.43-10.4-14.58-.45-.13-.88-.32-1.45-.53,1.29-1,2.45-1.86,3.57-2.78,5.11-4.16,5.16-14.84-1.56-18.31a17.79,17.79,0,0,0-6.92-2.19c-7.87-.38-15.77-.36-23.65-.48A4.28,4.28,0,0,0,32.94,24.78Z"
            transform="translate(-4.21 -6.98)"
          />
          <path
            d="M32.94,24.78a4.28,4.28,0,0,1,.76-.14c7.88.12,15.78.1,23.65.48a17.79,17.79,0,0,1,6.92,2.19c6.72,3.47,6.67,14.15,1.56,18.31-1.12.92-2.28,1.78-3.57,2.78.57.21,1,.4,1.45.53,6.91,2.15,10.61,7.33,10.4,14.58-.25,8.54-6.34,15.18-15.45,15.94C51.49,80,44.25,79.83,37,80c-1.33,0-2.66,0-4.1,0Zm7.28,49c6.4-.2,12.76.53,19-1a11.08,11.08,0,0,0,3.64-1.64c3.68-2.61,4-6.44,3.5-10.49-.52-3.86-3.12-5.77-6.6-6.73a13.39,13.39,0,0,0-3.25-.49c-5.13-.11-10.26-.15-15.39-.2a5.48,5.48,0,0,0-1,.18V71.87C40.12,72.41,40.17,72.94,40.22,73.73Zm0-42.58V46.86c3.16,0,6.21,0,9.26,0A37.08,37.08,0,0,0,55,46.52c4.72-.79,7-3.88,6.89-8.85-.13-3.88-2.41-6.18-7.18-6.46S45.19,31.15,40.25,31.15Z"
            transform="translate(-4.21 -6.98)"
          />
          <path
            class="cls-1"
            d="M40.22,73.73c0-.79-.1-1.32-.1-1.86V53.32a5.48,5.48,0,0,1,1-.18c5.13,0,10.26.09,15.39.2a13.39,13.39,0,0,1,3.25.49c3.48,1,6.08,2.87,6.6,6.73.55,4,.18,7.88-3.5,10.49a11.08,11.08,0,0,1-3.64,1.64C53,74.26,46.62,73.53,40.22,73.73Z"
            transform="translate(-4.21 -6.98)"
          />
          <path
            class="cls-1"
            d="M40.25,31.15c4.94,0,9.71-.22,14.45.06s7,2.58,7.18,6.46c.16,5-2.17,8.06-6.89,8.85a37.08,37.08,0,0,1-5.48.33c-3,0-6.1,0-9.26,0Z"
            transform="translate(-4.21 -6.98)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
