import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "fireplace",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Fireplace",
    description: "",
    image: require("./fireplace.svg"),
    svgIcon: require("./fireplace.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue:100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g
          id={`${element.type}-${element.id}`}
          transform={`translate(${-WIDTH / 2},${-DEPTH / 2})`}
        >
          <path
            d="M.15,65.38V34.6h99.7V65.38H98.78c-7.54,0-15.09,0-22.63,0a1.51,1.51,0,0,1-1.68-1.1c-3.83-9.23-7.72-18.44-11.55-27.67a1.38,1.38,0,0,0-1.52-1q-11.41,0-22.83,0a1.34,1.34,0,0,0-1.46,1C33.28,45.8,29.4,55,25.57,64.25a1.56,1.56,0,0,1-1.74,1.15c-7.48,0-14.95,0-22.42,0Z"
            transform="translate(-0.15 -34.6)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
