import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Phone-Green",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Phone Green",
    description: "Phone-Green",
    image: require("./Phone-Green.svg"),
    svgIcon: require("./Phone-Green.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.phonegreencls1{fill:none;}.phonegreencls2{fill:#009f4d;}.phonegreencls3{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
           <rect class="phonegreencls1" x="11.9" y="4.58" width="77.92" height="90.7"/>
    <path class="phonegreencls2" d="M.07,100.07V.07h100v100Zm50.08-3a46.92,46.92,0,1,0-47-47A46.89,46.89,0,0,0,50.15,97Z" transform="translate(-0.07 -0.07)"/>
    <path class="phonegreencls3" d="M50.15,97A46.92,46.92,0,1,1,97,50.19,46.89,46.89,0,0,1,50.15,97Zm-.49-6.63A40.3,40.3,0,1,0,9.8,49.54,40.35,40.35,0,0,0,49.66,90.39Z" transform="translate(-0.07 -0.07)"/>
    <path class="phonegreencls2" d="M49.66,90.39a40.3,40.3,0,1,1,40.73-39.3A40.34,40.34,0,0,1,49.66,90.39ZM39.79,43.76a3.18,3.18,0,0,0,.35-.26l3.41-3.42a3.91,3.91,0,0,0,0-5.59c-2.42-2.38-4.8-4.79-7.2-7.19a5.1,5.1,0,0,0-.65-.57,3.9,3.9,0,0,0-4.93.44c-.4.38-.79.78-1.18,1.18a10.86,10.86,0,0,0-1.21,1.4,12.76,12.76,0,0,0-2.13,5.08A19.13,19.13,0,0,0,26.1,40a20.66,20.66,0,0,0,1.32,5.42,32.26,32.26,0,0,0,4.07,7.24,72.65,72.65,0,0,0,7.87,8.95,54.26,54.26,0,0,0,8.67,7,37.73,37.73,0,0,0,8.07,4,24.85,24.85,0,0,0,6.08,1.39,12.82,12.82,0,0,0,2.23.06,12,12,0,0,0,5.47-1.85A8.3,8.3,0,0,0,71,71.49c.71-.65,1.41-1.33,2.07-2a3.88,3.88,0,0,0,1.07-3.18A3.83,3.83,0,0,0,73,63.94l-6.79-6.79-.49-.49a3.75,3.75,0,0,0-4-.9A4.2,4.2,0,0,0,60,56.83c-1.11,1.12-2.23,2.23-3.35,3.35l-.26.25-.32-.17L54.05,59.1A34,34,0,0,1,42.71,48.69,57.18,57.18,0,0,1,39.79,43.76Z" transform="translate(-0.07 -0.07)"/>
    <path class="phonegreencls3" d="M39.79,43.76a57.18,57.18,0,0,0,2.92,4.93A34,34,0,0,0,54.05,59.1l2.06,1.16.32.17.26-.25C57.81,59.06,58.93,58,60,56.83a4.2,4.2,0,0,1,1.66-1.07,3.75,3.75,0,0,1,4,.9l.49.49L73,63.94a3.83,3.83,0,0,1,1.16,2.34,3.88,3.88,0,0,1-1.07,3.18c-.66.7-1.36,1.38-2.07,2a8.3,8.3,0,0,1-1.15.78,12,12,0,0,1-5.47,1.85,12.82,12.82,0,0,1-2.23-.06,24.85,24.85,0,0,1-6.08-1.39,37.73,37.73,0,0,1-8.07-4,54.26,54.26,0,0,1-8.67-7,72.65,72.65,0,0,1-7.87-8.95,32.26,32.26,0,0,1-4.07-7.24A20.66,20.66,0,0,1,26.1,40a19.13,19.13,0,0,1,.16-5.18,12.76,12.76,0,0,1,2.13-5.08,10.86,10.86,0,0,1,1.21-1.4c.39-.4.78-.8,1.18-1.18a3.9,3.9,0,0,1,4.93-.44,5.1,5.1,0,0,1,.65.57c2.4,2.4,4.78,4.81,7.2,7.19a3.91,3.91,0,0,1,0,5.59L40.14,43.5A3.18,3.18,0,0,1,39.79,43.76Z" transform="translate(-0.07 -0.07)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
