import React, { Component, Fragment, useState } from "react";
import PropTypes from "prop-types";
import Panel from "../../../sidebar/panel";
import { areaProperties } from "../../../../utils/objects-utils";
import AreaPanel from "../../areaPanel";
import PropertyString from "../../../../catalog/properties/property-string";

export default function AreaAttributesEditor(
  { element, onUpdate, attributeFormData, state },
  { translator }
) {
  const [selected, setSelected] = useState(areaProperties[0].name);
  let roomName = attributeFormData.has('roomName') ? attributeFormData.get('roomName') : element.getIn(['properties','roomName']);
 
  const togglePanel = (name) => {
    setSelected(name); // Toggle the selected panel
  };

  return (
    <Fragment>
        <PropertyString
      value={roomName}
      onUpdate={mapped => { 
        onUpdate('roomName', mapped)}}
      configs={{label: 'Room Name'}}
      state={state}
    //   {...rest}
    />
      {areaProperties.map(({ name, children }) => (
        <div
          onClick={() => {
            togglePanel(name);
          }}
        >
          <AreaPanel
            headingStyles={{ textAlign: "left" }}
            style={{
              backgroundColor: "white !important",
              color: "black !important",
              textAlign: "center",
            }}
            opened={selected === name}
            name={name}
          >
            {selected === name &&
              children.map((c) => (
                <p className="area-panel-list" onClick={() => {
                  onUpdate('roomName',c)
                }}>
                  {c}
                </p>
              ))}
          </AreaPanel>
        </div>
      ))}
    </Fragment>
  );
}

AreaAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};

AreaAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
