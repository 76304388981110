import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import PanelGuides from "../sidebar/panel-guides";
import * as SharedStyle from "../../shared-style";
import If from "../../utils/react-if";
import CatalogPanel from "./catalogpanel";
import { FaArrowLeft } from "react-icons/fa";
import { MODE_IDLE, VIEW_ONLY } from "../../constants";

const STYLE = {
  backgroundColor: SharedStyle.COLORS.white,
  display: "block",
  overflowY: "auto",
  overflowX: "hidden",
  paddingBottom: "20px",
};

const sortButtonsCb = (a, b) => {
  if (a.index === undefined || a.index === null) {
    a.index = Number.MAX_SAFE_INTEGER;
  }

  if (b.index === undefined || b.index === null) {
    b.index = Number.MAX_SAFE_INTEGER;
  }

  return a.index - b.index;
};

const mapButtonsCb = (el, ind) => (
  <If key={ind} condition={el.condition} style={{ position: "relative" }}>
    {el.dom}
  </If>
);

export default function Catalogbar(
  { modeFinish, state, width, height, sidebarComponents, mode, downloadpng },
  { projectActions, sceneActions }
) {
  let sorter = [
    {
      index: 0,
      condition: !modeFinish && state.get("mode") !== VIEW_ONLY,
      dom: <CatalogPanel state={state} />,
    },
    {
      index: 1,
      condition: modeFinish,
      dom: (
        <FinishingPanel
          downloadpng={downloadpng}
          projectActions={projectActions}
          sceneActions={sceneActions}
          state={state}
        />
      ),
    },
    {
      index: 2,
      condition: state.get("mode") === VIEW_ONLY,
      dom: (
        <FinishingPanel
          downloadpng={downloadpng}
          projectActions={projectActions}
          state={state}
        />
      ),
    },
  ];

  sorter = sorter.concat(
    sidebarComponents.map((Component, key) => {
      return Component.prototype //if is a react component
        ? {
            condition: true,
            dom: React.createElement(Component, { state, key }),
          }
        : {
            //else is a sortable toolbar button
            index: Component.index,
            condition: Component.condition,
            dom: React.createElement(Component.dom, { state, key }),
          };
    })
  );

  return (
    <aside
      style={{ width, height, ...STYLE }}
      onKeyDown={(event) => event.stopPropagation()}
      onKeyUp={(event) => event.stopPropagation()}
      className="sidebar"
    >
      {sorter.sort(sortButtonsCb).map(mapButtonsCb)}
    </aside>
  );
}

Catalogbar.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  downloadpng: PropTypes.func,
};
Catalogbar.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  sceneActions: PropTypes.object.isRequired,
};
const FinishingPanel = ({
  projectActions,
  sceneActions,
  state,
  downloadpng,
}) => {
  // console.log(downloadpng);

  const layers = state.getIn(["scene", "layers"]);
  return (
    <Fragment>
      {state.get("mode") !== VIEW_ONLY && (
        <div
          style={{ cursor: "pointer", marginBottom: 10 }}
          onClick={() => {
            projectActions.setMode(MODE_IDLE);
          }}
        >
          <FaArrowLeft color="rgb(255, 93, 23)" /> Back to drawing
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
        <div>
          <label for="switch-3" class="switch has-label">
            <input
              onChange={() => {
                projectActions.toggleEvacuationPlan();
              }}
              type="checkbox"
              name="switch-3"
              id="switch-3"
              checked={state.get("evacuationPlan")}
            />
            <span class="track">
              <span class="thumb"></span>
            </span>
            <span>Evacuation Plan</span>
            {/* <span class="on" style={{visibility:'hidden'}}></span>   */}
          </label>
        </div>
        <div>
          <label for="switch-2" class="switch has-label">
            <input
              onChange={() => {
                projectActions.toggleDimension();
              }}
              type="checkbox"
              name="switch-2"
              id="switch-2"
              checked={state.get("dimension")}
            />
            <span class="track">
              <span class="thumb"></span>
            </span>
            <span>Enable Dimensions</span>
            {/* <span class="on" style={{visibility:'hidden'}}></span> */}
          </label>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
        <div>
          <select
            name="layers"
            id="layers-mode-finishing-plan"
            onChange={(e) => {
              // console.log(e.target.value);
              if (e.target.value === "0") {
                projectActions.selectAllLayers(true);
              } else {
                sceneActions.selectLayer(e.target.value);
              }
            }}
            style={{ width: "100%" }}
            value={
              state.get("showAllLayers")
                ? "0"
                : state.getIn(["scene", "selectedLayer"])
            }
          >
            <option id="0" value={"0"}>
              All
            </option>
            {layers.valueSeq().map(({ visibleName, id }) => (
              <option id={id} value={id}>
                {visibleName}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div style={{ paddingTop: "10px" }}>
        {/* <button className="btn-class2">Download PDF</button> */}
        <button onClick={downloadpng} className="btn-class2">
          Download PNG
        </button>
      </div>
    </Fragment>
  );
};
