import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Curvychair",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Curvychair",
    description: "Curvychair",
    image: require("./Curvychair.svg"),
    svgIcon: require("./Curvychair.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M82.48,60.29c-.58,5.5-1.08,11-1.78,16.5a6.11,6.11,0,0,1-6.33,5.68,5.48,5.48,0,0,1-4.89-3.54,1.43,1.43,0,0,0-1.52-1q-19.45,0-38.91,0a1.28,1.28,0,0,0-1.39.81c-1.3,2.78-3.45,4-6.22,3.72a6.16,6.16,0,0,1-5-5.11c-.59-3.68-1.12-7.39-1.35-11.1-.51-8.52-.23-17,2-25.33,1.68-6.25,4.35-12,8.88-16.72a28.81,28.81,0,0,1,20.41-9A33.77,33.77,0,0,1,62.5,18.08C70.33,21.75,75.24,28,78.28,35.92A59,59,0,0,1,82,54.12c.1,2,0,4.09,0,6.14ZM68.67,76.74c0-.72,0-1.32.09-1.91.41-4.47,1-8.93,1.2-13.41a50.76,50.76,0,0,0-2.43-19C65.2,35.74,61.05,31,53.8,29.49A26.53,26.53,0,0,0,43,29.56a16.46,16.46,0,0,0-10.85,7.36A27.8,27.8,0,0,0,28.46,46a66.56,66.56,0,0,0-1.07,21.3c.28,3.14.68,6.27,1,9.45ZM25.85,62.08c.09-8.34.57-14.37,2.63-20.15C30.61,36,34.05,31.16,40.32,29a25.71,25.71,0,0,1,14.36-.6,17.84,17.84,0,0,1,11.83,8.94A32.84,32.84,0,0,1,70.15,48c1.48,8.14,1.17,16.3.1,24.45a21,21,0,0,0-.33,3.94c.2,3.62,3.88,5.93,6.86,4.36,1.9-1,2.65-2.77,3-4.82a97.94,97.94,0,0,0,1-23.51,52.38,52.38,0,0,0-5-19.56c-3.54-7-8.78-12.12-16.29-14.67a34.28,34.28,0,0,0-17.94-1A27.19,27.19,0,0,0,25.1,27.06a37.37,37.37,0,0,0-7,14.53,79.08,79.08,0,0,0-1.78,26.29c.26,3.15.73,6.29,1.29,9.4a4.79,4.79,0,0,0,4.29,4A4.71,4.71,0,0,0,26.73,78a7.77,7.77,0,0,0,.41-3.17C26.69,69.81,26.13,64.83,25.85,62.08Z"
              transform="translate(-14.91 -15.21)"
            />
            <path
              class="cls-1"
              d="M68.67,76.74H28.43c-.36-3.18-.76-6.31-1-9.45A66.56,66.56,0,0,1,28.46,46a27.8,27.8,0,0,1,3.73-9.07A16.46,16.46,0,0,1,43,29.56a26.53,26.53,0,0,1,10.76-.07c7.25,1.48,11.4,6.25,13.73,13a50.76,50.76,0,0,1,2.43,19c-.2,4.48-.79,8.94-1.2,13.41C68.7,75.42,68.7,76,68.67,76.74Z"
              transform="translate(-14.91 -15.21)"
            />
            <path
              class="cls-1"
              d="M25.85,62.08c.28,2.75.84,7.73,1.29,12.72A7.77,7.77,0,0,1,26.73,78a4.71,4.71,0,0,1-4.8,3.28,4.79,4.79,0,0,1-4.29-4c-.56-3.11-1-6.25-1.29-9.4a79.08,79.08,0,0,1,1.78-26.29,37.37,37.37,0,0,1,7-14.53,27.19,27.19,0,0,1,16.34-9.87,34.28,34.28,0,0,1,17.94,1c7.51,2.55,12.75,7.69,16.29,14.67a52.38,52.38,0,0,1,5,19.56,97.94,97.94,0,0,1-1,23.51c-.32,2-1.07,3.81-3,4.82-3,1.57-6.66-.74-6.86-4.36a21,21,0,0,1,.33-3.94c1.07-8.15,1.38-16.31-.1-24.45a32.84,32.84,0,0,0-3.64-10.64,17.84,17.84,0,0,0-11.83-8.94,25.71,25.71,0,0,0-14.36.6c-6.27,2.14-9.71,7-11.84,12.91C26.42,47.71,25.94,53.74,25.85,62.08Z"
              transform="translate(-14.91 -15.21)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
