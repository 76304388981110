import React from "react";
import PropTypes from "prop-types";
import { FormLabel, Button } from "../../components/style/export";
import PropertyStyle from "./shared-property-style";
import { confirmAlert } from "react-confirm-alert";
import "./../../app.css";

const filterVerticesWithDuplication = (selectedAreaId, areas) => {
  const selectedVertices = areas.get(selectedAreaId).get("vertices");
  let filteredVertices = new Map();

  areas.forEach((area, areaId) => {
    if (areaId !== selectedAreaId) {
      const areaVertices = area.get("vertices");
      selectedVertices.forEach((vertex) => {
        if (areaVertices.includes(vertex)) {
          filteredVertices = filteredVertices.set(vertex, true);
        }
      });
    }
  });

  return selectedVertices
    .filter((vertex) => !filteredVertices.has(vertex))
    .toList();
};
export default function PropertyButton(
  { value, onUpdate, configs, sourceElement, internalState, state },
  { linesActions, areaActions }
) {
  let update = (val) => {
    if (configs.hook) {
      return configs
        .hook(val, sourceElement, internalState, state)
        .then((_val) => {
          return onUpdate(_val);
        });
    }

    return onUpdate(val);
  };

  return (
    <table className="PropertyButton" style={PropertyStyle.tableStyle}>
      <tbody>
        <tr>
          <td>
            <Button
              className="btn-class"
              onClick={(e) => {
                confirmAlert({
                  closeOnClickOutside: false,
                  overlayClassName: "overlay-dialog",
                  title: "Confirm to continue",
                  message: configs.confirmationAlertMessage,
                  buttons: [
                    {
                      label: "Yes",
                      onClick: () => {
                        if (configs.actionType) {
                          switch (configs.actionType) {
                            case "delete-room":
                              const selectedLayer = state.getIn([
                                "scene",
                                "selectedLayer",
                              ]);
                              const areas = state.getIn([
                                "scene",
                                "layers",
                                selectedLayer,
                                "areas",
                              ]);
                              const selectedAreaId = state
                                .getIn([
                                  "scene",
                                  "layers",
                                  selectedLayer,
                                  "selected",
                                  "areas",
                                ])
                                .first();
                              areaActions.removeArea(
                                selectedLayer,
                                selectedAreaId
                              );
                              const filteredVertices =
                                filterVerticesWithDuplication(
                                  selectedAreaId,
                                  areas
                                );
                              const filteredLines = state
                                .getIn([
                                  "scene",
                                  "layers",
                                  selectedLayer,
                                  "lines",
                                ])
                                .filter((line) => {
                                  const lineVertices = line.get("vertices");
                                  return lineVertices.some((vertex) =>
                                    filteredVertices.includes(vertex)
                                  );
                                });
                              filteredLines.forEach((line) => {
                                linesActions.removeLine(
                                  selectedLayer,
                                  line.id
                                );
                              });
                              break;
                            default:
                              break;
                          }
                        }
                      },
                    },
                    {
                      label: "No",
                      onClick: () => {},
                    },
                  ],
                });
              }}
              size="small"
            >
              {configs.label}
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

PropertyButton.propTypes = {
  value: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired,
};

PropertyButton.contextTypes = {
  linesActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
};
