import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Television",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Television",
    description: "Television",
    image: require("./Television.svg"),
    svgIcon: require("./Television.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,  
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g id={`${element.type}-${element.id}`} transform={`translate(-47, 20) scale(1,-1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M1.14,34H99.26V61.89H1.14ZM98.29,61.17V34.78H2.08V61.17Z"
            transform="translate(-1.14 -33.98)"
          />
          <path
            class="cls-1"
            d="M98.29,61.17H2.08V34.78H98.29ZM35,44H17.47c-3,0-2.58-.19-2.59,2.58,0,1.89,0,1.9,1.86,1.9h66c3.58,0,3,.39,3-3,0-1.47,0-1.51-1.53-1.51H65.54c-.33-2-1.16-2.65-3.39-2.66q-11.83,0-23.67,0C36.28,41.36,35.62,41.89,35,44Z"
            transform="translate(-1.14 -33.98)"
          />
          <path
            d="M35,44c.67-2.12,1.33-2.65,3.53-2.66q11.84,0,23.67,0c2.23,0,3.06.69,3.39,2.66H84.17c1.51,0,1.55,0,1.53,1.51,0,3.35.57,3-3,3H16.74c-1.87,0-1.87,0-1.86-1.9,0-2.77-.37-2.58,2.59-2.58H35Zm49.69,3.75c0-.5.06-.88,0-1.27,0-2,.28-1.8-1.83-1.81H16v3.08ZM64.42,43.93c-.19-1.51-.77-1.93-2.64-1.93q-11.47,0-23,0a5.21,5.21,0,0,0-1.62.24,1.25,1.25,0,0,0-.93,1.68Z"
            transform="translate(-1.14 -33.98)"
          />
          <path
            class="cls-1"
            d="M84.64,47.76H16V44.68H82.86c2.11,0,1.81-.2,1.83,1.81C84.7,46.88,84.66,47.26,84.64,47.76Z"
            transform="translate(-1.14 -33.98)"
          />
          <path
            class="cls-1"
            d="M64.42,43.93H36.28a1.25,1.25,0,0,1,.93-1.68A5.21,5.21,0,0,1,38.83,42q11.47,0,23,0C63.65,42,64.23,42.42,64.42,43.93Z"
            transform="translate(-1.14 -33.98)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
