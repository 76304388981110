import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-no-smoking",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "no smoking",
    description: "no-smoking",
    image: require("./no-smoking.svg"),
    svgIcon: require("./no-smoking.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.nosmokingcls1{fill:#da2828;}.nosmokingcls2{fill:#fff;}.nosmokingcls3{fill:#010101;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="nosmokingcls1"
              d="M82.39,42.16A39.32,39.32,0,1,1,43.08,2.81,39.3,39.3,0,0,1,82.39,42.16ZM53.48,51l.18.2,9.39,10.07,6,6.41c.2.21.15.22.37,0A33.08,33.08,0,0,0,71.74,65a36.6,36.6,0,0,0,6.44-12.26,35.9,35.9,0,0,0,1.49-8.15,37.48,37.48,0,0,0,0-4.37,36.32,36.32,0,0,0-2-10.06A36.76,36.76,0,0,0,44,5.46a35.06,35.06,0,0,0-3.55.09A35.63,35.63,0,0,0,36.74,6a36.79,36.79,0,0,0-14.51,6,35.49,35.49,0,0,0-2.86,2.19c-.14.12-.14.12,0,.26l.74.79L30.71,26.58,44.92,41.82a.58.58,0,0,0,.2.18c0,.08.08.13.14.19q1.56,1.7,3.15,3.38,2.13,2.31,4.29,4.6C53,50.45,53.22,50.72,53.48,51ZM40.7,42c0-.1-.12-.16-.18-.23L30.66,31.19,17.5,17.07l-.58-.61A36.64,36.64,0,0,0,66.85,70c-.06-.07-.13-.14-.19-.22l-5.29-5.67-11-11.75c-.44-.48-.89-.94-1.33-1.41l-.21-.23-4.53-4.86c-.93-1-1.86-2-2.78-3C41.27,42.58,41,42.29,40.7,42Z"
              transform="translate(-3.76 -2.81)"
            />
            <path
              class="nosmokingcls2"
              d="M49.08,51c.44.47.89.93,1.33,1.41l11,11.75,5.29,5.67c.06.08.13.15.19.22A36.64,36.64,0,0,1,16.92,16.46l.58.61L30.66,31.19l9.86,10.57c.06.07.15.13.18.23H11.89c-.34,0-.3,0-.3.29v8.37c0,.36-.06.33.32.33H48.79Z"
              transform="translate(-3.76 -2.81)"
            />
            <path
              class="nosmokingcls2"
              d="M45.12,42a.58.58,0,0,1-.2-.18L30.71,26.58,20.1,15.2l-.74-.79c-.13-.14-.13-.14,0-.26A35.49,35.49,0,0,1,22.23,12,36.79,36.79,0,0,1,36.74,6a35.63,35.63,0,0,1,3.72-.45A35.06,35.06,0,0,1,44,5.46a37,37,0,0,1,3.79.3,36.65,36.65,0,0,1,29.94,24.4,36.32,36.32,0,0,1,2,10.06,37.48,37.48,0,0,1,0,4.37,35.9,35.9,0,0,1-1.49,8.15A36.6,36.6,0,0,1,71.74,65a33.08,33.08,0,0,1-2.35,2.66c-.22.23-.17.22-.37,0l-6-6.41L53.66,51.19l-.18-.2a1.29,1.29,0,0,1,.34,0h12c.33,0,.29,0,.29-.3V42.31c0-.32,0-.32-.32-.32H45.12Zm1.75-18.9a.31.31,0,0,1-.06.06,5.14,5.14,0,0,0-.2,2.2,5.37,5.37,0,0,0,1.32,2.91,8.43,8.43,0,0,0,2.27,1.81,17.54,17.54,0,0,0,4.19,1.64,30.22,30.22,0,0,0,3.65.73,17.56,17.56,0,0,0,2.4.23,7.93,7.93,0,0,1,.84.07,22.48,22.48,0,0,1,4.07.77,14,14,0,0,1,3.8,1.7,8.25,8.25,0,0,1,3,3.4l.26.59v-.12a11.64,11.64,0,0,0-.8-3A10.75,10.75,0,0,0,68,31.42a12.69,12.69,0,0,0-5.78-2.24A20.79,20.79,0,0,0,58.8,29c-1,0-2,0-3-.1a19.51,19.51,0,0,1-4-.71,8.86,8.86,0,0,1-3-1.5A4.51,4.51,0,0,1,46.87,23.1Zm1.68,6.68a2.48,2.48,0,0,0-.22.88,3.31,3.31,0,0,0,.84,2.46A6.33,6.33,0,0,0,51,34.52a14.56,14.56,0,0,0,3.6,1.27,27.69,27.69,0,0,0,3,.53c.5.07,1,.14,1.52.16a20.93,20.93,0,0,1,4.32.54,12.44,12.44,0,0,1,3.38,1.27,5.83,5.83,0,0,1,2.57,2.65.16.16,0,0,0,.07.07c0-.1,0-.21,0-.31s-.05-.21-.07-.31a7.65,7.65,0,0,0-3.73-4.92,11.68,11.68,0,0,0-4.34-1.42,20.9,20.9,0,0,0-3-.16,27.16,27.16,0,0,1-3-.1,15.32,15.32,0,0,1-3.54-.7,6.28,6.28,0,0,1-2-1.05,3,3,0,0,1-1.14-1.7C48.59,30.17,48.58,30,48.55,29.78ZM71.74,46.49v4.27c0,.21,0,.22.21.22h2.38c.2,0,.21,0,.21-.21V42.19c0-.19,0-.2-.2-.2H71.85c-.08,0-.12,0-.11.11v4.39Zm-4.2,0v4.28c0,.22,0,.23.21.23h2.38c.2,0,.2,0,.2-.21V42.2c0-.2,0-.21-.19-.21H67.65c-.08,0-.11,0-.11.11v4.37Z"
              transform="translate(-3.76 -2.81)"
            />
            <path
              class="nosmokingcls3"
              d="M49.08,51l-.29,0H11.91c-.38,0-.32,0-.32-.33V42.28c0-.33,0-.29.3-.29H40.7c.28.3.57.59.86.89.92,1,1.85,2,2.78,3l4.53,4.86Z"
              transform="translate(-3.76 -2.81)"
            />
            <path
              class="nosmokingcls3"
              d="M45.12,42H65.81c.32,0,.32,0,.32.32v8.37c0,.33,0,.3-.29.3h-12a1.29,1.29,0,0,0-.34,0c-.26-.27-.53-.54-.78-.82q-2.16-2.3-4.29-4.6-1.59-1.68-3.15-3.38C45.2,42.13,45.13,42.08,45.12,42Z"
              transform="translate(-3.76 -2.81)"
            />
            <path
              class="nosmokingcls3"
              d="M46.87,23.1a4.51,4.51,0,0,0,1.87,3.56,8.86,8.86,0,0,0,3,1.5,19.51,19.51,0,0,0,4,.71c1,.08,2,.11,3,.1a20.79,20.79,0,0,1,3.43.21A12.69,12.69,0,0,1,68,31.42a10.75,10.75,0,0,1,3.61,4.66,11.64,11.64,0,0,1,.8,3v.12l-.26-.59a8.25,8.25,0,0,0-3-3.4,14,14,0,0,0-3.8-1.7,22.48,22.48,0,0,0-4.07-.77,7.93,7.93,0,0,0-.84-.07,17.56,17.56,0,0,1-2.4-.23,30.22,30.22,0,0,1-3.65-.73,17.54,17.54,0,0,1-4.19-1.64,8.43,8.43,0,0,1-2.27-1.81,5.37,5.37,0,0,1-1.32-2.91,5.14,5.14,0,0,1,.2-2.2A.31.31,0,0,0,46.87,23.1Z"
              transform="translate(-3.76 -2.81)"
            />
            <path
              class="nosmokingcls3"
              d="M48.55,29.78c0,.22,0,.39.08.56A3,3,0,0,0,49.77,32a6.28,6.28,0,0,0,2,1.05,15.32,15.32,0,0,0,3.54.7,27.16,27.16,0,0,0,3,.1,20.9,20.9,0,0,1,3,.16,11.68,11.68,0,0,1,4.34,1.42,7.65,7.65,0,0,1,3.73,4.92c0,.1,0,.2.07.31s0,.21,0,.31a.16.16,0,0,1-.07-.07,5.83,5.83,0,0,0-2.57-2.65A12.44,12.44,0,0,0,63.42,37a20.93,20.93,0,0,0-4.32-.54c-.51,0-1-.09-1.52-.16a27.69,27.69,0,0,1-3-.53A14.56,14.56,0,0,1,51,34.52a6.33,6.33,0,0,1-1.81-1.4,3.31,3.31,0,0,1-.84-2.46A2.48,2.48,0,0,1,48.55,29.78Z"
              transform="translate(-3.76 -2.81)"
            />
            <path
              class="nosmokingcls3"
              d="M71.74,46.49V42.1c0-.08,0-.11.11-.11h2.49c.2,0,.2,0,.2.2v8.58c0,.2,0,.21-.21.21H72c-.21,0-.21,0-.21-.22Z"
              transform="translate(-3.76 -2.81)"
            />
            <path
              class="nosmokingcls3"
              d="M67.54,46.47V42.1c0-.08,0-.11.11-.11h2.49c.19,0,.19,0,.19.21v8.57c0,.2,0,.21-.2.21H67.75c-.21,0-.21,0-.21-.23Z"
              transform="translate(-3.76 -2.81)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
