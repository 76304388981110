import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-direction-left",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "direction left",
    description: "direction-left",
    image: require("./direction-left.svg"),
    svgIcon: require("./direction-left.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.dlcls1{fill:#0ea04b;}.dlcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="dlcls1"
              d="M6.23,90.77v-86H92.3c0,.16,0,.32,0,.48q0,42.54,0,85.07c0,.52,0,.47-.47.47H6.66C6.51,90.74,6.37,90.76,6.23,90.77ZM33.25,42l0-.07,1.3-1.29,1.31-1.32c.44-.43.87-.86,1.3-1.3l1.3-1.29,1.32-1.32L41,34.09l1.3-1.29,1.3-1.3L45,30.18l1.3-1.3,1.29-1.3,1.32-1.31,1.3-1.3,1.3-1.3,1.32-1.32A17.76,17.76,0,0,0,54.05,21a1.61,1.61,0,0,0-.37,0H38.22a.9.9,0,0,0-.7.29l-8.4,8.4q-8.86,8.88-17.75,17.75c-.1.09-.19.2-.3.32l.34.35Q24.48,61.15,37.55,74.23a.85.85,0,0,0,.65.28H53.87l.24-.06c-1.19-1.2-2.34-2.34-3.5-3.5l-3.49-3.48L43.66,64,40.17,60.5,36.7,57,33.17,53.5h54c.35,0,.36,0,.36-.35V42.42c0-.14,0-.28,0-.43Z"
              transform="translate(-6.23 -4.72)"
            />
            <path
              class="dlcls2"
              d="M6.23,90.77c.14,0,.28,0,.43,0H91.8c.52,0,.47.05.47-.47q0-42.54,0-85.07c0-.16,0-.32,0-.48l0,0a1.69,1.69,0,0,1,0,.23V90.83H6.49C6.4,90.82,6.29,90.89,6.23,90.77Z"
              transform="translate(-6.23 -4.72)"
            />
            <path
              class="dlcls2"
              d="M33.25,42H87.52c0,.15,0,.29,0,.43V53.15c0,.34,0,.35-.36.35h-54L36.7,57l3.47,3.47L43.66,64l3.46,3.47,3.49,3.48c1.16,1.16,2.31,2.3,3.5,3.5l-.24.06H38.2a.85.85,0,0,1-.65-.28Q24.49,61.15,11.41,48.09l-.34-.35c.11-.12.2-.23.3-.32q8.88-8.88,17.75-17.75l8.4-8.4a.9.9,0,0,1,.7-.29H53.68a1.61,1.61,0,0,1,.37,0,17.76,17.76,0,0,1-1.26,1.33l-1.32,1.32L50.17,25l-1.3,1.3-1.32,1.31-1.29,1.3L45,30.18,43.64,31.5l-1.3,1.3L41,34.09l-1.3,1.31-1.32,1.32L37.12,38c-.43.44-.86.87-1.3,1.3l-1.31,1.32-1.3,1.29Z"
              transform="translate(-6.23 -4.72)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
