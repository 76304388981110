import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "downarrow",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "down arrow",
    description: "downarrow",
    image: require("./downarrow.svg"),
    svgIcon: require("./downarrow.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-20, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M43.34,69.26V40.05L37,39.48c4.38-10.77,8.53-21,12.69-31.21l.83-.12c.94,2,2,3.91,2.81,5.93,2.46,6,4.85,12,7.25,18,.76,1.9,1.36,3.87,2.22,5.72s-.15,2-1.44,2.07-2.7,0-4.25,0V69.26Z"
              transform="translate(-25.8 -8.15)"
            />
            <path
              d="M25.8,73.78h4.37a5,5,0,0,1,5.32,5.38c0,3.21-2,5.65-5.42,5.65H25.8Zm2.38,9.1H30c2.12,0,3.05-1.57,3.05-3.72,0-1.8-.87-3.46-3-3.46h-1.8Z"
              transform="translate(-25.8 -8.15)"
            />
            <path
              d="M47.73,79.26c0,3.07-1.84,5.71-5.43,5.71S37,82.46,37,79.29s2-5.67,5.43-5.67C45.68,73.62,47.73,75.88,47.73,79.26Zm-8.24,0c0,2.15.95,3.78,2.9,3.78,2.12,0,2.88-1.78,2.88-3.74s-.86-3.73-2.92-3.73S39.49,77.1,39.49,79.25Z"
              transform="translate(-25.8 -8.15)"
            />
            <path
              d="M51.47,84.81l-2.81-11H51.2c1,5.07,1.51,7.32,1.64,8.53h0c.42-2,1.49-5.87,2.13-8.53h2.15c.56,2.11,1.68,6.39,2,8.42h0c.36-2.44,1.45-6.71,1.83-8.42h2.32l-3,11H57.88c-.58-2.44-1.55-6-1.94-7.9h0c-.4,2-1.39,5.68-2,7.9Z"
              transform="translate(-25.8 -8.15)"
            />
            <path
              d="M64.76,84.81v-11h2.95c1.38,2.47,3.93,6.94,4.44,8.14h0c-.13-1.15-.13-3.06-.13-4.87V73.78h2.17v11H71.41c-1.18-2.15-4-7.29-4.55-8.56h0c.08,1,.1,3.23.1,5.21v3.35Z"
              transform="translate(-25.8 -8.15)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
