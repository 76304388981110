import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "curved-based-unit",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "curved based unit",
    description: "curved-based-unit",
    image: require("./curved-based-unit.svg"),
    svgIcon: require("./curved-based-unit.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 200,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 200,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 35) scale(1, -1)`}>
          <defs>
            <style>{`.curvedcls-1{fill:#e5e5e5;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              class="curvedcls-1"
              d="M60.71,73.75q-17.44,0-34.88,0a23.25,23.25,0,0,1-12-3.31,23.68,23.68,0,0,1-11.23-15,23.27,23.27,0,0,1-.74-5.9q.07-11.34,0-22.68c0-.9.25-1.13,1.14-1.13,15,0,29.95-.14,44.93-.17,16.3,0,32.61,0,48.92,0,1,0,1.44.27,1.27,1.3a2.77,2.77,0,0,0,0,.5Q98.05,50,98,72.65c0,.92-.28,1.12-1.15,1.12Q78.76,73.72,60.71,73.75Z"
              transform="translate(-1.89 -25.51)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
