import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Table-and-chair",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Table and chair",
    description: "Table-and-chair",
    image: require("./Table-and-chair.svg"),
    svgIcon: require("./Table-and-chair.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g
          id={`${element.type}-${element.id}`}
          transform={`translate(-47, 55) scale(1,-1)`}
        >
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M85.78,51.42a11.2,11.2,0,0,1-1,1.48C79.89,58,75,63,70.18,68L52.3,86.52c-1.26,1.31-2.3,1.38-3.65.11s-2.87-2.85-4.27-4.31Q30,67.43,15.61,52.51a8,8,0,0,1-1.32-1.89,2.06,2.06,0,0,1,.5-2.59c3.67-3.76,7.31-7.55,11-11.33C33,29.23,40.19,21.79,47.34,14.29c1.28-1.34,2.42-1,3.51-.21a7.86,7.86,0,0,1,1.08,1Q68,31.66,84,48.25A5.6,5.6,0,0,1,85.78,51.42ZM48.93,14.08a12,12,0,0,0-1,.82Q31.71,31.7,15.5,48.52a1.48,1.48,0,0,0-.28,2.22,6.66,6.66,0,0,0,.8,1L48.46,85.39a5.88,5.88,0,0,0,1.12.94c.92.55,1.29.48,2-.3,1.71-1.76,3.41-3.53,5.12-5.3L84.13,52.36a1.51,1.51,0,0,0,.28-2.23,7.34,7.34,0,0,0-1-1.27q-16-16.63-32.09-33.24a6.4,6.4,0,0,0-1-.91A9.08,9.08,0,0,0,48.93,14.08Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            d="M80.46,62a10.53,10.53,0,0,1,1.54.83,20.11,20.11,0,0,1,1.51,1.55c.53.54.94,1.19,1.94.8a1.62,1.62,0,0,1,1.28.51c4,4.07,7.91,8.18,11.88,12.26a1,1,0,0,1,.08,1.41c-1,1.69-2,3.41-3,5A47,47,0,0,1,81.71,97.68c-.39.25-.8.46-1.2.7a1.37,1.37,0,0,1-2-.3c-3.67-3.88-7.39-7.72-11.12-11.55a2.18,2.18,0,0,1-.81-2.44c.07-.19-.13-.54-.29-.73-.55-.63-1.15-1.22-1.72-1.83-1.3-1.38-1.28-2.53,0-3.91Q71,71,77.42,64.41A21.57,21.57,0,0,1,79,62.81,8.74,8.74,0,0,1,80.46,62Zm-1.1,33c3.94-6.88,8.9-12.61,15.9-16.49-.36-.32-.55-.52-.77-.69a41.58,41.58,0,0,1-8.94-9.12c-.62-.91-1.13-1.9-1.8-2.77a24,24,0,0,0-2-2.14c-1-1-1.57-1-2.55,0q-7,7.24-14,14.52c-.93,1-.93,1.71,0,2.69a17,17,0,0,0,3.12,2.59,34,34,0,0,1,4.64,3.77A32.55,32.55,0,0,1,76,90.57C77.15,91.93,78.16,93.37,79.36,95ZM67.69,84a1.15,1.15,0,0,0,.14,1.74Q73.44,91.52,79,97.34c.45.48.8.55,1.32.19,1.91-1.35,3.89-2.59,5.73-4A47.05,47.05,0,0,0,97.8,79.41c.22-.39.38-.71,0-1.14-3.85-4-7.67-7.93-11.5-11.91-.47-.49-.91-.51-1.47,0,.77,1,1.49,1.93,2.27,2.85,1.2,1.44,2.34,2.94,3.67,4.25s3,2.55,4.49,3.81,1.39,1.19-.11,2.17c-4.38,2.87-8.46,6-11.44,10.45-1.24,1.84-2.48,3.69-3.75,5.51-.52.75-.74.73-1.29,0-1.06-1.41-2.16-2.79-3.16-4.23C73.42,88.21,70.25,86.5,67.69,84Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            d="M37.1,79.62a16.16,16.16,0,0,1-1,1.69,11.47,11.47,0,0,1-1.32,1.38,1.91,1.91,0,0,0-.8,2.07c.12.43-.32,1.15-.71,1.55C29.57,90.25,25.76,94.12,22,98.07a1.38,1.38,0,0,1-2,.26c-1.71-1.12-3.45-2.21-5.09-3.44A48,48,0,0,1,2.7,81c-1.39-2.33-1.38-2.34.49-4.28,3.43-3.56,6.87-7.1,10.29-10.67a2,2,0,0,1,2.29-.75,1,1,0,0,0,.71-.29c.7-.68,1.36-1.41,2.05-2.11a2,2,0,0,1,3.07,0q7.3,7.53,14.56,15.11A8,8,0,0,1,37.1,79.62ZM21.21,95c.72-1,1.29-1.75,1.9-2.51,1.39-1.76,2.51-3.75,4.41-5.07C29.57,86,31.18,84,33.46,82.87a8.76,8.76,0,0,0,2.24-2.26,1.44,1.44,0,0,0-.14-2.17q-7.13-7.38-14.27-14.76c-.86-.89-1.51-.89-2.36,0a14,14,0,0,0-2.59,3.25,22,22,0,0,1-2.74,3.55,39.9,39.9,0,0,1-3,3.46C9.13,75.31,7.53,76.55,6,77.85a4,4,0,0,1-.68.36l.12.32C12.32,82.36,17.26,88.07,21.21,95ZM32.9,84c-2.52,2.46-5.6,4.13-7.64,7-1.05,1.45-2.15,2.86-3.22,4.3-.71.94-.85.94-1.55-.06-.51-.73-1-1.49-1.47-2.24-2.44-3.7-5-7.27-8.64-10C8.61,81.64,6.77,80.4,5,79.11c-.8-.57-.82-.8-.08-1.44.92-.79,1.87-1.55,2.79-2.34A34.05,34.05,0,0,0,11,72.4c1.57-1.78,3-3.69,4.43-5.55a4.62,4.62,0,0,0,.35-.55c-.69-.57-1.13-.26-1.6.23C10.45,70.37,6.77,74.2,3.05,78a1.09,1.09,0,0,0-.21,1.55,50.78,50.78,0,0,0,7.27,9.9,45.87,45.87,0,0,0,10.06,8c.41.24.77.52,1.27,0q5.68-6,11.42-11.86C33.32,85.13,33.36,84.68,32.9,84Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            d="M82.8,35.07c-1.24-.8-1.68.22-2.29.8-.4.37-.76.79-1.15,1.17a2.19,2.19,0,0,1-3.48,0c-2-2-3.95-4.07-5.91-6.11l-8.24-8.55a2.44,2.44,0,0,1,0-3.8c.37-.4.75-.8,1.14-1.19.61-.6,1.24-1.13.86-2.19-.12-.33.23-.95.54-1.28C68.11,9.84,72.05,5.81,76,1.74a1.13,1.13,0,0,1,1.62-.07c1.61,1,3.25,2,4.77,3.13a48.5,48.5,0,0,1,12.9,14.67c1.15,2,1.12,2-.43,3.58-3.55,3.68-7.1,7.37-10.66,11A8.84,8.84,0,0,1,82.8,35.07ZM76.31,5.36l-4,5.34a5.36,5.36,0,0,1-.76.82c-2,1.65-4,3.29-6,4.9-.48.38-1.11.58-1.57,1a22.71,22.71,0,0,0-1.8,1.87,1.43,1.43,0,0,0,0,2.33Q69.29,29,76.42,36.39c.84.87,1.5.85,2.38,0a20.44,20.44,0,0,0,2.74-3.46c1.32-2,2.93-3.8,4.43-5.66a8.49,8.49,0,0,1,1.14-1.18c1.52-1.29,3-2.56,4.58-3.85.21-.17.4-.37.65-.61-3.29-2.15-6.53-4.23-9-7.15S78.65,8.43,76.31,5.36Zm5.58,28.38a1.07,1.07,0,0,0,1.63-.17Q89,27.92,94.42,22.29c.93-1,.93-1,.25-2.16A49,49,0,0,0,78.83,3.33c-.67-.44-1.38-.81-2.09-1.23l-12,12.42c-.48.49-.36,1,0,1.48.39-.31.72-.59,1.06-.85a38.28,38.28,0,0,0,9.3-9.57c1.28-1.93,1.42-1.89,2.63.12A42.09,42.09,0,0,0,92.23,20.59c1.57.93,1.5,1,.11,2.21-2.14,1.92-4.62,3.47-6.4,5.8C84.63,30.32,83.27,32,81.89,33.74Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            d="M20.15,38.11a13.93,13.93,0,0,1-1.65-1A18.55,18.55,0,0,1,17,35.67c-.53-.54-.94-1.22-1.94-.78-.3.12-1-.24-1.27-.55Q7.89,28.26,2,22.11A1.38,1.38,0,0,1,1.79,21a7.92,7.92,0,0,1,.85-1.79,48,48,0,0,1,17-17.26c1.44-.85,1.46-.81,2.62.38q5.43,5.61,10.86,11.2c.68.7,1.2,1.39.79,2.45-.07.19.11.55.28.73.57.65,1.2,1.25,1.79,1.88,1.21,1.3,1.23,2.47,0,3.73-4.71,4.9-9.41,9.79-14.15,14.65A10.23,10.23,0,0,1,20.15,38.11ZM5.43,21.62a2.73,2.73,0,0,0,.18.27c.31.27.6.54.92.79A37.73,37.73,0,0,1,15,31.32c.66,1,1.21,2,1.91,2.92a22.56,22.56,0,0,0,2,2.08c.95,1,1.6,1,2.56,0l14-14.46c1.06-1.1,1-1.62,0-2.81-1.27-1.58-3-2.48-4.59-3.68a36.62,36.62,0,0,1-8.63-9c-.25-.38-.54-.72-.73-1-2.33,3.07-4.51,6.2-7,9.09S8.66,19.48,5.43,21.62ZM15.74,33.79a1.31,1.31,0,0,0-.12-.3c-2.61-3.3-5-6.79-8.52-9.27-.56-.4-1.07-.88-1.6-1.33C4,21.57,4,21.58,5.71,20.43c3.19-2.09,6.38-4.23,8.77-7.26,2.07-2.62,3.94-5.41,5.87-8.14.85-1.2.92-1.23,1.78-.09.17.23.34.47.52.69,1.83,2.22,3.21,4.8,5.6,6.56,1.62,1.19,3.12,2.54,4.69,3.83a1.2,1.2,0,0,0-.23-1.76C29.05,10.49,25.38,6.72,21.78,2.9a1.22,1.22,0,0,0-1.87-.22,51.32,51.32,0,0,0-9.5,7.62A48.81,48.81,0,0,0,2.8,20.58c-.22.4-.45.75,0,1.22q5.79,5.93,11.52,11.89A1,1,0,0,0,15.74,33.79Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M48.93,14.08a9.08,9.08,0,0,1,1.32.63,6.4,6.4,0,0,1,1,.91Q67.34,32.24,83.38,48.86a7.34,7.34,0,0,1,1,1.27,1.51,1.51,0,0,1-.28,2.23L56.74,80.73C55,82.5,53.33,84.27,51.62,86c-.75.78-1.12.85-2,.3a5.88,5.88,0,0,1-1.12-.94L16,51.76a6.66,6.66,0,0,1-.8-1,1.48,1.48,0,0,1,.28-2.22Q31.71,31.71,47.94,14.9A12,12,0,0,1,48.93,14.08ZM50,64.2a12.2,12.2,0,0,0,5.29-1.11,14,14,0,0,0,5.6-20.25A12.94,12.94,0,0,0,44.2,38.29C39,40.93,36.29,46.4,37,52.57A13.31,13.31,0,0,0,50,64.2Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M79.36,95c-1.2-1.59-2.21-3-3.33-4.39a32.55,32.55,0,0,0-3.09-3.23,34,34,0,0,0-4.64-3.77A17,17,0,0,1,65.18,81c-1-1-1-1.72,0-2.69q7-7.26,14-14.52c1-1,1.57-1,2.55,0a24,24,0,0,1,2,2.14c.67.87,1.18,1.86,1.8,2.77a41.58,41.58,0,0,0,8.94,9.12c.22.17.41.37.77.69C88.26,82.35,83.3,88.08,79.36,95Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M67.69,84c2.56,2.51,5.73,4.22,7.79,7.18,1,1.44,2.1,2.82,3.16,4.23.55.73.77.75,1.29,0,1.27-1.82,2.51-3.67,3.75-5.51,3-4.4,7.06-7.58,11.44-10.45,1.5-1,1.49-1,.11-2.17s-3.09-2.44-4.49-3.81-2.47-2.81-3.67-4.25c-.78-.92-1.5-1.89-2.27-2.85.56-.51,1-.49,1.47,0,3.83,4,7.65,8,11.5,11.91.41.43.25.75,0,1.14A47.05,47.05,0,0,1,86.06,93.5c-1.84,1.44-3.82,2.68-5.73,4-.52.36-.87.29-1.32-.19q-5.57-5.82-11.18-11.61A1.15,1.15,0,0,1,67.69,84Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M21.21,95c-3.95-6.9-8.89-12.61-15.79-16.44l-.12-.32A4,4,0,0,0,6,77.85c1.55-1.3,3.15-2.54,4.61-3.93a39.9,39.9,0,0,0,3-3.46,22,22,0,0,0,2.74-3.55,14,14,0,0,1,2.59-3.25c.85-.87,1.5-.87,2.36,0Q28.43,71,35.56,78.44a1.44,1.44,0,0,1,.14,2.17,8.76,8.76,0,0,1-2.24,2.26C31.18,84,29.57,86,27.52,87.39c-1.9,1.32-3,3.31-4.41,5.07C22.5,93.22,21.93,94,21.21,95Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M32.9,84c.46.7.42,1.15,0,1.63q-5.73,5.91-11.42,11.86c-.5.53-.86.25-1.27,0a45.87,45.87,0,0,1-10.06-8,50.78,50.78,0,0,1-7.27-9.9A1.09,1.09,0,0,1,3.05,78c3.72-3.81,7.4-7.64,11.08-11.48.47-.49.91-.8,1.6-.23a4.62,4.62,0,0,1-.35.55c-1.46,1.86-2.86,3.77-4.43,5.55a34.05,34.05,0,0,1-3.29,2.93c-.92.79-1.87,1.55-2.79,2.34-.74.64-.72.87.08,1.44C6.77,80.4,8.61,81.64,10.38,83,14,85.69,16.58,89.26,19,93c.49.75,1,1.51,1.47,2.24.7,1,.84,1,1.55.06,1.07-1.44,2.17-2.85,3.22-4.3C27.3,88.11,30.38,86.44,32.9,84Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M76.31,5.36c2.34,3.07,4.54,6.22,7,9.13s5.73,5,9,7.15c-.25.24-.44.44-.65.61-1.53,1.29-3.06,2.56-4.58,3.85A8.49,8.49,0,0,0,86,27.28c-1.5,1.86-3.11,3.67-4.43,5.66A20.44,20.44,0,0,1,78.8,36.4c-.88.84-1.54.86-2.38,0Q69.3,29,62.18,21.6a1.43,1.43,0,0,1,0-2.33A22.71,22.71,0,0,1,64,17.4c.46-.4,1.09-.6,1.57-1,2-1.61,4-3.25,6-4.9a5.36,5.36,0,0,0,.76-.82Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M81.89,33.74c1.38-1.74,2.74-3.42,4-5.14,1.78-2.33,4.26-3.88,6.4-5.8,1.39-1.23,1.46-1.28-.11-2.21A42.09,42.09,0,0,1,77.77,5.7c-1.21-2-1.35-2.05-2.63-.12a38.28,38.28,0,0,1-9.3,9.57c-.34.26-.67.54-1.06.85-.37-.53-.49-1,0-1.48l12-12.42c.71.42,1.42.79,2.09,1.23a49,49,0,0,1,15.84,16.8c.68,1.2.68,1.2-.25,2.16Q89,27.93,83.52,33.57A1.07,1.07,0,0,1,81.89,33.74Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M5.43,21.62c3.23-2.14,6.5-4.23,9-7.17s4.64-6,7-9.09c.19.24.48.58.73,1a36.62,36.62,0,0,0,8.63,9c1.55,1.2,3.32,2.1,4.59,3.68.95,1.19,1,1.71,0,2.81l-14,14.46c-1,1-1.61,1-2.56,0a22.56,22.56,0,0,1-2-2.08c-.7-.92-1.25-2-1.91-2.92a37.73,37.73,0,0,0-8.42-8.64c-.32-.25-.61-.52-.92-.79A2.73,2.73,0,0,1,5.43,21.62Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M15.74,33.79a1,1,0,0,1-1.42-.1Q8.57,27.74,2.8,21.8c-.45-.47-.22-.82,0-1.22A48.81,48.81,0,0,1,10.41,10.3a51.32,51.32,0,0,1,9.5-7.62,1.22,1.22,0,0,1,1.87.22c3.6,3.82,7.27,7.59,10.93,11.36A1.2,1.2,0,0,1,32.94,16c-1.57-1.29-3.07-2.64-4.69-3.83-2.39-1.76-3.77-4.34-5.6-6.56-.18-.22-.35-.46-.52-.69-.86-1.14-.93-1.11-1.78.09-1.93,2.73-3.8,5.52-5.87,8.14-2.39,3-5.58,5.17-8.77,7.26C4,21.58,4,21.57,5.5,22.89c.53.45,1,.93,1.6,1.33,3.51,2.48,5.91,6,8.52,9.27A1.31,1.31,0,0,1,15.74,33.79Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            d="M50,64.2A13.31,13.31,0,0,1,37,52.57c-.73-6.17,2-11.64,7.18-14.28a12.94,12.94,0,0,1,16.71,4.55,14,14,0,0,1-5.6,20.25A12.2,12.2,0,0,1,50,64.2ZM37.7,50.51a12.65,12.65,0,0,0,2.55,7.83,11.86,11.86,0,0,0,15.44,3.58c4.58-2.59,6.77-6.67,6.66-12A12.48,12.48,0,0,0,48.24,37.89C42.31,38.71,37.72,44.18,37.7,50.51Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M37.7,50.51c0-6.33,4.61-11.8,10.54-12.62A12.48,12.48,0,0,1,62.35,50c.11,5.3-2.08,9.38-6.66,12a11.86,11.86,0,0,1-15.44-3.58A12.65,12.65,0,0,1,37.7,50.51Zm18.8-.66a7.23,7.23,0,0,0-.16-.93c-1.26-4.16-4.28-5.49-7.49-5-4.14.69-5.69,4.3-5.33,7.67a6.51,6.51,0,0,0,2.59,4.44,5.86,5.86,0,0,0,4.69,1.19A7.28,7.28,0,0,0,53.93,56,6.31,6.31,0,0,0,56.5,49.85Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            d="M56.5,49.85A6.31,6.31,0,0,1,53.93,56a7.28,7.28,0,0,1-3.13,1.31,5.86,5.86,0,0,1-4.69-1.19,6.51,6.51,0,0,1-2.59-4.44c-.36-3.37,1.19-7,5.33-7.67,3.21-.53,6.23.8,7.49,5A7.23,7.23,0,0,1,56.5,49.85ZM50,47.08C48.7,47,46.69,49,46.57,50.52c-.1,1.33,1.87,3.42,3.33,3.52,1.24.09,3.36-2.09,3.38-3.47S51.35,47.19,50,47.08Zm.74,9.31c2.12.18,4.93-2.54,4.83-4.91-1.38-.31-1.52-.24-2.14,1a3.78,3.78,0,0,1-2.12,1.94.72.72,0,0,0-.57.89C50.75,55.65,50.72,56,50.72,56.39Zm0-11.62v1c0,.4-.07.77.47,1a4,4,0,0,1,2.5,2.58.89.89,0,0,0,.61.43,7.44,7.44,0,0,0,1.25,0A5.43,5.43,0,0,0,50.72,44.77ZM44.4,51.46c0,2.37,2.5,5,4.78,4.91a5.8,5.8,0,0,1,0-.59,1.26,1.26,0,0,0-.85-1.48,5.51,5.51,0,0,1-1.92-1.89C45.69,51.28,45.76,51.21,44.4,51.46Zm4.75-6.67a5.44,5.44,0,0,0-4.75,5c.57-.19,1.35.49,1.71-.42a4.4,4.4,0,0,1,3-2.84Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M50,47.08c1.37.11,3.31,2.16,3.3,3.49S51.14,54.13,49.9,54c-1.46-.1-3.43-2.19-3.33-3.52C46.69,49,48.7,47,50,47.08Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M50.72,56.39c0-.36,0-.74,0-1.1a.72.72,0,0,1,.57-.89,3.78,3.78,0,0,0,2.12-1.94c.62-1.22.76-1.29,2.14-1C55.65,53.85,52.84,56.57,50.72,56.39Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M50.72,44.77a5.43,5.43,0,0,1,4.83,5,7.44,7.44,0,0,1-1.25,0,.89.89,0,0,1-.61-.43,4,4,0,0,0-2.5-2.58c-.54-.19-.47-.56-.47-1Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M44.4,51.46c1.36-.25,1.29-.18,2,.95a5.51,5.51,0,0,0,1.92,1.89,1.26,1.26,0,0,1,.85,1.48,5.8,5.8,0,0,0,0,.59C46.9,56.44,44.39,53.83,44.4,51.46Z"
            transform="translate(-1.71 -1.34)"
          />
          <path
            class="cls-1"
            d="M49.15,44.79v1.69a4.4,4.4,0,0,0-3,2.84c-.36.91-1.14.23-1.71.42A5.44,5.44,0,0,1,49.15,44.79Z"
            transform="translate(-1.71 -1.34)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
