import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-youAreHere",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "you are here",
    description: "you-are-here",
    image: require("./you-are-here.svg"),
    svgIcon: require("./you-are-here.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.you-are-herecls1{fill:#ed1b24;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="you-are-herecls1"
              d="M69.51,21.62a19.72,19.72,0,0,0-16.79-17c-.45-.06-.91-.09-1.36-.13-.12,0-.24,0-.35-.06H49c-.48.06-1,.1-1.46.17A19.68,19.68,0,0,0,30.46,22c-.05.42-.09.85-.13,1.27v1.51c.06.47.12.94.2,1.41a27.17,27.17,0,0,0,1.79,5.77,84.62,84.62,0,0,0,6.09,11.58,199.7,199.7,0,0,0,11.4,16.59l.15.21.08,0h0l.16-.23c4-5.29,7.81-10.74,11.28-16.4a89.74,89.74,0,0,0,5.83-10.9,30.72,30.72,0,0,0,2.07-6.2c.09-.45.14-.91.21-1.36a2,2,0,0,1,0-.24V23C69.62,22.56,69.57,22.09,69.51,21.62ZM50,33.59a11,11,0,1,1,11-11A11,11,0,0,1,50,33.59Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M26.6,69.27v4.65H24.42V69.27L21.51,61.8h2.27l1.72,5.12,1.71-5.12h2.26Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M31.66,73.61a3.09,3.09,0,0,1-1.31-1.27,3.92,3.92,0,0,1-.46-1.94V65.33a3.89,3.89,0,0,1,.46-1.94,3.09,3.09,0,0,1,1.31-1.27,4.72,4.72,0,0,1,4,0A3.15,3.15,0,0,1,37,63.39a3.89,3.89,0,0,1,.46,1.94V70.4A3.92,3.92,0,0,1,37,72.34a3.15,3.15,0,0,1-1.31,1.27,4.72,4.72,0,0,1-4,0Zm2.81-1.93a1.28,1.28,0,0,0,.53-.55,1.87,1.87,0,0,0,.18-.85V65.44A1.86,1.86,0,0,0,35,64.6a1.3,1.3,0,0,0-.53-.56,1.68,1.68,0,0,0-.82-.19,1.62,1.62,0,0,0-.81.19,1.39,1.39,0,0,0-.54.56,1.86,1.86,0,0,0-.18.84v4.84a1.87,1.87,0,0,0,.18.85,1.36,1.36,0,0,0,.54.55,1.62,1.62,0,0,0,.81.19A1.68,1.68,0,0,0,34.47,71.68Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M40.56,73.6a2.94,2.94,0,0,1-1.25-1.29,4.58,4.58,0,0,1-.43-2V61.8h2.18v8.52a1.63,1.63,0,0,0,.39,1.16,1.43,1.43,0,0,0,1.08.41,1.45,1.45,0,0,0,1.1-.41,1.59,1.59,0,0,0,.4-1.16V61.8H46.2v8.47a4.45,4.45,0,0,1-.43,2,2.91,2.91,0,0,1-1.26,1.29,4.56,4.56,0,0,1-3.95,0Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M55,61.8h1.45l3.76,12.12H58l-2.24-8.1-2.23,8.1H51.27ZM53.36,70H58.2V72H53.36Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M61.45,61.8H63.6V73.92H61.45Zm.92,5h3.19a1,1,0,0,0,.54-.14.92.92,0,0,0,.35-.38,1.35,1.35,0,0,0,.12-.59V65a1.44,1.44,0,0,0-.12-.61.81.81,0,0,0-.35-.4,1,1,0,0,0-.54-.14H62.37V61.8h3.21a3.58,3.58,0,0,1,1.72.38,2.64,2.64,0,0,1,1.12,1.1,3.45,3.45,0,0,1,.4,1.7v.71a3.5,3.5,0,0,1-.4,1.72,2.63,2.63,0,0,1-1.12,1.12,3.58,3.58,0,0,1-1.72.39H62.37Zm2.11,1.72,2.17-.38,2.59,5.75H66.76Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M70.87,61.8H73V73.92H70.87Zm.86,0h5.88v2.11H71.73Zm0,5.06h5.21V69H71.73Zm0,5h5.88v2.1H71.73Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M33.29,77.45h2.17V89.57H33.29Zm1,5.1h5.56v2.06H34.31Zm4.15-5.1h2.17V89.57H38.46Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M42.71,77.45h2.17V89.57H42.71Zm.87,0h5.88v2.11H43.58Zm0,5h5.21v2.1H43.58Zm0,5h5.88v2.11H43.58Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M51,77.45h2.15V89.57H51Zm.92,5h3.19a1,1,0,0,0,.54-.14.85.85,0,0,0,.36-.39,1.31,1.31,0,0,0,.12-.58v-.68a1.41,1.41,0,0,0-.12-.61.94.94,0,0,0-.35-.41,1.09,1.09,0,0,0-.55-.13H52V77.45h3.22a3.61,3.61,0,0,1,1.72.38A2.64,2.64,0,0,1,58,78.93a3.53,3.53,0,0,1,.39,1.7v.71A3.61,3.61,0,0,1,58,83.06a2.75,2.75,0,0,1-1.12,1.12,3.61,3.61,0,0,1-1.72.39H52Zm2.12,1.72,2.16-.39,2.59,5.76H56.35Z"
              transform="translate(-21.51 -4.47)"
            />
            <path
              d="M60.46,77.45h2.17V89.57H60.46Zm.87,0H67.2v2.11H61.33Zm0,5h5.21v2.1H61.33Zm0,5H67.2v2.11H61.33Z"
              transform="translate(-21.51 -4.47)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
