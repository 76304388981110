import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  MdSettings,
  MdUndo,
  MdDirectionsRun,
  MdCheckBox,
} from "react-icons/md";
import { IoIosUndo, IoIosRedo } from "react-icons/io";
import { FiFilePlus } from "react-icons/fi";

import ToolbarButton from "./toolbar-button";
import ToolbarSaveButton from "./toolbar-save-button";
import ToolbarLoadButton from "./toolbar-load-button";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import If from "../../utils/react-if";
import {
  MODE_IDLE,
  MODE_3D_VIEW,
  MODE_3D_FIRST_PERSON,
  MODE_VIEWING_CATALOG,
  MODE_CONFIGURING_PROJECT,
  MODE_FINISHING_PLAN,
} from "../../constants";
import * as SharedStyle from "../../shared-style";
import { provideAreaOfPolygon } from "../../utils/geometry";
import ConfigurationModal from "../modals/configuration-modal";


const iconTextStyle = {
  fontSize: "19px",
  textDecoration: "none",
  fontWeight: "bold",
  margin: "0px",
  userSelect: "none",
};

const Icon2D = ({ style }) => <p style={{ ...iconTextStyle, ...style }}>2D</p>;
const Icon3D = ({ style }) => <p style={{ ...iconTextStyle, ...style }}>3D</p>;

const ASIDE_STYLE = {
  backgroundColor: "#4e555b",
  display: "flex",
  justifyContent: "space-between",
};

const sortButtonsCb = (a, b) => {
  if (a.index === undefined || a.index === null) {
    a.index = Number.MAX_SAFE_INTEGER;
  }

  if (b.index === undefined || b.index === null) {
    b.index = Number.MAX_SAFE_INTEGER;
  }

  return a.index - b.index;
};

const mapButtonsCb = (el, ind) => {
  return (
    <If
      key={ind}
      condition={el.condition}
      style={{ position: "relative", backgroundColor: "#ff5d17" }}
    >
      {el.dom}
    </If>
  );
};

export default class Toolbar extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      modal: {
        type: "configure",
        open: false,
      },
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.state.scene.selectedLayer !==
        nextProps.state.scene.selectedLayer ||
      this.props.state.scene.layers.get(this.props.state.scene.selectedLayer)
        .size !==
        nextProps.state.scene.layers.get(this.props.state.scene.selectedLayer)
          .size ||
      this.props.state.mode !== nextProps.state.mode ||
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.state.alterate !== nextProps.state.alterate ||
      this.state.modal !== nextState.modal || 
      this.props.state.scene.layers.get(this.props.state.scene.selectedLayer).areas.size !==nextProps.state.scene.layers.get(this.props.state.scene.selectedLayer).areas.size
    );
  }
  render() {
    let {
      props: { state, width, height, toolbarButtons, allowProjectFileSupport },
      context: { projectActions, viewer3DActions, translator, store },
    } = this;
    let selectedLayer = state.getIn(["scene", "selectedLayer"]);
    let layer = state.getIn(["scene", "layers", selectedLayer]);
    let areas = state.getIn(["scene", "layers", selectedLayer, "areas"]);

    let mode = state.get("mode");
    let alterate = state.get("alterate");
    let alterateColor = alterate ? SharedStyle.MATERIAL_COLORS[500].orange : "";
    let sorter = [
      {
        index: 0,
        condition: allowProjectFileSupport,
        dom: (
          <ToolbuttonGroup>
            <ToolbarButton
              rtl={true}
              active={false}
              tooltip={translator.t("New project")}
              onClick={(event) =>
                confirm(translator.t("Would you want to start a new Project?"))
                  ? projectActions.newProject()
                  : null
              }
            >
              <FiFilePlus />
            </ToolbarButton>
            <ToolbarButton
              rtl={true}
              tooltip={translator.t("Undo")}
              onClick={(event)=>{
                // store.dispatch(undo())
                projectActions.undo()
              }}
              >
                <IoIosUndo/>
              </ToolbarButton>
            <ToolbarButton
              rtl={true}
              tooltip={translator.t("Redo")}
              onClick={(event)=>{
                projectActions.redo()
              }}
              >
                <IoIosRedo/>
              </ToolbarButton>
            {/* <ToolbarLoadButton rtl={true} state={state} /> */}
            {/* <ToolbarButton
              rtl={true}
              active={[MODE_IDLE].includes(mode)}
              tooltip={translator.t("Select")}
              onClick={(event) => projectActions.setMode(MODE_IDLE)}
            >
              {[MODE_3D_FIRST_PERSON, MODE_3D_VIEW].includes(mode) ? (
                <Icon2D style={{ color: alterateColor }} />
              ) : (
                <FaMousePointer style={{ color: alterateColor }} />
              )}
            </ToolbarButton> */}
            {/* select btn */}
          </ToolbuttonGroup>
        ),
      },
      {
        index: 1,
        condition: true,
        dom: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "white",
            }}
          >
            <p style={{ margin: 0, marginTop: "5px", fontSize: "12px" }}>
              Total Floor Area
            </p>
            <p style={{ margin: 0, fontSize: "12px" }}>
              <b>
                {parseFloat(areas
                  .toArray()
                  .reduce(
                    (accumulator, area) =>
                      parseFloat(accumulator) +
                      parseFloat(provideAreaOfPolygon(area, layer)),
                    0.0
                  )).toFixed(2)}
                m{String.fromCharCode(0xb2)}
              </b>
            </p>
          </div>
        ),
      },
      {
        index: 2,
        condition: true,
        dom: (
          <ToolbuttonGroup>
            <Modal
              center
              open={
                this.state.modal.type === "configure" && this.state.modal.open
              }
              onClose={() => {
                this.setState((prev) => ({
                  ...prev,
                  modal: { type: "configure", open: false },
                }));
              }}
            >
              <ConfigurationModal
                close={() => {
                  this.setState((prev) => ({
                    ...prev,
                    modal: { type: "configure", open: false },
                  }));
                }}
                state={state}
              />
            </Modal>
            <ToolbarSaveButton rtl={false} state={state} />
            <ToolbarButton
              rtl={false}
              tooltip={"Complete"}
              onClick={(event) => {
                projectActions.unselectAll();
                projectActions.setMode(MODE_FINISHING_PLAN)
              }}
            >
              <MdCheckBox />
            </ToolbarButton>
            <ToolbarButton
              rtl={false}
              tooltip={"Settings"}
              onClick={() => {
                this.setState({
                  ...this.state,
                  modal: { type: "configure", open: true },
                });
              }}
            >
              <MdSettings />
            </ToolbarButton>

            {/* <ToolbarButton rtl={false}
          active={[MODE_3D_VIEW].includes(mode)}
          tooltip={translator.t('3D View')}
          onClick={event => viewer3DActions.selectTool3DView()}>
          <Icon3D />
        </ToolbarButton> */}
            {/* <ToolbarButton rtl={false}
          active={[MODE_3D_FIRST_PERSON].includes(mode)}
          tooltip={translator.t('3D First Person')}
          onClick={event => viewer3DActions.selectTool3DFirstPerson()}>
          <MdDirectionsRun />
        </ToolbarButton> */}
          </ToolbuttonGroup>
        ),
      },
      // {
      //   index: 1, condition: allowProjectFileSupport,
      //   dom: <ToolbarSaveButton state={state} />
      // },
      // {
      //   index: 2, condition: allowProjectFileSupport,
      //   dom: <ToolbarLoadButton state={state} />
      // },
      // {
      //   index: 3, condition: true,
      //   dom: <ToolbarButton
      //     active={[MODE_VIEWING_CATALOG].includes(mode)}
      //     tooltip={translator.t('Open catalog')}
      //     onClick={event => projectActions.openCatalog()}>
      //     <FaPlus />
      //   </ToolbarButton>
      // },
      // {
      //   index: 4, condition: true, dom: <ToolbarButton
      //     active={[MODE_3D_VIEW].includes(mode)}
      //     tooltip={translator.t('3D View')}
      //     onClick={event => viewer3DActions.selectTool3DView()}>
      //     <Icon3D />
      //   </ToolbarButton>
      // },
      // {
      //   index: 1, condition: true, dom: <ToolbarButton
      //     active={[MODE_IDLE].includes(mode)}
      //     tooltip={translator.t('Select')}
      //     onClick={event => projectActions.setMode( MODE_IDLE )}>
      //     {[MODE_3D_FIRST_PERSON, MODE_3D_VIEW].includes(mode) ? <Icon2D style={{color: alterateColor}} /> : <FaMousePointer style={{color: alterateColor}} />}
      //   </ToolbarButton>
      // },
      // {
      //   index: 6, condition: true, dom: <ToolbarButton
      //     active={[MODE_3D_FIRST_PERSON].includes(mode)}
      //     tooltip={translator.t('3D First Person')}
      //     onClick={event => viewer3DActions.selectTool3DFirstPerson()}>
      //     <MdDirectionsRun />
      //   </ToolbarButton>
      // },
      // {
      //   index: 7, condition: true, dom: <ToolbarButton
      //     active={false}
      //     tooltip={translator.t('Undo (CTRL-Z)')}
      //     onClick={event => projectActions.undo()}>
      //     <MdUndo />
      //   </ToolbarButton>
      // },
      // {
      //   index: 8, condition: true, dom: <ToolbarButton
      //     active={[MODE_CONFIGURING_PROJECT].includes(mode)}
      //     tooltip={translator.t('Configure project')}
      //     onClick={event => projectActions.openProjectConfigurator()}>
      //     <MdSettings />
      //   </ToolbarButton>
      // }
    ];

    sorter = sorter.concat(
      toolbarButtons.map((Component, key) => {
        return Component.prototype //if is a react component
          ? {
              condition: true,
              dom: React.createElement(Component, { mode, state, key }),
            }
          : {
              //else is a sortable toolbar button
              index: Component.index,
              condition: Component.condition,
              dom: React.createElement(Component.dom, { mode, state, key }),
            };
      })
    );

    return (
      <aside
        style={{ ...ASIDE_STYLE, maxWidth: width, maxHeight: height }}
        className="toolbar"
      >
        {sorter.sort(sortButtonsCb).map(mapButtonsCb)}
      </aside>
    );
  }
}

Toolbar.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  allowProjectFileSupport: PropTypes.bool.isRequired,
  toolbarButtons: PropTypes.array,
};

Toolbar.contextTypes = {
  store: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};

const ToolbuttonGroup = ({ children }) => {
  const STYLE = {
    display: "flex",
    columnGap: "10px",
  };
  return <div style={STYLE}>{children}</div>;
};
