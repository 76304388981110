import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-direction-down",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "direction down",
    description: "direction-down",
    image: require("./direction-down.svg"),
    svgIcon: require("./direction-down.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.ddcls1{fill:#0ea04b;}.ddcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="ddcls1"
              d="M92.19,90.59H6.44V4.78l.49,0H91.61c.64,0,.56-.08.56.56V90.1C92.17,90.26,92.18,90.42,92.19,90.59ZM22.72,42.85c0,.22,0,.35,0,.49V58.69a.83.83,0,0,0,.26.65l5.4,5.4q8.56,8.58,17.12,17.15c1.21,1.22,2.43,2.43,3.64,3.64l.27.24.29-.26c1.34-1.35,2.68-2.71,4-4.05L75.78,59.37a.85.85,0,0,0,.27-.65Q76,51,76,43.31v-.2a2,2,0,0,0-.05-.23c-.12.11-.22.18-.3.26l-3.21,3.22-7.26,7.25L55.5,63.33l-.2.2-.15.11s0,0,0,0v-54l0,0H43.63c0,.2,0,.38,0,.56v53c0,.12,0,.23,0,.34a1.51,1.51,0,0,1,0,.22,4,4,0,0,1-.29-.28l-15-14.95-5.24-5.27C23,43.06,22.87,43,22.72,42.85Z"
              transform="translate(-6.44 -4.72)"
            />
            <path
              class="ddcls2"
              d="M92.19,90.59c0-.17,0-.33,0-.49V5.36c0-.64.08-.56-.56-.56H6.93l-.49,0s0-.06,0-.06H92.25V90.33C92.25,90.41,92.31,90.52,92.19,90.59Z"
              transform="translate(-6.44 -4.72)"
            />
            <path
              class="ddcls2"
              d="M22.72,42.85c.15.14.26.21.35.3l5.24,5.27,15,14.95a4,4,0,0,0,.29.28,1.51,1.51,0,0,0,0-.22c0-.11,0-.22,0-.34v-53c0-.18,0-.36,0-.56H55.05l0,0v54s0,0,0,0l.15-.11.2-.2,9.72-9.72,7.26-7.25,3.21-3.22c.08-.08.18-.15.3-.26a2,2,0,0,1,.05.23v.2q0,7.71,0,15.41a.85.85,0,0,1-.27.65L53.69,81.46c-1.35,1.34-2.69,2.7-4,4.05l-.29.26-.27-.24c-1.21-1.21-2.43-2.42-3.64-3.64q-8.57-8.56-17.12-17.15l-5.4-5.4a.83.83,0,0,1-.26-.65V43.34C22.68,43.2,22.7,43.07,22.72,42.85Z"
              transform="translate(-6.44 -4.72)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
