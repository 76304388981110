import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Exit-Right",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Exit Right",
    description: "Exit-Right",
    image: require("./Exit-Right.svg"),
    svgIcon: require("./Exit-Right.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.exitright-cls1{fill:#009f4d;}.exitright-cls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="exitright-cls1"
              d="M31.55,73.69H0V24.47l.39,0H98.2l.39,0V73.69H62.77q1-2.41,2-4.83a2.55,2.55,0,0,0,.21-1q0-19.37,0-38.74v-.36c0-.09,0-.17,0-.25l-.11,0H34l-.16,0h0l-.06.07V55.3c-.24,0-.42,0-.6,0a1.91,1.91,0,0,0-1.89,1.18,3.09,3.09,0,0,0-.1,2.51,2,2,0,0,0,2.09,1.46l.48,0c0,.11,0,.19,0,.26v7.19a1.29,1.29,0,0,1-.11.47c-.43,1.09-.87,2.17-1.31,3.25C32.08,72.32,31.82,73,31.55,73.69Zm48.71-14.2,0,.1h6a.77.77,0,0,0,.6-.26l10-10,.25-.28-.34-.35L87,38.92l-.21-.21a.45.45,0,0,0-.38-.15H80.49c-.07,0-.14,0-.27,0l8.11,8.1,0,.07H69.06c-.64,0-1.29,0-1.92,0-.07.33-.07,4.25,0,4.55H88.31l0,.07Zm-76-7.6V50l.41,0H7.86l.42,0V48.31l-.41,0H4.69l-.4,0a17.6,17.6,0,0,1,0-1.78H8.44l.36,0a5.71,5.71,0,0,0,0-1.64l-.12,0H2.41l-.13,0,0,0,0,0v8.67l.43,0H8.55a1.62,1.62,0,0,1,.22,0c.17,0,.24-.08.24-.24V52.07a1,1,0,0,0,0-.18Zm9.57-1.31.23.32c.56.8,1.13,1.61,1.68,2.42a.47.47,0,0,0,.46.24c.58,0,1.16,0,1.74,0l.34,0-.27-.39L15.4,49.46c-.38-.52-.36-.36,0-.88.81-1.14,1.64-2.27,2.46-3.4.07-.1.13-.21.23-.37h-.38c-.5,0-1,0-1.51,0a.56.56,0,0,0-.54.28c-.49.73-1,1.45-1.51,2.18l-.23.31c-.09-.12-.17-.21-.23-.31-.53-.74-1.06-1.48-1.57-2.22a.52.52,0,0,0-.47-.24H10l-.35,0,3,4.26-.53.74-.54.74-.52.72-.54.74c-.17.24-.35.47-.52.72a3.84,3.84,0,0,0-.51.79l.35,0c.55,0,1.09,0,1.64,0a.53.53,0,0,0,.53-.29c.54-.8,1.09-1.59,1.63-2.38Zm8.28-4.1.41,0H24.4c.55,0,.52,0,.52.52v6a.7.7,0,0,0,.09.5h1.66c.33,0,.34,0,.35-.33V47c0-.14,0-.27,0-.43.93-.05,1.83,0,2.75-.05,0-.57,0-1.11,0-1.64-.44-.08-7.38-.06-7.63,0Zm-3.07-1.66c-.08.5,0,8.47,0,8.74.64,0,1.3,0,2,0V44.82Z"
              transform="translate(0 -24.39)"
            />
            <path
              class="exitright-cls2"
              d="M31.55,73.69c.27-.68.53-1.37.8-2,.44-1.08.88-2.16,1.31-3.25a1.29,1.29,0,0,0,.11-.47V60.73c0-.07,0-.15,0-.26l-.48,0A2,2,0,0,1,31.18,59a3.09,3.09,0,0,1,.1-2.51,1.91,1.91,0,0,1,1.89-1.18c.18,0,.36,0,.6,0V28.56l.06-.07h0l.16,0H64.78l.11,0c0,.08,0,.16,0,.25v.36q0,19.36,0,38.74a2.55,2.55,0,0,1-.21,1q-1,2.42-2,4.83ZM49.07,45.27l0,.08a4,4,0,0,1-.26.32L42.3,53.25,40,55.93a.51.51,0,0,1-.45.21c-.28,0-.55,0-.82,0-1.77,0-3.53,0-5.29,0a1.7,1.7,0,0,0-1.73,1.79,1.73,1.73,0,0,0,1.76,1.64h7.36a5.24,5.24,0,0,0,.88-.08,2.19,2.19,0,0,0,1.35-.73c1.07-1.21,2.15-2.4,3.23-3.59a3.71,3.71,0,0,1,.28-.3l.28.25,3.73,3.61a.52.52,0,0,1,.18.57c-.15.6-.28,1.21-.42,1.82-.41,1.84-.83,3.68-1.24,5.53a1.72,1.72,0,0,0,3.25,1.06,2.21,2.21,0,0,0,.15-.5l1.29-5.72c.28-1.25.58-2.49.84-3.74a2,2,0,0,0-.58-2c-1.09-1.07-2.17-2.16-3.25-3.24l-.29-.31c1.36-1.57,2.7-3.13,4.07-4.7l.32.3,2.27,2.27a1.39,1.39,0,0,0,1.89.17,2.6,2.6,0,0,0,.45-.37l4-4,.11-.12A1.38,1.38,0,0,0,63,43.53a1.32,1.32,0,0,0-1.41.37L58.48,47l-.3.27-.3-.3c-1.28-1.27-2.56-2.54-3.83-3.83a2,2,0,0,0-1.44-.6l-6.34,0a1.62,1.62,0,0,0-1.32.61c-.91,1.07-1.85,2.13-2.77,3.2-.59.67-1.18,1.35-1.78,2a1.36,1.36,0,0,0-.22,1.5,1.33,1.33,0,0,0,1.32.73,1.34,1.34,0,0,0,1-.52l4-4.61a.62.62,0,0,1,.53-.25c.59,0,1.18,0,1.78,0Zm9.68-4.72a2.69,2.69,0,1,0-2.66,2.68A2.7,2.7,0,0,0,58.75,40.55Z"
              transform="translate(0 -24.39)"
            />
            <path
              class="exitright-cls2"
              d="M98.59,24.47l-.39,0H.39l-.39,0s0-.06,0-.06H98.29C98.39,24.41,98.52,24.34,98.59,24.47Z"
              transform="translate(0 -24.39)"
            />
            <path
              class="exitright-cls2"
              d="M80.26,59.49l8.09-8.08,0-.07H67.15c-.08-.3-.08-4.22,0-4.55.63,0,1.28,0,1.92,0H88.3l0-.07-8.11-8.1c.13,0,.2,0,.27,0H86.4a.45.45,0,0,1,.38.15l.21.21,9.8,9.79.34.35-.25.28-10,10a.77.77,0,0,1-.6.26h-6Z"
              transform="translate(0 -24.39)"
            />
            <path
              class="exitright-cls2"
              d="M4.3,51.89H9a1,1,0,0,1,0,.18v1.25c0,.16-.07.24-.24.24a1.62,1.62,0,0,0-.22,0H2.66l-.43,0V44.87l0,0,0,0,.13,0H8.65l.12,0a5.71,5.71,0,0,1,0,1.64l-.36,0H4.3a17.6,17.6,0,0,0,0,1.78l.4,0H7.87l.41,0V49.9l-.42,0H4.71L4.3,50Z"
              transform="translate(0 -24.39)"
            />
            <path
              class="exitright-cls2"
              d="M13.87,50.58l-.23.31c-.54.79-1.09,1.58-1.63,2.38a.53.53,0,0,1-.53.29c-.55,0-1.09,0-1.64,0l-.35,0a3.84,3.84,0,0,1,.51-.79c.17-.25.35-.48.52-.72l.54-.74.52-.72.54-.74.53-.74-3-4.26.35,0h1.7a.52.52,0,0,1,.47.24c.51.74,1,1.48,1.57,2.22.06.1.14.19.23.31l.23-.31c.51-.73,1-1.45,1.51-2.18a.56.56,0,0,1,.54-.28c.51,0,1,0,1.51,0h.38c-.1.16-.16.27-.23.37-.82,1.13-1.65,2.26-2.46,3.4-.37.52-.39.36,0,.88l2.65,3.67.27.39-.34,0c-.58,0-1.16,0-1.74,0a.47.47,0,0,1-.46-.24c-.55-.81-1.12-1.62-1.68-2.42Z"
              transform="translate(0 -24.39)"
            />
            <path
              class="exitright-cls2"
              d="M22.15,46.48V44.86c.25-.08,7.19-.1,7.63,0,0,.53,0,1.07,0,1.64-.92.07-1.82,0-2.75.05,0,.16,0,.29,0,.43v6.27c0,.31,0,.32-.35.33H25a.7.7,0,0,1-.09-.5V47c0-.54,0-.52-.52-.52H22.56Z"
              transform="translate(0 -24.39)"
            />
            <path
              class="exitright-cls2"
              d="M19.08,44.82h2v8.71c-.68,0-1.34,0-2,0C19,53.29,19,45.32,19.08,44.82Z"
              transform="translate(0 -24.39)"
            />
            <path
              class="exitright-cls1"
              d="M49.07,45.27h-.31c-.6,0-1.19,0-1.78,0a.62.62,0,0,0-.53.25l-4,4.61a1.34,1.34,0,0,1-1,.52,1.33,1.33,0,0,1-1.32-.73,1.36,1.36,0,0,1,.22-1.5c.6-.67,1.19-1.35,1.78-2,.92-1.07,1.86-2.13,2.77-3.2a1.62,1.62,0,0,1,1.32-.61l6.34,0a2,2,0,0,1,1.44.6c1.27,1.29,2.55,2.56,3.83,3.83l.3.3.3-.27,3.11-3.11A1.32,1.32,0,0,1,63,43.53a1.38,1.38,0,0,1,.64,2.21l-.11.12-4,4a2.6,2.6,0,0,1-.45.37,1.39,1.39,0,0,1-1.89-.17l-2.27-2.27-.32-.3c-1.37,1.57-2.71,3.13-4.07,4.7l.29.31C51.87,53.63,53,54.72,54,55.79a2,2,0,0,1,.58,2c-.26,1.25-.56,2.49-.84,3.74L52.49,67.2a2.21,2.21,0,0,1-.15.5,1.72,1.72,0,0,1-3.25-1.06c.41-1.85.83-3.69,1.24-5.53.14-.61.27-1.22.42-1.82a.52.52,0,0,0-.18-.57l-3.73-3.61-.28-.25a3.71,3.71,0,0,0-.28.3c-1.08,1.19-2.16,2.38-3.23,3.59a2.19,2.19,0,0,1-1.35.73,5.24,5.24,0,0,1-.88.08H33.46a1.73,1.73,0,0,1-1.76-1.64,1.7,1.7,0,0,1,1.73-1.79c1.76,0,3.52,0,5.29,0,.27,0,.54,0,.82,0a.51.51,0,0,0,.45-.21l2.31-2.68,6.55-7.58a4,4,0,0,0,.26-.32Z"
              transform="translate(0 -24.39)"
            />
            <path
              class="exitright-cls1"
              d="M58.75,40.55a2.68,2.68,0,0,1-2.66,2.68,2.69,2.69,0,1,1,2.66-2.68Z"
              transform="translate(0 -24.39)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
