import React, { Fragment, useEffect, useState } from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;
const STAIRS_DIFFERENCE = 10.74;
export default {
  name: "L-shaped-stairs-with-landing",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "L shaped stairs with landing",
    description: "",
    image: require("./L-shaped-stairs-with-landing-icon.svg"),
    svgIcon: require("./L-shaped-stairs-with-landing-icon.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    rightsidestairs: {
      label: "Right Stairs",
      type: "stairs-slider",
      min: 1,
      max: 25,
      defaultValue: 5,
    },
    bottomsidestairs: {
      label: "Bottom Stairs",
      type: "stairs-slider",
      min: 1,
      max: 25,
      defaultValue: 5,
    },
    arrow: {
      label: "Arrow Text",
      type: "dropdown",
      defaultValue: "up",
      options: ["up", "down"],
    },
    arrowDirection: {
      label: "Arrow Direction",
      type: "dropdown",
      defaultValue: "down",
      options: ["down", "right"],
    },
  },

  render2D: function (element, layer, scene) {
    let ArrowDirection = element.properties.get("arrowDirection");
    let angle = element.rotation + 90;
    let textRotation = 0;
    if (Math.sin((angle * Math.PI) / 180) < 0) {
      textRotation = 180;
    }
    let { rightsidestairs, bottomsidestairs } = element.properties.toJS();
    const rightRect = (
      <rect class="L-shaped-stairs-with-landing-cls-2" x="44.98" y="1.02" width="9.63" height="42.73" />
    );
    const bottomRect = (
      <rect
        class="L-shaped-stairs-with-landing-cls-2"
        x="18.37"
        y="29.78"
        width="9.63"
        height="42.73"
        transform="translate(-28.73 72.96) rotate(-90)"
      />
    );
    // const rect = (
    //   <rect class="rectL-shaped-stairs-with-landing-cls-2" x="0.81" y="1.03" width="43.34" height="8.95" />
    // );
    const [rightRectangles, setRightRectangles] = useState([rightRect]);
    const [bottomRectangles, setBottomRectangles] = useState([bottomRect]);
    useEffect(() => {
      if (rightsidestairs) {
        setRightRectangles(replicateRightRect(rightsidestairs));
      }
    }, [rightsidestairs]);
    useEffect(() => {
      if (bottomsidestairs) {
        setBottomRectangles(replicateBottomRect(bottomsidestairs));
      }
    }, [bottomsidestairs]);
    return {
      element: (
        <g transform={`translate(${-WIDTH / 2},${DEPTH})`}>
          <defs>
            <style>{`.L-shaped-stairs-with-landing-cls-1{fill:#050505;}.L-shaped-stairs-with-landing-cls-2{fill:#fff;}`}</style>
          </defs>
          <title>L shaped stairs with landing</title>
          <g
            style={{ transform: "scaleY(-1)" }}
            id={`${element.type}-${element.id}`}
            data-name="L shaped stairs with landing"
          >
            <polygon
              class="L-shaped-stairs-with-landing-cls-1"
              points={`${
                parseFloat(
                  rightRectangles[rightRectangles.length - 1].props.x
                ) + STAIRS_DIFFERENCE
              } 0 ${
                parseFloat(
                  rightRectangles[rightRectangles.length - 1].props.x
                ) + STAIRS_DIFFERENCE
              } 44.89 44.98 44.89 44.98 ${
                44.97 + bottomRectangles.length * STAIRS_DIFFERENCE
              } 0 ${
                44.97 + bottomRectangles.length * STAIRS_DIFFERENCE
              } 0 0 98.47 0`}
            />
            <rect
              class="L-shaped-stairs-with-landing-cls-2"
              x="1.04"
              y="0.99"
              width="42.76"
              height="42.76"
            />
            <g id="bottom-side-stairs">
              {bottomRectangles.map((rect) => rect)}
            </g>
            <g id="right-side-stairs">{rightRectangles.map((rect) => rect)}</g>
            {ArrowDirection === "right" && (
              <Fragment>
                <path
                  d="M98.21,23.17V20.51H80.36a22.34,22.34,0,0,1,0-2.35s-9.12,3.45-13.11,5c4,1.58,13.11,5,13.11,5a22.34,22.34,0,0,1,0-2.35H98.21V23.17Z"
                  transform={`translate(${
                    -43.5 * 2 +
                    parseFloat(
                      rightRectangles[rightRectangles.length - 1].props.x
                    )
                  } 0)`}
                />
                <text
                  key="5"
                  x="0"
                  y="0"
                  transform={`translate(${65 + rightRectangles.length * STAIRS_DIFFERENCE}, 25) rotate(${textRotation})`}
                  style={{ textAnchor: "middle", fontSize: "11px" }}
                >
                  {element.properties.get("arrow")}
                </text>
              </Fragment>
            )}
            {ArrowDirection === "down" && (
              <Fragment>
                <path
                  d="M23.2,99.57h2.65c0-3.92,0-13.94,0-17.85a21.33,21.33,0,0,1,2.35,0s-3.46-9.13-5-13.12c-1.58,4-5,13.12-5,13.12a21.33,21.33,0,0,1,2.35,0c0,3.91,0,13.93,0,17.85H23.2Z"
                  transform={`translate(-0.76 ${
                    -53.5 + bottomRectangles.length * STAIRS_DIFFERENCE
                  })`}
                />
                <text
                  key="5"
                  x="0"
                  y="0"
                  transform={`translate(22,${
                    60 + bottomRectangles.length * STAIRS_DIFFERENCE
                  }) rotate(${textRotation})`}
                  style={{ textAnchor: "middle", fontSize: "11px" }}
                >
                  {element.properties.get("arrow")}
                </text>
              </Fragment>
            )}
          </g>
        </g>
      ),
      transform: { width: -WIDTH / 2, depth: -55 },
    };
  },

  render3D: function (element, layer, scene) {},
};
function replicateBottomRect(countingState) {
  let elements = [];

  for (let i = 0; i < countingState; i++) {
    let newRect = (
      <rect
        class="L-shaped-stairs-with-landing-cls-2"
        x={
          i === 0
            ? 18.37
            : parseFloat(elements[i - 1].props.x) - STAIRS_DIFFERENCE
        }
        y={"29.78"}
        width="9.63"
        height="42.73"
        transform="translate(-28.73 72.96) rotate(-90)"
      />
    );
    elements.push(newRect);
  }

  return elements;
}
function replicateRightRect(countingState) {
  let elements = [];

  for (let i = 0; i < countingState; i++) {
    let newRect = (
      // <rect
      //   class={rect.props.class}
      //   x={rect.props.x}
      //   y={i === 0 ? rect.props.y : parseFloat(elements[i - 1].props.y) + 10.74}
      //   width={rect.props.width}
      //   height={rect.props.width}
      // />
      <rect
        class="L-shaped-stairs-with-landing-cls-2"
        x={
          i === 0
            ? "44.98"
            : parseFloat(elements[i - 1].props.x) + STAIRS_DIFFERENCE
        }
        y="1.02"
        width="9.63"
        height="42.73"
      />
    );
    elements.push(newRect);
  }

  return elements;
}
