import PropertyColor from "./property-color";
import PropertyEnum from "./property-enum";
import PropertyString from "./property-string";
import PropertyNumber from "./property-number";
import PropertyLengthMeasure from "./property-lenght-measure";
import PropertyToggle from "./property-toggle";
import PropertyCheckbox from "./property-checkbox";
import PropertyHidden from "./property-hidden";
import PropertyReadOnly from "./property-read-only";
import PropertySlider from "./property-slider";
import PropertyStairsSlider from "./property-stairs-slider";
import PropertyDropdown from "./property-dropdown";
import PropertyButton from "./property-button";
export {
  PropertyColor,
  PropertyEnum,
  PropertyString,
  PropertyNumber,
  PropertyLengthMeasure,
  PropertyToggle,
  PropertyCheckbox,
  PropertyHidden,
  PropertyReadOnly,
  PropertySlider,
  PropertyStairsSlider,
  PropertyDropdown,
  PropertyButton,
};

export default {
  PropertyColor,
  PropertyEnum,
  PropertyString,
  PropertyNumber,
  PropertyLengthMeasure,
  PropertyToggle,
  PropertyCheckbox,
  PropertyHidden,
  PropertyReadOnly,
  PropertySlider,
  PropertyStairsSlider,
  PropertyDropdown,
  PropertyButton
};
