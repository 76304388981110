import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-exit-down",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "exit down",
    description: "exit-down",
    image: require("./exit-down.svg"),
    svgIcon: require("./exit-down.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.exitdowncls1{fill:#009f4d;}.exitdowncls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="exitdowncls1"
              d="M0,63.91V21H86.07V63.91H54.81c.06-.14.09-.24.13-.34.52-1.28,1-2.56,1.56-3.84a2.38,2.38,0,0,0,.17-.88q0-16.89,0-33.78c0-.11,0-.21,0-.32a1.86,1.86,0,0,0,0-.26H29.69a1.23,1.23,0,0,0-.21.05V47.75a.15.15,0,0,1-.17.17h-.43a1.61,1.61,0,0,0-1.52.89,2.49,2.49,0,0,0-.3.92,2.62,2.62,0,0,0,.52,2,1.46,1.46,0,0,0,1.27.61c.15,0,.3,0,.45,0s.18,0,.18.17v.17c0,2,0,4.07,0,6.1a1.31,1.31,0,0,1-.09.47c-.58,1.45-1.17,2.89-1.75,4.34l-.13.27H0Zm71.59-8.38.24-.21,8.77-8.75a.54.54,0,0,0,.19-.43v-5a2.48,2.48,0,0,0,0-.27l-.06,0-7.11,7.09V31.45c0-.68,0-1.36,0-2a34.31,34.31,0,0,0-4,0V47.93l-1.8-1.8L66,44.37c-.59-.59-1.18-1.19-1.78-1.78s-1.15-1.17-1.81-1.78c0,.18,0,.28,0,.38,0,1.63,0,3.26,0,4.9a.66.66,0,0,0,.23.52l8.73,8.71ZM1.93,46.35H7.85c0-.44,0-.87,0-1.29,0-.05-.08-.12-.14-.14a1.11,1.11,0,0,0-.26,0H4.09l-.35,0c0-.57,0-1.1,0-1.67H6.89l.34,0c0-.48,0-.92,0-1.4H3.74c0-.53,0-1,0-1.58H7.33l.36,0V38.77l-.32,0H2.24l-.31,0Zm10.25-5.18c-.14-.17-.25-.3-.36-.45L10.57,39a.45.45,0,0,0-.4-.22H8.68a2.51,2.51,0,0,0-.3,0L11,42.49l-.56.79L9.91,44l-.55.76c-.19.26-.38.51-.56.77a8.18,8.18,0,0,0-.54.78l.31,0H10a.45.45,0,0,0,.44-.23c.45-.68.92-1.35,1.39-2,.07-.1.16-.2.25-.32l.16.2c.5.73,1,1.45,1.51,2.19a.39.39,0,0,0,.38.19h1.57l.27,0-2.82-3.9.53-.74.52-.72.54-.74.52-.72a5.9,5.9,0,0,0,.51-.76l-.33,0H14.16a.47.47,0,0,0-.45.23c-.44.65-.89,1.29-1.34,1.93Zm11.39,5.18V42.28c0-.67,0-1.34,0-2H26a8.35,8.35,0,0,0,0-1.5c-2.21,0-4.4,0-6.58,0a4,4,0,0,0,0,1.5H21a5.51,5.51,0,0,1,.81,0v6.09Zm-6.89,0h1.73c.07-.28.07-7.34,0-7.6-.56,0-1.14,0-1.7,0C16.61,39,16.61,46.09,16.68,46.35Z"
              transform="translate(0 -21)"
            />
            <path
              class="exitdowncls2"
              d="M0,63.91H27.51l.13-.27c.58-1.45,1.17-2.89,1.75-4.34a1.31,1.31,0,0,0,.09-.47c0-2,0-4.06,0-6.1v-.17c0-.12-.06-.17-.18-.17s-.3,0-.45,0a1.46,1.46,0,0,1-1.27-.61,2.62,2.62,0,0,1-.52-2,2.49,2.49,0,0,1,.3-.92,1.61,1.61,0,0,1,1.52-.89h.43a.15.15,0,0,0,.17-.17V24.54a1.23,1.23,0,0,1,.21-.05H56.62a1.86,1.86,0,0,1,0,.26c0,.11,0,.21,0,.32q0,16.89,0,33.78a2.38,2.38,0,0,1-.17.88c-.53,1.28-1,2.56-1.56,3.84,0,.1-.07.2-.13.34H86.07s0,.05,0,.05H.26C.17,64,.06,64,0,63.91ZM42.91,39.18c-.11.14-.18.24-.26.33Q38.8,44,35,48.4a.65.65,0,0,1-.56.25H29.34a3.26,3.26,0,0,0-.54,0A1.44,1.44,0,0,0,27.68,50a1.45,1.45,0,0,0,1,1.51,2.32,2.32,0,0,0,.65.08h6c.27,0,.54,0,.8,0a2.14,2.14,0,0,0,1.49-.7c.92-1,1.85-2.08,2.78-3.11l.23-.28.27.24c1.08,1,2.16,2.11,3.25,3.15a.46.46,0,0,1,.15.5c-.24,1-.48,2.08-.71,3.12s-.51,2.21-.74,3.32a1.49,1.49,0,0,0,1.88,1.72,1.52,1.52,0,0,0,1.09-1.24l.84-3.76c.34-1.5.68-3,1-4.49a1.76,1.76,0,0,0-.5-1.73c-1-.93-1.89-1.88-2.84-2.82l-.22-.24c.17-.3,3.37-4,3.57-4.1l.55.56,1.64,1.64a1.25,1.25,0,0,0,1.69.15,3.17,3.17,0,0,0,.39-.34l2.65-2.66c.3-.3.61-.6.9-.91a1.2,1.2,0,0,0-.49-2,1.17,1.17,0,0,0-1.24.3L51,40.68l-.24.23-.26-.24L48,38.14l-.9-.89a1.47,1.47,0,0,0-1.05-.45H40.27a1.16,1.16,0,0,0-.91.41l-4.14,4.71A1.15,1.15,0,0,0,35,43a1.14,1.14,0,0,0,1,.83,1.25,1.25,0,0,0,1.11-.46c1.15-1.35,2.32-2.69,3.48-4a.54.54,0,0,1,.44-.21c.52,0,1.05,0,1.57,0Zm8.36-4.13a2.34,2.34,0,0,0-4.67,0,2.34,2.34,0,1,0,4.67,0Z"
              transform="translate(0 -21)"
            />
            <path
              class="exitdowncls2"
              d="M71.59,55.53l-.23-.21-8.73-8.71a.66.66,0,0,1-.23-.52c0-1.64,0-3.27,0-4.9,0-.1,0-.2,0-.38.66.61,1.22,1.21,1.81,1.78s1.19,1.19,1.78,1.78l1.77,1.76,1.8,1.8V29.46a34.31,34.31,0,0,1,4,0c0,.67,0,1.35,0,2V47.94l7.11-7.09.06,0a2.48,2.48,0,0,1,0,.27v5a.54.54,0,0,1-.19.43l-8.77,8.75Z"
              transform="translate(0 -21)"
            />
            <path
              class="exitdowncls2"
              d="M1.93,46.35V38.77l.31,0H7.37l.32,0v1.42l-.36,0H3.75c0,.54,0,1.05,0,1.58H7.23c0,.48,0,.92,0,1.4l-.34,0H3.74c0,.57,0,1.1,0,1.67l.35,0H7.44a1.11,1.11,0,0,1,.26,0c.06,0,.14.09.14.14,0,.42,0,.85,0,1.29Z"
              transform="translate(0 -21)"
            />
            <path
              class="exitdowncls2"
              d="M12.18,41.17l.19-.28c.45-.64.9-1.28,1.34-1.93a.47.47,0,0,1,.45-.23h1.31l.33,0a5.9,5.9,0,0,1-.51.76l-.52.72-.54.74-.52.72-.53.74L16,46.34l-.27,0H14.16a.39.39,0,0,1-.38-.19c-.5-.74-1-1.46-1.51-2.19l-.16-.2c-.09.12-.18.22-.25.32-.47.68-.94,1.35-1.39,2a.45.45,0,0,1-.44.23H8.57l-.31,0a8.18,8.18,0,0,1,.54-.78c.18-.26.37-.51.56-.77L9.91,44l.56-.76.56-.79L8.38,38.78a2.51,2.51,0,0,1,.3,0h1.49a.45.45,0,0,1,.4.22l1.25,1.77C11.93,40.87,12,41,12.18,41.17Z"
              transform="translate(0 -21)"
            />
            <path
              class="exitdowncls2"
              d="M23.57,46.35H21.76V40.26a5.51,5.51,0,0,0-.81,0H19.4a4,4,0,0,1,0-1.5c2.18,0,4.37,0,6.58,0a8.35,8.35,0,0,1,0,1.5h-2.4c0,.7,0,1.37,0,2v4.07Z"
              transform="translate(0 -21)"
            />
            <path
              class="exitdowncls2"
              d="M16.68,46.35c-.07-.26-.07-7.31,0-7.6.56,0,1.14,0,1.7,0,.09.26.09,7.32,0,7.6Z"
              transform="translate(0 -21)"
            />
            <path
              class="exitdowncls1"
              d="M42.91,39.18l-.33,0c-.52,0-1,0-1.57,0a.54.54,0,0,0-.44.21c-1.16,1.34-2.33,2.68-3.48,4a1.25,1.25,0,0,1-1.11.46A1.14,1.14,0,0,1,35,43a1.15,1.15,0,0,1,.23-1.07l4.14-4.71a1.16,1.16,0,0,1,.91-.41h5.78a1.47,1.47,0,0,1,1.05.45l.9.89,2.53,2.53.26.24.24-.23L53.77,38a1.17,1.17,0,0,1,1.24-.3,1.2,1.2,0,0,1,.49,2c-.29.31-.6.61-.9.91L52,43.18a3.17,3.17,0,0,1-.39.34,1.25,1.25,0,0,1-1.69-.15l-1.64-1.64-.55-.56c-.2.12-3.4,3.8-3.57,4.1l.22.24c1,.94,1.89,1.89,2.84,2.82a1.76,1.76,0,0,1,.5,1.73c-.33,1.5-.67,3-1,4.49l-.84,3.76a1.52,1.52,0,0,1-1.09,1.24,1.49,1.49,0,0,1-1.88-1.72c.23-1.11.49-2.22.74-3.32s.47-2.08.71-3.12a.46.46,0,0,0-.15-.5c-1.09-1-2.17-2.1-3.25-3.15l-.27-.24-.23.28c-.93,1-1.86,2.06-2.78,3.11a2.14,2.14,0,0,1-1.49.7c-.26,0-.53,0-.8,0h-6a2.32,2.32,0,0,1-.65-.08,1.45,1.45,0,0,1-1-1.51,1.44,1.44,0,0,1,1.12-1.35,3.26,3.26,0,0,1,.54,0h5.05A.65.65,0,0,0,35,48.4q3.84-4.45,7.7-8.89C42.73,39.42,42.8,39.32,42.91,39.18Z"
              transform="translate(0 -21)"
            />
            <path
              class="exitdowncls1"
              d="M51.27,35.05a2.34,2.34,0,1,1-2.33-2.35A2.31,2.31,0,0,1,51.27,35.05Z"
              transform="translate(0 -21)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
