import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "split-level-stairs",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Split level stairs",
    description: "split-level-stairs",
    image: require("./Split-level-stairs.svg"),
    svgIcon: require("./Split-level-stairs.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 50,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 50,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g
          id={`${element.type}-${element.id}`}
          transform={`translate(-47, 55) scale(1,-1)`}
        >
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M50,76.17H3.78a1.48,1.48,0,0,1-1.69-1.7V25.58a1.53,1.53,0,0,1,1.74-1.75H96.16c1.2,0,1.75.55,1.75,1.76v48.8c0,1.25-.54,1.78-1.8,1.78ZM96.5,42.23h-93V57.81c.33,0,.6,0,.87,0q45.6,0,91.19,0c.76,0,1-.2,1-1,0-4.59,0-9.17,0-13.76C96.55,42.85,96.52,42.58,96.5,42.23Zm0-1.42V25.3h-93V40.81ZM3.48,74.7h93V59.23h-93Z"
            transform="translate(-2.09 -23.83)"
          />
          <path
            class="cls-1"
            d="M96.5,42.23c0,.35,0,.62,0,.9,0,4.59,0,9.17,0,13.76,0,.78-.22,1-1,1q-45.6,0-91.19,0c-.27,0-.54,0-.87,0V42.23Z"
            transform="translate(-2.09 -23.83)"
          />
          <path
            class="cls-1"
            d="M96.48,40.81h-93V25.3h93Z"
            transform="translate(-2.09 -23.83)"
          />
          <path
            class="cls-1"
            d="M3.48,74.7V59.23h93V74.7Z"
            transform="translate(-2.09 -23.83)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
