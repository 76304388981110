import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Toilet_with_cisern",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Toilet With Cisern",
    description: "Toilet With Cisern",
    image: require("./Toiletwithcistern.svg"),
    svgIcon: require("./Toiletwithcistern.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue:50,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 50,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="toilet with cistern">
          <path d="M69.56,25.17a3.4,3.4,0,0,1-.07.49,3,3,0,0,0,.86,3.09A42.18,42.18,0,0,1,80,49.31a51.1,51.1,0,0,1-2.73,29.31A36.85,36.85,0,0,1,65.13,94.86c-8.37,6.23-18.92,6.89-27.77,1.48-6.95-4.24-11.5-10.51-14.53-18A50.54,50.54,0,0,1,19.46,54.5a46,46,0,0,1,8.6-23.6c.77-1,1.62-2,2.48-3,.72-.82.07-1.66.08-2.49,0-.16-.67-.41-1.06-.49a14.14,14.14,0,0,1-7.92-5.17c-4-4.93-5.66-10.74-5.87-17,0-.73-.22-1.47.41-2.12A1.62,1.62,0,0,1,17.5,0c4.19,0,8.38,0,12.57,0H82.53C84,0,84.66.7,84.62,2.2a30.78,30.78,0,0,1-3.2,13.39c-2.12,4.11-5,7.37-9.45,9A22.93,22.93,0,0,1,69.56,25.17Zm-37.36.1c.11,1,.18,2,.32,3a3.47,3.47,0,0,1-.68,2.79,35.08,35.08,0,0,0-4.49,8.33,48.62,48.62,0,0,0-3.13,20A45.27,45.27,0,0,0,29.8,79.8c2.92,5.19,6.75,9.5,12.23,12a18.35,18.35,0,0,0,16,.06c5.53-2.54,9.44-6.86,12.39-12.13A44.14,44.14,0,0,0,75.84,61c.75-11-1.32-21.17-7.65-30.3a3.54,3.54,0,0,1-.51-1.9c0-1.16.19-2.33.31-3.56ZM82.56,2.5H17.79c0,.28,0,.49,0,.7a24.32,24.32,0,0,0,5.52,15.11,11.5,11.5,0,0,0,9.09,4.43H68a11.18,11.18,0,0,0,8-3.31,21.15,21.15,0,0,0,5.6-9.81A26.07,26.07,0,0,0,82.56,2.5ZM25.24,40a9.13,9.13,0,0,0-.51,1.11c-.81,2.63-1.79,5.24-2.38,7.92a47.5,47.5,0,0,0,.48,22.73C25,80.06,29,87.28,35.88,92.56A23,23,0,0,0,47.8,97.42c7.24.7,13.45-1.82,18.75-6.68,6-5.47,9.44-12.47,11.2-20.3a48.87,48.87,0,0,0-.93-25.62c-.68-2.18-1.6-4.28-2.46-6.55-.14.23-.18.26-.17.28.09.3.19.59.3.88a51.08,51.08,0,0,1,3,17.74,48.54,48.54,0,0,1-4.31,20.46c-2.7,5.93-6.4,11.06-12,14.53-6.7,4.13-13.63,4.53-20.65.91C34.16,89.76,30,84.29,27,77.89a46.79,46.79,0,0,1-4.21-16.44,51.36,51.36,0,0,1,.5-12.93C23.85,45.69,24.58,42.9,25.24,40Z" transform="translate(-15.72 0.04)"/>
    <path class="cls-1" d="M32.2,25.27H68c-.12,1.23-.31,2.4-.31,3.56a3.54,3.54,0,0,0,.51,1.9c6.33,9.13,8.4,19.35,7.65,30.3a44.14,44.14,0,0,1-5.43,18.73C67.46,85,63.55,89.35,58,91.89a18.35,18.35,0,0,1-16-.06C36.55,89.3,32.72,85,29.8,79.8a45.27,45.27,0,0,1-5.58-20.39,48.62,48.62,0,0,1,3.13-20,35.08,35.08,0,0,1,4.49-8.33,3.47,3.47,0,0,0,.68-2.79C32.38,27.29,32.31,26.3,32.2,25.27Z" transform="translate(-15.72 0.04)"/>
    <path class="cls-1" d="M82.56,2.5a26.07,26.07,0,0,1-1,7.12,21.15,21.15,0,0,1-5.6,9.81,11.18,11.18,0,0,1-8,3.31H32.4a11.5,11.5,0,0,1-9.09-4.43A24.32,24.32,0,0,1,17.79,3.2c0-.21,0-.42,0-.7Z" transform="translate(-15.72 0.04)"/>
    <path class="cls-1" d="M25.24,40c-.66,2.89-1.39,5.68-1.91,8.51a51.36,51.36,0,0,0-.5,12.93A46.79,46.79,0,0,0,27,77.89c3,6.4,7.12,11.87,13.52,15.18,7,3.62,13.95,3.22,20.65-.91,5.61-3.47,9.31-8.6,12-14.53a48.54,48.54,0,0,0,4.31-20.46,51.08,51.08,0,0,0-3-17.74c-.11-.29-.21-.58-.3-.88,0,0,0,0,.17-.28.86,2.27,1.78,4.37,2.46,6.55a48.87,48.87,0,0,1,.93,25.62c-1.76,7.83-5.22,14.83-11.2,20.3C61.25,95.6,55,98.12,47.8,97.42a23,23,0,0,1-11.92-4.86C29,87.28,25,80.06,22.83,71.77A47.5,47.5,0,0,1,22.35,49c.59-2.68,1.57-5.29,2.38-7.92A9.13,9.13,0,0,1,25.24,40Z" transform="translate(-15.72 0.04)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
