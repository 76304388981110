import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "CoffeeTable",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "CoffeeTable",
    description: "CoffeeTable",
    image: require("./CoffeeTable.svg"),
    svgIcon: require("./CoffeeTable.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M29.26,50.62c0,4.81,0,9.62,0,14.43a3.46,3.46,0,0,1-.93,2.71,20.3,20.3,0,0,1-2.19,1.73,2.11,2.11,0,0,1-1.09.25c-3.07,0-6.13,0-9.19,0a2,2,0,0,1-1.76-.87,1.11,1.11,0,0,0-1.1-.52c-.57,0-1.15,0-1.72,0a4.2,4.2,0,0,1-4-2.14,17.44,17.44,0,0,1-2.47-7.48A58.33,58.33,0,0,1,5,41.37a13.56,13.56,0,0,1,2.92-7A3.61,3.61,0,0,1,10.73,33c.49,0,1,0,1.48,0a2.27,2.27,0,0,0,2.24-.79,1.69,1.69,0,0,1,1.24-.46c3.15,0,6.29,0,9.44,0a1.22,1.22,0,0,1,.64.08A13.51,13.51,0,0,1,28.55,34a4.36,4.36,0,0,1,.63,2.54c.05,4.7,0,9.4,0,14.11Zm-2-14.42a7.82,7.82,0,0,1-2.37.76,48.75,48.75,0,0,0-12,1.21,2.08,2.08,0,0,0-1.31.92,20,20,0,0,0-1.47,4.14,37.61,37.61,0,0,0,0,14.42,28.31,28.31,0,0,0,1.57,4.88,1.74,1.74,0,0,0,1.15.87,48.09,48.09,0,0,0,11.48,1,2.72,2.72,0,0,1,2.71,1,2.34,2.34,0,0,0,1-2.13q0-12.55,0-25.1A2.47,2.47,0,0,0,27.28,36.2Zm-12,28.2c-.91-.15-1.55-.28-2.19-.34a1.9,1.9,0,0,1-1.83-1.28,36.26,36.26,0,0,1-1.58-5,41,41,0,0,1-.37-12.5,17.09,17.09,0,0,1,1.85-6.5,2.23,2.23,0,0,1,1.78-1.26c.75-.09,1.49-.25,2.47-.42-1.69-.77-2-2-1.85-3.58-.8,0-1.54.05-2.26,0a3.76,3.76,0,0,0-3.66,2,15.26,15.26,0,0,0-2.11,5.73,58.65,58.65,0,0,0-.62,13.56A25.77,25.77,0,0,0,6.5,63.68c1.85,4.36,3.15,4.44,7,4A3,3,0,0,1,15.24,64.4Zm5.14-27.93c1.56,0,3.12,0,4.68,0A1.91,1.91,0,0,0,27,34.35a1.93,1.93,0,0,0-1.86-2.08q-4.68,0-9.36,0a1.9,1.9,0,0,0-1.84,2.08,1.9,1.9,0,0,0,1.89,2.11Zm.09,32.74v0c1.5,0,3,0,4.5,0a2.09,2.09,0,0,0,0-4.17Q20.46,65,16,65a2.1,2.1,0,0,0,0,4.19ZM27.3,33.56c.06.78-.13,1.61.2,2.07a4.29,4.29,0,0,1,.83,2.58c0,8.26,0,16.51,0,24.77a3.3,3.3,0,0,1-.45,2c-.79.87-.45,1.74-.37,2.7a2,2,0,0,0,.82-.8,5.68,5.68,0,0,0,.41-1.9c0-9.56,0-19.13,0-28.7a4.39,4.39,0,0,0-.13-1.14A2,2,0,0,0,27.3,33.56Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              d="M71.05,50.52c0-4.71,0-9.41,0-14.11,0-1.1,0-2.24,1-2.9,1.32-.91,2.49-2,4.4-1.83a76.5,76.5,0,0,0,7.87.05,2.44,2.44,0,0,1,1.88.83,1.73,1.73,0,0,0,1,.43,5.05,5.05,0,0,0,1.4,0c2.45-.3,3.89,1.06,4.94,3a20.47,20.47,0,0,1,2.07,7.46,64.81,64.81,0,0,1,.17,12.61,23.37,23.37,0,0,1-2.22,9.18,9.31,9.31,0,0,1-1.31,1.86,3.63,3.63,0,0,1-2.92,1.25c-.49,0-1,0-1.48,0a1.91,1.91,0,0,0-1.91.74,2,2,0,0,1-1.44.6c-3.11.06-6.23,0-9.35,0a1.36,1.36,0,0,1-.79-.15,13,13,0,0,1-2.71-2.33A4.78,4.78,0,0,1,71,64.63c0-4.71,0-9.41,0-14.11ZM73.28,36a2.94,2.94,0,0,0-1,2.58q0,12.14,0,24.28a3.75,3.75,0,0,0,.1,1.21,11.76,11.76,0,0,0,.78,1.54,2.58,2.58,0,0,1,2.61-1.13,53.48,53.48,0,0,0,11.57-1,1.45,1.45,0,0,0,1.26-.89,28.14,28.14,0,0,0,1.49-4.4,40.59,40.59,0,0,0,.37-13.06,14.73,14.73,0,0,0-1.77-6,2.19,2.19,0,0,0-1.24-.92A44,44,0,0,0,75.71,37,2.46,2.46,0,0,1,73.28,36ZM86.71,67.85a15.26,15.26,0,0,1,1.66,0c2.37.29,3.79-.91,4.81-2.9a20.12,20.12,0,0,0,1.91-6.9,63.52,63.52,0,0,0,0-14.65,20.09,20.09,0,0,0-2-7.13,4.86,4.86,0,0,0-6.36-2.56,2.93,2.93,0,0,1-1.93,3.44c1,.18,1.87.37,2.7.47a2,2,0,0,1,1.6,1.12A17.5,17.5,0,0,1,91,45.48a43.4,43.4,0,0,1,0,10.06,20,20,0,0,1-2,7.31,2,2,0,0,1-1.73,1.2c-.68.07-1.35.2-2.27.34A3.11,3.11,0,0,1,86.71,67.85ZM79.87,36.47c1.53,0,3.06,0,4.59,0a2.15,2.15,0,0,0,1.14-.34,2.4,2.4,0,0,0,.61-2.55,1.77,1.77,0,0,0-1.95-1.29c-3,0-5.91,0-8.86,0a2,2,0,0,0-2.11,2.08,1.93,1.93,0,0,0,2.07,2.11C76.86,36.48,78.37,36.47,79.87,36.47Zm0,28.55v0c-1.52,0-3,0-4.58,0a2.09,2.09,0,0,0-.06,4.17q4.59,0,9.18,0a1.86,1.86,0,0,0,1.93-2.06,1.89,1.89,0,0,0-2-2.12ZM72.89,33.49a3,3,0,0,0-1.43,2.91q0,14.26,0,28.54a5.07,5.07,0,0,0,.19,1.45c.16.56.4,1.13,1.1,1.28.07-.52.14-1,.18-1.49a.69.69,0,0,0-.16-.5,3.42,3.42,0,0,1-.9-2.63q0-12.46.07-24.93a2.92,2.92,0,0,1,.4-1.8C73.12,35.48,72.84,34.58,72.89,33.49Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              d="M68.88,50a26.55,26.55,0,0,1-5.23,15.8c-3.31,4.3-7.57,7-13.14,7.15a15.61,15.61,0,0,1-11.17-4.38c-4.46-4-6.68-9.24-7.41-15.12a26.62,26.62,0,0,1,2.72-15.83c2.35-4.54,5.75-8,10.71-9.72a14.87,14.87,0,0,1,14.08,2.25c4.87,3.51,7.56,8.45,8.71,14.26C68.52,46.25,68.64,48.14,68.88,50Zm-.51,1.1c-.12-1.63-.15-3.27-.37-4.89a22.69,22.69,0,0,0-7.14-14.28c-3.83-3.48-8.36-5.05-13.54-4s-8.94,4.3-11.61,8.77a25.45,25.45,0,0,0-3.5,14.84A23.77,23.77,0,0,0,38.43,67c4,4.29,8.9,6.32,14.78,5.14,5.19-1,8.87-4.29,11.53-8.75A26,26,0,0,0,68.37,51.1Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M27.28,36.2a2.47,2.47,0,0,1,.65,2q0,12.56,0,25.1a2.34,2.34,0,0,1-1,2.13,2.72,2.72,0,0,0-2.71-1,48.09,48.09,0,0,1-11.48-1,1.74,1.74,0,0,1-1.15-.87,28.31,28.31,0,0,1-1.57-4.88,37.61,37.61,0,0,1,0-14.42,20,20,0,0,1,1.47-4.14,2.08,2.08,0,0,1,1.31-.92,48.75,48.75,0,0,1,12-1.21A7.82,7.82,0,0,0,27.28,36.2Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M15.24,64.4a3,3,0,0,0-1.71,3.31c-3.88.41-5.18.33-7-4a25.77,25.77,0,0,1-1.63-8.83,58.65,58.65,0,0,1,.62-13.56A15.26,15.26,0,0,1,7.6,35.56a3.76,3.76,0,0,1,3.66-2c.72.06,1.46,0,2.26,0-.16,1.56.16,2.81,1.85,3.58-1,.17-1.72.33-2.47.42a2.23,2.23,0,0,0-1.78,1.26,17.09,17.09,0,0,0-1.85,6.5,41,41,0,0,0,.37,12.5,36.26,36.26,0,0,0,1.58,5,1.9,1.9,0,0,0,1.83,1.28C13.69,64.12,14.33,64.25,15.24,64.4Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M20.38,36.47H15.79a1.9,1.9,0,0,1-1.89-2.11,1.9,1.9,0,0,1,1.84-2.08q4.68,0,9.36,0A1.93,1.93,0,0,1,27,34.35a1.91,1.91,0,0,1-1.9,2.11C23.5,36.48,21.94,36.47,20.38,36.47Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M20.47,69.21H16A2.1,2.1,0,0,1,16,65q4.45,0,8.93,0a2.09,2.09,0,0,1,0,4.17c-1.5,0-3,0-4.5,0Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M27.3,33.56a2,2,0,0,1,1.36,1.6,4.39,4.39,0,0,1,.13,1.14c0,9.57,0,19.14,0,28.7a5.68,5.68,0,0,1-.41,1.9,2,2,0,0,1-.82.8c-.08-1-.42-1.83.37-2.7a3.3,3.3,0,0,0,.45-2c0-8.26,0-16.51,0-24.77a4.29,4.29,0,0,0-.83-2.58C27.17,35.17,27.36,34.34,27.3,33.56Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M73.28,36a2.46,2.46,0,0,0,2.43,1,44,44,0,0,1,11.78,1.18,2.19,2.19,0,0,1,1.24.92,14.73,14.73,0,0,1,1.77,6,40.59,40.59,0,0,1-.37,13.06,28.14,28.14,0,0,1-1.49,4.4,1.45,1.45,0,0,1-1.26.89,53.48,53.48,0,0,1-11.57,1,2.58,2.58,0,0,0-2.61,1.13A11.76,11.76,0,0,1,72.42,64a3.75,3.75,0,0,1-.1-1.21q0-12.15,0-24.28A2.94,2.94,0,0,1,73.28,36Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M86.71,67.85A3.11,3.11,0,0,0,85,64.39c.92-.14,1.59-.27,2.27-.34A2,2,0,0,0,89,62.85a20,20,0,0,0,2-7.31,43.4,43.4,0,0,0,0-10.06,17.5,17.5,0,0,0-1.9-6.74,2,2,0,0,0-1.6-1.12c-.83-.1-1.66-.29-2.7-.47a2.93,2.93,0,0,0,1.93-3.44,4.86,4.86,0,0,1,6.36,2.56,20.09,20.09,0,0,1,2,7.13,63.52,63.52,0,0,1,0,14.65A20.12,20.12,0,0,1,93.18,65c-1,2-2.44,3.19-4.81,2.9A15.26,15.26,0,0,0,86.71,67.85Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M79.87,36.47c-1.5,0-3,0-4.51,0a1.93,1.93,0,0,1-2.07-2.11,2,2,0,0,1,2.11-2.08c2.95,0,5.91,0,8.86,0a1.77,1.77,0,0,1,1.95,1.29,2.4,2.4,0,0,1-.61,2.55,2.15,2.15,0,0,1-1.14.34C82.93,36.49,81.4,36.47,79.87,36.47Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M79.83,65h4.51a1.89,1.89,0,0,1,2,2.12,1.86,1.86,0,0,1-1.93,2.06q-4.59,0-9.18,0A2.09,2.09,0,0,1,75.25,65c1.53,0,3.06,0,4.58,0Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M72.89,33.49c0,1.09.23,2-.54,2.83a2.92,2.92,0,0,0-.4,1.8q-.08,12.45-.07,24.93a3.42,3.42,0,0,0,.9,2.63.69.69,0,0,1,.16.5c0,.49-.11,1-.18,1.49-.7-.15-.94-.72-1.1-1.28a5.07,5.07,0,0,1-.19-1.45q0-14.28,0-28.54A3,3,0,0,1,72.89,33.49Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M68.37,51.1a26,26,0,0,1-3.63,12.24C62.08,67.8,58.4,71,53.21,72.09c-5.88,1.18-10.79-.85-14.78-5.14a23.77,23.77,0,0,1-6.22-15.4,25.45,25.45,0,0,1,3.5-14.84c2.67-4.47,6.37-7.71,11.61-8.77s9.71.51,13.54,4A22.69,22.69,0,0,1,68,46.21C68.22,47.83,68.25,49.47,68.37,51.1Zm-8.9-.52a13.22,13.22,0,0,0-3.2-8.92,8.07,8.07,0,0,0-12.11-.39,13.48,13.48,0,0,0-.21,18,8,8,0,0,0,11.77.37A12.43,12.43,0,0,0,59.47,50.58Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              d="M59.47,50.58a12.43,12.43,0,0,1-3.75,9.11A8,8,0,0,1,44,59.32a13.48,13.48,0,0,1,.21-18,8.07,8.07,0,0,1,12.11.39A13.22,13.22,0,0,1,59.47,50.58Zm-18.41.16a11.92,11.92,0,0,0,2.46,7.33c3.1,4.13,8.4,4.64,12,1.14,4.61-4.48,4.67-13,.13-17.47a7.7,7.7,0,0,0-11.55.32A12.15,12.15,0,0,0,41.06,50.74Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M41.06,50.74a12.15,12.15,0,0,1,3-8.68,7.7,7.7,0,0,1,11.55-.32c4.54,4.52,4.48,13-.13,17.47-3.61,3.5-8.91,3-12-1.14A11.92,11.92,0,0,1,41.06,50.74Zm13.7-.92a20.48,20.48,0,0,0-.91-2.6,4.23,4.23,0,0,0-4.46-2.46,3.81,3.81,0,0,0-2.67,1.6,6.5,6.5,0,0,0-1.29,4.16,6.09,6.09,0,0,0,1.14,3.8,3.82,3.82,0,0,0,3.48,1.78,4,4,0,0,0,2.74-1.05A6.61,6.61,0,0,0,54.76,49.82Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              d="M54.76,49.82a6.61,6.61,0,0,1-2,5.23,4,4,0,0,1-2.74,1.05,3.82,3.82,0,0,1-3.48-1.78,6.09,6.09,0,0,1-1.14-3.8,6.5,6.5,0,0,1,1.29-4.16,3.81,3.81,0,0,1,2.67-1.6,4.23,4.23,0,0,1,4.46,2.46A20.48,20.48,0,0,1,54.76,49.82Zm-4.94,3.8c.22-.13.26-.17.31-.19a3.15,3.15,0,0,0,1.34-5.18,2.28,2.28,0,0,0-1.69-.76A3,3,0,0,0,48,52.05,8.32,8.32,0,0,0,49.82,53.62Zm.64,2a4.43,4.43,0,0,0,3.61-4.53c-.78-.13-1.37-.18-1.7.87-.21.66-1,1.14-1.49,1.72a1.69,1.69,0,0,0-.41.67A8.88,8.88,0,0,0,50.46,55.59Zm0-10.27c-.12.93-.22,1.72.85,2.2a2.87,2.87,0,0,1,1.06,1.39c.48,1,.52,1.08,1.68.78A4.48,4.48,0,0,0,50.51,45.32Zm-4.59,5.77c-.17,2,1.77,4.49,3.57,4.44a5.77,5.77,0,0,1,0-.72,1.16,1.16,0,0,0-.73-1.32,3.11,3.11,0,0,1-1.28-1.6C47.18,51,46.69,50.86,45.92,51.09Zm0-1.34c.75.17,1.3.16,1.54-.82a2.68,2.68,0,0,1,1.65-1.81.73.73,0,0,0,.36-.52,10.83,10.83,0,0,0,0-1.28A4.48,4.48,0,0,0,45.92,49.75Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M49.82,53.62A8.32,8.32,0,0,1,48,52.05a3,3,0,0,1,1.74-4.56,2.28,2.28,0,0,1,1.69.76,3.15,3.15,0,0,1-1.34,5.18C50.08,53.45,50,53.49,49.82,53.62Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M50.46,55.59a8.88,8.88,0,0,1,0-1.27,1.69,1.69,0,0,1,.41-.67c.5-.58,1.28-1.06,1.49-1.72.33-1,.92-1,1.7-.87A4.43,4.43,0,0,1,50.46,55.59Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M50.51,45.32a4.48,4.48,0,0,1,3.59,4.37c-1.16.3-1.2.23-1.68-.78a2.87,2.87,0,0,0-1.06-1.39C50.29,47,50.39,46.25,50.51,45.32Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M45.92,51.09c.77-.23,1.26-.13,1.56.8a3.11,3.11,0,0,0,1.28,1.6,1.16,1.16,0,0,1,.73,1.32,5.77,5.77,0,0,0,0,.72C47.69,55.58,45.75,53.12,45.92,51.09Z"
              transform="translate(-4.27 -27.04)"
            />
            <path
              class="cls-1"
              d="M45.92,49.75a4.48,4.48,0,0,1,3.57-4.43,10.83,10.83,0,0,1,0,1.28.73.73,0,0,1-.36.52,2.68,2.68,0,0,0-1.65,1.81C47.22,49.91,46.67,49.92,45.92,49.75Z"
              transform="translate(-4.27 -27.04)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
