import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Fire-Extinguisher",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Fire Extinguisher",
    description: "Fire-Extinguisher",
    image: require("./Fire-Extinguisher.svg"),
    svgIcon: require("./Fire-Extinguisher.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.fireExtcls1{fill:#d71214;}.fireExtcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="fireExtcls1"
              d="M0,95.72V0H98.59V95.72ZM65.73,93v-.42q0-32.08,0-64.16a6.59,6.59,0,0,0-1.3-4,10.53,10.53,0,0,0-2.92-2.68,17.46,17.46,0,0,0-3.94-1.84l-.82-.26c-.11,0-.17-.07-.19-.18,0-.3-.1-.59-.14-.89-.16-1.29-.33-2.59-.49-3.89,0-.39-.09-.79-.15-1.23h.37c.84,0,1.68,0,2.52,0A6.14,6.14,0,0,0,60.9,13a1,1,0,0,0,.68-.77c.14-.8.31-1.59.46-2.38.18-1,.35-1.92.53-2.88a.57.57,0,0,0-.2-.6,2.13,2.13,0,0,0-.44-.26,5.45,5.45,0,0,0-2.32-.48c-1.24,0-2.49,0-3.73.08H54.79c-.16-1-.23-2-.4-2.94h-9c-.19,1.14-.27,2.28-.46,3.4l-1.67.08c-1.23.06-2.46.11-3.69.2a4.44,4.44,0,0,0-1.7.49.77.77,0,0,0-.49.62.42.42,0,0,1-.16.22l-1.7,1.45-3.33,2.87-2.94,2.54-1.39,1.18a2.84,2.84,0,0,0-1.08,2.88,7.79,7.79,0,0,1,.2,1.45c0,1.78,0,3.55,0,5.33,0,.12,0,.24,0,.36h.1l2.25-.73a1.31,1.31,0,0,0,.92-1.21c.12-1.29.26-2.58.38-3.87a.72.72,0,0,1,.3-.56c1-.7,1.91-1.42,2.85-2.14l1.82-1.4,3.64-2.74,1.68-1.28a3.49,3.49,0,0,1,.34.31,1.71,1.71,0,0,0,.92.6c.41.12.83.19,1.24.28.2,0,.39.06.61.09-.3,2.19-.51,4.35-.86,6.49l-.23.09A17.42,17.42,0,0,0,39,21.45,11.94,11.94,0,0,0,35.92,24a7.2,7.2,0,0,0-1.5,3,5.88,5.88,0,0,0-.11,1.33c0,3.27,0,6.55,0,9.82V93Z"
            />
            <path
              class="fireExtcls2"
              d="M65.73,93H34.33V38.16c0-3.27,0-6.55,0-9.82A5.88,5.88,0,0,1,34.42,27a7.2,7.2,0,0,1,1.5-3A11.94,11.94,0,0,1,39,21.45a17.42,17.42,0,0,1,3.92-1.68l.23-.09c.35-2.14.56-4.3.86-6.49-.22,0-.41,0-.61-.09-.41-.09-.83-.16-1.24-.28a1.71,1.71,0,0,1-.92-.6,3.49,3.49,0,0,0-.34-.31l-1.68,1.28L35.6,15.93l-1.82,1.4c-.94.72-1.89,1.44-2.85,2.14a.72.72,0,0,0-.3.56c-.12,1.29-.26,2.58-.38,3.87a1.31,1.31,0,0,1-.92,1.21l-2.25.73H27c0-.12,0-.24,0-.36,0-1.78,0-3.55,0-5.33a7.79,7.79,0,0,0-.2-1.45,2.84,2.84,0,0,1,1.08-2.88l1.39-1.18,2.94-2.54,3.33-2.87,1.7-1.45a.42.42,0,0,0,.16-.22.77.77,0,0,1,.49-.62,4.44,4.44,0,0,1,1.7-.49c1.23-.09,2.46-.14,3.69-.2l1.67-.08c.19-1.12.27-2.26.46-3.4h9c.17,1,.24,2,.4,2.94h1.09c1.24,0,2.49-.09,3.73-.08a5.45,5.45,0,0,1,2.32.48,2.13,2.13,0,0,1,.44.26.57.57,0,0,1,.2.6c-.18,1-.35,1.92-.53,2.88-.15.79-.32,1.58-.46,2.38a1,1,0,0,1-.68.77,6.14,6.14,0,0,1-2.23.47c-.84,0-1.68,0-2.52,0h-.37c.06.44.1.84.15,1.23.16,1.3.33,2.6.49,3.89,0,.3.1.59.14.89,0,.11.08.15.19.18l.82.26a17.46,17.46,0,0,1,3.94,1.84,10.53,10.53,0,0,1,2.92,2.68,6.59,6.59,0,0,1,1.3,4q0,32.09,0,64.16ZM37.22,51.59c.42.06,25.29,0,25.53,0V36.41c-.41-.06-25.29,0-25.53,0Z"
            />
            <path
              class="fireExtcls1"
              d="M37.22,51.59V36.43c.24-.07,25.12-.08,25.53,0V51.57C62.51,51.63,37.64,51.65,37.22,51.59Z"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
