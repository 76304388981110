import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Sofa1",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Sofa1",
    description: "Sofa1",
    image: require("./Sofa1.svg"),
    svgIcon: require("./Sofa1.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 48) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M17.1,25.4c0-2.73,0-5.37,0-8a7.52,7.52,0,0,1,.17-1.9,3,3,0,0,1,3.2-2.21h57c2.85,0,3.87,1,3.89,3.84s0,5.46,0,8.39H83a2.73,2.73,0,0,1,3,2.79c0,.55.06,1.11.06,1.67q0,20.54.07,41.07A11.19,11.19,0,0,1,86,72.55c-.21,2.08-1.16,3-3.24,3-1.08,0-1.39.33-1.36,1.38.06,2.87,0,5.74,0,8.6,0,1.62-.18,1.8-1.74,1.8H20.15c-1.56,0-1.72-.17-1.72-1.8,0-3.25,0-6.5,0-9.95-1,0-2,0-3,0a2.71,2.71,0,0,1-3.07-2.59,9.68,9.68,0,0,1-.11-1.79c0-13.86,0-27.72,0-41.58,0-3.08,1.46-4.46,4-4.09A4.09,4.09,0,0,0,17.1,25.4Zm55.54,7.73h-47V34.7l0,36.32a13.33,13.33,0,0,1-.07,1.79,2.84,2.84,0,0,1-3,2.72,17.75,17.75,0,0,1-1.93,0c-.85-.08-1.11.29-1.09,1.11,0,2.14,0,4.28,0,6.42v2.67H80.22V75.54c-1.51,0-3,0-4.39,0a2.69,2.69,0,0,1-3-2.63,15.56,15.56,0,0,1-.12-2.05q0-18-.07-36.06C72.63,34.29,72.64,33.78,72.64,33.13ZM49.23,31.6h28.6c1.87,0,2.4-.5,2.41-2.33q0-6.1,0-12.19c0-1.87-.49-2.33-2.38-2.33H20.66c-1.89,0-2.44.52-2.44,2.43q0,6,0,11.93c0,1.93.59,2.49,2.54,2.49ZM17.12,27.11c-3.28-.44-3.79,0-3.79,3.05V71c0,.34,0,.68,0,1,.1,1.54.61,2,2.16,2.06h6.67c1.89,0,2.37-.46,2.37-2.34,0-12.49,0-25,0-37.47,0-.87-.22-1.26-1.14-1.2s-2,0-3,0a3.06,3.06,0,0,1-3.35-3.22C17.08,29,17.12,28.06,17.12,27.11Zm56.71,6V71.28c0,.26,0,.52,0,.77.08,1.52.63,2.07,2.14,2.08,2.09,0,4.19,0,6.28,0,2.35,0,2.7-.33,2.7-2.64V29.81c0-2.7-.83-3.32-3.63-2.59,0,.93.06,1.9,0,2.87a2.87,2.87,0,0,1-3.15,3C76.79,33.08,75.42,33.06,73.83,33.06Z"
              transform="translate(-12.23 -13.26)"
            />
            <path
              class="cls-1"
              d="M72.64,33.13c0,.65,0,1.16,0,1.66q0,18,.07,36.06a15.56,15.56,0,0,0,.12,2.05,2.69,2.69,0,0,0,3,2.63c1.44,0,2.88,0,4.39,0V85.73H19.58V83.06c0-2.14,0-4.28,0-6.42,0-.82.24-1.19,1.09-1.11a17.75,17.75,0,0,0,1.93,0,2.84,2.84,0,0,0,3-2.72A13.33,13.33,0,0,0,25.65,71l0-36.32V33.13Z"
              transform="translate(-12.23 -13.26)"
            />
            <path
              class="cls-1"
              d="M49.23,31.6H20.76c-2,0-2.54-.56-2.54-2.49q0-6,0-11.93c0-1.91.55-2.43,2.44-2.43h57.2c1.89,0,2.37.46,2.38,2.33q0,6.09,0,12.19c0,1.83-.54,2.32-2.41,2.33Z"
              transform="translate(-12.23 -13.26)"
            />
            <path
              class="cls-1"
              d="M17.12,27.11c0,.95,0,1.84,0,2.73a3.06,3.06,0,0,0,3.35,3.22c1,0,2,0,3,0s1.14.33,1.14,1.2c0,12.49,0,25,0,37.47,0,1.88-.48,2.33-2.37,2.34H15.5c-1.55,0-2.06-.52-2.16-2.06,0-.35,0-.69,0-1V30.16C13.33,27.11,13.84,26.67,17.12,27.11Z"
              transform="translate(-12.23 -13.26)"
            />
            <path
              class="cls-1"
              d="M73.83,33.06c1.59,0,3,0,4.32,0a2.87,2.87,0,0,0,3.15-3c.08-1,0-1.94,0-2.87,2.8-.73,3.63-.11,3.63,2.59V71.49c0,2.31-.35,2.64-2.7,2.64-2.09,0-4.19,0-6.28,0-1.51,0-2.06-.56-2.14-2.08,0-.25,0-.51,0-.77V33.06Z"
              transform="translate(-12.23 -13.26)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
