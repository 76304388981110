import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Fire-Extinguisher-Water",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Fire Extinguisher Water",
    description: "Fire-Extinguisher-Water",
    image: require("./Fire-Extinguisher-Water.svg"),
    svgIcon: require("./Fire-Extinguisher-Water.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.watercls1{fill:#d71214;}.watercls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="watercls1"
              d="M0,95.72V0H98.59V95.72ZM43.2,19.63a2.24,2.24,0,0,1-.26.1A17.9,17.9,0,0,0,39,21.43a11.65,11.65,0,0,0-3.16,2.65A7.24,7.24,0,0,0,34.42,27a5.93,5.93,0,0,0-.11,1.33c0,3.29,0,6.58,0,9.86V93l.13,0h31l.19,0,0,0,0,0v-.38q0-32.07,0-64.13a6.39,6.39,0,0,0-.61-2.81,8.28,8.28,0,0,0-2-2.62,12.85,12.85,0,0,0-3.84-2.42c-.82-.33-1.66-.61-2.49-.9a.31.31,0,0,1-.26-.3c-.06-.5-.14-1-.21-1.51C56.15,16.42,56,15,55.8,13.6a.32.32,0,0,1,0-.13h.36c.83,0,1.66,0,2.49,0A6.14,6.14,0,0,0,60.9,13a1.06,1.06,0,0,0,.7-.87c.12-.75.29-1.5.43-2.25.19-1,.36-2,.55-2.94a.51.51,0,0,0-.15-.5A1.35,1.35,0,0,0,62,6.12a9.69,9.69,0,0,0-1.3-.38,9.53,9.53,0,0,0-2.32-.14l-3.25.13a1.9,1.9,0,0,1-.34,0L54.4,2.77c-.64-.07-8.8,0-9,0-.18,1.11-.27,2.24-.45,3.36l-1.66.08c-1.24.06-2.47.11-3.7.2a4.44,4.44,0,0,0-1.7.49.79.79,0,0,0-.49.62.42.42,0,0,1-.16.22l-1.7,1.45-3.33,2.86-2.94,2.55c-.45.39-.91.77-1.37,1.15a2.89,2.89,0,0,0-1.1,2.91,7.48,7.48,0,0,1,.2,1.42c0,1.79,0,3.57,0,5.36,0,.12,0,.24,0,.37h.13l2.16-.7a1.32,1.32,0,0,0,1-1.23c.11-1.29.26-2.58.38-3.87a.72.72,0,0,1,.3-.56c1-.71,1.92-1.44,2.88-2.16l1.79-1.38,3.64-2.74L41,11.89c.11.12.22.21.31.33a1.71,1.71,0,0,0,.92.6c.41.12.83.19,1.24.28.19,0,.39.06.61.09Z"
            />
            <path
              class="watercls2"
              d="M43.2,19.63,44,13.19c-.22,0-.42,0-.61-.09-.41-.09-.83-.16-1.24-.28a1.71,1.71,0,0,1-.92-.6c-.09-.12-.2-.21-.31-.33l-1.71,1.3L35.6,15.93l-1.79,1.38c-1,.72-1.91,1.45-2.88,2.16a.72.72,0,0,0-.3.56c-.12,1.29-.27,2.58-.38,3.87a1.32,1.32,0,0,1-1,1.23l-2.16.7H27c0-.13,0-.25,0-.37,0-1.79,0-3.57,0-5.36a7.48,7.48,0,0,0-.2-1.42,2.89,2.89,0,0,1,1.1-2.91c.46-.38.92-.76,1.37-1.15l2.94-2.55,3.33-2.86,1.7-1.45a.42.42,0,0,0,.16-.22.79.79,0,0,1,.49-.62,4.44,4.44,0,0,1,1.7-.49c1.23-.09,2.46-.14,3.7-.2l1.66-.08c.18-1.12.27-2.25.45-3.36.23-.07,8.39-.1,9,0l.38,2.95a1.9,1.9,0,0,0,.34,0l3.25-.13a9.53,9.53,0,0,1,2.32.14,9.69,9.69,0,0,1,1.3.38,1.35,1.35,0,0,1,.44.31.51.51,0,0,1,.15.5c-.19,1-.36,2-.55,2.94-.14.75-.31,1.5-.43,2.25a1.06,1.06,0,0,1-.7.87,6.14,6.14,0,0,1-2.23.47c-.83,0-1.66,0-2.49,0h-.36a.32.32,0,0,0,0,.13c.17,1.41.35,2.82.53,4.24.07.5.15,1,.21,1.51a.31.31,0,0,0,.26.3c.83.29,1.67.57,2.49.9A12.85,12.85,0,0,1,63.13,23a8.28,8.28,0,0,1,2,2.62,6.39,6.39,0,0,1,.61,2.81q0,32.06,0,64.13v.38l0,0,0,0-.19,0h-31l-.13,0V38.16c0-3.28,0-6.57,0-9.86A5.93,5.93,0,0,1,34.42,27a7.24,7.24,0,0,1,1.42-2.89A11.65,11.65,0,0,1,39,21.43a17.9,17.9,0,0,1,3.94-1.7A2.24,2.24,0,0,0,43.2,19.63Zm19.53,32c.07-.4.05-15,0-15.2H37.24c-.07.42-.05,15,0,15.2Z"
            />
            <path
              class="watercls1"
              d="M62.73,51.6H37.26c-.07-.22-.09-14.78,0-15.2H62.71C62.78,36.61,62.8,51.2,62.73,51.6ZM42.61,43.53h.06l.79,2.36h.83a.41.41,0,0,0,.19-.14s0-.11.06-.17l1.08-3.2a.55.55,0,0,0,0-.18h-.82l-.88,2.51-.86-2.51-.13,0h-.39c-.32,0-.32,0-.42.3-.22.63-.43,1.27-.64,1.9,0,.09-.07.17-.1.25h-.06l-.82-2.45a4.21,4.21,0,0,0-.93,0l1.25,3.66h1ZM57,42.2c0,1.22,0,2.44,0,3.67h.89l0-1h.5a.4.4,0,0,1,.42.22c.15.22.3.44.46.65s.12.14.19.14c.27,0,.54,0,.89,0l-.84-1.17.25-.17a1.28,1.28,0,0,0,0-2,2,2,0,0,0-1-.32H57.2Zm-.77,0H53.36a29.44,29.44,0,0,0,0,3.67h2.85v-.7h-2v-.82H56v-.7H54.21v-.77h2Zm-8.09,0h-.68a.26.26,0,0,0-.29.19c-.2.44-.42.88-.62,1.32l-.89,2a2.18,2.18,0,0,0-.06.22h.7a.22.22,0,0,0,.24-.16c.07-.16.16-.32.23-.48a.21.21,0,0,1,.22-.15h1.41a.2.2,0,0,1,.21.15c.06.15.13.3.19.44s.09.2.22.2h.8C49.25,44.59,48.63,43.41,48.09,42.17Zm2.64.72c0,1,0,2,0,3h.88c0-1,0-2,0-3h1.17v-.65a27.6,27.6,0,0,0-3.28,0v.67Z"
            />
            <path
              class="watercls2"
              d="M42.61,43.53l-.83,2.34h-1l-1.25-3.66a4.21,4.21,0,0,1,.93,0l.82,2.45h.06c0-.08.07-.16.1-.25.21-.63.42-1.27.64-1.9.1-.3.1-.3.42-.3h.39l.13,0,.86,2.51.88-2.51h.82a.55.55,0,0,1,0,.18l-1.08,3.2c0,.06,0,.13-.06.17a.41.41,0,0,1-.19.14h-.83l-.79-2.36Z"
            />
            <path
              class="watercls2"
              d="M57,42.2l.25,0h1.5a2,2,0,0,1,1,.32,1.28,1.28,0,0,1,0,2l-.25.17.84,1.17c-.35,0-.62,0-.89,0-.07,0-.15-.08-.19-.14s-.31-.43-.46-.65a.4.4,0,0,0-.42-.22h-.5l0,1H57C56.94,44.64,56.94,43.42,57,42.2Zm.93,2a9.71,9.71,0,0,0,1,0,.54.54,0,0,0,.48-.59.56.56,0,0,0-.48-.59,4,4,0,0,0-1,0Z"
            />
            <path
              class="watercls2"
              d="M56.18,42.2v.68h-2v.77H56v.7H54.21v.82h2v.7H53.39a29.44,29.44,0,0,1,0-3.67Z"
            />
            <path
              class="watercls2"
              d="M48.09,42.17c.54,1.24,1.16,2.42,1.68,3.71H49c-.13,0-.18-.09-.22-.2s-.13-.29-.19-.44a.2.2,0,0,0-.21-.15H46.94a.21.21,0,0,0-.22.15c-.07.16-.16.32-.23.48a.22.22,0,0,1-.24.16h-.7a2.18,2.18,0,0,1,.06-.22l.89-2c.2-.44.42-.88.62-1.32a.26.26,0,0,1,.29-.19Zm.13,2.23a13,13,0,0,0-.6-1.32l-.55,1.32Z"
            />
            <path
              class="watercls2"
              d="M50.73,42.89H49.56v-.67a27.6,27.6,0,0,1,3.28,0v.65H51.67c-.05,1,0,2,0,3h-.88C50.72,44.89,50.75,43.91,50.73,42.89Z"
            />
            <path
              class="watercls1"
              d="M57.88,44.16V42.9a4,4,0,0,1,1,0,.56.56,0,0,1,.48.59.54.54,0,0,1-.48.59A9.71,9.71,0,0,1,57.88,44.16Z"
            />
            <path
              class="watercls1"
              d="M48.22,44.4H47.07l.55-1.32A13,13,0,0,1,48.22,44.4Z"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
