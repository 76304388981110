import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "measuring-arrow",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "measuring arrow",
    description: "measuring-arrow",
    image: require("./measuring-arrow.svg"),
    svgIcon: require("./measuring-arrow.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 950,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 950,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-45, 55) scale(1, -1)`}>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M.21,99.78,50,.22,99.79,99.78Z"
              transform="translate(-0.21 -0.22)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
