import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Sink-with-drainer",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Sink with drainer",
    description: "Sink-with-drainer",
    image: require("./Sink-with-drainer.svg"),
    svgIcon: require("./Sink-with-drainer.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            {" "}
            <path
              d="M49.63,73.15H5.49c-1.84,0-1.84,0-1.84-1.8V30.27c0-1.58.1-1.69,1.65-1.69H94.19c1.51,0,1.64.13,1.64,1.61V71.47c0,1.61-.06,1.68-1.66,1.68Zm-44.07-43v41.4H93.92V30.18Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M5.56,30.18H93.92v41.4H5.56ZM42.22,67.89a19.43,19.43,0,0,0,3.23.64c1.7,0,2.41-1.09,1.94-2.74a5.18,5.18,0,0,1-.27-1.39c0-2-1-2.82-3-2.32-.82.21-1.44.59-1.37,1.59,0,.2-.19.41-.29.61a2.7,2.7,0,0,1-.31-.64,4.37,4.37,0,0,1,0-.81v-30H8.39v36.6H40.14C42,69.42,42,69.42,42.22,67.89Zm7.34-12.35h39c2.13,0,2.08,0,2.14-2.09,0-1-.27-1.25-1.25-1.25q-19.37,0-38.72,0c-.36,0-.72,0-1.12.06Zm20.47-6H88.57c2.33,0,2,.37,2.08-2,0-1-.28-1.3-1.31-1.29q-18.84,0-37.69,0c-2.16,0-2.11,0-2.17,2.13,0,.93.25,1.21,1.19,1.2C57.12,49.54,63.58,49.56,70,49.56Zm0,11.95q9.33,0,18.66,0c2.13,0,1.93.32,1.94-2,0-1.34,0-1.34-1.33-1.34H51.47c-2,0-2,0-2,2,0,1.31,0,1.31,1.37,1.31Zm0-27.21H51.4c-2.22,0-1.87-.27-1.9,1.88,0,1.44,0,1.45,1.44,1.45H88.46c2.44,0,2.11.39,2.19-2.15,0-1-.3-1.2-1.22-1.19C83,34.32,76.51,34.3,70.06,34.3Zm20.56,6H51.44c-1.94,0-1.94,0-1.94,1.94,0,1.37,0,1.38,1.4,1.38H89.24a1.62,1.62,0,0,0,.7,0c.26-.11.63-.35.65-.56C90.66,42.15,90.62,41.27,90.62,40.28ZM70,67.49c6.22,0,12.44,0,18.66,0,2.13,0,1.93.34,1.94-2,0-1.35,0-1.35-1.32-1.35H51.48c-2,0-2,0-2,2,0,1.32,0,1.32,1.36,1.32Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              d="M42.22,67.89c-.27,1.53-.27,1.53-2.08,1.53H8.39V32.82H42.13v30a4.37,4.37,0,0,0,0,.81,2.7,2.7,0,0,0,.31.64c.1-.2.3-.41.29-.61-.07-1,.55-1.38,1.37-1.59,2-.5,3,.3,3,2.32a5.18,5.18,0,0,0,.27,1.39c.47,1.65-.24,2.78-1.94,2.74A19.43,19.43,0,0,1,42.22,67.89ZM41.07,33.67H9.38V68.5H41c.35-1.1,0-1.68-1.06-1.93-1.88-.45-3.76-.92-5.62-1.46A7.74,7.74,0,0,1,32,64.05a1.51,1.51,0,0,1,.33-2.66,2,2,0,0,1,2.21.17,20.73,20.73,0,0,0,2.38,1.56,10.13,10.13,0,0,0,4.19,1.09Zm4.14,29.15c-1.37,0-1.59.3-1.09,1.48.26.62-.12.75-.56.75A30.18,30.18,0,0,1,40,64.94a11.41,11.41,0,0,1-6-2.62c-.56-.46-1.17-.52-1.58.13s.11,1.11.64,1.34a9.83,9.83,0,0,0,2.12.66A28.06,28.06,0,0,1,42.93,67a6.51,6.51,0,0,0,2,.69c1.32.22,1.82-.43,1.48-1.72a4.58,4.58,0,0,1-.26-1.88C46.35,63.06,45.88,62.85,45.21,62.82Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              d="M49.56,55.54V52.28c.4,0,.76-.06,1.12-.06q19.37,0,38.72,0c1,0,1.28.27,1.25,1.25-.06,2.09,0,2.09-2.14,2.09H49.56Zm.65-2.75V54.9H89.88V52.79Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              d="M70,49.56c-6.45,0-12.91,0-19.36,0-.94,0-1.22-.27-1.19-1.2.06-2.13,0-2.13,2.17-2.13q18.84,0,37.69,0c1,0,1.36.25,1.31,1.29-.1,2.41.25,2-2.08,2Zm19.88-2.71H50.22v2.07H89.91Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              d="M70,61.51H50.87c-1.37,0-1.37,0-1.37-1.31,0-2,0-2,2-2H89.3c1.33,0,1.33,0,1.33,1.34,0,2.29.19,2-1.94,2Q79.36,61.53,70,61.51Zm-19.85-.64h39.7V58.78H50.18Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              d="M70.06,34.3c6.45,0,12.91,0,19.37,0,.92,0,1.25.23,1.22,1.19-.08,2.54.25,2.15-2.19,2.15H50.94c-1.44,0-1.45,0-1.44-1.45,0-2.15-.32-1.87,1.9-1.88Zm19.85.6H50.22V37H89.91Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              d="M90.62,40.28c0,1,0,1.87,0,2.73,0,.21-.39.45-.65.56a1.62,1.62,0,0,1-.7,0H50.9c-1.4,0-1.4,0-1.4-1.38,0-1.94,0-1.94,1.94-1.94H90.62ZM89.86,43V40.86H50.21V43Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              d="M70,67.49H50.86c-1.36,0-1.36,0-1.36-1.32,0-2,0-2,2-2H89.31c1.32,0,1.32,0,1.32,1.35,0,2.3.19,2-1.94,2C82.47,67.5,76.25,67.49,70,67.49Zm19.86-2.73H50.2v2.11H89.89Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M41.07,33.67V64.21a10.13,10.13,0,0,1-4.19-1.09,20.73,20.73,0,0,1-2.38-1.56,2,2,0,0,0-2.21-.17A1.51,1.51,0,0,0,32,64.05a7.74,7.74,0,0,0,2.38,1.06c1.86.54,3.74,1,5.62,1.46,1,.25,1.41.83,1.06,1.93H9.38V33.67ZM30,56.07a6.12,6.12,0,0,0,3.87-1.33A4.06,4.06,0,0,0,34,48.37a6.33,6.33,0,0,0-7.66-.25,4.05,4.05,0,0,0-.19,6.62A6,6,0,0,0,30,56.07Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M45.21,62.82c.67,0,1.14.24.94,1.24a4.58,4.58,0,0,0,.26,1.88c.34,1.29-.16,1.94-1.48,1.72a6.51,6.51,0,0,1-2-.69,28.06,28.06,0,0,0-7.72-2.52,9.83,9.83,0,0,1-2.12-.66c-.53-.23-1.08-.66-.64-1.34s1-.59,1.58-.13a11.41,11.41,0,0,0,6,2.62,30.18,30.18,0,0,0,3.56.11c.44,0,.82-.13.56-.75C43.62,63.12,43.84,62.81,45.21,62.82Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M50.21,52.79H89.88V54.9H50.21Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M89.91,46.85v2.07H50.22V46.85Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M50.18,60.87V58.78h39.7v2.09Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M89.91,34.9V37H50.22V34.9Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M89.86,43H50.21V40.86H89.86Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M89.89,64.76v2.11H50.2V64.76Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              d="M30,56.07a6,6,0,0,1-3.87-1.33,4.05,4.05,0,0,1,.19-6.62,6.33,6.33,0,0,1,7.66.25,4.06,4.06,0,0,1-.11,6.37A6.12,6.12,0,0,1,30,56.07ZM30,47.79c-2.46,0-4.45,1.68-4.46,3.71s2,3.7,4.44,3.73,4.47-1.58,4.53-3.61S32.51,47.77,30,47.79Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M30,47.79c2.54,0,4.56,1.69,4.51,3.83S32.4,55.26,30,55.23s-4.45-1.7-4.44-3.73S27.51,47.81,30,47.79ZM30.35,49c-.61.09-1,.11-1.35.2a2.33,2.33,0,0,0,0,4.63,3.41,3.41,0,0,0,3.1-.56,2.08,2.08,0,0,0,.07-3.4A8.4,8.4,0,0,0,30.35,49Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              d="M30.35,49a8.4,8.4,0,0,1,1.8.87,2.08,2.08,0,0,1-.07,3.4,3.41,3.41,0,0,1-3.1.56,2.33,2.33,0,0,1,0-4.63C29.36,49.12,29.74,49.1,30.35,49Zm-.47,4.13a1.89,1.89,0,0,0,2.1-1.6,1.82,1.82,0,0,0-1.87-1.62A1.92,1.92,0,0,0,28,51.47,1.85,1.85,0,0,0,29.88,53.14Z"
              transform="translate(-3.65 -28.58)"
            />
            <path
              class="cls-1"
              d="M29.88,53.14A1.85,1.85,0,0,1,28,51.47a1.92,1.92,0,0,1,2.16-1.55A1.82,1.82,0,0,1,32,51.54,1.89,1.89,0,0,1,29.88,53.14Z"
              transform="translate(-3.65 -28.58)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
