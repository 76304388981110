import React, { Fragment, useEffect, useLayoutEffect, useRef } from "react";
import PropTypes, { func } from "prop-types";

import {
  ReactSVGPanZoom,
  TOOL_NONE,
  TOOL_PAN,
  TOOL_ZOOM_IN,
  TOOL_ZOOM_OUT,
  TOOL_AUTO,
  fitToViewer,
} from "react-svg-pan-zoom";
import * as constants from "../../constants";
import State from "./state";
import * as SharedStyle from "../../shared-style";
import { Line, RulerX, RulerY } from "./export";

function mode2Tool(mode) {
  switch (mode) {
    case constants.MODE_2D_PAN:
      return TOOL_PAN;
    case constants.MODE_2D_ZOOM_IN:
      return TOOL_ZOOM_IN;
    case constants.MODE_2D_ZOOM_OUT:
      return TOOL_ZOOM_OUT;
    case constants.MODE_IDLE:
      return TOOL_AUTO;
    default:
      return TOOL_NONE;
  }
}

function mode2PointerEvents(mode) {
  switch (mode) {
    case constants.MODE_DRAWING_LINE:
    case constants.MODE_DRAWING_HOLE:
    case constants.MODE_DRAWING_ITEM:
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_DRAGGING_ITEM:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
      return { pointerEvents: "none" };

    default:
      return {};
  }
}

function mode2Cursor(mode, scene) {
  switch (mode) {
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
    case constants.MODE_DRAGGING_ITEM:
    // return { cursor: "move" };

    case constants.MODE_ROTATING_ITEM:
      return { cursor: "ew-resize" };

    case constants.MODE_WAITING_DRAWING_LINE:
    // case constants.MODE_WAITING_DRAWING_SQUARE:
    // case constants.MODE_DRAWING_SQUARE:
    case constants.MODE_DRAWING_LINE:
      return { cursor: "crosshair" };
    default:
      return { cursor: "default" };
  }
}

function mode2DetectAutopan(mode) {
  switch (mode) {
    case constants.MODE_DRAWING_LINE:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_DRAGGING_ITEM:
    case constants.MODE_DRAWING_HOLE:
    case constants.MODE_DRAWING_ITEM:
      return true;

    default:
      return false;
  }
}

function extractElementData(node) {
  while (
    !node.attributes.getNamedItem("data-element-root") &&
    node.tagName !== "svg"
  ) {
    node = node.parentNode;
  }
  if (node.tagName === "svg") return null;

  return {
    part: node.attributes.getNamedItem("data-part")
      ? node.attributes.getNamedItem("data-part").value
      : undefined,
    layer: node.attributes.getNamedItem("data-layer").value,
    prototype: node.attributes.getNamedItem("data-prototype").value,
    selected: node.attributes.getNamedItem("data-selected")
      ? node.attributes.getNamedItem("data-selected").value === "true"
      : true,
    id: node.attributes.getNamedItem("data-id").value,
  };
}
function closure() {
  let hasRun = false;

  return function (viewer2D, Viewer) {
    if (!hasRun && viewer2D.size) {
      // Perform your logic here with the provided value
      // viewer2DActions.updateCameraView({
      //   ...viewer2D,
      //   a: 0.5,
      //   e: - viewer2D.SVGWidth / 2,
      //   f: - viewer2D.SVGHeight / 2,
      // });

      hasRun = true;
    }
  };
}
const updateCameraOnceOnMount = closure();

export default function Viewer2D(
  { state, width, height },
  {
    viewer2DActions,
    linesActions,
    holesActions,
    verticesActions,
    itemsActions,
    areaActions,
    projectActions,
    catalog,
  }
) {
  // useEffect(() => {
  //   if (
  //     mode === constants.MODE_DRAWING_SQUARE ||
  //     mode === constants.MODE_WAITING_DRAWING_SQUARE ||
  //     mode === constants.UPDATE_DRAWING_SQUARE
  //   ) {

  //   }
  // }, [mode]);

  function onMouseMovesquare(mouseX, mouseY) {
    const squareBox = document.getElementById("square-box");
    if (squareBox && squareBox.style) {
      squareBox.style.left = mouseX - 235 + "px";
      squareBox.style.top = mouseY -6 + "px";
    }
  }
  const Viewer = useRef(null);

  let { viewer2D, mode, scene } = state;
  let layerID = scene.selectedLayer;

  let mapCursorPosition = ({ x, y }) => {
    return { x, y: -y + scene.height };
  };

  let onMouseMove = (viewerEvent) => {
    //workaround that allow imageful component to work
    let evt = new Event("mousemove-planner-event");
    const { clientX, clientY } = viewerEvent.originalEvent;
    // console.log(document.getElementById("square-box"));
    onMouseMovesquare(clientX, clientY);
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);
    let { x, y } = mapCursorPosition(viewerEvent);
    switch (mode) {
      case constants.MODE_DRAGGING_HORIZONTALGUIDE:
        projectActions.updateHorizontalGuide(y);
        break;
      case constants.MODE_DRAGGING_VERTICALGUIDE:
        projectActions.updateVerticalGuide(x);
        break;
      case constants.MODE_DRAWING_SQUARE:
        linesActions.updateDrawingSquare(x, y, state.snapMask);
        break;
      case constants.MODE_DRAWING_LINE:
        linesActions.updateDrawingLine(x, y, state.snapMask);
        break;

      case constants.MODE_DRAWING_HOLE:
        holesActions.updateDrawingHole(layerID, x, y);
        break;

      case constants.MODE_DRAWING_ITEM:
        itemsActions.updateDrawingItem(layerID, x, y);
        break;

      case constants.MODE_DRAGGING_HOLE:
        const currentHole = state.getIn([
          "scene",
          "layers",
          layerID,
          "holes",
          state.getIn(["draggingSupport", "holeID"]),
        ]);
        if (viewerEvent.originalEvent.ctrlKey) {
          holesActions.duplicate(layerID, currentHole.id);
          return;
        }
        holesActions.selectToolDrawingHole(
          currentHole.type,
          currentHole.properties
        );
        break;

      case constants.MODE_DRAGGING_LINE:
        linesActions.updateDraggingLine(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_VERTEX:
        verticesActions.updateDraggingVertex(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_ITEM:
        const currentItem = state.getIn([
          "scene",
          "layers",
          layerID,
          "items",
          state.getIn(["draggingSupport", "itemID"]),
        ]);
        if (viewerEvent.originalEvent.ctrlKey) {
          itemsActions.duplicate(layerID, currentItem.id);
          return;
        }
        itemsActions.updateDraggingItem(x, y);
        break;

      case constants.MODE_ROTATING_ITEM:
        itemsActions.updateRotatingItem(x, y);
        break;
      case constants.RESIZE_ITEM:
        itemsActions.resizeItem(x, y);
        break;
      case constants.HOLD_AREA:
        projectActions.setMode(constants.MODE_IDLE);
        break;
    }

    viewerEvent.originalEvent.stopPropagation();
  };
  const filterVerticesWithDuplication = (selectedAreaId, areas) => {
    const selectedVertices = areas.get(selectedAreaId).get("vertices");
    let filteredVertices = new Map();

    areas.forEach((area, areaId) => {
      if (areaId !== selectedAreaId) {
        const areaVertices = area.get("vertices");
        selectedVertices.forEach((vertex) => {
          if (areaVertices.includes(vertex)) {
            filteredVertices = filteredVertices.set(vertex, true);
          }
        });
      }
    });

    return selectedVertices
      .filter((vertex) => !filteredVertices.has(vertex))
      .toList();
  };
  let onMouseDown = (viewerEvent) => {
    let event = viewerEvent.originalEvent;
    //workaround that allow imageful component to work
    let evt = new Event("mousedown-planner-event");
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);

    let { x, y } = mapCursorPosition(viewerEvent);
    if (mode === constants.MODE_IDLE) {
      let elementData = extractElementData(event.target);
      if (!elementData) return;

      switch (elementData.prototype) {
        case "lines":
          if (event.nativeEvent.button === 2) {
            // rightclick
            linesActions.splitLine(
              elementData.layer,
              elementData.id,
              x,
              y,
              state.snapMask
            );
          } else {
            linesActions.beginDraggingLine(
              elementData.layer,
              elementData.id,
              x,
              y,
              state.snapMask
            );
          }
          break;

        case "vertices":
          verticesActions.beginDraggingVertex(
            elementData.layer,
            elementData.id,
            x,
            y,
            state.snapMask
          );
          break;

        case "areas":
          areaActions.selectArea(elementData.layer, elementData.id);
          // projectActions.setMode(constants.HOLD_AREA);

          break;
        case "items":
          itemsActions.selectItem(elementData.layer, elementData.id);
          if (elementData.part === "rotation-anchor")
            itemsActions.beginRotatingItem(
              elementData.layer,
              elementData.id,
              x,
              y
            );
          else if (elementData.part === "resize-box") {
            itemsActions.selectResizeItem(elementData.layer, elementData.id);
          } else
            itemsActions.beginDraggingItem(
              elementData.layer,
              elementData.id,
              x,
              y
            );
          break;

        case "holes":
          holesActions.selectHole(elementData.layer, elementData.id);

          holesActions.beginDraggingHole(
            elementData.layer,
            elementData.id,
            x,
            y
          );
          break;

        default:
          break;
      }
    } else {
      switch (mode) {
        case constants.MODE_DRAGGING_LINE:
          break;
        case constants.MODE_DRAGGING_HORIZONTALGUIDE:
        case constants.MODE_DRAWING_SQUARE:
        case constants.MODE_DRAGGING_VERTICALGUIDE:
        case "SELECT_GUIDE":
          projectActions.setMode(constants.MODE_IDLE);
          break;
        case constants.MODE_WAITING_DRAWING_SQUARE:
          linesActions.beginDrawingSquare(layerID, x, y, state.snapMask);
          break;
      }
    }
    event.stopPropagation();
  };

  let onMouseUp = (viewerEvent) => {
    let event = viewerEvent.originalEvent;
    let evt = new Event("mouseup-planner-event");
    evt.viewerEvent = viewerEvent;

    document.dispatchEvent(evt);
    let { x, y } = mapCursorPosition(viewerEvent);
    switch (mode) {
      case constants.MODE_IDLE:
        let elementData = extractElementData(event.target);
        // console.log(elementData);
        if (elementData && elementData.selected) return;

        switch (elementData ? elementData.prototype : "none") {
          case "areas":
            areaActions.selectArea(elementData.layer, elementData.id);
            break;

          case "lines":
            // console.log(elementData.id);
            linesActions.selectLine(elementData.layer, elementData.id);
            break;

          case "holes":
            holesActions.selectHole(elementData.layer, elementData.id);
            break;

          case "items":
            itemsActions.selectItem(elementData.layer, elementData.id);
            break;

          case "none":
            projectActions.unselectAll();
            break;
        }
        break;

      case constants.MODE_WAITING_DRAWING_LINE:
        linesActions.beginDrawingLine(layerID, x, y, state.snapMask);
        break;

      // case constants.MODE_WAITING_DRAWING_SQUARE:
      //   linesActions.beginDrawingSquare(layerID, x, y, state.snapMask);
      //   break;

      case constants.MODE_DRAWING_LINE:
        linesActions.endDrawingLine(x, y, state.snapMask);
        break;

      case constants.MODE_DRAWING_SQUARE:
      case constants.UPDATE_DRAWING_SQUARE:
        linesActions.endDrawingSquare(layerID, x, y, state.snapMask);
        break;

      case constants.MODE_DRAWING_HOLE:
        holesActions.endDrawingHole(layerID, x, y);
        break;

      case constants.MODE_DRAWING_ITEM:
        itemsActions.endDrawingItem(layerID, x, y);
        break;

      case constants.MODE_DRAGGING_LINE:
        linesActions.endDraggingLine(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_VERTEX:
        verticesActions.endDraggingVertex(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_ITEM:
        itemsActions.endDraggingItem(x, y);

        break;

      case constants.MODE_DRAGGING_HOLE:
        holesActions.endDraggingHole(x, y);
        break;

      case constants.MODE_ROTATING_ITEM:
        itemsActions.endRotatingItem(x, y);
        break;
      case constants.RESIZE_ITEM:
        itemsActions.endResizingItem(x, y);
        break;
    }

    event.stopPropagation();
  };
  let { e, f, SVGWidth, SVGHeight } = state.get("viewer2D").toJS();

  let onChangeValue = (value) => {
    let valueE = value.e;
    let valueF = value.f;
    // if ((value.e >= -10 || value.e<=-2930)) {
    //   valueE = e
    // }
    // if((value.f>=0 || value.f<=-2000)){
    //   valueF = f
    // }
    projectActions.updateZoomScale(value.a);
    return viewer2DActions.updateCameraView({ ...value, e: valueE, f: valueF });
  };

  let onChangeTool = (tool) => {
    switch (tool) {
      case TOOL_NONE:
        projectActions.selectToolEdit();
        break;

      case TOOL_PAN:
        viewer2DActions.selectToolPan();
        break;

      case TOOL_ZOOM_IN:
        viewer2DActions.selectToolZoomIn();
        break;

      case TOOL_ZOOM_OUT:
        viewer2DActions.selectToolZoomOut();
        break;
    }
  };

  const onDoubleClick = (viewerEvent) => {
    let event = viewerEvent.originalEvent;

    let evt = new Event("doubleclick-planner-event");
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);
    let { x, y } = mapCursorPosition(viewerEvent);
    switch (mode) {
      case constants.MODE_IDLE:
        let elementData = extractElementData(event.target);

        if (!elementData && !elementData.selected) return;
        switch (elementData ? elementData.prototype : "none") {
          case "areas":
            // if (event.altKey) {

            const selectedLayer = state.getIn(["scene", "selectedLayer"]);
            const areas = state.getIn([
              "scene",
              "layers",
              selectedLayer,
              "areas",
            ]);
            const selectedAreaId = state
              .getIn(["scene", "layers", selectedLayer, "selected", "areas"])
              .first();
            const filteredVertices = filterVerticesWithDuplication(
              selectedAreaId,
              areas
            );
            const filteredLines = state
              .getIn(["scene", "layers", selectedLayer, "lines"])
              .filter((line) => {
                const lineVertices = line.get("vertices");
                return lineVertices.some((vertex) =>
                  filteredVertices.includes(vertex)
                );
              });
            filteredLines.forEach((line) => {
              linesActions.selectLine(elementData.layer, line.id);
            });
            // }
            break;
          case "lines":
            linesActions.splitLine(
              elementData.layer,
              elementData.id,
              x,
              y,
              state.snapMask
            );
            break;
        }
        break;
    }
    event.stopPropagation();
  };

  const returnGridStyle = () => {
    const gridStyle = {
      margin: 0,
      padding: 0,
      display: "grid",
      gridRowGap: "0",
      gridColumnGap: "0",
      position: "relative",
    };

    if (scene.get("vg")) {
      gridStyle.gridTemplateColumns = `${rulerSize}px ${width - rulerSize}px`;
    }
    if (scene.get("hg")) {
      gridStyle.gridTemplateRows = `${rulerSize}px ${height - rulerSize}px`;
    }
    return gridStyle;
    // scene.get("vg")
    // ? {
    //     ...gridStyle,
    //     gridTemplateRows: `${rulerSize}px ${height - rulerSize}px`,
    //   }
    // : scene.get("hg")
    // ? {
    //     ...gridStyle,
    //     gridTemplateColumns: `${rulerSize}px ${width - rulerSize}px`,
    //   }
    // : scene.get("vg") && scene.get("hg")
    // ? {
    //     ...gridStyle,
    //     gridTemplateRows: `${rulerSize}px ${height - rulerSize}px`,
    //     gridTemplateColumns: `${rulerSize}px ${width - rulerSize}px`,
    //   }
    // : gridStyle
  };
  let rulerSize = 15; //px
  let rulerUnitPixelSize = 100;
  let rulerBgColor = SharedStyle.PRIMARY_COLOR.main;
  let rulerFnColor = SharedStyle.COLORS.white;
  let rulerMkColor = SharedStyle.SECONDARY_COLOR.main;
  let sceneWidth = SVGWidth || state.getIn(["scene", "width"]);
  let sceneHeight = SVGHeight || state.getIn(["scene", "height"]);
  let sceneZoom = state.zoom || 1;
  let rulerXElements = Math.ceil(sceneWidth / rulerUnitPixelSize) + 1;
  let rulerYElements = Math.ceil(sceneHeight / rulerUnitPixelSize) + 1;
  let defaultState = {
    a: 0.5,
    b: 0,
    SVGWidth: 30000,
    c: 0,
    mode: "idle",
    d: 0.5,
    e: -6814,
    f: -3505,
    miniatureOpen: true,
    SVGHeight: 20000,
    pinchPointDistance: null,
    scaleFactorMax: 5,
    lastAction: null,
    viewerWidth: 1336,
    startX: null,
    startY: null,
    version: 2,
    focus: false,
    scaleFactorMin: 0.14,
    viewerHeight: 727.2000122070312,
    prePinchMode: null,
    endX: null,
    endY: null,
  };

  return (
    <Fragment>
      {(mode === constants.MODE_DRAWING_SQUARE ||
        mode === constants.MODE_WAITING_DRAWING_SQUARE ||
        mode === constants.UPDATE_DRAWING_SQUARE) && (
        <div id="square-box"></div>
      )}
      <div
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        style={returnGridStyle()}
      >
        <div
          style={{ gridColumn: 1, gridRow: 1, backgroundColor: rulerBgColor }}
        ></div>
        <div
          style={{
            gridRow: 1,
            gridColumn: 2,
            position: "relative",
            overflow: "hidden",
          }}
          id="rulerX"
        >
          {sceneWidth ? (
            <RulerX
              unitPixelSize={rulerUnitPixelSize}
              zoom={sceneZoom}
              mouseX={state.mouse.get("x")}
              mouseY={state.mouse.get("y")}
              width={width - rulerSize}
              zeroLeftPosition={e || 0}
              backgroundColor={rulerBgColor}
              fontColor={rulerFnColor}
              markerColor={rulerMkColor}
              positiveUnitsNumber={rulerXElements}
              negativeUnitsNumber={0}
            />
          ) : null}
        </div>
        <div
          style={{
            gridColumn: 1,
            gridRow: 2,
            position: "relative",
            overflow: "hidden",
          }}
          id="rulerY"
        >
          {sceneHeight ? (
            <RulerY
              unitPixelSize={rulerUnitPixelSize}
              zoom={sceneZoom}
              mouseX={state.mouse.get("x")}
              mouseY={state.mouse.get("y")}
              height={height - rulerSize}
              zeroTopPosition={sceneHeight * sceneZoom + f || 0}
              backgroundColor={rulerBgColor}
              fontColor={rulerFnColor}
              markerColor={rulerMkColor}
              positiveUnitsNumber={rulerYElements}
              negativeUnitsNumber={0}
            />
          ) : null}
        </div>
        <ReactSVGPanZoom
          style={{ gridColumn: 2, gridRow: 2 }}
          width={width}
          disableDoubleClickZoomWithToolAuto={true}
          ref={Viewer}
          height={height}
          value={viewer2D.isEmpty() ? defaultState : viewer2D.toJS()}
          onChangeValue={onChangeValue}
          tool={mode2Tool(mode)}
          background={"white"}
          onChangeTool={onChangeTool}
          detectAutoPan={mode2DetectAutopan(mode)}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onDoubleClick={onDoubleClick}
          // onDoubleClick={onDoubleClick}
          onMouseUp={onMouseUp}
          scaleFactorMax={5}
          scaleFactorMin={0.14}
          miniaturePosition="none"
          toolbarPosition="none"
        >
          <svg width={scene.width} height={scene.height}>
            <defs>
              <pattern
                id="diagonalFill"
                patternUnits="userSpaceOnUse"
                width="4"
                height="4"
                fill="#FFF"
              >
                <rect x="0" y="0" width="4" height="4" fill="#FFF" />
                <path
                  d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                  style={{ stroke: "#8E9BA2", strokeWidth: 1 }}
                />
              </pattern>
            </defs>
            <g
              style={{
                ...Object.assign(mode2Cursor(mode), mode2PointerEvents(mode)),
                strokeLinejoin: "round",
              }}
            >
              <State showGuides={true} state={state} catalog={catalog} />
            </g>
          </svg>
        </ReactSVGPanZoom>
      </div>
    </Fragment>
  );
}

Viewer2D.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

Viewer2D.contextTypes = {
  viewer2DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  verticesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};
