import React, { useMemo } from "react";
import PropTypes from "prop-types";
import * as SharedStyle from "../../shared-style";
import { MODE_FINISHING_PLAN } from "../../constants";

const STYLE = {
  fill: "gray",
  stroke: SharedStyle.COLORS.white,
  cursor: "move",
};

export default function Vertex({ vertex, layer }, { store }) {
  let { x, y } = vertex;
  const mode = useMemo(() => store.getState().toJS()["react-planner"]["mode"]);
  return (
    <g
      transform={`translate(${x}, ${y})`}
      data-element-root
      data-prototype={vertex.prototype}
      data-id={vertex.id}
      data-selected={vertex.selected}
      data-layer={layer.id}
    >
      {mode !==
        MODE_FINISHING_PLAN && (
        <rect
          width={8}
          height={8}
          style={{
            transform: "translate(-5px, -5px)",
            fill: "gray",
            stroke: SharedStyle.COLORS.white,
          }}
        ></rect>
      )}
      {/* <rect r="7" cx="0" cy="0" width="20" height="20" style={STYLE}/> */}
    </g>
  );
}

Vertex.propTypes = {
  vertex: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
};
Vertex.contextTypes = {
  store: PropTypes.object.isRequired,
};
