import { Map, List } from 'immutable';
import diff from 'immutablediff';
import patch from 'immutablepatch';

// export const historyPush = (historyStructure, item) => {
//   if (historyStructure.last) {
//     if (historyStructure.last.hashCode() !== item.hashCode()) {
//       let toPush = new Map({
//         time: Date.now(),
//         diff: diff(historyStructure.last, item)
//       });

//       historyStructure = historyStructure
//         .set('last', item)
//         .set('list', historyStructure.list.push(toPush));
//     }
//   }
//   else {
//     historyStructure = historyStructure.set('last', item);
//   }
//   return historyStructure;
// };

// export const historyPop = (historyStructure) => {
//   if (historyStructure.last) {
//     if (historyStructure.list.size) {
//       let last = historyStructure.first;
//       for (let x = 0; x < historyStructure.list.size - 1; x++) {
//         last = patch(last, historyStructure.list.get(x).get('diff'));
//       }

//       historyStructure = historyStructure
//         .set('last', last)
//         .set('list', historyStructure.list.pop());
//     }
//   }
//   return historyStructure;
// };


export const historyPush = (historyStructure, item) => {
  if (historyStructure.last) {
    if (historyStructure.last.hashCode() !== item.hashCode()) {
      let toPush = new Map({
        time: Date.now(),
        diff: diff(historyStructure.last, item)
      });

      historyStructure = historyStructure
        .set('last', item)
        .set('list', historyStructure.list.push(toPush))
        .set('redoList', new List()); // Initialize redo list
    }
  }
  else {
    historyStructure = historyStructure
      .set('last', item)
      .set('redoList', new List()); // Initialize redo list
  }
  return historyStructure;
};

export const historyPop = (historyStructure) => {
  if (historyStructure.last && historyStructure.list.size > 0) {
    let last = historyStructure.first;
    for (let x = 0; x < historyStructure.list.size - 1; x++) {
      last = patch(last, historyStructure.list.get(x).get('diff'));
    }

    historyStructure = historyStructure
      .set('last', last)
      .set('redoList', historyStructure.redoList.push(historyStructure.list.last())) // Store the popped item in redo list
      .set('list', historyStructure.list.pop());
  }
  return historyStructure;
};

export const historyRedo = (historyStructure) => {
  if (historyStructure.redoList.size > 0) {
    let redoItem = historyStructure.redoList.last();
    historyStructure = historyStructure
      .set('last', patch(historyStructure.last, redoItem.get('diff')))
      .set('list', historyStructure.list.push(redoItem))
      .set('redoList', historyStructure.redoList.pop());
  }
  return historyStructure;
};