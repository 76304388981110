import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Biget",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Biget",
    description: "Biget",
    image: require("./Biget.svg"),
    svgIcon: require("./Biget.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M50.21-.25H82c1.4,0,1.43,0,1.2,1.44Q81.07,13.9,79,26.6A1.22,1.22,0,0,0,79.58,28a41.38,41.38,0,0,1,15.77,19,13.86,13.86,0,0,1,1.14,5.53c-.09,5.32-.07,10.64,0,16a11.42,11.42,0,0,1-1,4.38,40.31,40.31,0,0,1-9.9,14.61,49.8,49.8,0,0,1-24,12.38,8.84,8.84,0,0,1-1.92.16c-6.41,0-12.82.07-19.23,0a20.81,20.81,0,0,1-5.09-.92,48.62,48.62,0,0,1-19.11-10A39.5,39.5,0,0,1,3.81,70.7a5.73,5.73,0,0,1-.26-1.64c0-5.88-.09-11.76,0-17.63a13.62,13.62,0,0,1,1.17-4.5A40.33,40.33,0,0,1,16,31.42C17.4,30.16,19,29.08,20.58,28a1,1,0,0,0,.53-1.16q-1.77-11.61-3.5-23.21c-.13-.89-.27-1.77-.42-2.66s0-1.17,1-1.17ZM20.66,1.3c.42,2.83.82,5.57,1.23,8.3Q23.2,18.31,24.52,27a2.3,2.3,0,0,1-1.09,2.41c-2,1.53-4.14,3-6,4.7A35.27,35.27,0,0,0,6,55.14,32.58,32.58,0,0,0,7.83,72.29,39,39,0,0,0,24.26,91.45a47,47,0,0,0,20.1,6.8,50.56,50.56,0,0,0,16.37-.8A45.48,45.48,0,0,0,85.17,83.63,35,35,0,0,0,93.25,69a33.11,33.11,0,0,0-.91-20.54,35.21,35.21,0,0,0-9.45-14.09c-2-1.85-4.33-3.44-6.51-5.14a2,2,0,0,1-.93-2q1.81-11,3.61-22c.21-1.29.41-2.58.62-3.93Z"
              transform="translate(-3.51 0.25)"
            />
            <path
              class="cls-1"
              d="M20.66,1.3h59c-.21,1.35-.41,2.64-.62,3.93q-1.8,11-3.61,22a2,2,0,0,0,.93,2c2.18,1.7,4.47,3.29,6.51,5.14a35.21,35.21,0,0,1,9.45,14.09A33.11,33.11,0,0,1,93.25,69a35,35,0,0,1-8.08,14.62A45.48,45.48,0,0,1,60.73,97.45a50.56,50.56,0,0,1-16.37.8,47,47,0,0,1-20.1-6.8A39,39,0,0,1,7.83,72.29,32.58,32.58,0,0,1,6,55.14a35.27,35.27,0,0,1,11.45-21c1.87-1.72,4-3.17,6-4.7A2.3,2.3,0,0,0,24.52,27q-1.34-8.7-2.63-17.41C21.48,6.87,21.08,4.13,20.66,1.3Zm23,25.46c-1.16.21-2.22.36-3.25.6A41,41,0,0,0,20.09,38.28a32.54,32.54,0,0,0-8.94,15.6,30.41,30.41,0,0,0,.33,15.76A33.08,33.08,0,0,0,22,85.7a41.72,41.72,0,0,0,25.19,10,43.76,43.76,0,0,0,18-2.45c8.5-3.08,15.45-8.16,20.25-15.92A30.13,30.13,0,0,0,90,58.12,31.83,31.83,0,0,0,80.4,38.36,40.9,40.9,0,0,0,58.57,27.11l-2.52-.47c0-4.37,0-8.71,0-13a6.17,6.17,0,0,0-5.49-6.42,6.08,6.08,0,0,0-6.74,6.25c-.06,3.66-.09,7.33-.13,11C43.69,25.18,43.7,25.93,43.7,26.76Z"
              transform="translate(-3.51 0.25)"
            />
            <path
              d="M43.7,26.76c0-.83,0-1.58,0-2.33,0-3.67.07-7.34.13-11a6.08,6.08,0,0,1,6.74-6.25,6.17,6.17,0,0,1,5.49,6.42c0,4.33,0,8.67,0,13l2.52.47A40.9,40.9,0,0,1,80.4,38.36,31.83,31.83,0,0,1,90,58.12a30.13,30.13,0,0,1-4.52,19.26c-4.8,7.76-11.75,12.84-20.25,15.92a43.76,43.76,0,0,1-18,2.45A41.72,41.72,0,0,1,22,85.7,33.08,33.08,0,0,1,11.48,69.64a30.41,30.41,0,0,1-.33-15.76,32.54,32.54,0,0,1,8.94-15.6A41,41,0,0,1,40.45,27.36C41.48,27.12,42.54,27,43.7,26.76Zm0,1.44c-.16,0-.24,0-.32,0l-.5.07a39.61,39.61,0,0,0-18.76,8.45C17.76,41.89,13.62,48.42,12.35,56.6a29,29,0,0,0,4,20.06c5.12,8.39,12.7,13.61,22,16.28a42,42,0,0,0,26.17-.82A37,37,0,0,0,81.74,80a29.58,29.58,0,0,0,6.42-22.78C87.09,49,83,42.35,76.76,37a37.92,37.92,0,0,0-14.18-7.59c-2-.59-4.11-1-6.29-1.52,0,.49,0,.76,0,1,0,1.17.08,2.35.08,3.52,0,2.31-1,3.57-3.25,3.82a26.21,26.21,0,0,1-5.6.06c-2.68-.28-3.79-1.69-3.86-4.39,0-.5,0-1,0-1.51Zm1.87-10.7c0,.25,0,.44,0,.63,0,3.38-.09,6.77-.13,10.15,0,1.26,0,2.52,0,3.78,0,2.07.7,2.88,2.75,3.09a22.56,22.56,0,0,0,4.19,0c1.89-.17,2.41-.82,2.38-2.73-.08-4.81-.18-9.62-.27-14.44a3.15,3.15,0,0,0-.07-.47,12.28,12.28,0,0,1-1.54,1,1,1,0,0,0-.64,1.11c0,3.19,0,6.38,0,9.57a1.2,1.2,0,0,1-1.27,1.31c-.53,0-1.06,0-1.6,0A1.2,1.2,0,0,1,48,29.13c0-3,0-5.93,0-8.9,0-.91-.15-1.51-1.1-1.81A6.82,6.82,0,0,1,45.53,17.5Zm4.21,6.27c0,1.62,0,3.24,0,4.86,0,.19.21.36.33.55a2.55,2.55,0,0,0,.36-.52,1.86,1.86,0,0,0,0-.58c0-3,0-6,0-9a1.38,1.38,0,0,1,.83-1.4,4.75,4.75,0,0,0,3-5.33A4.31,4.31,0,0,0,47.67,9.1a4.66,4.66,0,0,0-2.11,4.48,4.51,4.51,0,0,0,3,4.09,1.57,1.57,0,0,1,1.19,1.74C49.68,20.86,49.73,22.31,49.74,23.77Z"
              transform="translate(-3.51 0.25)"
            />
            <path
              class="cls-1"
              d="M43.66,28.2v2.25c0,.5,0,1,0,1.51.07,2.7,1.18,4.11,3.86,4.39a26.21,26.21,0,0,0,5.6-.06c2.28-.25,3.24-1.51,3.25-3.82,0-1.17,0-2.35-.08-3.52,0-.28,0-.55,0-1,2.18.52,4.26.93,6.29,1.52A37.92,37.92,0,0,1,76.76,37C83,42.35,87.09,49,88.16,57.22A29.58,29.58,0,0,1,81.74,80a37,37,0,0,1-17.2,12.12,42,42,0,0,1-26.17.82c-9.31-2.67-16.89-7.89-22-16.28a29,29,0,0,1-4-20.06c1.27-8.18,5.41-14.71,11.73-19.92a39.61,39.61,0,0,1,18.76-8.45l.5-.07C43.42,28.16,43.5,28.18,43.66,28.2Zm6.57,23.06a9,9,0,0,0,5.11-1.59c2.95-2.07,3.13-5.53.39-7.87a9.32,9.32,0,0,0-11.14-.14c-3,2.45-2.76,6.11.53,8.21A9.65,9.65,0,0,0,50.23,51.26Z"
              transform="translate(-3.51 0.25)"
            />
            <path
              class="cls-1"
              d="M45.53,17.5a6.82,6.82,0,0,0,1.43.92c.95.3,1.12.9,1.1,1.81-.05,3,0,5.93,0,8.9a1.2,1.2,0,0,0,1.25,1.33c.54,0,1.07,0,1.6,0a1.2,1.2,0,0,0,1.27-1.31c0-3.19,0-6.38,0-9.57a1,1,0,0,1,.64-1.11,12.28,12.28,0,0,0,1.54-1,3.15,3.15,0,0,1,.07.47c.09,4.82.19,9.63.27,14.44,0,1.91-.49,2.56-2.38,2.73a22.56,22.56,0,0,1-4.19,0c-2.05-.21-2.7-1-2.75-3.09,0-1.26,0-2.52,0-3.78,0-3.38.09-6.77.13-10.15C45.49,17.94,45.51,17.75,45.53,17.5Z"
              transform="translate(-3.51 0.25)"
            />
            <path
              class="cls-1"
              d="M49.74,23.77c0-1.46-.06-2.91,0-4.36a1.57,1.57,0,0,0-1.19-1.74,4.51,4.51,0,0,1-3-4.09A4.66,4.66,0,0,1,47.67,9.1a4.31,4.31,0,0,1,6.58,3.28,4.75,4.75,0,0,1-3,5.33,1.38,1.38,0,0,0-.83,1.4c0,3,0,6,0,9a1.86,1.86,0,0,1,0,.58,2.55,2.55,0,0,1-.36.52c-.12-.19-.33-.36-.33-.55C49.72,27,49.74,25.39,49.74,23.77Z"
              transform="translate(-3.51 0.25)"
            />
            <path
              d="M50.23,51.26a9.65,9.65,0,0,1-5.11-1.39c-3.29-2.1-3.55-5.76-.53-8.21a9.32,9.32,0,0,1,11.14.14c2.74,2.34,2.56,5.8-.39,7.87A9,9,0,0,1,50.23,51.26ZM49.31,50a7.39,7.39,0,0,0,5.55-1.81,3.58,3.58,0,0,0,0-5.18,7.17,7.17,0,0,0-9.62,0,3.59,3.59,0,0,0,.09,5.24A6.42,6.42,0,0,0,49.31,50Z"
              transform="translate(-3.51 0.25)"
            />
            <path
              class="cls-1"
              d="M49.31,50a6.42,6.42,0,0,1-4-1.75A3.59,3.59,0,0,1,45.26,43a7.17,7.17,0,0,1,9.62,0,3.58,3.58,0,0,1,0,5.18A7.39,7.39,0,0,1,49.31,50Z"
              transform="translate(-3.51 0.25)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
