import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Smoke-Detector-V2",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Smoke Detector V2",
    description: "Smoke-Detector-V2",
    image: require("./Smoke-Detector-V2.svg"),
    svgIcon: require("./Smoke-Detector-V2.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.smokedetector2cls1{fill:#da2727;}.smokedetector2cls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
          <path class="smokedetector2cls1" d="M50.17,1A49.12,49.12,0,1,1,1,50.06,49.09,49.09,0,0,1,50.17,1ZM82.66,82.3a.25.25,0,0,0,0-.09l-32.05-32-.2-.18h-.08L17.78,82.51C35.35,100.37,65.07,100.41,82.66,82.3Zm-.38-64.81C64.3-.54,35,.34,18.16,17.3L50.31,49.46Z" transform="translate(-1.01 -0.96)"/>
    <path class="smokedetector2cls2" d="M82.66,82.3c-17.59,18.11-47.31,18.07-64.88.21L50.29,50h.08l.2.18L82.62,82.21A.25.25,0,0,1,82.66,82.3Z" transform="translate(-1.01 -0.96)"/>
    <path class="smokedetector2cls2" d="M82.28,17.49l-32,32L18.16,17.3C35,.34,64.3-.54,82.28,17.49Z" transform="translate(-1.01 -0.96)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
