import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Exit-Up",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Exit Up",
    description: "Exit-Up",
    image: require("./Exit-Up.svg"),
    svgIcon: require("./Exit-Up.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.exitup-cls1{fill:#009f4d;}.exitup-cls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="exitup-cls1"
              d="M31.52,73.17H0V23.94H97.87c.12,0,.24,0,.36,0l.26,0V72.68c0,.16,0,.32,0,.49H62.72q1-2.42,2-4.83a2.54,2.54,0,0,0,.2-1V28.56c0-.12,0-.24,0-.36a1.75,1.75,0,0,0,0-.24l-.12,0H34l-.16,0,0,0-.06.07V54.77h-.6A1.9,1.9,0,0,0,31.26,56a3.16,3.16,0,0,0-.11,2.52,2,2,0,0,0,2.09,1.46h.48c0,.12,0,.2,0,.27,0,2.4,0,4.79,0,7.19a1.52,1.52,0,0,1-.1.47l-1.32,3.25C32.05,71.8,31.79,72.49,31.52,73.17ZM92.39,50.4l.08,0c0-.1,0-.19,0-.29,0-1.91,0-3.83,0-5.74a.64.64,0,0,0-.21-.49c-.69-.67-1.37-1.36-2-2l-8-7.95-.32-.3-.38.37q-4.8,4.79-9.6,9.57a1.66,1.66,0,0,0-.55,1.34c0,1.73,0,3.46,0,5.18,0,.12,0,.26.09.39l.91-.91c.29-.29.59-.58.88-.88l.91-.9.88-.88.91-.91L76.9,45l.9-.9.89-.88c.3-.3.58-.61.9-.89l.07,0V63.12c0,.13,0,.27,0,.41h4.52c0-.19,0-.36,0-.53V42.94c0-.12,0-.24,0-.36a2.16,2.16,0,0,1,0-.25ZM8.28,47.79l-.4,0H4.7l-.4,0V46H6.54c.75,0,1.51,0,2.28,0,0-.57,0-1.09,0-1.61-.4-.08-6.34-.07-6.58,0-.08.44-.05,8.45,0,8.71H8.69C9,53,9,53,9,52.74S9,52,9,51.59A1.48,1.48,0,0,0,9,51.35H4.28c0-.66,0-1.28,0-1.93,1.34,0,2.65,0,4,0Zm9.8-3.49-.33,0c-.54,0-1.07,0-1.61,0a.5.5,0,0,0-.46.24c-.51.76-1,1.5-1.55,2.24-.06.09-.13.18-.22.29l-.24-.33-1.55-2.19a.5.5,0,0,0-.46-.25c-.57,0-1.13,0-1.7,0l-.36,0c1,1.45,2,2.85,3,4.27l-.54.75L9.65,52.69c-.06.09-.18.17-.12.33h.29c.55,0,1.1,0,1.64,0a.57.57,0,0,0,.54-.29c.54-.81,1.09-1.61,1.64-2.41l.22-.29.24.34,1.66,2.4a.51.51,0,0,0,.46.25c.58,0,1.16,0,1.74,0l.33,0-.23-.36-2.15-3-.71-1a.23.23,0,0,1,0-.35l.11-.16,2.54-3.5Zm4.06,0V46l.42,0h1.91l.4,0c0,.17,0,.31,0,.45v6.07c0,.16,0,.32,0,.48h2c0-.17,0-.32,0-.47v-6.1c0-.14,0-.28,0-.44l.42,0h1.9c.14,0,.27,0,.42,0,0-.57,0-1.1,0-1.65Zm-3.07,0c-.08.46-.05,8.47,0,8.74.65,0,1.31,0,2,0V44.29Z"
              transform="translate(0 -23.94)"
            />
            <path
              class="exitup-cls2"
              d="M31.6,73.06c.26-.68.53-1.36.8-2l1.3-3.24a1.34,1.34,0,0,0,.11-.47V60.16c0-.07,0-.15,0-.26l-.48,0a2,2,0,0,1-2.08-1.45,3.05,3.05,0,0,1,.11-2.51,1.87,1.87,0,0,1,1.87-1.17h.6V28.11l.06-.06,0,0,.16,0H64.66l.11,0c0,.08,0,.17,0,.25V67.24a2.46,2.46,0,0,1-.2,1c-.66,1.6-1.3,3.21-2,4.81Zm26.5-26.3-.3-.29c-1.27-1.27-2.55-2.54-3.81-3.82a1.94,1.94,0,0,0-1.47-.6c-2.08,0-4.16,0-6.24,0a1.67,1.67,0,0,0-1.36.63c-1.5,1.75-3,3.47-4.53,5.21a1.32,1.32,0,0,0,2,1.78c.16-.16.31-.34.46-.51L46.44,45a.58.58,0,0,1,.51-.22h1.79l.36,0c-.09.13-.14.21-.2.28L40,55.35a.63.63,0,0,1-.53.23H33.41a1.69,1.69,0,0,0-1.66,1.68A1.71,1.71,0,0,0,33.43,59q3.84,0,7.68,0a2.6,2.6,0,0,0,2.07-.93c1-1.18,2-2.31,3.09-3.46a3.31,3.31,0,0,1,.26-.27l.14.1,3.94,3.82a.38.38,0,0,1,.1.41c-.15.64-.29,1.27-.43,1.91-.41,1.83-.83,3.67-1.24,5.51a1.71,1.71,0,0,0,3.23,1.06,4,4,0,0,0,.17-.52Q53,64,53.59,61.48c.32-1.43.65-2.86,1-4.29A2,2,0,0,0,54,55.24c-1.09-1.06-2.16-2.15-3.23-3.23-.1-.09-.18-.18-.3-.31l4-4.69c.12.12.21.19.29.28l1.36,1.36,1,1a1.38,1.38,0,0,0,1.82.17,2.93,2.93,0,0,0,.48-.39l4-4,.13-.14A1.38,1.38,0,0,0,62.9,43a1.34,1.34,0,0,0-1.41.37l-3.07,3.07ZM56,37.39A2.65,2.65,0,0,0,53.32,40a2.68,2.68,0,1,0,5.35,0A2.67,2.67,0,0,0,56,37.39Z"
              transform="translate(0 -23.94)"
            />
            <path
              class="exitup-cls2"
              d="M98.3,73.06c0-.16,0-.32,0-.49V24.1l-.26,0H.73L.21,24s0-.06,0-.06H98.36V72.77C98.36,72.86,98.43,73,98.3,73.06Z"
              transform="translate(0 -23.94)"
            />
            <path
              class="exitup-cls2"
              d="M92.2,50.39l-8-8a2.24,2.24,0,0,0-.05.26c0,.12,0,.24,0,.36q0,10,0,20c0,.17,0,.35,0,.53h-4.5a2.85,2.85,0,0,1,0-.41V42.42l-.07,0c-.31.28-.6.58-.9.88s-.59.58-.88.88l-.9.9-.88.87-.9.91-.88.87-.9.9-.88.88-.91.9c-.14-.12-.09-.26-.09-.38,0-1.72,0-3.44,0-5.16a1.69,1.69,0,0,1,.55-1.34c3.2-3.16,6.38-6.35,9.56-9.53l.38-.36c.11.1.22.19.31.29l7.94,7.92c.68.68,1.35,1.36,2,2a.6.6,0,0,1,.21.49v5.72a2.64,2.64,0,0,1,0,.28Z"
              transform="translate(0 -23.94)"
            />
            <path
              class="exitup-cls2"
              d="M8.45,47.79v1.6c-1.32,0-2.62,0-4,0,0,.64,0,1.26,0,1.92H9.12a1.48,1.48,0,0,1,.05.24q0,.57,0,1.14c0,.27,0,.29-.31.29H2.48c-.09-.26-.11-8.24,0-8.67.24-.07,6.15-.08,6.56,0,0,.52,0,1.05,0,1.61-.77.05-1.52,0-2.26,0H4.49v1.78l.4,0H8.06Z"
              transform="translate(0 -23.94)"
            />
            <path
              class="exitup-cls2"
              d="M18.22,44.31c-.1.15-.16.26-.24.36l-2.52,3.5a1.58,1.58,0,0,1-.12.15.25.25,0,0,0,0,.35l.71,1,2.14,3c.08.1.14.21.24.36l-.34,0c-.57,0-1.15,0-1.73,0a.48.48,0,0,1-.45-.25l-1.66-2.39c-.06-.1-.13-.19-.23-.33-.09.11-.16.19-.22.28-.55.8-1.11,1.6-1.64,2.41a.56.56,0,0,1-.53.28c-.55,0-1.09,0-1.64,0H9.7c-.06-.16.06-.24.12-.32l2.44-3.36c.17-.25.35-.49.54-.75-1-1.42-2-2.8-3-4.25l.36,0c.56,0,1.13,0,1.69,0a.49.49,0,0,1,.46.24c.51.74,1,1.46,1.54,2.19l.24.33.23-.29c.51-.74,1-1.48,1.54-2.23a.49.49,0,0,1,.45-.24c.54,0,1.07,0,1.6,0Z"
              transform="translate(0 -23.94)"
            />
            <path
              class="exitup-cls2"
              d="M22.25,44.31h7.6c0,.54,0,1.07,0,1.64-.14,0-.28,0-.41,0h-1.9l-.41,0c0,.16,0,.3,0,.44v6.08c0,.14,0,.29,0,.46H25c0-.16,0-.32,0-.48v-6c0-.14,0-.28,0-.45l-.41,0H22.68l-.43,0Z"
              transform="translate(0 -23.94)"
            />
            <path
              class="exitup-cls2"
              d="M19.2,44.31h2V53c-.68,0-1.34,0-2,0C19.15,52.74,19.12,44.77,19.2,44.31Z"
              transform="translate(0 -23.94)"
            />
            <path
              class="exitup-cls1"
              d="M58.1,46.76l.32-.3,3.07-3.07A1.34,1.34,0,0,1,62.9,43a1.38,1.38,0,0,1,.64,2.19l-.13.14-4,4a2.93,2.93,0,0,1-.48.39,1.38,1.38,0,0,1-1.82-.17l-1-1-1.36-1.36c-.08-.09-.17-.16-.29-.28l-4,4.69c.12.13.2.22.3.31,1.07,1.08,2.14,2.17,3.23,3.23a2,2,0,0,1,.57,1.95c-.31,1.43-.64,2.86-1,4.29Q53,64,52.44,66.61a4,4,0,0,1-.17.52A1.71,1.71,0,0,1,49,66.07c.41-1.84.83-3.68,1.24-5.51.14-.64.28-1.27.43-1.91a.38.38,0,0,0-.1-.41l-3.94-3.82-.14-.1a3.31,3.31,0,0,0-.26.27c-1,1.15-2.09,2.28-3.09,3.46a2.6,2.6,0,0,1-2.07.93q-3.84,0-7.68,0a1.71,1.71,0,0,1-1.68-1.74,1.69,1.69,0,0,1,1.66-1.68h6.08a.63.63,0,0,0,.53-.23l8.88-10.3c.06-.07.11-.15.2-.28l-.36,0H47a.58.58,0,0,0-.51.22l-3.63,4.21c-.15.17-.3.35-.46.51a1.32,1.32,0,0,1-2-1.78c1.51-1.74,3-3.46,4.53-5.21a1.67,1.67,0,0,1,1.36-.63c2.08,0,4.16,0,6.24,0a1.94,1.94,0,0,1,1.47.6c1.26,1.28,2.54,2.55,3.81,3.82Z"
              transform="translate(0 -23.94)"
            />
            <path
              class="exitup-cls1"
              d="M56,37.39a2.67,2.67,0,0,1,2.68,2.66,2.68,2.68,0,1,1-5.35,0A2.65,2.65,0,0,1,56,37.39Z"
              transform="translate(0 -23.94)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
