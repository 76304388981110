import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import State from "../viewer2d/state";
const  AppLogo  = require('../../assets/app-logo.png');
import { ReactSVGPanZoom, setPointOnViewerCenter } from "react-svg-pan-zoom";
import { Catalog } from "../../catalog/catalog";
import { provideAreaOfPolygon } from "../../utils/geometry";
import { getFloorPlan } from "../../api/service";
import { loadProject, setMode } from "../../actions/project-actions";
import { MODE_IDLE, VIEW_ONLY } from "../../constants";
import { List } from "immutable";
import { confirmAlert } from "react-confirm-alert";
import Modal from "react-responsive-modal";
const ViewOnly = ({ state, width, height, catalog }, { store }) => {
  let defaultState = {
    a: 0.5,
    b: 0,
    SVGWidth: 30000,
    c: 0,
    mode: "idle",
    d: 0.5,
    e: -6814,
    f: -3505,
    miniatureOpen: true,
    SVGHeight: 20000,
    pinchPointDistance: null,
    scaleFactorMax: 5,
    lastAction: null,
    viewerWidth: 1336,
    startX: null,
    startY: null,
    version: 2,
    focus: false,
    scaleFactorMin: 0.14,
    viewerHeight: 727.2000122070312,
    prePinchMode: null,
    endX: null,
    endY: null,
  };
  const [isFloorPlan, setIsFloorPlan] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const floorId = searchParams.get("floorId");
  const token = searchParams.get("token");
  useEffect(() => {
    if (floorId && token) {
      getFloorPlan(floorId, token)
        .then((res) => {
          if (res.data.data && res.data.data.floorplan) {
            store.dispatch(loadProject(res.data.data.floorplan, VIEW_ONLY));
            setIsFloorPlan(true);
          }
        })
        .catch(() => {
          confirmAlert({
            overlayClassName: "overlay-dialog",
            title: "Error occured",
            message: "Unable to find requested floorplan",

            buttons: [
              {
                label: "Visit portal",
                onClick: () => {
                  window.location.href =
                    "http://frawizard-portal.s3-website-us-east-1.amazonaws.com/";
                  return;
                },
              },
            ],
          });
        });
    }
  }, [floorId, token]);
  const [viewerState, setViewerState] = useState(defaultState);

  let onChangeValue = (value) => {
    let valueE = value.e;
    let valueF = value.f;
    if (value.e >= -10 || value.e <= -2930) {
      valueE = value.e;
    }
    if (value.f >= 0 || value.f <= -2000) {
      valueF = value.f;
    }
    setViewerState({ ...value, a: value.a, e: valueE, f: valueF });
    // projectActions.updateZoomScale(value.a);
  };

  let selectedLayer = state.getIn(["scene", "selectedLayer"]);
  let layer = state.getIn(["scene", "layers", selectedLayer]);
  let areas = state.getIn(["scene", "layers", selectedLayer, "areas"]);
  let safetysymbols = layer
    .getIn(["items"])
    .filter((item) => item.type.includes("firesafety"));
  safetysymbols = safetysymbols.size
    ? safetysymbols.reduce((acc, current) => {
        if (!acc.some((item) => item.type === current.type)) {
          acc = acc.push(current);
        }
        return acc;
      }, new List())
    : safetysymbols;

  let evacuationPlan = state.get("evacuationPlan");

  return (
    <div width={width} height={height} style={{ overflow: "auto", flex: 1 }}>
      {isFloorPlan ? (
        evacuationPlan ? (
          <div>
            <div style={{ textAlign: "center", background: "#08b763" }}>
              <p
                style={{
                  fontSize: "31px",
                  fontWeight: "800",
                  margin: "0",
                  color: "white",
                }}
              >
                FIRE EVACUATION PLAN
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: "15%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ background: "#2196f3", color: "white", flex: 1 }}>
                  <p style={{ padding: 10, margin: 0, fontSize: "12px" }}>
                    <span>
                      <u>
                        <b>IN CASE OF FIRE</b>
                      </u>
                    </span>{" "}
                    <br />
                    <br />
                    Immediately raise the alarm by operating the nearest RED
                    break glass call point and:
                    <ul style={{ paddingLeft: 20 }}>
                      <li>
                        Dial 999 to confirm the location and details of the fire
                      </li>
                      <li>
                        Close all doors and windows in the immediate vicinity{" "}
                      </li>
                      <li>
                        Commence evacuation using the nearest, safest evacuation
                        route.
                      </li>
                    </ul>
                  </p>
                </div>
                <div style={{ background: "#08b763", color: "white", flex: 3 }}>
                  <p style={{ padding: 10, margin: 0, fontSize: "12px" }}>
                    <br />
                    <p style={{ margin: 0 }}>
                      <b>ON HEARING THE FIRE ALARM</b>
                    </p>
                    <br />
                    <span>
                      <u>
                        <b>A Continuous Sound Means</b>
                      </u>
                    </span>
                    <br />
                    <br />
                    The alarm has been activated in your area. Investigate your
                    area and evacuate as necessary.
                    <br />
                    <br />
                    <span>
                      <u>
                        <b>An Intermittent Sound Means</b>
                      </u>
                    </span>
                    <br />
                    <br />
                    The alarm was activated in the adjacent area. Check your
                    area for signs of a fire. Identify the location of the alarm
                    zone and prepare your area as necessary in case of emergency
                    evacuation.
                  </p>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    background: "#da2727",
                    color: "white",
                    flex: 1,
                  }}
                >
                  <p style={{ padding: 10, margin: 0, fontSize: "12px" }}>
                    <br />
                    <p style={{ margin: 0 }}>
                      <b>EMERGENCY NUMBER</b>
                    </p>
                    <br />
                    <p style={{ margin: 0, fontSize: 24 }}>
                      <b>999</b>
                    </p>
                  </p>
                </div>
              </div>
              <div style={{ flex: "85%" }}>
                <ReactSVGPanZoom
                  value={viewerState}
                  onChangeValue={onChangeValue}
                  tool="auto"
                  preventPanOutside={true}
                  disableDoubleClickZoomWithToolAuto={true}
                  detectAutoPan={false}
                  scaleFactorMax={5}
                  scaleFactorMin={0.14}
                  miniaturePosition="none"
                  toolbarPosition="none"
                  width={"100%"}
                  height={"80vh"}
                >
                  <svg>
                    <g
                      style={
                        {
                          // strokeLinejoin: "round",
                        }
                      }
                    >
                      <State
                        state={state}
                        catalog={catalog}
                        showGuides={false}
                      />
                    </g>
                  </svg>
                </ReactSVGPanZoom>

                <div style={{ textAlign: "center" }}>
                  <p style={{ margin: 0 }}>{layer.get("name").toUpperCase()}</p>
                  <p style={{ margin: 0 }}>
                    {parseFloat(
                      areas
                        .toArray()
                        .reduce(
                          (accumulator, area) =>
                            parseFloat(accumulator) +
                            parseFloat(provideAreaOfPolygon(area, layer)),
                          0.0
                        )
                    ).toFixed(2)}
                    m{String.fromCharCode(0xb2)}({" "}
                    {parseFloat(
                      areas
                        .toArray()
                        .reduce(
                          (accumulator, area) =>
                            parseFloat(accumulator) +
                            parseFloat(provideAreaOfPolygon(area, layer)),
                          0.0
                        )
                    ).toFixed(2) * 10.764}
                    ft{String.fromCharCode(0xb2)}) approx.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "12vw",
                  height: "50vh",
                  overflow: "auto",
                  position: "absolute",
                  bottom: "50px",
                  right: "10px",
                  border: "1px solid",
                  padding: "10px",
                  backgroundColor: "white",
                }}
              >
                <p style={{ textAlign: "center" }}>Legends</p>
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                  }}
                >
                  {safetysymbols.valueSeq().map((symbol) => (
                    <div style={{ display: "flex" }}>
                      <img
                        src={require(`../../assets/firesafety/${symbol.type.replace(
                          "firesafety-",
                          ""
                        )}/legend.png`)}
                        alt=""
                        style={{ width: "30%" }}
                      />
                      <p style={{ fontSize: "12px", paddingLeft: "5px" }}>
                        {symbol.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            <div>
              <ReactSVGPanZoom
                value={viewerState}
                onChangeValue={onChangeValue}
                tool="auto"
                preventPanOutside={true}
                disableDoubleClickZoomWithToolAuto={true}
                detectAutoPan={false}
                scaleFactorMax={5}
                scaleFactorMin={0.14}
                miniaturePosition="none"
                toolbarPosition="none"
                width={"100%"}
                height={"80vh"}
              >
                <svg>
                  <g>
                    <State state={state} catalog={catalog} showGuides={false} />
                  </g>
                </svg>
              </ReactSVGPanZoom>
              <div
                style={{
                  width: "12vw",
                  height: "50vh",
                  overflow: "auto",
                  position: "absolute",
                  bottom: "50px",
                  right: "10px",
                  border: "1px solid",
                  padding: "10px",
                  backgroundColor: "white",
                }}
              >
                <p style={{ textAlign: "center" }}>Legends</p>
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                  }}
                >
                  {safetysymbols.valueSeq().map((symbol) => (
                    <div style={{ display: "flex" }}>
                      <img
                        src={require(`../../assets/firesafety/${symbol.type.replace(
                          "firesafety-",
                          ""
                        )}/legend.png`)}
                        alt=""
                        style={{ width: "30%" }}
                      />
                      <p style={{ fontSize: "12px", paddingLeft: "5px" }}>
                        {symbol.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div style={{ textAlign: "center" }}>
              <p style={{ margin: 0 }}>{layer.get("name").toUpperCase()}</p>
              <p style={{ margin: 0 }}>
                {parseFloat(
                  areas
                    .toArray()
                    .reduce(
                      (accumulator, area) =>
                        parseFloat(accumulator) +
                        parseFloat(provideAreaOfPolygon(area, layer)),
                      0.0
                    )
                ).toFixed(2)}
                m{String.fromCharCode(0xb2)}({" "}
                {parseFloat(
                  areas
                    .toArray()
                    .reduce(
                      (accumulator, area) =>
                        parseFloat(accumulator) +
                        parseFloat(provideAreaOfPolygon(area, layer)),
                      0.0
                    )
                ).toFixed(2) * 10.764}
                ft{String.fromCharCode(0xb2)}) approx.
              </p>
            </div>
          </Fragment>
        )
      ) : (
        <Modal
          showCloseIcon={false}
          styles={{ width: "350px", height: "350px" }}
          center
          open={true}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img src={AppLogo} alt="" height={80} />
            Please wait while your project is loading...
            <div class="loader-container">
              <div class="loader"></div>
            </div>
          </div>
        </Modal>
      )}

      <div></div>
    </div>
  );
};
ViewOnly.propTypes = {
  catalog: PropTypes.instanceOf(Catalog),
};
ViewOnly.contextTypes = {
  store: PropTypes.object.isRequired,
};
export default ViewOnly;
