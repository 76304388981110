import React, { Fragment, useEffect, useState } from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "stairs-with-landing",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Stairs with a Landing",
    description: "",
    image: require("./Stairs-with-landing-icon.svg"),
    svgIcon: require("./Stairs-with-landing-icon.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    arrow: {
      label: "Arrow Text",
      type: "dropdown",
      defaultValue: "up",
      options: ["up", "down"],
    },
    steps: {
      label: "Number Of Steps",
      type: "stairs-slider",
      min: 1,
      max: 25,
      defaultValue: 10,
    },
    arrowDirection: {
      label: "Arrow Direction",
      type: "dropdown",
      defaultValue: "upward",
      options: ["upward", "downward"],
    },
  },

  render2D: function (element, layer, scene, properties) {
    //  
    let ArrowDirection = element.properties.get("arrowDirection");
    let angle = element.rotation + 90;
    let textRotation = 0;
    if (Math.sin((angle * Math.PI) / 180) < 0) {
      textRotation = 180;
    }
    let { steps } = element.properties.toJS();
    const rect = (
      <rect class="rectcls-2" x="0.81" y="1.03" width="43.34" height="8.95" />
    );
    const [rectangles, setRectangles] = useState([rect]);
    useEffect(() => {
      if (steps) {
        setRectangles(replicateRect(steps));
      }
    }, [steps]);

    //  

    return {
      element: (
        <g
          transform={`translate(-22.6,${
            -parseFloat(rectangles[rectangles.length - 1].props.y) + 30
          })`}
        >
          <defs>
            <style>{`.rectcls-1{fill:#050505;}.rectcls-2{fill:#fff;}`}</style>
          </defs>
          <title>straight-stairs</title>
          <g id="Stairs">
            <g
              id={`${element.type}-${element.id}_Group_`}
              data-name="&lt;Group&gt;"
            >
              <rect
                class="rectcls-1"
                width="45.28"
                transform={ `translate(0,-10)`}
                height={
                  Math.abs(
                    parseFloat(rectangles[rectangles.length - 1].props.y) + 68
                  )
                }
              />

              <polygon
               transform={ `translate(0,${
                parseFloat(rectangles[rectangles.length - 1].props.y) + 9.00
              })`}
                class="rectcls-2"
                points="44.2 1.06 44.2  10.44 44.2  10.44 44.2  47.91 0.81 47.91 0.81 10.44 0.84 10.44 0.84 1.06 44.2  1.06"
              />
              {rectangles.map((rect) => rect)}
            </g>

            {ArrowDirection === "downward" && (
              <Fragment>
                <path
                  d="M49,.89H45.93c0,4.59,0,16.35,0,20.94a26.15,26.15,0,0,1-2.76,0s4.06,10.71,5.91,15.39C50.91,32.54,55,21.83,55,21.83a26,26,0,0,1-2.75,0c0-4.59,0-16.35,0-20.94H49Z"
                  style={{
                    transform:
                      rectangles.length <= 3
                        ? `translate(-2px, -10px) scale(0.5,0.5)`
                        : `translate(-26.61px,-10px) `,
                  }}
                />
                <text
                  key="5"
                  x="0"
                  y="0"
                  transform={`translate(22, -22) scale(1,-1) rotate(${textRotation})`}
                  style={{ textAnchor: "middle", fontSize: "11px" }}
                >
                  {element.properties.get("arrow")}
                </text>
              </Fragment>
            )}

            {ArrowDirection === "upward" && (
              <Fragment>
                <text
                  key="5"
                  x="0"
                  y="0"
                  transform={`translate(22, ${
                    parseFloat(rectangles[rectangles.length - 1].props.y) + 68
                  }) scale(1,-1) rotate(${textRotation})`}
                  style={{ textAnchor: "middle", fontSize: "11px" }}
                >
                  {element.properties.get("arrow")}
                </text>
                <path
                  d="M49.07,98.9h3.12c0-4.59,0-16.35,0-20.94A26,26,0,0,1,55,78s-4.05-10.71-5.9-15.39C47.21,67.25,43.15,78,43.15,78a26.15,26.15,0,0,1,2.76,0c0,4.59,0,16.35,0,20.94h3.14Z"
                  style={{
                    transform:
                      rectangles.length <= 3
                        ? `translate(-2px, ${parseFloat(rectangles[rectangles.length - 1].props.y)+8}px) scale(0.5,0.5)`
                        : `translate(-26.61px, ${parseFloat(rectangles[rectangles.length - 1].props.y) - 42}px) `,
                  }}
                />
              </Fragment>
            )}

            {/* <path
              d="M49,.89H45.93c0,4.59,0,16.35,0,20.94a26.15,26.15,0,0,1-2.76,0s4.06,10.71,5.91,15.39C50.91,32.54,55,21.83,55,21.83a26,26,0,0,1-2.75,0c0-4.59,0-16.35,0-20.94H49Z"
              // transform={`translate(-43.17px,${
              //   parseFloat(rectangles[rectangles.length - 1].props.y) + 10 + 100
              // }px)) `}
            /> */}
            {/* <path
              d="M49.07,98.9h3.12c0-4.59,0-16.35,0-20.94A26,26,0,0,1,55,78s-4.05-10.71-5.9-15.39C47.21,67.25,43.15,78,43.15,78a26.15,26.15,0,0,1,2.76,0c0,4.59,0,16.35,0,20.94h3.14Z"
              // style={{
              //   transform: `translate(${-25}px,${
              //     parseFloat(rectangles[rectangles.length - 1].props.y) +
              //     10 -
              //     100
              //   }px)`,
              // }}
            /> */}
          </g>
        </g>
      ),
      transform: { width: -WIDTH / 2, depth: -DEPTH / 2 },
    };
  },

  render3D: function (element, layer, scene) {},
};

function replicateRect(countingState) {
  let elements = [];

  for (let i = 0; i < countingState; i++) {
    let newRect = (
      <rect
        class="rectcls-2"
        x="0.81"
        y={i === 0 ? "-9.0" : parseFloat(elements[i - 1].props.y) + 9.74}
        width="43.34"
        height="8.95"
      />
    );
    elements.push(newRect);
  }

  return elements;
}
