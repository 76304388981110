import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-direction-diagonal-bottom-left",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "direction diagonal bottom left",
    description: "direction-diagonal-bottom-left",
    image: require("./direction-diagonal-bottom-left.svg"),
    svgIcon: require("./direction-diagonal-bottom-left.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.directionBLcls1{fill:#009f4c;}.directionBLcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="directionBLcls1"
              d="M6.34,4.8h86V90.86l-.4,0H6.55c-.13,0-.2,0-.2-.19V5.31C6.35,5.14,6.34,5,6.34,4.8ZM42.12,63.31l0-.06.27-.28L80,25.32l.29-.29.1-.15c-.25-.35-7.94-8-8.17-8.12L33.85,55.15V25.91c0-.1,0-.21,0-.39-.16.14-.26.22-.35.31Q28,31.29,22.55,36.74a.88.88,0,0,0-.28.68q0,18.49,0,37c0,.56,0,.48.49.48h37a.9.9,0,0,0,.7-.3c1.79-1.81,3.6-3.61,5.41-5.42l5.48-5.48.28-.31a.84.84,0,0,0-.48-.07h-29Z"
              transform="translate(-6.23 -4.8)"
            />
            <path
              class="directionBLcls2"
              d="M6.34,4.8c0,.17,0,.34,0,.51V90.65c0,.14.07.19.2.19H92l.4,0s0,.06,0,.06H6.23V4.8Z"
              transform="translate(-6.23 -4.8)"
            />
            <path
              class="directionBLcls2"
              d="M42.12,63.31h29a.84.84,0,0,1,.48.07l-.28.31-5.48,5.48c-1.81,1.81-3.62,3.61-5.41,5.42a.9.9,0,0,1-.7.3h-37c-.46,0-.49.08-.49-.48q0-18.5,0-37a.88.88,0,0,1,.28-.68Q28,31.3,33.47,25.83c.09-.09.19-.17.35-.31,0,.18,0,.29,0,.39V55.15Q53.1,35.91,72.24,16.76c.23.11,7.92,7.77,8.17,8.12l-.1.15-.29.29L42.36,63l-.27.28Z"
              transform="translate(-6.23 -4.8)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
