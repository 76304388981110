import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-direction-diagonal-top-left",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "direction diagonal top left",
    description: "direction-diagonal-top-left",
    image: require("./direction-diagonal-top-left.svg"),
    svgIcon: require("./direction-diagonal-top-left.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.directiontlcls1{fill:#0ea04b;}.directiontlcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="directiontlcls1"
              d="M92.07,4.79V90.84H6.34c0-.17,0-.34,0-.51v-85c0-.6-.05-.52.54-.52H40.27q25.67,0,51.35,0ZM71.33,32.3l0-.07c-.07-.08-.14-.17-.22-.25-1.79-1.8-3.58-3.59-5.36-5.39-.78-.78-1.55-1.57-2.33-2.36-1.08-1.08-2.16-2.15-3.24-3.24a.7.7,0,0,0-.55-.22H22.41a.16.16,0,0,0-.18.17V47q0,5.64,0,11.29a.82.82,0,0,0,.25.6l3.47,3.48q3.7,3.7,7.4,7.4a2,2,0,0,0,.21.2,1.07,1.07,0,0,0,.21.12V40.56l.06,0q6.38,6.4,12.74,12.8T59.3,66.1c4.26,4.25,8.48,8.55,12.77,12.79l.24-.21,7.6-7.6c.1-.1.19-.22.29-.34l-.28-.31-8.58-8.6-9-9-8.58-8.61q-4.48-4.49-9-9c-.88-.88-1.77-1.76-2.65-2.65A.4.4,0,0,1,42,32.3Z"
              transform="translate(-6.23 -4.72)"
            />
            <path
              class="directiontlcls2"
              d="M92.07,4.79l-.45,0q-25.68,0-51.35,0H6.9c-.59,0-.54-.08-.54.52v85c0,.17,0,.34,0,.51H6.23V4.73H91.81C91.9,4.74,92,4.67,92.07,4.79Z"
              transform="translate(-6.23 -4.72)"
            />
            <path
              class="directiontlcls2"
              d="M71.33,32.3H42a.4.4,0,0,0,.18.27c.88.89,1.77,1.77,2.65,2.65q4.47,4.5,9,9l8.58,8.61,9,9,8.58,8.6.28.31c-.1.12-.19.24-.29.34l-7.6,7.6-.24.21c-4.29-4.24-8.51-8.54-12.77-12.79s-8.5-8.51-12.74-12.77-8.49-8.53-12.74-12.8l-.06,0v29.5a1.07,1.07,0,0,1-.21-.12,2,2,0,0,1-.21-.2q-3.71-3.69-7.4-7.4l-3.47-3.48a.82.82,0,0,1-.25-.6q0-5.65,0-11.29V21a.16.16,0,0,1,.18-.17H59.67a.7.7,0,0,1,.55.22c1.08,1.09,2.16,2.16,3.24,3.24.78.79,1.55,1.58,2.33,2.36,1.78,1.8,3.57,3.59,5.36,5.39.08.08.15.17.22.25Z"
              transform="translate(-6.23 -4.72)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
