import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Sofa2",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Sofa2",
    description: "Sofa2",
    image: require("./Sofa2.svg"),
    svgIcon: require("./Sofa2.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 150,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 150,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 30) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              class="cls-1"
              d="M95.1,35.26C95,35.1,95,35.05,95,35c0-1.4,0-2.79,0-4.18,0-2-.58-2.61-2.58-2.61q-20,0-40.07,0a11.26,11.26,0,0,0-2.14.4,12,12,0,0,0-2.2-.4q-20,0-40.07,0c-.23,0-.45,0-.67,0A1.81,1.81,0,0,0,5.39,30c-.07.81,0,1.62,0,2.43s0,1.87,0,2.81l-.71.06c-2.25,0-2.72.53-2.72,2.8l0,22.43c0,.53,0,1.06,0,1.59a1.9,1.9,0,0,0,2.14,2H6.28v5.39c0,1.37,0,1.39,1.42,1.39H92.54c.17,0,.34,0,.5,0,.64,0,.91-.29.9-.92,0-1.64,0-3.29,0-4.93v-.93h1.89c1.87,0,2.49-.62,2.49-2.52l0-23.76a5.56,5.56,0,0,0-.05-.91,1.81,1.81,0,0,0-1.8-1.66C96,35.23,95.55,35.26,95.1,35.26Z"
              transform="translate(-1.91 -28.21)"
            />
            <path
              d="M95.1,35.26c.45,0,.9,0,1.34,0a1.81,1.81,0,0,1,1.8,1.66,5.56,5.56,0,0,1,.05.91l0,23.76c0,1.9-.62,2.52-2.49,2.52H93.94v.93c0,1.64,0,3.29,0,4.93,0,.63-.26,1-.9.92-.16,0-.33,0-.5,0H7.7c-1.4,0-1.42,0-1.42-1.39V64.12H4.09A1.9,1.9,0,0,1,2,62.1c0-.53,0-1.06,0-1.59l0-22.43c0-2.27.47-2.75,2.72-2.8l.71-.06c0-.94,0-1.88,0-2.81s0-1.62,0-2.43a1.81,1.81,0,0,1,1.86-1.77c.22,0,.44,0,.67,0q20,0,40.07,0a12,12,0,0,1,2.2.4,11.26,11.26,0,0,1,2.14-.4q20,0,40.07,0c2,0,2.57.58,2.58,2.61,0,1.39,0,2.78,0,4.18C95,35.05,95,35.1,95.1,35.26ZM49.73,70V39.55H11.38V40.6q0,8,0,16c0,1.73,0,3.46,0,5.19s-.73,2.34-2.36,2.35H7.17V70ZM88.81,39.56H50.65V70H93.08V64.12c-.78,0-1.53,0-2.27,0A1.84,1.84,0,0,1,88.9,62.3a8.53,8.53,0,0,1,0-1q0-10.29,0-20.57ZM28,29.05q-10.12,0-20.26,0c-1.28,0-1.58.3-1.58,1.58q0,3.25,0,6.53c0,1.24.35,1.6,1.58,1.6q20.25,0,40.5,0c1.18,0,1.54-.36,1.54-1.55q0-3.23,0-6.45c0-1.44-.27-1.71-1.7-1.71Zm44.4,9.72H92.44c1.51,0,1.72-.22,1.73-1.76,0-2.2,0-4.41,0-6.61,0-1-.33-1.35-1.36-1.35q-20.42,0-40.81,0c-1.1,0-1.38.3-1.38,1.41,0,2.2,0,4.4,0,6.61,0,1.48.21,1.69,1.69,1.7Zm-67-2.65H4.12c-1.09,0-1.39.3-1.39,1.39q0,12.21,0,24.42c0,1,.36,1.34,1.38,1.35H9c1.32,0,1.61-.29,1.61-1.63V40.49c0-.26,0-.53-.05-.76-.12-.06-.17-.1-.22-.1-1,0-2,0-2.93,0a1.87,1.87,0,0,1-2-2C5.33,37.18,5.35,36.71,5.35,36.12ZM89.73,39.6a2.66,2.66,0,0,0-.07.34q0,11,0,22c0,1,.37,1.33,1.41,1.34h4.68c1.56,0,1.77-.21,1.77-1.79l0-23.68c0-.25.09-.57,0-.74a2.72,2.72,0,0,0-1-.94,4.43,4.43,0,0,0-1.47,0c0,.39,0,.69,0,1,0,1.9-.6,2.51-2.49,2.51Z"
              transform="translate(-1.91 -28.21)"
            />
            <path
              class="cls-1"
              d="M49.73,70H7.17V64.12H9.06c1.63,0,2.35-.71,2.36-2.35s0-3.46,0-5.19q0-8,0-16V39.55H49.73Z"
              transform="translate(-1.91 -28.21)"
            />
            <path
              class="cls-1"
              d="M88.81,39.56v1.16q0,10.29,0,20.57a8.53,8.53,0,0,0,0,1,1.84,1.84,0,0,0,1.91,1.81c.74,0,1.49,0,2.27,0V70H50.65V39.56Z"
              transform="translate(-1.91 -28.21)"
            />
            <path
              class="cls-1"
              d="M28,29.05H48.05c1.43,0,1.7.27,1.7,1.71q0,3.22,0,6.45c0,1.19-.36,1.55-1.54,1.55q-20.25,0-40.5,0c-1.23,0-1.58-.36-1.58-1.6q0-3.27,0-6.53c0-1.28.3-1.58,1.58-1.58Q17.85,29,28,29.05Z"
              transform="translate(-1.91 -28.21)"
            />
            <path
              class="cls-1"
              d="M72.37,38.77H52.3c-1.48,0-1.69-.22-1.69-1.7,0-2.21,0-4.41,0-6.61,0-1.11.28-1.41,1.38-1.41q20.4,0,40.81,0c1,0,1.35.32,1.36,1.35,0,2.2,0,4.41,0,6.61,0,1.54-.22,1.76-1.73,1.76Z"
              transform="translate(-1.91 -28.21)"
            />
            <path
              class="cls-1"
              d="M5.35,36.12c0,.59,0,1.06,0,1.53a1.87,1.87,0,0,0,2,2c1,0,2,0,2.93,0,0,0,.1,0,.22.1,0,.23.05.5.05.76V61.65c0,1.34-.29,1.63-1.61,1.63H4.13c-1,0-1.38-.35-1.38-1.35q0-12.21,0-24.42c0-1.09.3-1.37,1.39-1.39Z"
              transform="translate(-1.91 -28.21)"
            />
            <path
              class="cls-1"
              d="M89.73,39.6h2.75C94.37,39.6,95,39,95,37.09c0-.3,0-.6,0-1a4.43,4.43,0,0,1,1.47,0,2.72,2.72,0,0,1,1,.94c.13.17,0,.49,0,.74l0,23.68c0,1.58-.21,1.79-1.77,1.79H91.06c-1,0-1.41-.34-1.41-1.34q0-11,0-22A2.66,2.66,0,0,1,89.73,39.6Z"
              transform="translate(-1.91 -28.21)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
