import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Washing-machine",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Washing machine",
    description: "Washing-machine",
    image: require("./Washing-machine.svg"),
    svgIcon: require("./Washing-machine.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g id={`${element.type}-${element.id}`} transform={`translate(-47, 55) scale(1,-1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M74.82,92.2c-.76,2.94-2.73,3.8-5.48,3.79Q50,95.92,30.67,96c-2.45,0-4.38-.58-5.33-3.13-.14-.37-1.06-.62-1.62-.62-5.81,0-11.62,0-17.43,0-2.38,0-2.77-.39-2.77-2.71V5.62c0-2.34.4-2.75,2.73-2.75q43.74,0,87.49,0c2.34,0,2.74.4,2.74,2.74V89.48c0,2.32-.39,2.71-2.76,2.72Zm-69-2.27H94.13V5.09H5.8Zm21.83,2.39c.63,1.74,1.94,1.54,3.11,1.54q16.14,0,32.28,0c2.3,0,4.59,0,6.89,0,1,0,2-.12,2.44-1.54Z"
            transform="translate(-3.52 -2.87)"
          />
          <path
            class="cls-1"
            d="M5.8,89.93V5.09H94.13V89.93ZM21.55,35.81a8.92,8.92,0,0,0,.35,1C26.15,45.91,30.42,55,34.64,64.09a2.58,2.58,0,0,0,2.77,1.53c9.13,0,18.26,0,27.39,0a2.91,2.91,0,0,0,3-2c4-8.72,8.13-17.41,12.21-26.12.25-.53.44-1.08.72-1.76L78.87,35c-.57,1.2-1,2.26-1.58,3.26a4.39,4.39,0,0,1-1.16,1.27c-1.24.94-2.64,1.7-3.78,2.73-1.44,1.31-3.06,1.94-4.81,1.21a21.4,21.4,0,0,1-5.47-3.07c-1.74-1.47-3.27-1.23-4.94-.31A11.27,11.27,0,0,0,55,41.79c-3,2.69-4.74,2.67-7.6-.14-2.06-2-4.45-3-7.24-1.89a12.3,12.3,0,0,0-3.37,2.36c-2.46,2.18-4.43,2.2-6.84,0-.7-.66-1.36-1.58-2.21-1.83-2.2-.63-3.11-2.24-3.83-4.14A6.27,6.27,0,0,0,23.26,35Z"
            transform="translate(-3.52 -2.87)"
          />
          <path
            class="cls-1"
            d="M27.63,92.32H72.35c-.42,1.42-1.42,1.53-2.44,1.54-2.3,0-4.59,0-6.89,0q-16.14,0-32.28,0C29.57,93.86,28.26,94.06,27.63,92.32Z"
            transform="translate(-3.52 -2.87)"
          />
          <path
            d="M21.55,35.81,23.26,35a6.27,6.27,0,0,1,.66,1.08c.72,1.9,1.63,3.51,3.83,4.14.85.25,1.51,1.17,2.21,1.83,2.41,2.23,4.38,2.21,6.84,0a12.3,12.3,0,0,1,3.37-2.36c2.79-1.14,5.18-.13,7.24,1.89,2.86,2.81,4.64,2.83,7.6.14a11.27,11.27,0,0,1,2.12-1.68c1.67-.92,3.2-1.16,4.94.31a21.4,21.4,0,0,0,5.47,3.07c1.75.73,3.37.1,4.81-1.21,1.14-1,2.54-1.79,3.78-2.73a4.39,4.39,0,0,0,1.16-1.27c.56-1,1-2.06,1.58-3.26l1.9.76c-.28.68-.47,1.23-.72,1.76C76,46.25,71.87,54.94,67.84,63.66a2.91,2.91,0,0,1-3,2c-9.13,0-18.26,0-27.39,0a2.58,2.58,0,0,1-2.77-1.53C30.42,55,26.15,45.91,21.9,36.83A8.92,8.92,0,0,1,21.55,35.81Zm53.51,7.26c-3.83,3.64-7.27,3.51-12.05.78a9.24,9.24,0,0,1-2.07-1.47c-1.32-1.35-2.43-.83-3.62.16C52,47,50.67,47,45.25,42.58a4.16,4.16,0,0,0-5.9,0c-.32.27-.61.58-.92.87-3.12,3.07-6.6,3.15-9.81.26-.38-.34-.78-.65-1.25-1-.05.3-.11.43-.07.52,3,6.46,6,12.93,9.11,19.36a2.48,2.48,0,0,0,1.87.9c8.59.06,17.18,0,25.76.06a1.94,1.94,0,0,0,2.06-1.31c1.67-3.69,3.42-7.35,5.14-11C72.52,48.5,73.79,45.79,75.06,43.07Z"
            transform="translate(-3.52 -2.87)"
          />
          <path
            class="cls-1"
            d="M75.06,43.07c-1.27,2.72-2.54,5.43-3.82,8.15-1.72,3.66-3.47,7.32-5.14,11A1.94,1.94,0,0,1,64,63.54c-8.58,0-17.17,0-25.76-.06a2.48,2.48,0,0,1-1.87-.9c-3.1-6.43-6.1-12.9-9.11-19.36,0-.09,0-.22.07-.52.47.39.87.7,1.25,1,3.21,2.89,6.69,2.81,9.81-.26.31-.29.6-.6.92-.87a4.16,4.16,0,0,1,5.9,0C50.67,47,52,47,57.32,42.54c1.19-1,2.3-1.51,3.62-.16A9.24,9.24,0,0,0,63,43.85C67.79,46.58,71.23,46.71,75.06,43.07Z"
            transform="translate(-3.52 -2.87)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
