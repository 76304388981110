import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Exit-Stairs-Up",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Exit Stairs Up",
    description: "Exit-Stairs-Up",
    image: require("./Exit-Stairs-Up.svg"),
    svgIcon: require("./Exit-Stairs-Up.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.exitStairsup-cls1{fill:#009f4d;}.exitStairsup-cls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="exitStairsup-cls1"
              d="M61.06,72.51H0V23.29l.39,0H98.2l.39,0V72.51H92.28c0-.12.07-.23.11-.34l1.86-4.59a2,2,0,0,0,.16-.79q0-19.49,0-39a2.25,2.25,0,0,0,0-.26,1.9,1.9,0,0,0,0-.25l-.11,0H63.34l0,0,0,.05v22.9c0,.64,0,1.29,0,1.94s0,1.26,0,1.92h-.68a1.83,1.83,0,0,0-1.59.82A3.17,3.17,0,0,0,61,58.49a1.85,1.85,0,0,0,1.58.78c.2,0,.41,0,.64,0,0,.16,0,.29,0,.42,0,2.29,0,4.57,0,6.86a1.87,1.87,0,0,1-.15.73l-2,4.89A2.84,2.84,0,0,0,61.06,72.51Zm-49.9-7.17H1.8a.24.24,0,0,0-.24.23v5a1.15,1.15,0,0,0,0,.26c0,.14.16.22.27.14s.44-.11.66-.11H59.12c.17,0,.24-.08.23-.24s0-.11,0-.16q0-7.45,0-14.9,0-8,0-16a.41.41,0,0,1,.13-.37c.15-.13.08-.29-.12-.33H49.75a.22.22,0,0,0-.22.24v4.62c0,.13,0,.26,0,.42h-9a1.82,1.82,0,0,0-.33,0,.22.22,0,0,0-.21.21v5.09h-9.3c-.23,0-.28.06-.3.3v4.62c0,.12,0,.23,0,.38H21c-.22,0-.25.06-.27.29v5l-.49,0H11.45c-.22,0-.28.08-.29.3a2.43,2.43,0,0,0,0,.27v4.72ZM4.77,55.67l.09,0L18.7,44.87l.08,0h0l0,0c0,.12,0,.25,0,.38l-.3,2.38L17.75,54a1.89,1.89,0,0,0,0,.35,2.76,2.76,0,0,0,.27-.17l3.81-3a.55.55,0,0,0,.21-.41c.09-.79.19-1.57.28-2.35.21-1.69.43-3.38.64-5.07l.51-4.2a4,4,0,0,0,0-.5l-1.85-.22-2.38-.29-3-.37-2.44-.3c-.83-.1-1.66-.19-2.48-.3a.64.64,0,0,0-.51.15l-3.7,2.89-.23.21v.07c3.16.41,6.32.77,9.49,1.21-2.32,1.85-4.64,3.66-7,5.48s-4.67,3.63-7,5.49Z"
              transform="translate(0 -23.21)"
            />
            <path
              class="exitStairsup-cls2"
              d="M61.06,72.51a2.84,2.84,0,0,1,.08-.34l2-4.89a1.87,1.87,0,0,0,.15-.73c0-2.29,0-4.57,0-6.86,0-.13,0-.26,0-.42-.23,0-.44,0-.64,0A1.85,1.85,0,0,1,61,58.49,3.17,3.17,0,0,1,61,54.94a1.83,1.83,0,0,1,1.59-.82h.68c0-.66,0-1.29,0-1.92s0-1.3,0-1.94V27.36l0-.05,0,0H94.26l.11,0a1.9,1.9,0,0,1,0,.25,2.25,2.25,0,0,1,0,.26q0,19.49,0,39a2,2,0,0,1-.16.79l-1.86,4.59c0,.11-.07.22-.11.34ZM80,51.16c.23-.41,3.91-4.63,4.13-4.76l.37.36,2.15,2.16a1.42,1.42,0,0,0,1.94.16,2.57,2.57,0,0,0,.4-.34L93,44.67l.12-.12a1.38,1.38,0,0,0,.11-1.62,1.35,1.35,0,0,0-1.5-.6,1.48,1.48,0,0,0-.7.42L88,45.83l-.29.27-.31-.3L83.52,42a1.93,1.93,0,0,0-1.48-.6l-6.27,0a1.59,1.59,0,0,0-1.34.62q-1.92,2.22-3.86,4.43l-.71.81a1.37,1.37,0,0,0-.07,1.68,1.29,1.29,0,0,0,1.64.39,1.7,1.7,0,0,0,.55-.45q2-2.28,4-4.56a.58.58,0,0,1,.5-.24c.62,0,1.23,0,1.84,0l.34,0-.21.29q-4.47,5.18-8.93,10.34A.58.58,0,0,1,69,55H65.59c-.9,0-1.8,0-2.7,0a1.61,1.61,0,0,0-1.53.95,1.72,1.72,0,0,0,1.53,2.48h7.56a2.78,2.78,0,0,0,2.25-1c1-1.17,2-2.29,3.07-3.43.08-.09.18-.17.27-.26l.13.09,4,3.84a.37.37,0,0,1,.1.41c-.14.58-.27,1.17-.4,1.76l-1.26,5.56a1.76,1.76,0,0,0,.69,1.88,1.72,1.72,0,0,0,2.67-1.09c.72-3.16,1.44-6.33,2.14-9.49a2,2,0,0,0-.58-2.07c-1.1-1.06-2.16-2.15-3.24-3.22A1.54,1.54,0,0,0,80,51.16Zm5.59-14.47a2.66,2.66,0,0,0-2.7,2.67,2.69,2.69,0,0,0,5.38,0A2.65,2.65,0,0,0,85.56,36.69Z"
              transform="translate(0 -23.21)"
            />
            <path
              class="exitStairsup-cls2"
              d="M98.59,23.29l-.39,0H.39l-.39,0s0-.06,0-.06H98.29C98.39,23.23,98.52,23.16,98.59,23.29Z"
              transform="translate(0 -23.21)"
            />
            <path
              class="exitStairsup-cls2"
              d="M11.16,65.34V60.62a2.43,2.43,0,0,1,0-.27c0-.22.07-.28.29-.3h8.81l.49,0v-5c0-.23.05-.27.27-.29h9.3c0-.15,0-.26,0-.38V49.76c0-.24.07-.29.3-.3h9.3V44.37a.22.22,0,0,1,.21-.21,1.82,1.82,0,0,1,.33,0h9c0-.16,0-.29,0-.42V39.11a.22.22,0,0,1,.22-.24h9.56c.2,0,.27.2.12.33a.41.41,0,0,0-.13.37q0,8,0,16,0,7.45,0,14.9s0,.11,0,.16-.06.25-.23.24H2.5c-.22,0-.45-.06-.66.11s-.24,0-.27-.14a1.15,1.15,0,0,1,0-.26v-5a.24.24,0,0,1,.24-.23h9.36Z"
              transform="translate(0 -23.21)"
            />
            <path
              class="exitStairsup-cls2"
              d="M4.77,55.67l-2.34-3c2.36-1.86,4.7-3.67,7-5.49s4.65-3.63,7-5.48c-3.17-.44-6.33-.8-9.49-1.21v-.07l.23-.21,3.7-2.89a.64.64,0,0,1,.51-.15c.82.11,1.65.2,2.48.3l2.44.3,3,.37,2.38.29,1.85.22a4,4,0,0,1,0,.5L23,43.36c-.21,1.69-.43,3.38-.64,5.07-.09.78-.19,1.56-.28,2.35a.55.55,0,0,1-.21.41l-3.81,3a2.76,2.76,0,0,1-.27.17,1.89,1.89,0,0,1,0-.35l.78-6.37.3-2.38c0-.13,0-.26,0-.38l0,0h0l-.08,0L4.86,55.64Z"
              transform="translate(0 -23.21)"
            />
            <path
              class="exitStairsup-cls1"
              d="M80,51.16a1.54,1.54,0,0,1,.29.21c1.08,1.07,2.14,2.16,3.24,3.22a2,2,0,0,1,.58,2.07c-.7,3.16-1.42,6.33-2.14,9.49a1.72,1.72,0,0,1-2.67,1.09,1.76,1.76,0,0,1-.69-1.88l1.26-5.56c.13-.59.26-1.18.4-1.76a.37.37,0,0,0-.1-.41l-4-3.84L76,53.7c-.09.09-.19.17-.27.26-1,1.14-2.08,2.26-3.07,3.43a2.78,2.78,0,0,1-2.25,1H62.89a1.72,1.72,0,0,1-1.53-2.48A1.61,1.61,0,0,1,62.89,55c.9,0,1.8,0,2.7,0H69a.58.58,0,0,0,.48-.22q4.45-5.18,8.93-10.34l.21-.29-.34,0c-.61,0-1.22,0-1.84,0a.58.58,0,0,0-.5.24q-2,2.28-4,4.56a1.7,1.7,0,0,1-.55.45,1.29,1.29,0,0,1-1.64-.39,1.37,1.37,0,0,1,.07-1.68l.71-.81q1.94-2.2,3.86-4.43a1.59,1.59,0,0,1,1.34-.62l6.27,0a1.93,1.93,0,0,1,1.48.6l3.83,3.83.31.3.29-.27L91,42.75a1.48,1.48,0,0,1,.7-.42,1.35,1.35,0,0,1,1.5.6,1.38,1.38,0,0,1-.11,1.62l-.12.12L89,48.74a2.57,2.57,0,0,1-.4.34,1.42,1.42,0,0,1-1.94-.16l-2.15-2.16-.37-.36C83.88,46.53,80.2,50.75,80,51.16Z"
              transform="translate(0 -23.21)"
            />
            <path
              class="exitStairsup-cls1"
              d="M85.56,36.69a2.65,2.65,0,0,1,2.68,2.67,2.69,2.69,0,0,1-5.38,0A2.66,2.66,0,0,1,85.56,36.69Z"
              transform="translate(0 -23.21)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
