import React from "react";
import PropTypes from "prop-types";
import Viewer2D from "./viewer2d/viewer2d";
import Viewer3D from "./viewer3d/viewer3d";
import Viewer3DFirstPerson from "./viewer3d/viewer3d-first-person";
import CatalogList from "./catalog-view/catalog-list";
import ProjectConfigurator from "./configurator/project-configurator";
import ViewerFinish from "./viewerFinish/viewerFinish";
import * as constants from "../constants";
import Modal from "react-responsive-modal";
import AppLogo from "../assets/app-logo.png";
import ViewOnly from "./viewOnly/viewOnly";

export default function Content({
  width,
  height,
  state,
  customContents,
  catalog,
  componentRef
}) {
  let mode = state.get("mode");
  //  
  switch (mode) {
    case constants.MODE_3D_VIEW:
      return <Viewer3D state={state} width={width} height={height} />;
    case constants.MODE_FINISHING_PLAN:
      return (
        <ViewerFinish
          catalog={catalog}
          state={state}
          width={width}
          componentRef={componentRef}
          height={height}
        />
      );
    case constants.MODE_3D_FIRST_PERSON:
      return (
        <Viewer3DFirstPerson state={state} width={width} height={height} />
      );

    case constants.MODE_VIEWING_CATALOG:
      return <CatalogList state={state} width={width} height={height} />;

    case constants.HOLD_AREA:
    case constants.MODE_IDLE:
    case constants.SELECT_GUIDE:
    case constants.MODE_DRAGGING_SQUARE:
    case constants.MODE_DRAGGING_HORIZONTALGUIDE:
    case constants.MODE_DRAGGING_VERTICALGUIDE:
    case constants.MODE_2D_ZOOM_IN:
    case constants.MODE_2D_ZOOM_OUT:
    case constants.MODE_2D_PAN:
    case constants.MODE_WAITING_DRAWING_LINE:
    case constants.MODE_WAITING_DRAWING_SQUARE:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
    case constants.MODE_DRAGGING_ITEM:
    case constants.MODE_DRAWING_LINE:
    case constants.MODE_DRAWING_SQUARE:
    case constants.END_DRAWING_SQUARE:
    case constants.UPDATE_DRAWING_SQUARE:
    case constants.MODE_DRAWING_HOLE:
    case constants.MODE_DRAWING_ITEM:
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_ROTATING_ITEM:
    case constants.RESIZE_ITEM:
    case constants.END_RESIZING_ITEM:
    case constants.API_REQUEST_FINISH:
    case constants.REMOVELINE:
    case constants.DUPLICATE_HOLE:
      return <Viewer2D state={state} width={width} height={height} />;

    case constants.API_REQUEST_START:
      return (
        <div>
          <Modal
            showCloseIcon={false}
            styles={{ width: "350px", height: "350px" }}
            center
            open={true}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img src={AppLogo} alt="" height={80} />
              Please wait while your project is loading...
              <div class="loader-container">
                <div class="loader"></div>
              </div>
            </div>
          </Modal>
        </div>
      );
    case constants.MODE_CONFIGURING_PROJECT:
      return (
        <ProjectConfigurator width={width} height={height} state={state} />
      );
    case constants.VIEW_ONLY:
      return <ViewOnly  catalog={catalog}  width={width} height={height} state={state} />;
    default:
      if (customContents.hasOwnProperty(mode)) {
        let CustomContent = customContents[mode];
        return <CustomContent width={width} height={height} state={state} />;
      } else {
        throw new Error(`Mode ${mode} doesn't have a mapped content`);
      }
  }
}

Content.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
