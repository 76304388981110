import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-First-Aid-Green",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "First Aid Green",
    description: "First-Aid-Green",
    image: require("./First-Aid-Green.svg"),
    svgIcon: require("./First-Aid-Green.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.aidgreencls1{fill:#009f4d;}.aidgreencls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
             <path class="aidgreencls1" d="M98.59,95.72H0V0H98.59ZM56.8,86.06q0-5.18-.06-10.38c0-3.45-.05-6.91-.06-10.37s-.09-6.92-.06-10.41l.34,0,1.5,0,12.86-.07,15.88-.07h1.19l.29,0c.07-.45,0-13.77,0-14L78,40.7l-10.72.07c-3.57,0-7.14.08-10.73,0,0-.14,0-.24,0-.34,0-1.29,0-2.58,0-3.86l-.06-10.09q0-6.39-.08-12.79c0-1.23,0-2.47,0-3.7,0-.13,0-.27,0-.42h-14l-.34,0v.44c0,2.48,0,4.95.05,7.43L42,30l0,10.5a3,3,0,0,1,0,.4h-.44L32.91,41l-10.72.07-11.9.07-.36,0c-.08.37-.07,13.66,0,13.92.12,0,.26,0,.4,0l7.49,0L34.34,55l7.4,0c.38,0,.38,0,.39.36,0,.67,0,1.34,0,2q0,6,.07,12c0,2.42,0,4.85.06,7.27,0,3,0,6.08,0,9.13,0,.14,0,.28,0,.41C42.84,86.18,56.52,86.15,56.8,86.06Z" transform="translate(0 0)"/>
    <path class="aidgreencls2" d="M56.8,86.06c-.28.09-14,.12-14.45,0,0-.13,0-.27,0-.41,0-3.05,0-6.09,0-9.13,0-2.42-.05-4.85-.06-7.27q0-6-.07-12c0-.67,0-1.34,0-2,0-.36,0-.36-.39-.36l-7.4,0L17.84,55l-7.49,0c-.14,0-.28,0-.4,0-.09-.26-.1-13.55,0-13.92l.36,0,11.9-.07L32.91,41l8.68-.06H42a3,3,0,0,0,0-.4L42,30,42,17.5c0-2.48,0-4.95-.05-7.43V9.63l.34,0h14c0,.15,0,.29,0,.42,0,1.23,0,2.47,0,3.7q0,6.41.08,12.79l.06,10.09c0,1.28,0,2.57,0,3.86,0,.1,0,.2,0,.34,3.59,0,7.16,0,10.73,0L78,40.7l10.7-.06c.08.27.1,13.59,0,14l-.29,0H87.2l-15.88.07-12.86.07-1.5,0-.34,0c0,3.49,0,7,.06,10.41s0,6.92.06,10.37S56.78,82.61,56.8,86.06Z" transform="translate(0 0)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
