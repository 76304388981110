import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-designated-smoking-area",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "designated smoking area",
    description: "designated-smoking-area",
    image: require("./designated-smoking-area.svg"),
    svgIcon: require("./designated-smoking-area.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.smokingareacls1{fill:#009f4c;}.smockingareacls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="smokingareacls1"
              d="M49.33,2.78A45.14,45.14,0,1,1,17.51,16,45.09,45.09,0,0,1,49.33,2.78ZM91.47,47.89A42.09,42.09,0,0,0,49.63,5.73,41.4,41.4,0,0,0,19.61,18,42.14,42.14,0,1,0,91.47,47.89Z"
              transform="translate(-4.24 -2.78)"
            />
            <path
              class="smockingareacls2"
              d="M91.47,47.89A42.15,42.15,0,1,1,19.61,18a41.4,41.4,0,0,1,30-12.27A42.09,42.09,0,0,1,91.47,47.89ZM75.8,47.72H13.22V58l.11,0H75.75l0,0,0,0ZM53.66,26.07a4.59,4.59,0,0,0-.32,1.63,6.05,6.05,0,0,0,1.43,4.09,9.61,9.61,0,0,0,2.92,2.34,21.3,21.3,0,0,0,5.84,2.07c1.09.24,2.2.42,3.31.59A18.63,18.63,0,0,0,69,37c.75,0,1.49.1,2.24.19a25.86,25.86,0,0,1,3.7.77,16,16,0,0,1,4.36,2,9.29,9.29,0,0,1,3.65,4.35.59.59,0,0,0,.08.16,1,1,0,0,0,0-.12c0-.08,0-.16,0-.24A13.7,13.7,0,0,0,82.14,41a12.25,12.25,0,0,0-5.65-6.36,16.17,16.17,0,0,0-6.74-1.82c-.85-.06-1.72-.07-2.58-.07a38.14,38.14,0,0,1-3.85-.17A22.2,22.2,0,0,1,60,32a12,12,0,0,1-3.51-1.46A6.76,6.76,0,0,1,54.76,29,5.12,5.12,0,0,1,53.66,26.07ZM79.57,46.55a.81.81,0,0,0,0-.09.77.77,0,0,1,0-.15A8.66,8.66,0,0,0,76.33,41a12.1,12.1,0,0,0-5.69-2.33,21.36,21.36,0,0,0-3.75-.25A32.37,32.37,0,0,1,63,38.24a17.62,17.62,0,0,1-3.67-.77,7.34,7.34,0,0,1-2.2-1.15,3.55,3.55,0,0,1-1.33-1.87c-.06-.23-.09-.47-.14-.71l-.06.07a2.58,2.58,0,0,0-.08.26,3.62,3.62,0,0,0,.55,3,6.06,6.06,0,0,0,2.07,1.83,15.61,15.61,0,0,0,4.72,1.7c1,.22,2,.38,3.07.54.57.09,1.14.14,1.72.19s1.33.07,2,.12a21.48,21.48,0,0,1,3.08.5,14.53,14.53,0,0,1,4,1.51A7.11,7.11,0,0,1,79,45.59C79.2,45.89,79.37,46.21,79.57,46.55ZM80.6,58V47.72H77.42V58Zm4.82-10.28H82.24V58h3.18Z"
              transform="translate(-4.24 -2.78)"
            />
            <path
              d="M75.8,47.72V58l0,0,0,0H13.33l-.11,0V47.72Z"
              transform="translate(-4.24 -2.78)"
            />
            <path
              d="M53.66,26.07A5.12,5.12,0,0,0,54.76,29a6.76,6.76,0,0,0,1.71,1.53A12,12,0,0,0,60,32a22.2,22.2,0,0,0,3.34.57,38.14,38.14,0,0,0,3.85.17c.86,0,1.73,0,2.58.07a16.17,16.17,0,0,1,6.74,1.82A12.25,12.25,0,0,1,82.14,41,13.7,13.7,0,0,1,83,44.07c0,.08,0,.16,0,.24a1,1,0,0,1,0,.12.59.59,0,0,1-.08-.16,9.29,9.29,0,0,0-3.65-4.35,16,16,0,0,0-4.36-2,25.86,25.86,0,0,0-3.7-.77C70.44,37.11,69.7,37,69,37a18.63,18.63,0,0,1-2.11-.22c-1.11-.17-2.22-.35-3.31-.59a21.3,21.3,0,0,1-5.84-2.07,9.61,9.61,0,0,1-2.92-2.34,6.05,6.05,0,0,1-1.43-4.09A4.59,4.59,0,0,1,53.66,26.07Z"
              transform="translate(-4.24 -2.78)"
            />
            <path
              d="M79.57,46.55c-.2-.34-.37-.66-.57-1a7.11,7.11,0,0,0-2.34-2.09,14.53,14.53,0,0,0-4-1.51,21.48,21.48,0,0,0-3.08-.5c-.67-.05-1.34-.07-2-.12s-1.15-.1-1.72-.19c-1-.16-2.06-.32-3.07-.54a15.61,15.61,0,0,1-4.72-1.7A6.06,6.06,0,0,1,56,37.11a3.62,3.62,0,0,1-.55-3,2.58,2.58,0,0,1,.08-.26l.06-.07c0,.24.08.48.14.71a3.55,3.55,0,0,0,1.33,1.87,7.34,7.34,0,0,0,2.2,1.15,17.62,17.62,0,0,0,3.67.77,32.37,32.37,0,0,0,3.94.16,21.36,21.36,0,0,1,3.75.25A12.1,12.1,0,0,1,76.33,41a8.66,8.66,0,0,1,3.24,5.33.77.77,0,0,0,0,.15A.81.81,0,0,1,79.57,46.55Z"
              transform="translate(-4.24 -2.78)"
            />
            <path
              d="M80.6,58H77.42V47.72H80.6Z"
              transform="translate(-4.24 -2.78)"
            />
            <path
              d="M85.42,47.72V58H82.24V47.72Z"
              transform="translate(-4.24 -2.78)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
