import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Fire-Extinguisher-Foam",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Fire Extinguisher Foam",
    description: "Fire-Extinguisher-Foam",
    image: require("./Fire-Extinguisher-Foam.svg"),
    svgIcon: require("./Fire-Extinguisher-Foam.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.foamcls1{fill:#edbf6b;}.foamcls2{fill:#fff;}.foamcls3{fill:#050402;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="foamcls1"
              d="M98.59,95.72H0V0H98.59ZM34.34,60.18V92.44c0,.62-.08.55.53.55H65.18c.61,0,.54.07.54-.52V33.89c0-1.87,0-3.74,0-5.62a5.92,5.92,0,0,0-.4-2.24,8,8,0,0,0-1.92-2.8,12.48,12.48,0,0,0-2.83-2.05,17.94,17.94,0,0,0-2-.92c-.59-.23-1.19-.42-1.79-.6-.13,0-.22-.09-.24-.25,0-.41-.13-.82-.19-1.23-.18-1.44-.34-2.89-.54-4.33-.06-.49,0-.4.36-.4a23.39,23.39,0,0,0,3,0A5.92,5.92,0,0,0,61,13a1,1,0,0,0,.63-.81c.13-.75.27-1.5.43-2.25.22-1,.37-2,.56-3a.55.55,0,0,0-.16-.49A1.08,1.08,0,0,0,62,6.14,5.63,5.63,0,0,0,61,5.79a13.45,13.45,0,0,0-3.47-.14L55,5.75c-.24,0-.25,0-.28-.25-.1-.81-.21-1.62-.31-2.43,0-.31,0-.32-.34-.32H45.6c-.15,0-.23,0-.25.21-.05.45-.11.91-.17,1.36S45,5.42,45,6c0,.16-.09.23-.25.22h-.12c-1.23,0-2.45.08-3.67.14a11.36,11.36,0,0,0-2,.23A4.49,4.49,0,0,0,37.84,7c-.19.11-.4.23-.42.45a.77.77,0,0,1-.34.51l-.45.36-3.28,2.82-3.46,3c-.68.58-1.35,1.17-2,1.74a2.83,2.83,0,0,0-1.07,2.89,8.44,8.44,0,0,1,.2,1.51c0,1.78,0,3.56,0,5.33,0,.31,0,.32.3.22l1.91-.62a2.19,2.19,0,0,0,.57-.29.92.92,0,0,0,.41-.65c0-.12,0-.23.06-.35.13-1.26.27-2.53.38-3.8a.79.79,0,0,1,.34-.61c.51-.37,1-.76,1.53-1.14l4.82-3.66,3.3-2.51c.39-.29.31-.32.65.06a2.27,2.27,0,0,0,1.06.66,9.81,9.81,0,0,0,1.51.29c.2,0,.21,0,.2.23a.51.51,0,0,1,0,.13l-.42,3.38c-.11.85-.24,1.69-.35,2.53a.36.36,0,0,1-.3.35c-.37.1-.73.22-1.09.35A17,17,0,0,0,39,21.42a11.82,11.82,0,0,0-3.19,2.66A7.36,7.36,0,0,0,34.42,27a5.07,5.07,0,0,0-.1,1.23q0,5,0,9.93C34.35,45.5,34.34,52.84,34.34,60.18Z"
              transform="translate(0 0)"
            />
            <path
              class="foamcls2"
              d="M34.34,60.18c0-7.34,0-14.68,0-22q0-5,0-9.93a5.07,5.07,0,0,1,.1-1.23,7.36,7.36,0,0,1,1.43-2.93A11.82,11.82,0,0,1,39,21.42a17,17,0,0,1,2.8-1.31c.36-.13.72-.25,1.09-.35a.36.36,0,0,0,.3-.35c.11-.84.24-1.68.35-2.53L44,13.5a.51.51,0,0,0,0-.13c0-.19,0-.21-.2-.23a9.81,9.81,0,0,1-1.51-.29,2.27,2.27,0,0,1-1.06-.66c-.34-.38-.26-.35-.65-.06l-3.3,2.51L32.48,18.3c-.51.38-1,.77-1.53,1.14a.79.79,0,0,0-.34.61c-.11,1.27-.25,2.54-.38,3.8,0,.12,0,.23-.06.35a.92.92,0,0,1-.41.65,2.19,2.19,0,0,1-.57.29l-1.91.62c-.3.1-.3.09-.3-.22,0-1.77,0-3.55,0-5.33a8.44,8.44,0,0,0-.2-1.51,2.83,2.83,0,0,1,1.07-2.89c.68-.57,1.35-1.16,2-1.74l3.46-3,3.28-2.82.45-.36a.77.77,0,0,0,.34-.51c0-.22.23-.34.42-.45a4.49,4.49,0,0,1,1.08-.39,11.36,11.36,0,0,1,2-.23c1.22-.06,2.44-.1,3.67-.14h.12c.16,0,.23-.06.25-.22.06-.54.13-1.09.2-1.64s.12-.91.17-1.36c0-.17.1-.22.25-.21h8.49c.31,0,.31,0,.34.32.1.81.21,1.62.31,2.43,0,.24,0,.25.28.25l2.48-.1A13.45,13.45,0,0,1,61,5.79,5.63,5.63,0,0,1,62,6.14a1.08,1.08,0,0,1,.39.28.55.55,0,0,1,.16.49c-.19,1-.34,2-.56,3-.16.75-.3,1.5-.43,2.25A1,1,0,0,1,61,13a5.92,5.92,0,0,1-1.76.45,23.39,23.39,0,0,1-3,0c-.32,0-.42-.09-.36.4.2,1.44.36,2.89.54,4.33.06.41.14.82.19,1.23,0,.16.11.21.24.25.6.18,1.2.37,1.79.6a17.94,17.94,0,0,1,2,.92,12.48,12.48,0,0,1,2.83,2.05A8,8,0,0,1,65.31,26a5.92,5.92,0,0,1,.4,2.24c0,1.88,0,3.75,0,5.62V92.47c0,.59.07.52-.54.52H34.87c-.61,0-.53.07-.53-.55ZM50,36.36H37.62c-.47,0-.43,0-.43.44V51.15c0,.54,0,.48.48.48H62.49c.29,0,.29,0,.29-.3V36.68c0-.31,0-.31-.32-.32H50Z"
              transform="translate(0 0)"
            />
            <path
              class="foamcls1"
              d="M50,36.36h12.5c.32,0,.32,0,.32.32V51.33c0,.3,0,.3-.29.3H37.67c-.53,0-.48.06-.48-.48V36.8c0-.47,0-.44.43-.44Zm5.57,7.4a.57.57,0,0,1,.19.21c.32.53.64,1.05,1,1.57a.38.38,0,0,0,.68,0c.35-.52.66-1.06,1-1.6a.38.38,0,0,1,.2-.22V44c0,.6,0,1.21,0,1.81v.23c0,.09,0,.13.13.13h.71c.09,0,.12-.06.12-.14v-.2c0-1.21,0-2.42,0-3.63V42c0-.11,0-.15-.15-.15a3.38,3.38,0,0,1-.45,0,.37.37,0,0,0-.38.22c-.26.46-.53.92-.81,1.37s-.44.69-.66,1c-.08,0-.1-.09-.13-.15-.42-.66-.83-1.33-1.24-2-.3-.48-.3-.48-.87-.47-.27,0-.28,0-.28.28v3.73a.56.56,0,0,1,0,.13c0,.12,0,.18.17.17h.58c.21,0,.22,0,.22-.24V43.76Zm-9,2.5h.19a2.28,2.28,0,0,0,2.13-1.51,2.12,2.12,0,0,0-1.07-2.65,2.59,2.59,0,0,0-3.38.85,2.35,2.35,0,0,0,0,2.1A2.44,2.44,0,0,0,46.57,46.26ZM54,46.19a.62.62,0,0,0-.11-.34c-.51-1.13-1-2.26-1.54-3.39-.08-.18-.12-.41-.27-.53s-.41,0-.62-.06a.48.48,0,0,0-.57.36,0,0,0,0,0,0,0c-.56,1.23-1.12,2.45-1.67,3.68,0,.07-.1.14,0,.25H50a.22.22,0,0,0,.24-.16c.07-.2.16-.39.24-.59a.22.22,0,0,1,.25-.18h1.69a.23.23,0,0,1,.26.18c.08.19.17.39.24.58s.1.18.23.17ZM43.48,43.82H41.74c-.27,0-.27,0-.27-.28v-.61c0-.24,0-.24.24-.24H43.6c.13,0,.19,0,.18-.18s0-.26,0-.38,0-.25-.24-.25H40.6c-.1,0-.16,0-.15.14s0,.13,0,.19v3.64a1.21,1.21,0,0,1,0,.19c0,.11.05.15.16.15h.6c.27,0,.27,0,.27-.26V44.85c0-.23,0-.23.23-.23h1.15c.22,0,.51.1.63,0s.05-.45,0-.68C43.51,43.87,43.5,43.86,43.48,43.82Z"
              transform="translate(0 0)"
            />
            <path
              class="foamcls3"
              d="M55.53,43.76V46c0,.23,0,.24-.22.24h-.58c-.12,0-.17,0-.17-.17a.56.56,0,0,0,0-.13V42.16c0-.28,0-.28.28-.28.57,0,.57,0,.87.47.41.67.82,1.34,1.24,2,0,.06,0,.12.13.15.22-.35.45-.69.66-1s.55-.91.81-1.37a.37.37,0,0,1,.38-.22,3.38,3.38,0,0,0,.45,0c.11,0,.16,0,.15.15v.19c0,1.21,0,2.42,0,3.63v.2c0,.08,0,.14-.12.14h-.71c-.09,0-.13,0-.13-.13v-.23c0-.6,0-1.21,0-1.81v-.29a.38.38,0,0,0-.2.22c-.33.54-.64,1.08-1,1.6a.38.38,0,0,1-.68,0c-.34-.52-.66-1-1-1.57A.57.57,0,0,0,55.53,43.76Z"
              transform="translate(0 0)"
            />
            <path
              class="foamcls3"
              d="M46.57,46.26a2.44,2.44,0,0,1-2.18-1.21,2.35,2.35,0,0,1,0-2.1,2.59,2.59,0,0,1,3.38-.85,2.12,2.12,0,0,1,1.07,2.65,2.28,2.28,0,0,1-2.13,1.51Zm0-3.6a1.38,1.38,0,1,0,0,2.75,1.38,1.38,0,1,0,0-2.75Z"
              transform="translate(0 0)"
            />
            <path
              class="foamcls3"
              d="M54,46.19h-.87c-.13,0-.19,0-.23-.17s-.16-.39-.24-.58a.23.23,0,0,0-.26-.18H50.75a.22.22,0,0,0-.25.18c-.08.2-.17.39-.24.59a.22.22,0,0,1-.24.16H49.2c-.05-.11,0-.18,0-.25.55-1.23,1.11-2.45,1.67-3.68a0,0,0,0,1,0,0,.48.48,0,0,1,.57-.36c.21,0,.45-.07.62.06s.19.35.27.53c.52,1.13,1,2.26,1.54,3.39A.62.62,0,0,1,54,46.19ZM51.6,42.88l-.68,1.6h1.39C52.05,44,51.85,43.45,51.6,42.88Z"
              transform="translate(0 0)"
            />
            <path
              class="foamcls3"
              d="M43.48,43.82s0,0,0,.06c0,.23.1.51,0,.68s-.41,0-.63,0H41.7c-.22,0-.23,0-.23.23v1.08c0,.26,0,.26-.27.26h-.6c-.11,0-.16,0-.16-.15a1.21,1.21,0,0,0,0-.19V42.21c0-.06,0-.12,0-.19s0-.15.15-.14h2.94c.24,0,.24,0,.24.25s0,.25,0,.38-.05.18-.18.18H41.71c-.23,0-.24,0-.24.24v.61c0,.28,0,.28.27.28h1.74Z"
              transform="translate(0 0)"
            />
            <path
              class="foamcls1"
              d="M46.59,42.66a1.38,1.38,0,1,1,0,2.75,1.38,1.38,0,1,1,0-2.75Z"
              transform="translate(0 0)"
            />
            <path
              class="foamcls1"
              d="M51.6,42.88c.25.57.45,1.08.71,1.6H50.92Z"
              transform="translate(0 0)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
