import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Straight-Corner-Shower",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Straight Corner Shower",
    description: "Straight-Corner-Shower",
    image: require("./Straight-Corner-Shower.svg"),
    svgIcon: require("./Straight-Corner-Shower.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M50.48,3.76H96.11c1.57,0,1.9.32,1.9,1.86q0,45.58,0,91.16c0,1.54-.43,2-2,2q-21.47.38-42.92.78a3.46,3.46,0,0,1-2.67-1.07Q27.09,75.84,3.69,53.25a3,3,0,0,1-1-2.28q-.09-22.44-.2-44.9c0-1.71.57-2.3,2.25-2.3H50.48ZM9.24,10.51c0,.35-.05.58-.05.82q.12,18.51.29,37a2.17,2.17,0,0,0,.62,1.42Q32.48,71,54.9,92.24a2.29,2.29,0,0,0,1.44.57q17-.3,34-.68l1.12-.06V10.49H65.65v3.69H64.43V10.5H58.59c0,.36-.06.66-.06,1,0,3.25,0,6.51,0,9.77a1.36,1.36,0,0,0,.9,1.42,8.16,8.16,0,0,1,3.44,3.22c2.46,4.18,1.47,8.87-2.62,11.82a9.2,9.2,0,0,1-14-4.59,8.52,8.52,0,0,1,4.5-10.56A1.43,1.43,0,0,0,51.6,21c-.05-3.15,0-6.31,0-9.46,0-.36,0-.72-.06-1.14-3.66,0-7.19,0-10.72,0a1.28,1.28,0,0,0-.74.28,6.49,6.49,0,0,1-4.18,1.33c-6.59,0-13.17,0-19.75-.08a6.36,6.36,0,0,1-3.25-.85A5.55,5.55,0,0,0,9.24,10.51ZM65.7,9.26H91.27c1.24,0,1.45.21,1.45,1.45V91.78c0,1.24-.25,1.53-1.39,1.55q-17.61.32-35.23.61a2.84,2.84,0,0,1-1.82-.68Q31.62,71.84,9,50.34a2.59,2.59,0,0,1-.73-1.69c-.1-7.62-.15-15.24-.2-22.86,0-5-.06-9.91-.09-14.86,0-1.48.17-1.66,1.67-1.67h1.25L10.8,6.18H4.86c0,.3,0,.5,0,.71Q5,28.72,5.11,50.54A2.25,2.25,0,0,0,5.73,52Q28.85,74.33,52,96.64a2.06,2.06,0,0,0,1.34.55q20.9-.34,41.78-.74a5.92,5.92,0,0,0,.65-.1V6.13H65.7ZM46.93,30.37A8,8,0,1,0,55,22.71,7.89,7.89,0,0,0,46.93,30.37ZM12.07,5.52V7.44a2.87,2.87,0,0,0,2.45,3.21,6.8,6.8,0,0,0,1.74.21c6.57,0,13.14,0,19.72,0a7,7,0,0,0,2.94-.8,2.58,2.58,0,0,0,1.14-1.6,14.87,14.87,0,0,0,0-2.92Zm45.2,16.07V5.49H52.86v16.1ZM41.36,9.16H51.1V9H49.29l.07-1.05,2.17-.11V6.13H41.36Zm17-3c.24.69.4,1.65.62,1.67,1.8.12,3.62.07,5.41.07V6.12Zm.73,2.94v.07H64V9.06Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M9.24,10.51a5.55,5.55,0,0,1,3.64.63,6.36,6.36,0,0,0,3.25.85c6.58.09,13.16.09,19.75.08a6.49,6.49,0,0,0,4.18-1.33,1.28,1.28,0,0,1,.74-.28c3.53,0,7.06,0,10.72,0,0,.42.06.78.06,1.14,0,3.15,0,6.31,0,9.46a1.43,1.43,0,0,1-.92,1.51,8.52,8.52,0,0,0-4.5,10.56,9.2,9.2,0,0,0,14,4.59c4.09-3,5.08-7.64,2.62-11.82a8.16,8.16,0,0,0-3.44-3.22,1.36,1.36,0,0,1-.9-1.42c0-3.26,0-6.52,0-9.77,0-.31,0-.61.06-1h5.84v3.68h1.22V10.49H91.44V92.07l-1.12.06q-17,.36-34,.68a2.29,2.29,0,0,1-1.44-.57Q32.48,71,10.1,49.75a2.17,2.17,0,0,1-.62-1.42q-.2-18.49-.29-37C9.19,11.09,9.22,10.86,9.24,10.51ZM78.41,29.45a7.43,7.43,0,0,0,7.66-7.13A7.55,7.55,0,0,0,71,22.08,7.46,7.46,0,0,0,78.41,29.45Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M65.7,9.26V6.13H95.79V96.35a5.92,5.92,0,0,1-.65.1q-20.9.38-41.78.74A2.06,2.06,0,0,1,52,96.64Q28.85,74.33,5.73,52a2.25,2.25,0,0,1-.62-1.42Q4.93,28.72,4.82,6.89c0-.21,0-.41,0-.71H10.8l.14,3.08H9.69C8.19,9.27,8,9.45,8,10.93c0,5,.05,9.9.09,14.86.05,7.62.1,15.24.2,22.86A2.59,2.59,0,0,0,9,50.34Q31.62,71.85,54.28,93.26a2.84,2.84,0,0,0,1.82.68q17.62-.27,35.23-.61c1.14,0,1.39-.31,1.39-1.55V10.72c0-1.24-.21-1.45-1.45-1.45H65.7Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M46.93,30.37a8,8,0,0,1,16,.13,8,8,0,0,1-16-.13Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M12.07,5.52h28a14.87,14.87,0,0,1,0,2.92A2.58,2.58,0,0,1,38.92,10a7,7,0,0,1-2.94.8c-6.58.06-13.15,0-19.72,0a6.8,6.8,0,0,1-1.74-.21,2.87,2.87,0,0,1-2.45-3.21Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M57.27,21.59H52.86V5.49h4.41Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M41.36,9.16v-3H51.53V7.81l-2.17.11L49.29,9H51.1v.19Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M58.35,6.12h6V7.86c-1.79,0-3.61,0-5.41-.07C58.75,7.77,58.59,6.81,58.35,6.12Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M59.08,9.06H64v.07h-4.9Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              d="M78.41,29.45A7.46,7.46,0,0,1,71,22.08a7.55,7.55,0,0,1,15.1.24A7.43,7.43,0,0,1,78.41,29.45Zm6.44-7.31a6.35,6.35,0,0,0-12.68,0,6.25,6.25,0,0,0,6.39,6.14A6.13,6.13,0,0,0,84.85,22.14Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M84.85,22.14a6.13,6.13,0,0,1-6.29,6.11,6.25,6.25,0,0,1-6.39-6.14,6.35,6.35,0,0,1,12.68,0Zm-2,.07a4.31,4.31,0,1,0-4.35,4.11A4.25,4.25,0,0,0,82.82,22.21Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              d="M82.82,22.21A4.31,4.31,0,1,1,78.51,18,4.25,4.25,0,0,1,82.82,22.21Zm-7.39,0a3.11,3.11,0,1,0,3.09-3.06A3,3,0,0,0,75.43,22.18Z"
              transform="translate(-2.49 -3.76)"
            />
            <path
              class="cls-1"
              d="M75.43,22.18a3,3,0,0,1,3.09-3.06,3.08,3.08,0,0,1,3.12,3.07,3.11,3.11,0,0,1-6.21,0Z"
              transform="translate(-2.49 -3.76)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
