import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-exit-stairs-down",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "exit stairs down",
    description: "exit-stairs-down",
    image: require("./exit-stairs-down.svg"),
    svgIcon: require("./exit-stairs-down.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.exitStairsDown-cls1{fill:#009f4d;}.exitStairsDown-cls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="exitStairsDown-cls1"
              d="M53.3,65.31H.14V21.54h86V65.31H80.54a.33.33,0,0,0,.06-.1L82.27,61a2.15,2.15,0,0,0,.16-.83V25.36c0-.07,0-.14-.05-.25H55.49c-.07,0-.15,0-.25,0V49h-.71a1.35,1.35,0,0,0-1,.39,2.65,2.65,0,0,0-.41.54,2.81,2.81,0,0,0-.07,2.54,2.19,2.19,0,0,0,.63.81,1.29,1.29,0,0,0,.85.29,2.57,2.57,0,0,1,.68,0,1.49,1.49,0,0,1,0,.21v6.42a.93.93,0,0,1-.07.33c-.19.51-.4,1-.6,1.52C54.15,63.16,53.73,64.24,53.3,65.31ZM10.18,40.17V35.83c0-.34,0-.37-.36-.37h-8a.69.69,0,0,0-.2,0c-.12,0-.19.16-.1.23s.14.35.14.53q0,13.59,0,27.19v.49l.5,0H51.48a.65.65,0,0,1,.2,0c.27.12.33.09.34-.2a1.41,1.41,0,0,0,0-.2v-4c0-.53,0-.53-.55-.53h-7.4l-.41,0c0-.15,0-.28,0-.4V54.77c0-.47,0-.48-.48-.48H35.69l-.39,0c0-.15,0-.27,0-.39,0-1.22,0-2.43,0-3.65,0-.78,0-.65-.65-.65h-7.7c0-.15,0-.27,0-.39V45.36c0-.46,0-.48-.48-.48H18.57c0-.16,0-.28,0-.4V40.64c0-.46,0-.47-.46-.47H10.18Zm31.3-6-.06,0a.67.67,0,0,1-.14-.09l-2.17-1.6L36.74,30.8l-2.3-1.71-3-2.19-1.61-1.19c-.31-.24-.63-.46-.94-.69a26.39,26.39,0,0,0-2,2.78l.2.16c.64.48,1.28,1,1.93,1.43l2.44,1.81,2.17,1.6,2.7,2,2.9,2.14a1.35,1.35,0,0,1,.17.17l-4.13.68c-1.37.24-2.74.42-4.16.68.09.09.12.14.17.18.52.39,1,.78,1.57,1.16s1.27.92,1.89,1.39a.42.42,0,0,0,.37.09c.15,0,.29-.08.45-.1l4.1-.65,5.71-.91a1.54,1.54,0,0,0,.27-.07c0-.1,0-.18,0-.26-.17-1.14-.35-2.28-.53-3.42l-.72-4.61L44,28.8a.43.43,0,0,0-.2-.33L40.45,26l-.27-.16Z"
              transform="translate(-0.03 -21.54)"
            />
            <path
              class="exitStairsDown-cls2"
              d="M53.3,65.31c.43-1.07.85-2.15,1.27-3.22.2-.51.41-1,.6-1.52a.93.93,0,0,0,.07-.33V53.82a1.49,1.49,0,0,0,0-.21,2.57,2.57,0,0,0-.68,0,1.29,1.29,0,0,1-.85-.29,2.19,2.19,0,0,1-.63-.81,2.81,2.81,0,0,1,.07-2.54,2.65,2.65,0,0,1,.41-.54,1.35,1.35,0,0,1,1-.39h.71V25.15c.1,0,.18,0,.25,0H82.38c0,.11,0,.18.05.25V60.18a2.15,2.15,0,0,1-.16.83l-1.67,4.2a.33.33,0,0,1-.06.1ZM76.54,41.88l-.74-.77-2.92-3a1.65,1.65,0,0,0-1.23-.5c-1.83,0-3.65,0-5.48,0a1.37,1.37,0,0,0-1.14.53l-1.25,1.47c-.9,1.05-1.81,2.09-2.71,3.15a1.94,1.94,0,0,0-.27.43,1.19,1.19,0,0,0,.89,1.6,1.06,1.06,0,0,0,1-.37c.44-.49.87-1,1.3-1.49l2.33-2.76a.43.43,0,0,1,.37-.16h1.67l.26,0-.19.25-2.34,2.78-2.88,3.39c-.83,1-1.68,2-2.51,2.95a.66.66,0,0,1-.58.26H55a1.79,1.79,0,0,0-.72.15,1.48,1.48,0,0,0-.88,1.5,1.51,1.51,0,0,0,1.11,1.35,2.76,2.76,0,0,0,.57.06l6,0c.27,0,.54,0,.81,0a2.1,2.1,0,0,0,1.5-.73c.92-1.06,1.85-2.11,2.78-3.17a2,2,0,0,0,.2-.26l.27.24c.42.41.83.83,1.25,1.23l2.06,2a.36.36,0,0,1,.12.38c-.08.31-.15.63-.22,1L68.62,59a1.62,1.62,0,0,0,.06,1,1.48,1.48,0,0,0,2.73.13,2.51,2.51,0,0,0,.17-.55l1.08-4.86c.25-1.17.52-2.33.77-3.5a1.83,1.83,0,0,0-.52-1.78c-1-.95-1.9-1.91-2.85-2.87L70,46.47l-.14-.2c.2-.3,3.4-4,3.56-4.15l.23.21,1.9,2a3.88,3.88,0,0,0,.31.26,1.11,1.11,0,0,0,1.37,0,5.17,5.17,0,0,0,.51-.42l2-2c.51-.51,1-1,1.5-1.54a1.21,1.21,0,0,0-1.66-1.75c-.53.51-1,1.05-1.55,1.57-.32.32-.63.65-.94,1Zm.49-6a2.33,2.33,0,0,0-2.3-2.39,2.37,2.37,0,0,0-2.38,2.35,2.4,2.4,0,0,0,2.37,2.43A2.38,2.38,0,0,0,77,35.88Z"
              transform="translate(-0.03 -21.54)"
            />
            <path
              class="exitStairsDown-cls2"
              d="M.14,21.54V65.31s-.12.06-.11,0V21.54Z"
              transform="translate(-0.03 -21.54)"
            />
            <path
              class="exitStairsDown-cls2"
              d="M10.18,40.17h7.91c.44,0,.46,0,.46.47v3.84c0,.12,0,.24,0,.4h7.87c.46,0,.48,0,.48.48v3.82c0,.12,0,.24,0,.39h7.7c.64,0,.65-.13.65.65,0,1.22,0,2.43,0,3.65,0,.12,0,.24,0,.39l.39,0h7.48c.47,0,.48,0,.48.48v3.81c0,.12,0,.25,0,.4l.41,0h7.4c.54,0,.54,0,.55.53v4a1.41,1.41,0,0,1,0,.2c0,.29-.07.32-.34.2a.65.65,0,0,0-.2,0H2.11l-.5,0v-.49q0-13.59,0-27.19c0-.18,0-.37-.14-.53s0-.2.1-.23a.69.69,0,0,1,.2,0h8c.32,0,.36,0,.36.37v4.34Z"
              transform="translate(-0.03 -21.54)"
            />
            <path
              class="exitStairsDown-cls2"
              d="M41.48,34.22l-1.3-8.4.27.16,3.39,2.49a.43.43,0,0,1,.2.33l.39,2.46.72,4.61c.18,1.14.36,2.28.53,3.42,0,.08,0,.16,0,.26a1.54,1.54,0,0,1-.27.07l-5.71.91-4.1.65c-.16,0-.3.07-.45.1a.42.42,0,0,1-.37-.09c-.62-.47-1.26-.92-1.89-1.39s-1-.77-1.57-1.16c-.05,0-.08-.09-.17-.18,1.42-.26,2.79-.44,4.16-.68l4.13-.68a1.35,1.35,0,0,0-.17-.17l-2.9-2.14-2.7-2-2.17-1.6-2.44-1.81c-.65-.47-1.29-.95-1.93-1.43L27,27.8a26.39,26.39,0,0,1,2-2.78c.31.23.63.45.94.69l1.61,1.19,3,2.19,2.3,1.71,2.37,1.75,2.17,1.6a.67.67,0,0,0,.14.09Z"
              transform="translate(-0.03 -21.54)"
            />
            <path
              class="exitStairsDown-cls1"
              d="M76.54,41.88l.55-.54c.31-.32.62-.65.94-1,.51-.52,1-1.06,1.55-1.57a1.21,1.21,0,0,1,1.66,1.75c-.5.51-1,1-1.5,1.54l-2,2a5.17,5.17,0,0,1-.51.42,1.11,1.11,0,0,1-1.37,0,3.88,3.88,0,0,1-.31-.26l-1.9-2-.23-.21c-.16.11-3.36,3.85-3.56,4.15l.14.2.06.06c1,1,1.89,1.92,2.85,2.87a1.83,1.83,0,0,1,.52,1.78c-.25,1.17-.52,2.33-.77,3.5l-1.08,4.86a2.51,2.51,0,0,1-.17.55A1.48,1.48,0,0,1,68.68,60a1.62,1.62,0,0,1-.06-1l1.23-5.59c.07-.32.14-.64.22-1a.36.36,0,0,0-.12-.38l-2.06-2c-.42-.4-.83-.82-1.25-1.23l-.27-.24a2,2,0,0,1-.2.26C65.24,49.9,64.31,51,63.39,52a2.1,2.1,0,0,1-1.5.73c-.27,0-.54,0-.81,0l-6,0a2.76,2.76,0,0,1-.57-.06,1.51,1.51,0,0,1-1.11-1.35,1.48,1.48,0,0,1,.88-1.5,1.79,1.79,0,0,1,.72-.15h5.11a.66.66,0,0,0,.58-.26c.83-1,1.68-2,2.51-2.95l2.88-3.39,2.34-2.78.19-.25-.26,0H66.72a.43.43,0,0,0-.37.16L64,43c-.43.5-.86,1-1.3,1.49a1.06,1.06,0,0,1-1,.37,1.19,1.19,0,0,1-.89-1.6,1.94,1.94,0,0,1,.27-.43c.9-1.06,1.81-2.1,2.71-3.15L65,38.19a1.37,1.37,0,0,1,1.14-.53c1.83,0,3.65,0,5.48,0a1.65,1.65,0,0,1,1.23.5l2.92,3Z"
              transform="translate(-0.03 -21.54)"
            />
            <path
              class="exitStairsDown-cls1"
              d="M77,35.88a2.38,2.38,0,0,1-2.31,2.39,2.4,2.4,0,0,1-2.37-2.43,2.37,2.37,0,0,1,2.38-2.35A2.33,2.33,0,0,1,77,35.88Z"
              transform="translate(-0.03 -21.54)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
