import {HOLD_AREA, REMOVE_AREA, SELECT_AREA} from '../constants';

export function selectArea(layerID, areaID) {
  return {
    type: SELECT_AREA,
    layerID,
    areaID
  }
}


export function holdArea(layerID, areaID) {
  return {
    type: HOLD_AREA,
    layerID,
    areaID
  }
}


export function removeArea(layerID, areaID){
  return {
    type: REMOVE_AREA,
    layerID,
    areaID
  }
}