import React from "react";
import * as Three from "three";
import { loadObjWithMaterial } from "../../utils/load-obj";
import path from "path";

let cached3DDoor = null;

const STYLE_HOLE_BASE = { stroke: "#000", strokeWidth: "3px", fill: "#000" };
const STYLE_HOLE_SELECTED = {
  stroke: "#0096fd",
  strokeWidth: "4px",
  fill: "#0096fd",
  cursor: "move",
};
const STYLE_ARC_BASE = { stroke: "rgb(170 168 168)", fill: "none" };
const STYLE_ARC_SELECTED = {
  stroke: "#0096fd",
  strokeWidth: "1px",
  fill: "none",
  cursor: "move",
};
// const EPSILON = 3;

export default {
  name: "door",
  prototype: "holes",

  info: {
    title: "door",
    tag: ["door"],
    description: "Wooden door",
    image: require("./door.png"),
    svgicon: require("./doordoor.png"),
  },

  properties: {
    width: {
      label: "Opening Width",
      type: "slider",
      min: 50,
      max: 350,
      defaultValue: 100,
      // type: "length-measure",
      // defaultValue: {
      //   length: 80,
      //   minLimit: 10,
      //   maxLimit: 500,
      // },
    },
    // height: {
    //   label: 'Height',
    //   type: 'length-measure',
    //   defaultValue: {
    //     length: 215
    //   }
    // },
    // altitude: {
    //   label: 'Altitude',
    //   type: 'length-measure',
    //   defaultValue: {
    //     length: 0
    //   }
    // },
    // thickness: {
    //   label: "Thickness",
    //   type: "length-measure",
    //   defaultValue: {
    //     length: 30,
    //   },
    // },
    swap_hinge:{
      label:"Swap hinge side",
      type: "checkbox",
      defaultValue: false,
      values:{
        none:false,
        yes: true
      }
    },
    flip_orizzontal: {
      label: "Swap Opening Side",
      type: "checkbox",
      defaultValue: false,
      values: {
        none: false,
        yes: true,
      },
    },
  },

  render2D: function (element, layer, scene) {
    const line = scene.getIn(['layers',scene.get('selectedLayer'),'lines',element.get('line')]);
    //  
    let linethickness = line.getIn(['properties','thickness'])
    linethickness = linethickness>5?linethickness:linethickness*100;
    const EPSILON = linethickness/4;
    let flip = element.properties.get("flip_orizzontal");
    let hinge = element.properties.get("swap_hinge");
    let holeWidth = (element.properties.get('width')===100?element.properties.get('width'):element.properties.get('width')*100)
    let holePath = `M${0} ${-EPSILON}  L${holeWidth} ${-EPSILON}  L${holeWidth} ${EPSILON}  L${0} ${EPSILON}  z`;
    let arcPath = `M${flip?18:20},${flip?0:5}  A${holeWidth},${holeWidth} 0 0,1 ${holeWidth},${holeWidth}`;
    let holeStyle =  STYLE_HOLE_BASE;
    let arcStyle =  STYLE_ARC_BASE;
    let length = (element.properties.get('width')===100?element.properties.get('width'):element.properties.get('width')*100)

    if (flip == false) {
      return (
        <g transform={`translate(${-length / 2}, 0)`}>
            <path
            d={holePath}
            style={{ stroke: "white", strokeWidth: "3px", fill:"white"}}
          />
          <path
            d={arcPath}
            style={arcStyle}
            transform={hinge?`translate(${holeWidth},${holeWidth}) scale(-1,-1) rotate(0)`:`translate(0,${holeWidth}) scale(1,-1) rotate(0)`}
          />
          <line
            x1={20}
            y1={(holeWidth - EPSILON)+4}
            x2={-4}
            y2={-EPSILON}
            transform={hinge?`translate(${holeWidth}, 0) scale(-1, 1)`:""}
            style={holeStyle}
          />
        
        </g>
      );
    } else {
      return (
        <g transform={`translate(${-length / 2}, 0)`}>
           <path
            d={holePath}
            style={{ stroke:element.selected?"#0096fd":"white", strokeWidth: "3px", fill: element.selected?"#0096fd":"white" }}
          />
          <path
            d={arcPath}
            style={arcStyle}
            // transform={`translate(${0},${-holeWidth}) scale(${1},${1}) rotate(${0})`}
            transform={hinge?`translate(${holeWidth},${-holeWidth}) scale(-1,1) rotate(0)`:`translate(0,${-holeWidth}) scale(1,1) rotate(0)`}

          />
          <line
            x1={-19}
            y1={(-holeWidth - EPSILON)+10}
            x2={4}
            y2={EPSILON}
            style={holeStyle}
            transform={hinge?`translate(${holeWidth}, 0) scale(1, 1)`:`scale(${-1},${1})`}
          />
         
        </g>
      );
    }
  },

  render3D: function (element, layer, scene) {
    let onLoadItem = (object) => {
      let boundingBox = new Three.Box3().setFromObject(object);

      let initialWidth = boundingBox.max.x - boundingBox.min.x;
      let initialHeight = boundingBox.max.y - boundingBox.min.y;
      let initialThickness = boundingBox.max.z - boundingBox.min.z;

      if (element.selected) {
        let box = new Three.BoxHelper(object, 0x99c3fb);
        box.material.linewidth = 2;
        box.material.depthTest = false;
        box.renderOrder = 1000;
        object.add(box);
      }

      let width = element.properties.get("width");
      let height = element.properties.get("height").get("length");
      let thickness = element.properties.get("thickness").get("length");

      object.scale.set(
        width / initialWidth,
        height / initialHeight,
        thickness / initialThickness
      );

      return object;
    };

    if (cached3DDoor) {
      return Promise.resolve(onLoadItem(cached3DDoor.clone()));
    }

    let mtl = require("./door.mtl");
    let obj = require("./door.obj");
    let img = require("./texture.jpg");

    return loadObjWithMaterial(mtl, obj, path.dirname(img) + "/").then(
      (object) => {
        cached3DDoor = object;
        return onLoadItem(cached3DDoor.clone());
      }
    );
  },
};
