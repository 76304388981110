import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-direction-right",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "direction right",
    description: "direction-right",
    image: require("./direction-right.svg"),
    svgIcon: require("./direction-right.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.drcls1{fill:#0ea04b;}.drcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="drcls1"
              d="M92.36,5V90.84h-86c0-.17,0-.34,0-.51V5.57C6.36,5,6.31,5,6.9,5h85ZM87.59,47.89l-.23-.25L86.64,47,76,36.38,64.37,24.75q-1.65-1.65-3.29-3.31a.87.87,0,0,0-.66-.26c-5.14,0-10.28,0-15.42,0h-.2a1.79,1.79,0,0,0-.27,0c3.53,3.5,7,7,10.47,10.44s7,6.93,10.46,10.45H11.57c-.46,0-.49-.07-.49.48V53.42a.19.19,0,0,0,.21.22h53.9l.22,0-.24.25L53.91,65.16l-7.07,7.06-2.06,2.07c-.07.07-.17.12-.16.27s.24,0,.36,0H60.46a.8.8,0,0,0,.61-.26c1.53-1.54,3.08-3.07,4.62-4.6L77,58.43l4.37-4.37,5.82-5.79Z"
              transform="translate(-6.23 -4.94)"
            />
            <path
              class="drcls2"
              d="M92.36,5,91.9,5H6.9c-.59,0-.54-.08-.54.53V90.33c0,.17,0,.34,0,.51H6.23V5H92.1C92.18,5,92.29,4.89,92.36,5Z"
              transform="translate(-6.23 -4.94)"
            />
            <path
              class="drcls2"
              d="M87.59,47.89l-.37.38L81.4,54.06,77,58.43,65.69,69.74c-1.54,1.53-3.09,3.06-4.62,4.6a.8.8,0,0,1-.61.26H45c-.12,0-.24,0-.36,0s.09-.2.16-.27l2.06-2.07,7.07-7.06L65.17,53.93l.24-.25-.22,0H11.29a.19.19,0,0,1-.21-.22V42.61c0-.55,0-.48.49-.48H65.46c-3.51-3.52-7-7-10.46-10.45s-6.94-6.94-10.47-10.44a1.79,1.79,0,0,1,.27,0H45c5.14,0,10.28,0,15.42,0a.87.87,0,0,1,.66.26q1.64,1.66,3.29,3.31L76,36.38,86.64,47l.72.69Z"
              transform="translate(-6.23 -4.94)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
