import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "hob",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "hob",
    description: "hob",
    image: require("./hob.svg"),
    svgIcon: require("./hob.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            {" "}
            <path d="M50.93,10.7H92.29c2.63,0,3.22.58,3.22,3.19V89.24c0,2.75-.54,3.29-3.31,3.29H9.48c-2.69,0-3.19-.5-3.19-3.18q0-37.77,0-75.54c0-2.55.56-3.11,3.09-3.11ZM9,13.56V89.65H81.81V13.56Zm74.34,0V89.7h9.45V13.55Z" />
            <path
              class="cls-1"
              d="M9,13.56H81.81V89.65H9Zm17.74,2.92c-8.55,0-15.38,7.12-15.39,16s6.89,16.26,15.44,16.23,15.3-7.15,15.42-16.08S35.28,16.46,26.71,16.48ZM48.62,71.37c-.06,8.9,6.93,16.25,15.43,16.21s15.34-7.23,15.36-16.09-7-16.3-15.43-16.24S48.69,62.47,48.62,71.37ZM63.84,45.49C70.55,45.62,76.15,40,76.33,33c.19-7.33-5.25-13.27-12.23-13.35-6.72-.08-12.2,5.52-12.38,12.61S56.91,45.35,63.84,45.49ZM14.27,71.43A12.54,12.54,0,0,0,26.61,84.25c6.78,0,12.35-5.86,12.3-12.91A12.58,12.58,0,0,0,26.53,58.53C19.66,58.56,14.26,64.24,14.27,71.43Z"
            />
            <path
              class="cls-1"
              d="M83.31,13.55h9.45V89.7H83.31Zm7.36,28a2.81,2.81,0,1,0-5.6,0,2.8,2.8,0,1,0,5.6,0ZM87.82,53a3,3,0,0,0-2.75,3,2.8,2.8,0,1,0,5.6-.11A2.94,2.94,0,0,0,87.82,53Zm2.85,9.66a3,3,0,0,0-2.83-2.94,3.08,3.08,0,0,0-2.77,3,2.8,2.8,0,1,0,5.6-.07ZM87.89,51.75a3,3,0,0,0,0-5.93,3,3,0,0,0-2.78,3A2.93,2.93,0,0,0,87.89,51.75Z"
            />
            <path d="M26.71,16.48c8.57,0,15.58,7.32,15.47,16.17s-7,16.05-15.42,16.08S11.31,41.5,11.32,32.5,18.16,16.49,26.71,16.48ZM40.19,32.77A13.69,13.69,0,0,0,26.9,18.53c-7.36-.15-13.49,6-13.66,13.72-.17,7.9,5.72,14.26,13.33,14.38S40,40.76,40.19,32.77Z" />
            <path d="M48.62,71.37c.07-8.9,6.9-16.07,15.36-16.12s15.45,7.3,15.43,16.24S72.53,87.54,64.05,87.58,48.56,80.27,48.62,71.37Zm2-.13c-.11,7.66,5.85,14.11,13.12,14.2A13.72,13.72,0,0,0,77.47,71.69c.13-7.94-5.73-14.27-13.32-14.4S50.68,63.36,50.57,71.24Z" />
            <path d="M63.84,45.49c-6.93-.14-12.3-6-12.12-13.22S57.38,19.58,64.1,19.66c7,.08,12.42,6,12.23,13.35C76.15,40,70.55,45.62,63.84,45.49ZM53.26,32.39a10.79,10.79,0,1,0,21.55.33A11.14,11.14,0,0,0,64.18,21.25C58.33,21.1,53.35,26.17,53.26,32.39Z" />
            <path d="M14.27,71.43c0-7.19,5.39-12.87,12.26-12.9A12.58,12.58,0,0,1,38.91,71.34c.05,7-5.52,12.9-12.3,12.91A12.54,12.54,0,0,1,14.27,71.43ZM26.49,60.09A11,11,0,0,0,15.82,71.46c0,6.24,4.9,11.28,10.83,11.21A11.11,11.11,0,0,0,37.37,71.3C37.31,65.1,32.4,60,26.49,60.09Z" />
            <path d="M90.67,41.59a2.8,2.8,0,1,1-5.6,0,2.81,2.81,0,1,1,5.6,0Zm-.56.68c-.26-1.93-.89-3-2.3-2.9a2.1,2.1,0,0,0-2,2.42c0,1.34.91,2.21,2.15,2C88.79,43.61,89.52,42.7,90.11,42.27Z" />
            <path d="M87.82,53a3,3,0,0,1,.11,5.92A2.9,2.9,0,0,1,85.07,56,3,3,0,0,1,87.82,53Zm2.49,3.3v-.86c-.81-.58-1.56-1.54-2.43-1.67-1.23-.18-2.09.76-2.09,2.09s.81,2.42,2.16,2.2C88.8,57.93,89.52,56.92,90.31,56.3Z" />
            <path d="M90.67,62.66a2.8,2.8,0,1,1-5.6.07,3.08,3.08,0,0,1,2.77-3A3,3,0,0,1,90.67,62.66Zm-.35.39,0-.85c-.82-.58-1.59-1.52-2.46-1.63-1.23-.15-2.08.8-2.06,2.14s.86,2.4,2.21,2.15C88.84,64.7,89.54,63.69,90.32,63.05Z" />
            <path d="M87.89,51.75a2.93,2.93,0,0,1-2.82-2.94,3,3,0,0,1,2.78-3,3,3,0,0,1,0,5.93Zm2.44-2.63,0-.87c-.82-.58-1.59-1.54-2.47-1.66a1.88,1.88,0,0,0-2,2.17c0,1.36.77,2.47,2.2,2.18C88.85,50.77,89.55,49.76,90.33,49.12Z" />
            <path
              class="cls-1"
              d="M40.19,32.77c-.18,8-6.07,14-13.62,13.86s-13.5-6.48-13.33-14.38c.17-7.7,6.3-13.87,13.66-13.72A13.69,13.69,0,0,1,40.19,32.77Z"
            />
            <path
              class="cls-1"
              d="M50.57,71.24c.11-7.88,6.14-14.07,13.58-13.95s13.45,6.46,13.32,14.4A13.72,13.72,0,0,1,63.69,85.44C56.42,85.35,50.46,78.9,50.57,71.24Z"
            />
            <path
              class="cls-1"
              d="M53.26,32.39c.09-6.22,5.07-11.29,10.92-11.14A11.14,11.14,0,0,1,74.81,32.72a10.79,10.79,0,1,1-21.55-.33Z"
            />
            <path
              class="cls-1"
              d="M26.49,60.09c5.91-.05,10.82,5,10.88,11.21A11.11,11.11,0,0,1,26.65,82.67c-5.93.07-10.8-5-10.83-11.21A11,11,0,0,1,26.49,60.09Z"
            />
            <path
              class="cls-1"
              d="M90.11,42.27c-.59.43-1.32,1.34-2.19,1.5-1.24.23-2.12-.64-2.15-2a2.1,2.1,0,0,1,2-2.42C89.22,39.32,89.85,40.34,90.11,42.27Z"
            />
            <path
              class="cls-1"
              d="M90.31,56.3c-.79.62-1.51,1.63-2.37,1.76-1.35.22-2.17-.8-2.16-2.2s.86-2.27,2.09-2.09c.87.13,1.62,1.09,2.43,1.67Z"
            />
            <path
              class="cls-1"
              d="M90.32,63.05c-.78.64-1.48,1.65-2.34,1.81-1.35.25-2.18-.75-2.21-2.15s.83-2.29,2.06-2.14c.87.11,1.64,1,2.46,1.63Z"
            />
            <path
              class="cls-1"
              d="M90.33,49.12c-.78.64-1.48,1.65-2.35,1.82-1.43.29-2.16-.82-2.2-2.18a1.88,1.88,0,0,1,2-2.17c.88.12,1.65,1.08,2.47,1.66Z"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
