import React from "react";
import { createArea, updatedArea } from "./area-factory-3d";
import * as SharedStyle from "../../shared-style";
import Translator from "../../translator/translator";

let translator = new Translator();

export default function AreaFactory(name, info, textures) {
  let areaElement = {
    name,
    prototype: "areas",
    info: {
      ...info,
      visibility: {
        catalog: false,
        layerElementsVisible: false,
      },
    },
    properties: {
      roomName: {
        label: "Room Name",
        type: "string",
        defaultValue: "",
      },
      patternColor: {
        label: translator.t("color"),
        type: "color",
        defaultValue: "#fff",
      },
      opacity: {
        label: "opacity",
        type: "slider",
        min: 20,
        max: 100,
        defaultValue: 100,
      },
      deleteButton: {
        label: "Delete Room",
        type: "button",
        actionType: "delete-room",
        confirmationAlertMessage: "Are you sure you want to delete the room?",
        defaultValue: "",
      },
      // thickness: {
      //   label: translator.t('thickness'),
      //   type: 'length-measure',
      //   defaultValue: {
      //     length: 0,
      //   }
      // }
    },
    render2D: function (element, layer, scene) {
      let path = "";

      ///print area path
      element.vertices.forEach((vertexID, ind) => {
        let vertex = layer.vertices.get(vertexID);
        path += (ind ? "L" : "M") + vertex.x + " " + vertex.y + " ";
      });

      //add holes
      element.holes.forEach((areaID) => {
        let area = layer.areas.get(areaID);

        area.vertices.reverse().forEach((vertexID, ind) => {
          let vertex = layer.vertices.get(vertexID);
          path += (ind ? "L" : "M") + vertex.x + " " + vertex.y + " ";
        });
      });

      let fill = element.selected
        ? SharedStyle.AREA_MESH_COLOR.selected
        : element.properties.get("patternColor");

      return (
        <path
          opacity={element.properties.get("opacity")}
          d={path}
          fill={element.properties.get("patternColor")}
        />
      );
    },

    render3D: function (element, layer, scene) {
      return createArea(element, layer, scene, textures);
    },

    updateRender3D: (
      element,
      layer,
      scene,
      mesh,
      oldElement,
      differences,
      selfDestroy,
      selfBuild
    ) => {
      return updatedArea(
        element,
        layer,
        scene,
        textures,
        mesh,
        oldElement,
        differences,
        selfDestroy,
        selfBuild
      );
    },
  };

  if (textures && textures !== {}) {
    let textureValues = { none: "None" };

    for (let textureName in textures) {
      textureValues[textureName] = textures[textureName].name;
    }

    // areaElement.properties.texture = {
    //   label: translator.t('texture'),
    //   type: 'enum',
    //   defaultValue: 'none',
    //   values: textureValues
    // };
  }

  return areaElement;
}
