import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Circular-sink",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Circular sink",
    description: "Circular-sink",
    image: require("./Circular-sink.svg"),
    svgIcon: require("./Circular-sink.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M53.6,97.56c-13.93,0-24.18-3-33.19-9.62-9.79-7.17-16-16.74-17.56-28.89a37,37,0,0,1,3.8-22C13.29,24,24.13,16.27,38.13,12.69a67.37,67.37,0,0,1,6.79-1.18c.81-.12,1.09-.4,1.08-1.22a33.31,33.31,0,0,1,.15-4.12,5.41,5.41,0,0,1,3.48-4.76c3.09-1.2,6.35,1.2,6.7,4.92a27.75,27.75,0,0,1,.07,3.87c0,1,.31,1.35,1.31,1.47a47.41,47.41,0,0,1,19.47,6.57C89.24,25.53,97,35.93,98.68,50.12c1.33,11.13-2,21.14-9.11,29.77A47.81,47.81,0,0,1,53.6,97.56ZM45.87,22.19c-.5,0-.82,0-1.13.07-8.57,1.42-16,5-22,11.43a29.57,29.57,0,0,0-8.29,20.9c.09,9.44,4.1,17.16,11.18,23.26A36.62,36.62,0,0,0,50.11,86.9c13.37,0,24.53-4.88,32-16.27,6.29-9.55,6.75-19.84,1.46-30-5.42-10.4-14.53-15.92-25.8-18.2a6.1,6.1,0,0,0-1.14,0c0,1.81.05,3.55,0,5.29-.07,2.05-.94,3.06-3,3.31a17.66,17.66,0,0,1-4,.07C47,30.77,46,29.57,45.88,26.89,45.83,25.38,45.87,23.87,45.87,22.19Zm0-8.54c-4,1-7.8,1.62-11.41,2.9C21.94,21,12.64,29,7.84,41.49A35.26,35.26,0,0,0,9.23,70.56C15.08,82.48,25,89.78,37.56,93.23c12.93,3.53,25.58,2.24,37.25-4.51,14.19-8.21,22-20.38,21.06-37.21a35.55,35.55,0,0,0-8-20.37A44.59,44.59,0,0,0,64.59,15.69C62,15,59.36,14.55,56.67,14v5.87c4,1.29,7.83,2.21,11.42,3.79A34.7,34.7,0,0,1,86.3,41.14c7,15.07,1.21,31.27-11.7,40.3A40.43,40.43,0,0,1,44,88.31C32.76,86.49,23.5,81.17,17.35,71.39c-6.47-10.29-6.91-21-1.27-31.87,4.48-8.58,11.71-14.06,20.7-17.24,2.89-1,5.94-1.58,9-2.38Zm1.47-1.75c0,4.41,0,8.64,0,12.87,0,4.77.83,5.56,5.59,5,1.9-.2,2.39-.7,2.37-2.66,0-4.69-.15-9.38-.23-14.07,0-.39-.05-.78-.09-1.21a2.71,2.71,0,0,0-1.88,3c.15,2.8,0,5.61.06,8.41,0,1.25-.45,1.72-1.7,1.73s-1.83-.44-1.79-1.78c.07-2.57-.1-5.15.06-7.72A3.13,3.13,0,0,0,47.29,11.9Zm3.79,11.66h.58V22.3c0-2.85,0-5.7,0-8.54a2.18,2.18,0,0,1,1.13-2.14c2.72-1.72,3-6.33.51-8.41a3,3,0,0,0-4-.08C46.57,5.29,47,10.25,50,11.85a1.78,1.78,0,0,1,1.07,1.85c0,2.85,0,5.7,0,8.55Z"
              transform="translate(-2.52 -1.1)"
            />
            <path
              class="cls-1"
              d="M45.87,22.19c0,1.68,0,3.19,0,4.7.1,2.68,1.15,3.88,3.81,4.19a17.66,17.66,0,0,0,4-.07c2-.25,2.91-1.26,3-3.31.06-1.74,0-3.48,0-5.29a6.1,6.1,0,0,1,1.14,0c11.27,2.28,20.38,7.8,25.8,18.2,5.29,10.17,4.83,20.46-1.46,30C74.64,82,63.48,86.94,50.11,86.9a36.62,36.62,0,0,1-24.47-9.05C18.56,71.75,14.55,64,14.46,54.59a29.57,29.57,0,0,1,8.29-20.9c5.95-6.45,13.42-10,22-11.43C45.05,22.21,45.37,22.22,45.87,22.19ZM44.49,54.06a6,6,0,0,0,5.77,5.76c3.51.25,6.53-1.88,7-4.9A5.43,5.43,0,0,0,53.86,49C49.7,47,44.52,49.8,44.49,54.06Z"
              transform="translate(-2.52 -1.1)"
            />
            <path
              class="cls-1"
              d="M45.82,13.65V19.9c-3.1.8-6.15,1.36-9,2.38-9,3.18-16.22,8.66-20.7,17.24-5.64,10.82-5.2,21.58,1.27,31.87C23.5,81.17,32.76,86.49,44,88.31A40.43,40.43,0,0,0,74.6,81.44c12.91-9,18.67-25.23,11.7-40.3a34.7,34.7,0,0,0-18.21-17.5c-3.59-1.58-7.46-2.5-11.42-3.79V14c2.69.57,5.36,1,7.92,1.71A44.59,44.59,0,0,1,87.91,31.14a35.55,35.55,0,0,1,8,20.37c.89,16.83-6.87,29-21.06,37.21-11.67,6.75-24.32,8-37.25,4.51C25,89.78,15.08,82.48,9.23,70.56A35.26,35.26,0,0,1,7.84,41.49C12.64,29,21.94,21,34.41,16.55,38,15.27,41.86,14.64,45.82,13.65Z"
              transform="translate(-2.52 -1.1)"
            />
            <path
              class="cls-1"
              d="M47.29,11.9a3.13,3.13,0,0,1,2.39,3.61c-.16,2.57,0,5.15-.06,7.72,0,1.34.5,1.78,1.79,1.78s1.73-.48,1.7-1.73c-.06-2.8.09-5.61-.06-8.41a2.71,2.71,0,0,1,1.88-3c0,.43.09.82.09,1.21.08,4.69.18,9.38.23,14.07,0,2-.47,2.46-2.37,2.66-4.76.52-5.61-.27-5.59-5C47.3,20.54,47.29,16.31,47.29,11.9Z"
              transform="translate(-2.52 -1.1)"
            />
            <path
              class="cls-1"
              d="M51.08,23.56V22.25c0-2.85,0-5.7,0-8.55A1.78,1.78,0,0,0,50,11.85c-3.05-1.6-3.45-6.56-.76-8.72a3,3,0,0,1,4,.08c2.45,2.08,2.21,6.69-.51,8.41a2.18,2.18,0,0,0-1.13,2.14c0,2.84,0,5.69,0,8.54v1.26Z"
              transform="translate(-2.52 -1.1)"
            />
            <path
              d="M44.49,54.06c0-4.26,5.21-7,9.37-5a5.43,5.43,0,0,1,3.36,5.89c-.43,3-3.45,5.15-7,4.9A6,6,0,0,1,44.49,54.06Zm6.39,4.47c2.83,0,4.95-1.9,5-4.45a4.71,4.71,0,0,0-5-4.46A4.81,4.81,0,0,0,45.92,54,4.7,4.7,0,0,0,50.88,58.53Z"
              transform="translate(-2.52 -1.1)"
            />
            <path
              class="cls-1"
              d="M50.88,58.53a4.7,4.7,0,0,1-5-4.49,4.81,4.81,0,0,1,4.93-4.42,4.71,4.71,0,0,1,5,4.46C55.83,56.63,53.71,58.53,50.88,58.53Z"
              transform="translate(-2.52 -1.1)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
