import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Bath",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Bath",
    description: "Bath",
    image: require("./Bath.svg"),
    svgIcon: require("./Bath.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 135,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 135,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-18,55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M27.35,0H72.17V100H27.35ZM28,.63V99.35H71.5V.63Z"
              transform="translate(-27.35)"
            />
            <path
              class="cls-1"
              d="M28,.63H71.5V99.35H28ZM43.74,3.19c-2.85,0-5.66,0-8.46,0a3.43,3.43,0,0,0-3.65,2.3,13.43,13.43,0,0,0-.81,3.9c-.37,11.26-.67,22.53-1,33.8s-.59,22.43-.93,33.64a23.14,23.14,0,0,0,.74,7.09,19,19,0,0,0,5.26,8.82,20.66,20.66,0,0,0,9.55,4.93,20.74,20.74,0,0,0,7,.71A24.87,24.87,0,0,0,60,96a17.44,17.44,0,0,0,8.67-8.87,22.77,22.77,0,0,0,1.67-9.54q-.14-8.6-.31-17.18c-.23-11.58-.49-23.17-.72-34.75q-.18-9-.31-18a4.61,4.61,0,0,0-4.69-4.87c-1.18,0-2.36,0-3.54,0H56.54C56.13,1,55.76.76,54,1a11.22,11.22,0,0,0-1.6.37,10.31,10.31,0,0,1-4.59,0c-.77-.14-1.53-.36-2.3-.43a1.49,1.49,0,0,0-1.66,1.34C43.82,2.49,43.8,2.72,43.74,3.19Z"
              transform="translate(-27.35)"
            />
            <path
              d="M43.74,3.19c.06-.47.08-.7.12-.93A1.49,1.49,0,0,1,45.52.92c.77.07,1.53.29,2.3.43a10.31,10.31,0,0,0,4.59,0A11.22,11.22,0,0,1,54,1c1.75-.23,2.12,0,2.53,1.78h4.23c1.18,0,2.36,0,3.54,0A4.61,4.61,0,0,1,69,7.64q.12,9,.31,18c.23,11.58.49,23.17.72,34.75q.18,8.6.31,17.18a22.77,22.77,0,0,1-1.67,9.54A17.44,17.44,0,0,1,60,96a24.87,24.87,0,0,1-8.52,2.43,20.74,20.74,0,0,1-7-.71,20.66,20.66,0,0,1-9.55-4.93,19,19,0,0,1-5.26-8.82,23.14,23.14,0,0,1-.74-7.09c.34-11.21.62-22.43.93-33.64s.61-22.54,1-33.8a13.43,13.43,0,0,1,.81-3.9,3.43,3.43,0,0,1,3.65-2.3C38.08,3.21,40.89,3.19,43.74,3.19ZM68.9,44q-.27-11.94-.54-23.87-.14-6.27-.19-12.54a3.84,3.84,0,0,0-3.92-4c-2.5,0-5,0-7.49.06-.2,0-.47,0-.57.16-.85,1-2,.93-3.12.85s-1.27,0-1.3,1.08c0,1.31,0,2.62-.09,3.94a1.47,1.47,0,0,1-.93,1.5,1.44,1.44,0,0,1-2.06-1.34c-.08-1.28,0-2.57,0-3.86s-.07-1.31-1.34-1.29a2.74,2.74,0,0,1-.7,0c-.8-.2-1.58-.45-2.37-.67a2.29,2.29,0,0,0-.67-.14c-2.92,0-5.83,0-8.75.12a3,3,0,0,0-1.5.61,3.49,3.49,0,0,0-1.41,2.55c-.2,2-.34,4-.4,6Q31,32.21,30.45,51.32c-.24,8.93-.51,17.86-.72,26.79a18.2,18.2,0,0,0,5.15,13.47c4.27,4.45,9.81,6,15.76,6a20.48,20.48,0,0,0,10.92-3.42A16.11,16.11,0,0,0,68,86.54a23.4,23.4,0,0,0,1.5-9.5Q69.26,60.53,68.9,44ZM51.35,2.17H48.89l-.35,2c.6,0,.7.52.68,1.15,0,1.39,0,2.78,0,4.17,0,.65.16,1.18.94,1.18s1-.46,1-1.14c0-1.44.06-2.89.15-4.33A4.64,4.64,0,0,1,51.68,4Zm-5.12.49a1.06,1.06,0,0,0-.91-1.17,1.08,1.08,0,0,0-1,1.2,1.13,1.13,0,0,0,1,1.22A1.11,1.11,0,0,0,46.23,2.66Zm7.8,0A1.14,1.14,0,0,0,55,3.92a1.12,1.12,0,0,0,.93-1.25c0-.57-.2-1.11-.9-1.17C54.46,1.45,54,2,54,2.68Zm-.55-1.06c-.49.08-1.15.17-1.39.86a1.28,1.28,0,0,0,.12,1.07,1.48,1.48,0,0,0,1.53.58ZM46.55,4.11a1.35,1.35,0,0,0,1.72-1c.1-.84-.57-1.43-1.53-1.48Z"
              transform="translate(-27.35)"
            />
            <path
              class="cls-1"
              d="M68.9,44q.33,16.52.64,33a23.4,23.4,0,0,1-1.5,9.5,16.11,16.11,0,0,1-6.48,7.61,20.48,20.48,0,0,1-10.92,3.42c-6,0-11.49-1.54-15.76-6a18.2,18.2,0,0,1-5.15-13.47c.21-8.93.48-17.86.72-26.79Q31,32.21,31.51,13.1c.06-2,.2-4,.4-6a3.49,3.49,0,0,1,1.41-2.55A3,3,0,0,1,34.82,4c2.92-.09,5.83-.1,8.75-.12a2.29,2.29,0,0,1,.67.14c.79.22,1.57.47,2.37.67a2.74,2.74,0,0,0,.7,0c1.27,0,1.33,0,1.34,1.29s0,2.58,0,3.86a1.44,1.44,0,0,0,2.06,1.34,1.47,1.47,0,0,0,.93-1.5c.06-1.32.06-2.63.09-3.94,0-1,.23-1.16,1.3-1.08s2.27.17,3.12-.85c.1-.13.37-.16.57-.16,2.5,0,5-.1,7.49-.06a3.84,3.84,0,0,1,3.92,4q.06,6.27.19,12.54Q68.62,32.07,68.9,44Zm-21-27.31a2.19,2.19,0,0,0,4.37,0,2.19,2.19,0,1,0-4.37,0Z"
              transform="translate(-27.35)"
            />
            <path
              class="cls-1"
              d="M51.35,2.17,51.68,4a4.64,4.64,0,0,0-.4,1.19c-.09,1.44-.12,2.89-.15,4.33,0,.68-.23,1.14-1,1.14s-.94-.53-.94-1.18c0-1.39,0-2.78,0-4.17,0-.63-.08-1.1-.68-1.15l.35-2Z"
              transform="translate(-27.35)"
            />
            <path
              class="cls-1"
              d="M46.23,2.66a1.11,1.11,0,0,1-.92,1.25,1.13,1.13,0,0,1-1-1.22,1.08,1.08,0,0,1,1-1.2A1.06,1.06,0,0,1,46.23,2.66Z"
              transform="translate(-27.35)"
            />
            <path
              class="cls-1"
              d="M54,2.68c0-.69.43-1.23,1-1.18.7.06.86.6.9,1.17A1.12,1.12,0,0,1,55,3.92,1.14,1.14,0,0,1,54,2.68Z"
              transform="translate(-27.35)"
            />
            <path
              class="cls-1"
              d="M53.48,1.62l.26,2.51a1.48,1.48,0,0,1-1.53-.58,1.28,1.28,0,0,1-.12-1.07C52.33,1.79,53,1.7,53.48,1.62Z"
              transform="translate(-27.35)"
            />
            <path
              class="cls-1"
              d="M46.55,4.11l.19-2.47c1,.05,1.63.64,1.53,1.48A1.35,1.35,0,0,1,46.55,4.11Z"
              transform="translate(-27.35)"
            />
            <path
              d="M47.92,16.7a2.19,2.19,0,1,1,2.22,2.16A2.15,2.15,0,0,1,47.92,16.7Zm2.16-1.64a1.67,1.67,0,0,0-1.63,1.67,1.74,1.74,0,0,0,1.63,1.61,1.71,1.71,0,0,0,1.66-1.65A1.64,1.64,0,0,0,50.08,15.06Z"
              transform="translate(-27.35)"
            />
            <path
              class="cls-1"
              d="M50.08,15.06a1.64,1.64,0,0,1,1.66,1.63,1.71,1.71,0,0,1-1.66,1.65,1.74,1.74,0,0,1-1.63-1.61A1.67,1.67,0,0,1,50.08,15.06Z"
              transform="translate(-27.35)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
      rotation:{
        x1:0,
        x2:0,
        cx:0,
      },
      // box:{
      //   topleft:{
      //     x:0,
      //     y:0
      //   },
      //   topRight:{
      //     x:0,
      //     y:0
      //   },
      //   bottomLeft:{
      //     x:0,
      //     y:0
      //   },
      //   bottomRight:{
      //     x:0,
      //     y:0
      //   }
      // }
    };
  },

  render3D: function (element, layer, scene) {},
};
