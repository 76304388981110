import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-ControlPanel",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "control panel",
    description: "ControlPanel",
    image: require("./ControlPanel.svg"),
    svgIcon: require("./ControlPanel.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.controlpanelcls1{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path d="M2.74,10.13A1.88,1.88,0,0,0,2.86,10,1.26,1.26,0,0,1,4.1,9.09H94.26a3.77,3.77,0,0,1,.58,0,1.15,1.15,0,0,1,1,1.07c0,.15,0,.29,0,.44V85a3.55,3.55,0,0,1,0,.46,1.13,1.13,0,0,1-1,1,3,3,0,0,1-.52,0h-90a2.84,2.84,0,0,1-.58,0,1.23,1.23,0,0,1-.85-.7,1.7,1.7,0,0,0-.15-.22ZM93.27,11.7H5.4c-.07.42-.05,72,0,72.26H93.27Z" />
            <path
              class="controlpanelcls1"
              d="M93.27,11.7V84H5.41c-.06-.22-.08-71.84,0-72.26ZM48.65,47.8V16c0-.11,0-.22,0-.34a.69.69,0,0,0-.59-.66,2.31,2.31,0,0,0-.37,0H10.12a2,2,0,0,0-.49,0,.66.66,0,0,0-.57.64,2.53,2.53,0,0,0,0,.28v63.7c0,.1,0,.21,0,.31a.67.67,0,0,0,.54.6,1.75,1.75,0,0,0,.46,0H47.63a2.09,2.09,0,0,0,.43,0,.69.69,0,0,0,.59-.63c0-.12,0-.25,0-.37Zm33.09-3.11a3.14,3.14,0,0,0-3.16,3.15,3.15,3.15,0,0,0,6.3,0A3.13,3.13,0,0,0,81.74,44.69Z"
            />
            <path d="M48.65,47.8V79.57c0,.12,0,.25,0,.37a.69.69,0,0,1-.59.63,2.09,2.09,0,0,1-.43,0H10.06a1.75,1.75,0,0,1-.46,0,.67.67,0,0,1-.54-.6c0-.1,0-.21,0-.31V16a2.53,2.53,0,0,1,0-.28A.66.66,0,0,1,9.63,15a2,2,0,0,1,.49,0H47.69a2.31,2.31,0,0,1,.37,0,.69.69,0,0,1,.59.66c0,.12,0,.23,0,.34ZM12,77.62H45.69V18H12Z" />
            <path d="M81.74,44.69a3.13,3.13,0,0,1,3.14,3.16,3.15,3.15,0,0,1-6.3,0A3.14,3.14,0,0,1,81.74,44.69Z" />
            <path
              class="controlpanelcls1"
              d="M12,77.62V18H45.69V77.62Zm16.84-54H16.18a1.4,1.4,0,0,0-1.46,1.46c0,.22,0,.43,0,.65V28A1.32,1.32,0,0,0,16,29.44a2.37,2.37,0,0,0,.46,0H41.32a2.62,2.62,0,0,0,.28,0A1.34,1.34,0,0,0,43,28.12C43,27.07,43,26,43,25a1.28,1.28,0,0,0-.62-1.11,1.54,1.54,0,0,0-.89-.22Zm-3,24.55a3,3,0,0,0,5.94,0,3,3,0,0,0-5.94,0Zm3,5.61a3,3,0,1,0,3,3A3,3,0,0,0,28.8,53.82Zm-3,11.64a3,3,0,0,0,5.94,0,3,3,0,1,0-5.94,0ZM19.89,48.23a3,3,0,1,0-3,3A3,3,0,0,0,19.89,48.23Zm0,8.55a3,3,0,1,0-3,3A3,3,0,0,0,19.89,56.78Zm0,8.69a3,3,0,1,0-2.95,3A3,3,0,0,0,19.89,65.47ZM40.65,45.25a3,3,0,1,0,3,3A3,3,0,0,0,40.65,45.25Zm-3,11.52a3,3,0,1,0,3-3A3,3,0,0,0,37.68,56.77Zm3,5.73a3,3,0,0,0-3,3,3,3,0,0,0,6,0A3,3,0,0,0,40.64,62.5Z"
            />
            <path d="M28.86,23.66H41.49a1.54,1.54,0,0,1,.89.22A1.28,1.28,0,0,1,43,25c0,1.05,0,2.09,0,3.14a1.34,1.34,0,0,1-1.4,1.35,2.62,2.62,0,0,1-.28,0H16.41a2.37,2.37,0,0,1-.46,0A1.32,1.32,0,0,1,14.72,28V25.76c0-.22,0-.43,0-.65a1.4,1.4,0,0,1,1.46-1.46Z" />
            <path d="M25.82,48.21a3,3,0,1,1,3,3A3,3,0,0,1,25.82,48.21Z" />
            <path d="M28.8,53.82a3,3,0,1,1-3,2.93A3,3,0,0,1,28.8,53.82Z" />
            <path d="M25.82,65.46a3,3,0,1,1,5.94,0,3,3,0,0,1-5.94,0Z" />
            <path d="M19.89,48.23a3,3,0,1,1-2.94-3A2.95,2.95,0,0,1,19.89,48.23Z" />
            <path d="M19.89,56.78a3,3,0,1,1-3-3A3,3,0,0,1,19.89,56.78Z" />
            <path d="M19.89,65.47a3,3,0,0,1-5.94,0,3,3,0,1,1,5.94,0Z" />
            <path d="M40.65,45.25a3,3,0,1,1-3,3A3,3,0,0,1,40.65,45.25Z" />
            <path d="M37.68,56.77a3,3,0,1,1,3,3A3,3,0,0,1,37.68,56.77Z" />
            <path d="M40.64,62.5a3,3,0,0,1,3,3,3,3,0,0,1-6,0A3,3,0,0,1,40.64,62.5Z" />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
