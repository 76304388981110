import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Running-Man-Door-Symbol-Right",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Running Man Door Symbol Right",
    description: "Running-Man-Door-Symbol-Right",
    image: require("./Running-Man-Door-Symbol-Right.svg"),
    svgIcon: require("./Running-Man-Door-Symbol-Right.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.symbolrightcls1{fill:#fff;}.symbolrightcls2{fill:#009f4d;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
           <path class="symbolrightcls1" d="M77.33,100.07H14.07c0-.14.08-.28.13-.41q2.15-5.3,4.27-10.59a1.34,1.34,0,0,0,.11-.48v-15c0-.11,0-.22,0-.33l-.26,0H17A3.33,3.33,0,0,1,15.76,73a4.06,4.06,0,0,1-2-1.8A6.34,6.34,0,0,1,13,66.61a5.2,5.2,0,0,1,1.13-2.42,3.45,3.45,0,0,1,2.64-1.36h1.36c.14,0,.28,0,.4,0s0-.06,0-.07q0-27.1,0-54.2v-.1a0,0,0,0,1,0,0s0-.05,0-.07l0,0,0,0,.09,0H81.4l.2,0,0,0,.06.05V88.93c0,.1,0,.2,0,.3a.75.75,0,0,1-.05.26c-1.14,2.81-2.27,5.62-3.41,8.42C77.93,98.63,77.63,99.35,77.33,100.07ZM44.46,61.91a2.92,2.92,0,0,1,.23.19L52.85,70a.46.46,0,0,1,.15.5c-.3,1.3-.59,2.6-.88,3.9l-2.55,11.3a3.66,3.66,0,0,0,0,1.71,3.54,3.54,0,0,0,6.86-.06c.39-1.67.75-3.33,1.13-5Q59,76,60.41,69.7a24.13,24.13,0,0,0,.52-2.5,3.75,3.75,0,0,0-1.08-3.26c-2.36-2.35-4.7-4.7-7.05-7l-.29-.31.18-.24,8-9.24h.06l.06-.06a1.66,1.66,0,0,0,.22.3c.68.7,1.38,1.39,2.06,2.08,1,1,1.91,1.93,2.88,2.88a2.88,2.88,0,0,0,3.75.38,7.17,7.17,0,0,0,1-.86q4.13-4.14,8.24-8.3a2.68,2.68,0,0,0,.63-2.93A2.8,2.8,0,0,0,75,39.52l-6.63,6.62-.32.31-.33-.32Q63.66,42.08,59.62,38a3.69,3.69,0,0,0-2.77-1.16q-6.6,0-13.2,0a2.86,2.86,0,0,0-2.28,1.05c-1.36,1.58-2.74,3.16-4.11,4.73-1.78,2.05-3.58,4.1-5.37,6.15a2.75,2.75,0,0,0,1.21,4.41,2.81,2.81,0,0,0,3-.86c1.76-2.05,3.54-4.1,5.32-6.15l3.1-3.58c.11-.12.2-.24.4-.24,1.51,0,3,0,4.54,0a1.37,1.37,0,0,1,.24.07l-.32.39-11.19,13c-2.4,2.78-4.81,5.57-7.2,8.36a.63.63,0,0,1-.6.27c-.11,0-.22,0-.33,0l-12,0a6.2,6.2,0,0,0-.86,0,3.46,3.46,0,0,0-2.93,3.21,3.56,3.56,0,0,0,3.6,3.78c5,0,10.09,0,15.13,0a8.79,8.79,0,0,0,1.62-.14,4.52,4.52,0,0,0,2.75-1.5c2.25-2.54,4.53-5.06,6.8-7.59ZM63.79,27.32a5.49,5.49,0,1,0,5.46,5.5A5.48,5.48,0,0,0,63.79,27.32Z" transform="translate(-0.07 -0.07)"/>
    <path class="symbolrightcls2" d="M77.33,100.07c.3-.72.6-1.44.89-2.16,1.14-2.8,2.27-5.61,3.41-8.42a.75.75,0,0,0,.05-.26c0-.1,0-.2,0-.3V8.34l-.06-.05,0,0-.2,0H18.74l-.09,0,0,0,0,0s0,0,0,.07a0,0,0,0,0,0,0v.1q0,27.1,0,54.2s0,0,0,.07-.26,0-.4,0H16.79a3.45,3.45,0,0,0-2.64,1.36A5.2,5.2,0,0,0,13,66.61a6.34,6.34,0,0,0,.71,4.57,4.06,4.06,0,0,0,2,1.8A3.33,3.33,0,0,0,17,73.2H18.3l.26,0c0,.11,0,.22,0,.33v15a1.34,1.34,0,0,1-.11.48q-2.13,5.3-4.27,10.59c0,.13-.09.27-.13.41H.07V.07h100v100Z" transform="translate(-0.07 -0.07)"/>
    <path class="symbolrightcls2" d="M44.46,61.91l-.27.3c-2.27,2.53-4.55,5.05-6.8,7.59a4.52,4.52,0,0,1-2.75,1.5,8.79,8.79,0,0,1-1.62.14c-5,0-10.09,0-15.13,0a3.56,3.56,0,0,1-3.6-3.78,3.46,3.46,0,0,1,2.93-3.21,6.2,6.2,0,0,1,.86,0l12,0c.11,0,.22,0,.33,0a.63.63,0,0,0,.6-.27c2.39-2.79,4.8-5.58,7.2-8.36l11.19-13,.32-.39a1.37,1.37,0,0,0-.24-.07c-1.51,0-3,0-4.54,0-.2,0-.29.12-.4.24l-3.1,3.58c-1.78,2-3.56,4.1-5.32,6.15a2.81,2.81,0,0,1-3,.86,2.75,2.75,0,0,1-1.21-4.41c1.79-2,3.59-4.1,5.37-6.15,1.37-1.57,2.75-3.15,4.11-4.73a2.86,2.86,0,0,1,2.28-1.05q6.6,0,13.2,0A3.69,3.69,0,0,1,59.62,38q4.05,4.06,8.11,8.11l.33.32.32-.31L75,39.52a2.8,2.8,0,0,1,4.64,1.06A2.68,2.68,0,0,1,79,43.51q-4.11,4.16-8.24,8.3a7.17,7.17,0,0,1-1,.86A2.88,2.88,0,0,1,66,52.29c-1-.95-1.92-1.92-2.88-2.88-.68-.69-1.38-1.38-2.06-2.08a1.66,1.66,0,0,1-.22-.3l-.06.06H60.7l-8,9.24-.18.24.29.31c2.35,2.35,4.69,4.7,7.05,7a3.75,3.75,0,0,1,1.08,3.26,24.13,24.13,0,0,1-.52,2.5Q59,76,57.58,82.37c-.38,1.67-.74,3.33-1.13,5a3.54,3.54,0,0,1-6.86.06,3.66,3.66,0,0,1,0-1.71l2.55-11.3c.29-1.3.58-2.6.88-3.9a.46.46,0,0,0-.15-.5L44.69,62.1A2.92,2.92,0,0,0,44.46,61.91Z" transform="translate(-0.07 -0.07)"/>
    <path class="symbolrightcls2" d="M63.79,27.32a5.49,5.49,0,0,1,0,11,5.49,5.49,0,1,1,0-11Z" transform="translate(-0.07 -0.07)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
