import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import If from "../../utils/react-if";
import { connect } from "react-redux";
import { extractElementData } from "../../utils/objects-utils";

const STYLE_LINE = {
  fill: "#0096fd",
  stroke: "#0096fd",
};

const STYLE_CIRCLE = {
  fill: "#d1d1e6",
  stroke: "#d1d1e6",
  cursor: "ew-resize",
};

const STYLE_CIRCLE2 = {
  fill: "none",
  stroke: "#0096fd",
  cursor: "ew-resize",
};

const Item = function (
  { layer, item, scene, catalog, state },
  { itemsActions }
) {
  let { x, y, rotation } = item;
  let properties = item.properties.toJS();
  //  
  let scaleX =
    "scaleX" in properties
      ? Number.isInteger(properties.scaleX) && properties.scaleX > 5
        ? properties.scaleX / 100
        : properties.scaleX
      : 1.0;
  let scaleY =
    "scaleY" in properties
      ? Number.isInteger(properties.scaleY) && properties.scaleY > 5
        ? properties.scaleY / 100
        : properties.scaleY
      : 1.0;
  // const [bbox, setBBox] = useState(null);
  const [boundingClientRect, setBoundingClientRect] = useState({
    topLeftX: 0,
    topLeftY: 0,
    topRightX: 0,
    topRightY: 0,
    bottomLeftX: 0,
    bottomLeftY: 0,
    bottomRightX: 0,
    bottomRightY: 0,
  });
  // const [bbo]
  // let path = document.querySelector(`#${item.type}-${item.id}`);
  let g = document.getElementById(`resize-${item.id}`);
  useEffect(() => {
    if (g) {
      let {
        left,
        top,
        right,
        bottom,
        // x,
        // y,
        width: w,
        height: h,
      } = g.getBoundingClientRect();
      let { width, height, x, y } = g.getBBox();
      var transformMatrix = g.getCTM();

      var topLeftX = -width * (transformMatrix.a + Math.abs(transformMatrix.d));
      var topLeftY = height * (transformMatrix.a + Math.abs(transformMatrix.d));
      var topRightX = width * (transformMatrix.a + Math.abs(transformMatrix.d));
      var topRightY =
        height * (transformMatrix.a + Math.abs(transformMatrix.d));
      var bottomLeftX =
        -width * (transformMatrix.a + Math.abs(transformMatrix.d));
      var bottomLeftY =
        -height * (transformMatrix.a + Math.abs(transformMatrix.d));
      var bottomRightX =
        width * (transformMatrix.a + Math.abs(transformMatrix.d));
      var bottomRightY =
        -height * (transformMatrix.a + Math.abs(transformMatrix.d));

      // var topLeftX = x;
      // var topLeftY = y;
      // var topRightX =x + width;
      // var topRightY = y;
      // var bottomLeftX = x;
      // var bottomLeftY = y + height;
      // var bottomRightX = x + width;
      // var bottomRightY = y + height;

      // var svgRect = g.getBoundingClientRect();
      // var topLeftX = svgRect.left * transformMatrix.a + transformMatrix.e;
      // var topLeftY = svgRect.top * transformMatrix.d + transformMatrix.f;
      // var topRightX =
      //   (svgRect.left + svgRect.width) * transformMatrix.a + transformMatrix.e;
      // var topRightY = svgRect.top * transformMatrix.d + transformMatrix.f;
      // var bottomLeftX = svgRect.left * transformMatrix.a + transformMatrix.e;
      // var bottomLeftY =
      //   (svgRect.top + svgRect.height) * transformMatrix.d + transformMatrix.f;
      // var bottomRightX =
      //   (svgRect.left + svgRect.width) * transformMatrix.a + transformMatrix.e;
      // var bottomRightY =
      //   (svgRect.top + svgRect.height) * transformMatrix.d + transformMatrix.f;
      setBoundingClientRect({
        topLeftX,
        topLeftY,
        topRightX,
        topRightY,
        bottomLeftX,
        bottomLeftY,
        bottomRightX,
        bottomRightY,
        width: width * (transformMatrix.a + Math.abs(transformMatrix.d)),
        height: height * (transformMatrix.a + Math.abs(transformMatrix.d)),
      });
    }
    // if (path && !item.type.includes("stairs")) {
    //   setBBox(path.getBBox());
    // }
  }, [g, scaleX, scaleY]);
  let renderedItem = catalog
    .getElement(item.type)
    .render2D(item, layer, scene, {
      scaleX,
      scaleY,
    });
  return (
    <g
      data-element-root
      display={item.visible ? "block" : "none"}
      data-prototype={item.prototype}
      data-id={item.id}
      data-selected={item.selected}
      data-layer={layer.id}
      style={
        item.selected
          ? {
              cursor: "move",
              fontSize: `${
                12 / state.getIn(["react-planner"]).viewer2D.toJS().a < 12
                  ? 12
                  : 12 /
                    (state.getIn(["react-planner"]).viewer2D.toJS().a < 0.3
                      ? state.getIn(["react-planner"]).viewer2D.toJS().a * 1.5
                      : state.getIn(["react-planner"]).viewer2D.toJS().a)
              }px`,
            }
          : {
              fontSize: `${
                12 / state.getIn(["react-planner"]).viewer2D.toJS().a < 12
                  ? 12
                  : 12 /
                    (state.getIn(["react-planner"]).viewer2D.toJS().a < 0.3
                      ? state.getIn(["react-planner"]).viewer2D.toJS().a * 1.5
                      : state.getIn(["react-planner"]).viewer2D.toJS().a)
              }px`,
            }
      }
      transform={
        // item.type.includes("stairs")
        false
          ? `translate(${x},${y}) rotate(${rotation})`
          : `translate(${x},${y}) rotate(${rotation})`
      }
    >
      <If condition={item.selected}>
        <g
          data-element-root
          data-prototype={item.prototype}
          data-id={item.id}
          data-selected={item.selected}
          data-layer={layer.id}
          data-part="rotation-anchor"
        >
          <circle
            cx="0"
            cy={boundingClientRect.height + 10}
            r="10"
            style={STYLE_CIRCLE}
          />
          <line
            x1="0"
            y1={0}
            // y1={boundingClientRect.width}
            x2="0"
            y2={boundingClientRect.height + 10}
            stroke="#d1d1e6"
            stroke-width="4"
          />
        </g>
      </If>
      <g
        id={`resize-${item.id}`}
        transform={`scale(${scaleX},${
          item.type.includes("firesafety") ? scaleX : scaleY
        })`}
      >
        {React.isValidElement(renderedItem)
          ? renderedItem
          : renderedItem.element}
      </g>
      {!React.isValidElement(renderedItem) && item.selected && (
        <Fragment>
          <g
            data-element-root
            data-prototype={item.prototype}
            data-id={item.id}
            data-selected={item.selected}
            data-layer={layer.id}
            data-part="resize-box"
          >
            <rect
              // x={-(bbox.x + bbox.width / 2)}
              // y={bbox.y + bbox.height / 2}
              x={boundingClientRect.topLeftX}
              y={boundingClientRect.topLeftY}
              width="15"
              height="15"
              fill="#d1d1e6"
            />
          </g>
          <g
            data-element-root
            data-prototype={item.prototype}
            data-id={item.id}
            data-selected={item.selected}
            data-layer={layer.id}
            data-part="resize-box"
          >
            <rect
              // x={-(bbox.x + bbox.width / 2)}
              // y={-(bbox.y + bbox.height / 2)}
              x={boundingClientRect.topRightX}
              y={boundingClientRect.topRightY}
              width="15"
              height="15"
              fill="#d1d1e6"
            />
          </g>
          <g
            data-element-root
            data-prototype={item.prototype}
            data-id={item.id}
            data-selected={item.selected}
            data-layer={layer.id}
            data-part="resize-box"
          >
            <rect
              // x={bbox.x + bbox.width / 2}
              // y={bbox.y + bbox.height / 2}
              x={boundingClientRect.bottomLeftX}
              y={boundingClientRect.bottomLeftY}
              width="15"
              height="15"
              fill="#d1d1e6"
            />
          </g>
          <g
            data-element-root
            data-prototype={item.prototype}
            data-id={item.id}
            data-selected={item.selected}
            data-layer={layer.id}
            data-part="resize-box"
          >
            <rect
              // x={bbox.x + bbox.width / 2}
              // y={-(bbox.y + bbox.height / 2)}
              x={boundingClientRect.bottomRightX}
              y={boundingClientRect.bottomRightY}
              width="15"
              height="15"
              fill="#d1d1e6"
            />
          </g>
        </Fragment>
      )}
    </g>
  );
};
function mapStateToProps(reduxState) {
  return {
    state: reduxState,
  };
}
export default connect(mapStateToProps)(Item);

Item.propTypes = {
  item: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};
Item.contextTypes = {
  itemsActions: PropTypes.object.isRequired,
};
