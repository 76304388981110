import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "double-bed",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Double Bed",
    description: "double-bed",
    image: require("./double-bed.svg"),
    svgIcon: require("./double-bed.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-47, 40) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
          <path d="M6,86.76c0,.73-.37.85-.95.83-1.17,0-2.34,0-3.51,0-.55,0-.8-.21-.76-.76,0-.13,0-.26,0-.39V17.71c0-1.13,0-1.13,1.14-1.13s2.05,0,3.07,0c.6,0,1,.09,1,.83H33.31l64.58,0c1.13,0,1.15,0,1.15,1.15V85.65c0,1.12,0,1.12-1.1,1.12H6Zm28.8-68.68a.94.94,0,0,0,.09.18A44.05,44.05,0,0,0,45,25.32c.73.34.78.54.33,1.13a126.23,126.23,0,0,0-14,23.31A109.32,109.32,0,0,0,21.61,82.7c-.14,1.11-.24,2.23-.36,3.39H98.3v-68ZM7.42,51.26c.17,0,.28.05.38.05,4.94,0,9.89,0,14.83,0,.39,0,.51-.17.58-.51.24-1.12.52-2.24.76-3.36a21.33,21.33,0,0,0,.76-4.06c.08-7.69,0-15.38,0-23.08,0-.22,0-.45,0-.66H7.42ZM20.3,84.58c-.78-10.7.5-21.18,2.43-31.63H7.44V84.58ZM34.14,18.47l-.42,1a171.13,171.13,0,0,0-12,44,115.27,115.27,0,0,0-.93,18.1,4.43,4.43,0,0,0,.06.51c3-20.85,11.57-39.29,23.89-56.21C40.79,24.1,37.48,21.31,34.14,18.47ZM5.27,86.93V17.24H1.52V86.93Zm15.1-1.7H7.73c-1,0-1-.06-1-1V53.27c0-1,0-1,1-1,4.81,0,9.63,0,14.45,0a6.43,6.43,0,0,0,.74-.08V52c-.24,0-.48,0-.72,0H7.76c-1,0-1,0-1-1.06V20c0-1,0-1,1-1H24.41c1,0,1.05.05,1.05,1.05V40.85c1.32-3.85,2.47-7.7,3.8-11.49s2.81-7.52,4.24-11.3H6.09v68H20.43Z" transform="translate(-0.8 -16.57)"/>
    <path class="cls-1" d="M34.82,18.08H98.3v68h-77c.12-1.16.22-2.28.36-3.39a109.32,109.32,0,0,1,9.73-32.94,126.23,126.23,0,0,1,14-23.31c.45-.59.4-.79-.33-1.13a44.05,44.05,0,0,1-10.06-7.06A.94.94,0,0,1,34.82,18.08Z" transform="translate(-0.8 -16.57)"/>
    <path class="cls-1" d="M7.42,51.26V19.65H24.74c0,.21,0,.44,0,.66,0,7.7,0,15.39,0,23.08A21.33,21.33,0,0,1,24,47.45c-.24,1.12-.52,2.24-.76,3.36-.07.34-.19.51-.58.51-4.94,0-9.89,0-14.83,0C7.7,51.31,7.59,51.28,7.42,51.26Z" transform="translate(-0.8 -16.57)"/>
    <path class="cls-1" d="M20.3,84.58H7.44V53H22.73C20.8,63.4,19.52,73.88,20.3,84.58Z" transform="translate(-0.8 -16.57)"/>
    <path class="cls-1" d="M34.14,18.47c3.34,2.84,6.65,5.63,10.62,7.47C32.44,42.86,23.87,61.3,20.87,82.15a4.43,4.43,0,0,1-.06-.51,115.27,115.27,0,0,1,.93-18.1,171.13,171.13,0,0,1,12-44Z" transform="translate(-0.8 -16.57)"/>
    <path class="cls-1" d="M5.27,86.93H1.52V17.24H5.27Z" transform="translate(-0.8 -16.57)"/>
    <path class="cls-1" d="M20.37,85.23l.06.85H6.09v-68H33.5c-1.43,3.78-2.92,7.51-4.24,11.3S26.78,37,25.46,40.85V20c0-1,0-1.05-1.05-1.05H7.73c-1,0-1,.05-1,1V50.87c0,1,0,1.06,1,1.06H22.2c.24,0,.48,0,.72,0v.21a6.43,6.43,0,0,1-.74.08c-4.82,0-9.64,0-14.45,0-1,0-1,.05-1,1V84.22c0,1,.06,1,1,1H20.37Z" transform="translate(-0.8 -16.57)"/>

          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
