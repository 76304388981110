import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { GeometryUtils } from "../../utils/export";
import Ruler from "./ruler";
import {
  MODE_DRAGGING_SQUARE,
  MODE_DRAWING_SQUARE,
  MODE_IDLE,
} from "../../constants";

export default function Line({ line, layer, scene, catalog }, { store }) {
  let mode = store.getState().getIn(["react-planner"]).get("mode");
  let draggingSupport = store
    .getState()
    .getIn(["react-planner"])
    .get("draggingSupport");

  let vertex0 = layer.vertices.get(line.vertices.get(0));
  let vertex1 = layer.vertices.get(line.vertices.get(1));
  if (vertex0.id === vertex1.id || GeometryUtils.samePoints(vertex0, vertex1))
    return null; //avoid 0-length lines

  let { x: x1, y: y1 } = vertex0;
  let { x: x2, y: y2 } = vertex1;

  if (x1 > x2) {
    ({ x: x1, y: y1 } = vertex1);
    ({ x: x2, y: y2 } = vertex0);
  }

  let length = GeometryUtils.pointsDistance(x1, y1, x2, y2);
  let angle = GeometryUtils.angleBetweenTwoPointsAndOrigin(x1, y1, x2, y2);
  let renderedHoles = line.holes
    .filter((holeID) => {
      let hole = layer.holes.get(holeID);
      return hole.visible;
    })
    .map((holeID) => {
      let hole = layer.holes.get(holeID);
      let startAt = length * hole.offset;
      let renderedHole = catalog
        .getElement(hole.type)
        .render2D(hole, layer, scene);

      return (
        <g
          key={holeID}
          transform={`translate(${startAt}, 0)`}
          data-element-root
          data-prototype={hole.prototype}
          data-id={hole.id}
          data-selected={hole.selected}
          data-layer={layer.id}
        >
          {renderedHole}
        </g>
      );
    });

  let thickness = line.getIn(["properties", "thickness"]);
  let half_thickness = thickness / 2;

  let renderedLine = catalog
    .getElement(line.type)
    .render2D(
      line,
      layer,
      mode === MODE_DRAWING_SQUARE &&
        draggingSupport.get("squareLines").some((l) => l.id === line.id)
        ? MODE_DRAWING_SQUARE
        : MODE_IDLE
    );

  let renderedRuler =
    line.selected ||
    (mode === MODE_DRAWING_SQUARE &&
      draggingSupport.get("squareLines").some((l) => l.id === line.id)) ? (
      <Ruler
        unit={scene.unit}
        length={length}
        transform={`translate(0, ${half_thickness + 10} )`}
      />
    ) : null;

  return (
    <g
      transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
      data-element-root
      data-prototype={line.prototype}
      data-id={line.id}
      data-selected={line.selected}
      data-layer={layer.id}
    >
      {renderedRuler}
      {renderedLine}
      {renderedHoles}
    </g>
  );
}

Line.propTypes = {
  line: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};
Line.contextTypes = {
  store: PropTypes.object.isRequired,
};
