import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "parasol",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Parasol",
    description: "parasol",
    image: require("./parasol.svg"),
    svgIcon: require("./parasol.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M8.92,49.65c0-5.44,0-10.88,0-16.32a2,2,0,0,1,.62-1.55Q21,20.32,32.48,8.82a2.05,2.05,0,0,1,1.6-.68q16.28,0,32.56,0a2,2,0,0,1,1.54.63q11.47,11.54,23,23a2,2,0,0,1,.66,1.53q0,16.32,0,32.64a2,2,0,0,1-.64,1.55Q79.65,79.07,68.11,90.6a2.12,2.12,0,0,1-1.35.58q-16.41.06-32.82,0a2,2,0,0,1-1.29-.53Q21,79.1,9.46,67.44A2,2,0,0,1,9,66.15C8.91,60.65,8.92,55.15,8.92,49.65Zm82.47.07c0-5.42,0-10.83,0-16.24A1.88,1.88,0,0,0,90.8,32Q79.37,20.62,68,9.17a2,2,0,0,0-1.55-.61q-16.1,0-32.2,0a1.87,1.87,0,0,0-1.46.6Q21.34,20.64,9.89,32.07a1.88,1.88,0,0,0-.6,1.46q0,16.14,0,32.29a1.89,1.89,0,0,0,.6,1.47q11.46,11.43,22.9,22.88a2.12,2.12,0,0,0,1.36.58c10.79,0,21.58,0,32.37,0A1.87,1.87,0,0,0,68,90.17Q79.38,78.73,90.81,67.33a1.9,1.9,0,0,0,.59-1.47C91.38,60.48,91.39,55.1,91.39,49.72Z"
              transform="translate(-8.91 -8.14)"
            />
            <path
              class="cls-1"
              d="M91.39,49.72c0,5.38,0,10.76,0,16.14a1.9,1.9,0,0,1-.59,1.47Q79.38,78.73,68,90.17a1.87,1.87,0,0,1-1.47.61c-10.79,0-21.58,0-32.37,0a2.12,2.12,0,0,1-1.36-.58Q21.32,78.76,9.89,67.29a1.89,1.89,0,0,1-.6-1.47q0-16.14,0-32.29a1.88,1.88,0,0,1,.6-1.46Q21.35,20.63,32.77,9.16a1.87,1.87,0,0,1,1.46-.6q16.1,0,32.2,0A2,2,0,0,1,68,9.17Q79.36,20.62,90.8,32a1.88,1.88,0,0,1,.6,1.46C91.38,38.89,91.39,44.3,91.39,49.72ZM13.65,34l-.1.31c.28.12.55.26.84.38L40.63,45.53c2.44,1,4.89,2,7.32,3.06a.88.88,0,0,1,.48.67c0,.23-.24.52-.46.69a3.66,3.66,0,0,1-.88.38L14.41,63.89c-.29.12-.57.29-.86.43l.1.26a9,9,0,0,0,.92-.29L47.82,50.51c.37-.15.71-.48,1.18,0s.14.8,0,1.17q-6.89,16.7-13.8,33.39c-.11.27-.19.55-.29.82l.27.12a7.08,7.08,0,0,0,.41-.77Q38.32,78.63,41,72c2.81-6.78,5.63-13.57,8.41-20.36.27-.65.66-.82,1.2-.57.22.11.31.53.44.83Q58,68.54,64.84,85.15c.12.29.29.56.44.84l.27-.12c-.1-.28-.19-.56-.3-.83L51.42,51.56c-.15-.35-.41-.66,0-1.08s.75-.15,1.1,0L68.76,57.2,86,64.32a6.52,6.52,0,0,0,.87.22l.06-.27L86,63.89,69.9,57.19c-5.73-2.37-11.46-4.76-17.2-7.12-.54-.22-.92-.5-.69-1.07.11-.26.51-.44.82-.56L86,34.67a9.94,9.94,0,0,0,.92-.47l-.11-.27c-.37.13-.74.24-1.09.39L52.61,48c-.37.15-.72.49-1.18,0s-.12-.82,0-1.19L65.17,13.73c.14-.35.24-.72.36-1.08l-.27-.11c-.13.26-.28.51-.39.78L59.57,26.1Q55.3,36.45,51,46.79c-.21.52-.47.94-1.05.7-.26-.1-.45-.5-.57-.8L35.61,13.39c-.12-.29-.29-.56-.44-.85l-.26.12c.13.36.23.73.38,1.08L49,46.89c.15.37.46.72,0,1.18s-.82.11-1.18,0L14.73,34.31C14.38,34.17,14,34.07,13.65,34Z"
              transform="translate(-8.91 -8.14)"
            />
            <path
              d="M13.65,34c.36.12.73.22,1.08.36L47.82,48c.36.15.71.5,1.18,0s.15-.81,0-1.18L35.29,13.74c-.15-.35-.25-.72-.38-1.08l.26-.12c.15.29.32.56.44.85L49.4,46.69c.12.3.31.7.57.8.58.24.84-.18,1.05-.7q4.26-10.35,8.55-20.69l5.3-12.78c.11-.27.26-.52.39-.78l.27.11c-.12.36-.22.73-.36,1.08L51.46,46.87c-.15.37-.49.72,0,1.19s.81.13,1.18,0L85.7,34.32c.35-.15.72-.26,1.09-.39l.11.27a9.94,9.94,0,0,1-.92.47L52.83,48.44c-.31.12-.71.3-.82.56-.23.57.15.85.69,1.07,5.74,2.36,11.47,4.75,17.2,7.12L86,63.89l.85.38-.06.27a6.52,6.52,0,0,1-.87-.22L68.76,57.2,52.54,50.47c-.35-.14-.66-.42-1.1,0s-.17.73,0,1.08L65.25,85c.11.27.2.55.3.83l-.27.12c-.15-.28-.32-.55-.44-.84Q58,68.54,51.09,51.93c-.13-.3-.22-.72-.44-.83-.54-.25-.93-.08-1.2.57C46.67,58.46,43.85,65.25,41,72q-2.73,6.6-5.46,13.19a7.08,7.08,0,0,1-.41.77l-.27-.12c.1-.27.18-.55.29-.82Q42.09,68.36,49,51.66c.15-.37.49-.7,0-1.17s-.81-.13-1.18,0L14.57,64.29a9,9,0,0,1-.92.29l-.1-.26c.29-.14.57-.31.86-.43L47.09,50.33A3.66,3.66,0,0,0,48,50c.22-.17.47-.46.46-.69a.88.88,0,0,0-.48-.67c-2.43-1.05-4.88-2.05-7.32-3.06L14.39,34.64c-.29-.12-.56-.26-.84-.38Z"
              transform="translate(-8.91 -8.14)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
