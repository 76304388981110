import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Dishwasher",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Dishwasher",
    description: "Dishwasher",
    image: require("./Dishwasher.svg"),
    svgIcon: require("./Dishwasher.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g id={`${element.type}-${element.id}`} transform={`translate(-47, 55) scale(1,-1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <path
            d="M6.68,92.89c-3.25-.36-3.25-.36-3.25-3.58V5.83c0-2.94.21-3.16,3.11-3.16H94c2.86,0,3.14.28,3.14,3.16V89.12c0,3.33,0,3.33-3.17,3.81-.06.1-.17.2-.18.3-.3,3.31-.3,3.32-3.75,3.32H10.63C7,96.55,7,96.55,6.68,92.89Zm-.92-88V90.51H94.81V4.9ZM50.4,94.42H89a5.45,5.45,0,0,0,1.46,0c.37-.1.67-.48,1-.73a4.72,4.72,0,0,0-1-.86,2.81,2.81,0,0,0-1.09,0H11.21a5,5,0,0,0-1.45,0c-.3.1-.69.53-.67.79a1.11,1.11,0,0,0,.71.77,6.88,6.88,0,0,0,1.64,0Z"
            transform="translate(-3.43 -2.67)"
          />
          <path
            class="cls-1"
            d="M5.76,4.9H94.81V90.51h-89ZM33.28,71h12l-.11-2.06H40.5V51.69c2.22-1,4.42-1.73,6.22-3,6-4.13,8.54-13.74,5.53-20.38A3,3,0,0,0,50.05,27q-10.8-.16-21.59,0a2.87,2.87,0,0,0-2.19,1.44C22.48,38.15,28.1,49.47,37,51.07a1.89,1.89,0,0,1,1.16,1.39c.09,5.06,0,10.12.07,15.18,0,1-.38,1.42-1.39,1.37s-2.29,0-3.51,0Zm34.35-.55V42.21c0-.85,0-1.53,1-2.07,5-2.66,5.68-10.4,1.28-14a5.24,5.24,0,0,0-6.3-.31c-4.7,3.1-4.42,11.53.55,14.16a2.1,2.1,0,0,1,1.28,2.25c0,8.66,0,17.33,0,26v2.09Z"
            transform="translate(-3.43 -2.67)"
          />
          <path
            class="cls-1"
            d="M50.4,94.42h-39a6.88,6.88,0,0,1-1.64,0,1.11,1.11,0,0,1-.71-.77c0-.26.37-.69.67-.79a5,5,0,0,1,1.45,0h78.1a2.81,2.81,0,0,1,1.09,0,4.72,4.72,0,0,1,1,.86c-.33.25-.63.63-1,.73a5.45,5.45,0,0,1-1.46,0Z"
            transform="translate(-3.43 -2.67)"
          />
          <path
            d="M33.28,71V69c1.22,0,2.37,0,3.51,0s1.4-.32,1.39-1.37c0-5.06,0-10.12-.07-15.18A1.89,1.89,0,0,0,37,51.07c-8.85-1.6-14.47-12.92-10.68-22.68A2.87,2.87,0,0,1,28.46,27q10.8-.16,21.59,0a3,3,0,0,1,2.2,1.39c3,6.64.45,16.25-5.53,20.38-1.8,1.24-4,1.93-6.22,3V68.91h4.68L45.29,71Zm6.1-42c-3.23,0-6.46-.05-9.69,0a2.14,2.14,0,0,0-1.62.94c-2.16,5.42,0,13.12,4.66,16.73A10.17,10.17,0,0,0,45,47.24C50.2,43.73,52.72,36,50.45,30a2,2,0,0,0-1.56-1C45.72,28.93,42.55,29,39.38,29Z"
            transform="translate(-3.43 -2.67)"
          />
          <path
            d="M67.63,70.42l-2.23-.11V68.22c0-8.66,0-17.33,0-26A2.1,2.1,0,0,0,64.14,40c-5-2.63-5.25-11.06-.55-14.16a5.24,5.24,0,0,1,6.3.31c4.4,3.61,3.74,11.35-1.28,14-1,.54-1,1.22-1,2.07V70.42Z"
            transform="translate(-3.43 -2.67)"
          />
          <path
            class="cls-1"
            d="M39.38,29c3.17,0,6.34-.05,9.51.05a2,2,0,0,1,1.56,1C52.72,36,50.2,43.73,45,47.24a10.17,10.17,0,0,1-12.25-.55C28.1,43.08,25.91,35.38,28.07,30A2.14,2.14,0,0,1,29.69,29C32.92,28.93,36.15,29,39.38,29Z"
            transform="translate(-3.43 -2.67)"
          />
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
