import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Officechair",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Office chair",
    description: "Officechair",
    image: require("./Officechair.svg"),
    svgIcon: require("./Officechair.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g
          id={`${element.type}-${element.id}`}
          transform={`translate(-47, 55) scale(1,-1)`}
        >
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id="Office_Chair" data-name="Office Chair">
            <path
              d="M92.85,78.92c-1,3.28-1.53,6.42-2.85,9.19-3.14,6.63-8.42,10.25-16,10.24-16.21,0-32.42-.13-48.63,0-10.88.11-17.26-9.14-18-17.07-.07-.75-.16-1.49-.26-2.32-.58,0-1.07-.1-1.55-.13A4,4,0,0,1,1.44,74.5c-.05-3.46,0-6.91,0-10.37q0-19.68,0-39.38c0-4.44,1.36-6.09,5.67-5.71,3.2.28,4.85-1.25,6.34-3.75l-.76-1.08a8.59,8.59,0,0,1-1-8.76,5.71,5.71,0,0,1,5.69-3.81c21.3.06,42.59.11,63.89,0,5.07,0,7.38,5.25,6.23,9.62a27.58,27.58,0,0,1-1.71,3.93c.78,1,1.65,2.2,2.66,3.29a2.36,2.36,0,0,0,1.52.47c1.31.08,2.64,0,4,.06a3.82,3.82,0,0,1,3.68,3.4,14,14,0,0,1,.17,2.24q0,24.23,0,48.44A10.11,10.11,0,0,1,97,76.87C96.22,78.78,94.36,78.94,92.85,78.92Zm-3.34,0h-5c-3.5,0-5.09-1.54-5.09-5.05q0-24.88,0-49.77c0-3.45,1.35-4.81,4.83-5.13a2.43,2.43,0,0,0,.66-.26,4.67,4.67,0,0,0-4.31-2.31q-31,.11-62,0A4.45,4.45,0,0,0,14.5,18.7c4.49.9,5.34,2,5.33,6.5q0,23.57-.11,47.13c0,.69,0,1.38,0,2.07-.28,3.17-1.67,4.45-4.85,4.47h-5c-.44,7.36,5.56,16.54,14.49,16.54,16.91,0,33.81.1,50.72,0C83.38,95.29,90.47,87.12,89.51,78.87ZM17.26,49q0-12.15,0-24.31c0-2.53-.24-2.76-2.83-2.77-2.77,0-5.53.06-8.29,0-1.63,0-2.27.64-2.27,2.22q0,24.8-.12,49.57c0,1.75.81,2.48,2.56,2.43,2.63-.07,5.27,0,7.91,0,2.83,0,3-.21,3-3Zm78.05,0h.06q0-12.26,0-24.5c0-2.33-.24-2.56-2.62-2.59l-7.53-.07c-3,0-3.29.23-3.29,3.33q0,16.49,0,33c0,5.09,0,10.17,0,15.26,0,2.3.3,2.57,2.63,2.6l7.91.09c2.52,0,2.84-.3,2.84-2.79Q95.32,61.1,95.31,49ZM49.5,4.5H18c-1.42,0-2.82,0-3.65,1.43a5.65,5.65,0,0,0-.32,5.58c.84,1.83,2.44,2.08,4.26,2.08q19.89,0,39.77,0c7.61,0,15.21,0,22.81,0,1.9,0,3.3-.6,4-2.42,1.48-3.52-.55-6.66-4.33-6.66Z"
              transform="translate(-1.41 -1.61)"
            />
            <path
              class="cls-1"
              d="M89.51,78.87c1,8.25-6.13,16.42-14.44,16.49-16.91.15-33.81,0-50.72,0-8.93,0-14.93-9.18-14.49-16.54h5c3.18,0,4.57-1.3,4.85-4.47.06-.69,0-1.38,0-2.07q.06-23.56.11-47.13c0-4.54-.84-5.6-5.33-6.5a4.45,4.45,0,0,1,4.14-2.34q31,.09,62,0A4.67,4.67,0,0,1,85,18.66a2.43,2.43,0,0,1-.66.26c-3.48.32-4.82,1.68-4.83,5.13q0,24.89,0,49.77c0,3.51,1.59,5,5.09,5.05Z"
              transform="translate(-1.41 -1.61)"
            />
            <path
              class="cls-1"
              d="M17.26,49V73.08c0,2.76-.21,3-3,3-2.64,0-5.28,0-7.91,0-1.75.05-2.57-.68-2.56-2.43q.07-24.78.12-49.57c0-1.58.64-2.26,2.27-2.22,2.76.08,5.52,0,8.29,0,2.59,0,2.83.24,2.83,2.77Q17.27,36.81,17.26,49Z"
              transform="translate(-1.41 -1.61)"
            />
            <path
              class="cls-1"
              d="M95.31,49q0,12.15,0,24.3c0,2.49-.32,2.8-2.84,2.79L84.56,76c-2.33,0-2.62-.3-2.63-2.6,0-5.09,0-10.17,0-15.26q0-16.48,0-33c0-3.1.26-3.36,3.29-3.33l7.53.07c2.38,0,2.62.26,2.62,2.59q0,12.26,0,24.5Z"
              transform="translate(-1.41 -1.61)"
            />
            <path
              class="cls-1"
              d="M49.5,4.5H80.61c3.78,0,5.81,3.14,4.33,6.66-.75,1.82-2.15,2.43-4,2.42-7.6,0-15.2,0-22.81,0q-19.89,0-39.77,0c-1.82,0-3.42-.25-4.26-2.08a5.65,5.65,0,0,1,.32-5.58C15.2,4.52,16.6,4.49,18,4.49Z"
              transform="translate(-1.41 -1.61)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
