import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "Fancy-toilet",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Fancy Toilet",
    description: "Fancy-toilet",
    image: require("./Fancy-toilet.svg"),
    svgIcon: require("./Fancy-toilet.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 500,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;
    return {
      element: (
        <g transform={`translate(-47, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g transform={`rotate(180, ${WIDTH / 2}, ${WIDTH / 2})`} id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M28.11,24.91c-2.17-.23-4.29-.32-6.36-.7C16.16,23.16,11.33,20.66,7.91,16A14.27,14.27,0,0,1,6.23,1.09,1.08,1.08,0,0,1,7.34.35c.18,0,.37,0,.56,0q42.89,0,85.77,0a1.72,1.72,0,0,1,1.86,1.22,15,15,0,0,1-2.9,15.73c-.82.85-1.54,1.79-2.38,2.78.59,1.29.5,2.13-.52,3a3.6,3.6,0,0,1-4.34.18A1.26,1.26,0,0,0,84,23.05a26.89,26.89,0,0,1-9.61,1.7H73c.35.43.57.72.81,1A43.81,43.81,0,0,1,83.7,45.68a50.75,50.75,0,0,1-2.49,30.84A38.74,38.74,0,0,1,66.7,94.75a28.38,28.38,0,0,1-17.35,4.88A29.78,29.78,0,0,1,30.85,92a39.88,39.88,0,0,1-9.74-13,47.17,47.17,0,0,1-4.44-15c-1.36-11.21.4-21.87,6.09-31.72a76.91,76.91,0,0,1,4.74-6.57C27.69,25.4,27.9,25.17,28.11,24.91ZM50.78,1.23H42.34q-17,0-34,0A1.17,1.17,0,0,0,7,2.07a13.17,13.17,0,0,0-.79,7.18c.86,5.14,3.95,8.7,8.27,11.29a24.75,24.75,0,0,0,13,3.29c15.47,0,31,0,46.42,0a31.29,31.29,0,0,0,5.12-.4c5.57-1,10.37-3.35,13.72-8a13.51,13.51,0,0,0,1.78-13.2,1.22,1.22,0,0,0-1.37-1Q72,1.25,50.78,1.23ZM83.83,57.79a50.35,50.35,0,0,0-3.64-19.58,42.28,42.28,0,0,0-8-12.73,2.13,2.13,0,0,0-1.73-.75q-20,0-39.9,0a1.63,1.63,0,0,0-1.33.55,40.88,40.88,0,0,0-6.92,10A50,50,0,0,0,17.56,61.5a47,47,0,0,0,6,19.76C26.9,87.08,31.23,92,37.23,95.2,47.05,100.47,56.71,99.92,66,94A36.1,36.1,0,0,0,78.87,79,47.36,47.36,0,0,0,83.83,57.79ZM85.9,22.31a2.31,2.31,0,0,0,3,.11,1.35,1.35,0,0,0,.23-2Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              class="cls-1"
              d="M50.78,1.23q21.21,0,42.4,0a1.22,1.22,0,0,1,1.37,1,13.51,13.51,0,0,1-1.78,13.2c-3.35,4.69-8.15,7.08-13.72,8a31.29,31.29,0,0,1-5.12.4c-15.47,0-31,0-46.42,0a24.75,24.75,0,0,1-13-3.29C10.18,18,7.09,14.39,6.23,9.25A13.17,13.17,0,0,1,7,2.07a1.17,1.17,0,0,1,1.29-.86q17,0,34,0Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              class="cls-1"
              d="M83.83,57.79a47.36,47.36,0,0,1-5,21.23A36.1,36.1,0,0,1,66,94c-9.32,5.94-19,6.49-28.8,1.22-6-3.22-10.33-8.12-13.67-13.94a47,47,0,0,1-6-19.76A50,50,0,0,1,22.3,35.32a40.88,40.88,0,0,1,6.92-10,1.63,1.63,0,0,1,1.33-.55q20,0,39.9,0a2.13,2.13,0,0,1,1.73.75,42.28,42.28,0,0,1,8,12.73A50.35,50.35,0,0,1,83.83,57.79ZM50.8,32C45,32,39.27,32,33.5,32a1.85,1.85,0,0,0-1.61.7c-.47.58-1,1.1-1.49,1.68a36.5,36.5,0,0,0-8,20.52,38.53,38.53,0,0,0,2.95,18.54C28.25,80.08,32.57,85.47,39,89a23.67,23.67,0,0,0,20.87,1.27A28.51,28.51,0,0,0,74.51,76.82a38.61,38.61,0,0,0,4-26,35,35,0,0,0-8.87-18.08A2.13,2.13,0,0,0,67.93,32C62.22,32,56.51,32,50.8,32Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              class="cls-1"
              d="M85.9,22.31l3.24-1.87a1.35,1.35,0,0,1-.23,2A2.31,2.31,0,0,1,85.9,22.31Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              d="M50.8,32c5.71,0,11.42,0,17.13,0a2.13,2.13,0,0,1,1.75.74,35,35,0,0,1,8.87,18.08,38.61,38.61,0,0,1-4,26A28.51,28.51,0,0,1,59.87,90.25,23.67,23.67,0,0,1,39,89c-6.43-3.51-10.75-8.9-13.61-15.55a38.53,38.53,0,0,1-2.95-18.54,36.5,36.5,0,0,1,8-20.52c.47-.58,1-1.1,1.49-1.68A1.85,1.85,0,0,1,33.5,32C39.27,32,45,32,50.8,32Zm-.06.93c-5.63,0-11.26,0-16.89,0a1.6,1.6,0,0,0-1.31.58,35.48,35.48,0,0,0-9,21.57,37.56,37.56,0,0,0,2.78,17.79,30.37,30.37,0,0,0,13.52,15.5A22,22,0,0,0,51,91.11c8-.16,14.31-3.88,19.29-10a34.69,34.69,0,0,0,7.4-17.47,38.36,38.36,0,0,0-.75-15.16,34.92,34.92,0,0,0-7.85-14.94,1.84,1.84,0,0,0-1.52-.67C62,32.94,56.35,32.93,50.74,32.93Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              class="cls-1"
              d="M50.74,32.93c5.61,0,11.21,0,16.82,0a1.84,1.84,0,0,1,1.52.67,34.92,34.92,0,0,1,7.85,14.94,38.36,38.36,0,0,1,.75,15.16,34.69,34.69,0,0,1-7.4,17.47C65.3,87.23,59,91,51,91.11a22,22,0,0,1-11.12-2.75,30.37,30.37,0,0,1-13.52-15.5,37.56,37.56,0,0,1-2.78-17.79,35.48,35.48,0,0,1,9-21.57,1.6,1.6,0,0,1,1.31-.58C39.48,32.94,45.11,32.93,50.74,32.93ZM27.89,61.61a34.46,34.46,0,0,0,2.6,13.53c2.28,5.4,5.64,9.92,10.82,12.87A18,18,0,0,0,56.2,89.63c5.42-1.72,9.36-5.35,12.27-10.11a33.82,33.82,0,0,0,4.62-21.77,30.9,30.9,0,0,0-7.36-17.41,2.13,2.13,0,0,0-1.35-.65,21,21,0,0,0-5.45.59,48.7,48.7,0,0,1-16.25.29c-1-.12-1.87-.5-2.82-.62-1.21-.15-2.43-.18-3.65-.22a.83.83,0,0,0-.52.34,49.08,49.08,0,0,0-3.26,4.47A33.21,33.21,0,0,0,27.89,61.61Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              d="M27.89,61.61a33.21,33.21,0,0,1,4.54-17.07,49.08,49.08,0,0,1,3.26-4.47.83.83,0,0,1,.52-.34c1.22,0,2.44.07,3.65.22,1,.12,1.87.5,2.82.62a48.7,48.7,0,0,0,16.25-.29,21,21,0,0,1,5.45-.59,2.13,2.13,0,0,1,1.35.65,30.9,30.9,0,0,1,7.36,17.41,33.82,33.82,0,0,1-4.62,21.77c-2.91,4.76-6.85,8.39-12.27,10.11A18,18,0,0,1,41.31,88c-5.18-3-8.54-7.47-10.82-12.87A34.46,34.46,0,0,1,27.89,61.61Zm1.13,1a34.32,34.32,0,0,0,1.59,9.62,27.06,27.06,0,0,0,9.61,13.91,17.46,17.46,0,0,0,20.73,0A26.67,26.67,0,0,0,70.66,72a34.39,34.39,0,0,0,1.17-15A30.17,30.17,0,0,0,65,41.1c-.15-.18-.34-.46-.51-.46a28.59,28.59,0,0,0-3.59.11c-.92.11-1.8.53-2.72.67a54.88,54.88,0,0,1-13.79.28c-1.52-.15-3-.53-4.51-.77-1-.15-1.94-.24-2.91-.32a.78.78,0,0,0-.54.27c-.54.63-1.09,1.25-1.57,1.93C30.86,48.48,29.09,54.83,29,62.62Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              class="cls-1"
              d="M29,62.62c.07-7.79,1.84-14.14,5.82-19.81.48-.68,1-1.3,1.57-1.93a.78.78,0,0,1,.54-.27c1,.08,1.95.17,2.91.32,1.51.24,3,.62,4.51.77a54.88,54.88,0,0,0,13.79-.28c.92-.14,1.8-.56,2.72-.67a28.59,28.59,0,0,1,3.59-.11c.17,0,.36.28.51.46A30.17,30.17,0,0,1,71.83,57a34.39,34.39,0,0,1-1.17,15A26.67,26.67,0,0,1,61,86.18a17.46,17.46,0,0,1-20.73,0,27.06,27.06,0,0,1-9.61-13.91A34.32,34.32,0,0,1,29,62.62Zm21.64-18.2a21.37,21.37,0,0,0-3,.81c-3.22,1.42-4.94,4.83-4.49,8.65a7.65,7.65,0,0,0,8.93,6.64,8,8,0,0,0,6.13-7.6A8.09,8.09,0,0,0,50.66,44.42Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              d="M50.66,44.42a8.09,8.09,0,0,1,7.57,8.5,8,8,0,0,1-6.13,7.6,7.65,7.65,0,0,1-8.93-6.64c-.45-3.82,1.27-7.23,4.49-8.65A21.37,21.37,0,0,1,50.66,44.42Zm-6.33,8.9c1.08-.6,2.08-1.25,3.15-1.72a3.8,3.8,0,0,1,4.54.63,4.81,4.81,0,0,0,3,1.26c2.16.2,2.17.19,2-2a6.77,6.77,0,0,0-5.46-5.95A6.67,6.67,0,0,0,44.33,53.32Zm12.48,1.23c-.28,0-.46-.07-.64-.07a7.65,7.65,0,0,1-5.4-1.92,2.37,2.37,0,0,0-1.71-.4,8.45,8.45,0,0,0-4.26,2.27.82.82,0,0,0-.11.71c1.05,3.17,4.46,5.58,8.09,4.13A6.6,6.6,0,0,0,56.81,54.55Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              class="cls-1"
              d="M44.33,53.32a6.67,6.67,0,0,1,7.21-7.73A6.77,6.77,0,0,1,57,51.54c.17,2.14.16,2.15-2,2a4.81,4.81,0,0,1-3-1.26,3.8,3.8,0,0,0-4.54-.63C46.41,52.07,45.41,52.72,44.33,53.32Z"
              transform="translate(-4.87 -0.33)"
            />
            <path
              class="cls-1"
              d="M56.81,54.55a6.6,6.6,0,0,1-4,4.72c-3.63,1.45-7-1-8.09-4.13a.82.82,0,0,1,.11-.71,8.45,8.45,0,0,1,4.26-2.27,2.37,2.37,0,0,1,1.71.4,7.65,7.65,0,0,0,5.4,1.92C56.35,54.48,56.53,54.52,56.81,54.55Z"
              transform="translate(-4.87 -0.33)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },
  render3D: function (element, layer, scene) {},
};
