import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-Fire-Suppression-System",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "Fire Suppression System",
    description: "Fire-Suppression-System",
    image: require("./Fire-Suppression-System.svg"),
    svgIcon: require("./Fire-Suppression-System.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.firesuppcls1{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="firesuppcls1"
              d="M98.59,95.72H0V.17H98.59ZM66.25,22.85V41.72h8.37c0,.13,0,.24,0,.34,0,.29,0,.57,0,.86a3.49,3.49,0,0,0,.12.54H88.54V37.21c0-.11,0-.23,0-.35a.87.87,0,0,0-.37-.63,1.29,1.29,0,0,0-.79-.21H75.56a1,1,0,0,0-.92.95c0,.3,0,.6,0,.89a1.66,1.66,0,0,1,0,.24c-.39.06-4.41,0-4.65,0V22.84H94.12c.89,0,1.77,0,2.66,0V12.61c-.52-.06-94.68,0-94.9,0V22.84H3.77c.31,0,.61,0,.92,0a4.46,4.46,0,0,1,1,0V41.72H13a8.83,8.83,0,0,1,1,0c.09.6-.11,1.19.16,1.73H27.9c0-.27,0-.52,0-.77,0-1.83,0-3.65,0-5.48a2.93,2.93,0,0,0,0-.41.91.91,0,0,0-.83-.76,1.69,1.69,0,0,0-.32,0H15a.93.93,0,0,0-1,1v.89a1.94,1.94,0,0,1,0,.24H9.37V22.87l.1,0H36l0,0,0,0v6.26c0,2.09,0,4.18,0,6.27s-.05,4.17,0,6.29h8.39v1.14a.89.89,0,0,0,.17.59H58.26c0-.21.08-.4.09-.6,0-1.93,0-3.87,0-5.8a1,1,0,0,0-1.05-1H45.39a.93.93,0,0,0-.94.95c0,.3,0,.6,0,.9a1.94,1.94,0,0,1,0,.24c-.44.06-4.46,0-4.67,0-.07-.88,0-15,0-15.24ZM42.6,83.4H60.13V52.1a24.26,24.26,0,0,0-.26-3.61,17.73,17.73,0,0,0-.8-3.21,1.3,1.3,0,0,0-.08-.17H43.84c0,.08-.07.15-.09.22a21.31,21.31,0,0,0-.94,3.86,18.21,18.21,0,0,0-.22,3.14q0,15.35,0,30.7ZM74.09,45.09c-.14.41-.3.79-.41,1.19a19.68,19.68,0,0,0-.82,5.49q0,15.67,0,31.34c0,.09,0,.18,0,.29H90.39V52.5a17.7,17.7,0,0,0-.19-3.17c-.12-.68-.2-1.36-.34-2s-.33-1.29-.51-1.93c-.07-.27-.08-.26-.35-.27H74.09ZM29.75,83.36V52a18.75,18.75,0,0,0-.37-4,25.76,25.76,0,0,0-.64-2.59c0-.11-.08-.22-.11-.31H13.42c-.19.61-.41,1.21-.56,1.82a22,22,0,0,0-.65,5.42q0,15.32,0,30.61a.86.86,0,0,0,0,.16,1.94,1.94,0,0,0,0,.24Z"
              transform="translate(0 -0.17)"
            />
            <path
              d="M66.25,22.85H39.78c-.07.25-.1,14.36,0,15.24.21.07,4.23.09,4.67,0a1.94,1.94,0,0,0,0-.24c0-.3,0-.6,0-.9a.93.93,0,0,1,.94-.95H57.3a1,1,0,0,1,1.05,1c0,1.93,0,3.87,0,5.8,0,.2-.06.39-.09.6H44.61a.89.89,0,0,1-.17-.59V41.73H36.05c0-2.12,0-4.2,0-6.29s0-4.18,0-6.27V22.91l0,0,0,0H9.47l-.1,0V38.13H14a1.94,1.94,0,0,0,0-.24V37a.93.93,0,0,1,1-1H26.77a1.69,1.69,0,0,1,.32,0,.91.91,0,0,1,.83.76,2.93,2.93,0,0,1,0,.41c0,1.83,0,3.65,0,5.48,0,.25,0,.5,0,.77H14.2c-.27-.54-.07-1.13-.16-1.73a8.83,8.83,0,0,0-1,0H5.65V22.88a4.46,4.46,0,0,0-1,0c-.31,0-.61,0-.92,0H1.88V12.63c.22-.06,94.38-.08,94.9,0V22.83c-.89,0-1.77,0-2.66,0H70V38.1c.24.06,4.26.07,4.65,0a1.66,1.66,0,0,0,0-.24c0-.29,0-.59,0-.89a1,1,0,0,1,.92-.95H87.37a1.29,1.29,0,0,1,.79.21.87.87,0,0,1,.37.63c0,.12,0,.24,0,.35v6.25H74.76a3.49,3.49,0,0,1-.12-.54c0-.29,0-.57,0-.86,0-.1,0-.21,0-.34H66.25Z"
              transform="translate(0 -0.17)"
            />
            <path
              d="M42.6,83.4V83q0-15.36,0-30.7a18.21,18.21,0,0,1,.22-3.14,21.31,21.31,0,0,1,.94-3.86c0-.07.06-.14.09-.22H59a1.3,1.3,0,0,1,.08.17,17.73,17.73,0,0,1,.8,3.21,24.26,24.26,0,0,1,.26,3.61V83.4Z"
              transform="translate(0 -0.17)"
            />
            <path
              d="M74.09,45.09H89c.27,0,.28,0,.35.27.18.64.37,1.28.51,1.93s.22,1.36.34,2a17.7,17.7,0,0,1,.19,3.17V83.4H72.87c0-.11,0-.2,0-.29q0-15.67,0-31.34a19.68,19.68,0,0,1,.82-5.49C73.79,45.88,74,45.5,74.09,45.09Z"
              transform="translate(0 -0.17)"
            />
            <path
              d="M29.75,83.36H12.24a1.94,1.94,0,0,1,0-.24.86.86,0,0,1,0-.16q0-15.3,0-30.61a22,22,0,0,1,.65-5.42c.15-.61.37-1.21.56-1.82H28.63c0,.09.08.2.11.31A25.76,25.76,0,0,1,29.38,48a18.75,18.75,0,0,1,.37,4v31.4Z"
              transform="translate(0 -0.17)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
