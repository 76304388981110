import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "one-half-sink-with-drainer",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "one half sink with drainer",
    description: "one-half-sink-with-drainer",
    image: require("./one-half-sink-with-drainer.svg"),
    svgIcon: require("./one-half-sink-with-drainer.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 100,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g transform={`translate(-19, 55) scale(1, -1)`}>
          <defs>
            <style>{`.cls-1{fill:#fff;}`}</style>
          </defs>
          <g id={`${element.type}-${element.id}`} data-name="corner shower">
            <path
              d="M50.26,32.34H96.69c1.56,0,1.61,0,1.61,1.59V69.88c0,1.35-.12,1.48-1.46,1.48H3.8c-1.35,0-1.47-.13-1.47-1.47V33.76c0-1.31.11-1.42,1.42-1.42ZM3.92,69.91H96.66V33.72H3.92Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M3.92,69.91V33.72H96.66V69.91Zm2.25-1.18H36.6l.1-1.23a19.07,19.07,0,0,0,3.06.46,1.58,1.58,0,0,0,1.44-2.21A7,7,0,0,1,41,63.94a1.46,1.46,0,0,0-1.59-1.67c-1.4-.08-2.12.44-2.18,1.54,0,.18-.17.34-.26.51l-.28-.12V35.83H6.17ZM53.73,50c0-3.61,0-7.22,0-10.83,0-.76-.22-1-1-1q-6.34,0-12.69,0c-.74,0-1,.25-1,1q0,10.88,0,21.75c0,.7.19,1,1,1q6.34,0,12.69,0c.76,0,1-.22,1-1C53.71,57.24,53.73,53.63,53.73,50ZM59,50.7H92.17c2,0,1.69.26,1.71-1.75,0-1.18,0-1.18-1.22-1.18H61.34c-2.68,0-2.68,0-2.42,2.69A1.53,1.53,0,0,0,59,50.7ZM76.36,37.31H60.66c-1.78,0-1.78,0-1.78,1.77,0,1.17,0,1.17,1.14,1.17H92c1.86,0,1.82,0,1.86-1.9,0-.81-.21-1.06-1-1.05C87.36,37.33,81.86,37.31,76.36,37.31Zm-17.45,29H92c1.91,0,1.87,0,1.92-1.93,0-.8-.26-1-1-1q-16.51,0-33,0c-.31,0-.62,0-1,.06Zm0-20.9H92.08c1.81,0,1.78,0,1.82-1.84,0-.78-.15-1.07-1-1.07q-16.51,0-33,0c-.31,0-.62,0-1,.06ZM76.27,61.14H92.15c2,0,1.71.23,1.73-1.75,0-1.18,0-1.19-1.22-1.19H60.82c-2,0-1.91,0-2,2,0,.81.24,1,1,1C65.33,61.13,70.8,61.14,76.27,61.14ZM58.88,53c0,.74,0,1.33,0,1.91-.09.8.22,1,1,1q16.5,0,33,0c.31,0,.62,0,1,0V54.15c0-1.14,0-1.14-1.17-1.14H58.88Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M6.17,68.73V35.83H36.64V64.2l.28.12c.09-.17.26-.33.26-.51.06-1.1.78-1.62,2.18-1.54A1.46,1.46,0,0,1,41,63.94a7,7,0,0,0,.25,1.81A1.58,1.58,0,0,1,39.76,68a19.07,19.07,0,0,1-3.06-.46l-.1,1.23ZM35.75,36.54H7.09V68H35.7c.35-1-.07-1.5-1-1.73-1.43-.35-2.86-.72-4.28-1.14a10.23,10.23,0,0,1-2.29-.88,1.28,1.28,0,0,1-.48-1.91A1.74,1.74,0,0,1,30,61.78c.34.22.65.51,1,.75a9.42,9.42,0,0,0,4.81,1.72ZM40,64.26c.12-1.17-.48-1.59-1.54-1.12a.81.81,0,0,0-.31.74c.33,1.06.36,1.05-.79,1.13-.33,0-.65,0-1,0a10.21,10.21,0,0,1-6.59-2.43c-.47-.41-.94-.42-1.3.06s0,.92.42,1.14a7,7,0,0,0,1.66.59,24.94,24.94,0,0,1,7.1,2.41,4.41,4.41,0,0,0,1.41.46c1.14.15,1.6-.45,1.26-1.54C40.15,65.24,40.07,64.75,40,64.26Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M53.73,50c0,3.61,0,7.22,0,10.83,0,.79-.27,1-1,1q-6.35,0-12.69,0c-.76,0-1-.29-1-1q0-10.87,0-21.75c0-.73.23-1,1-1q6.35,0,12.69,0c.79,0,1,.28,1,1C53.71,42.8,53.73,46.41,53.73,50Zm-.92,11.07V39H40V61.09Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M59,50.7a1.53,1.53,0,0,1-.09-.25c-.26-2.69-.26-2.69,2.42-2.69H92.66c1.22,0,1.24,0,1.22,1.18,0,2,.29,1.75-1.71,1.75H59Zm.43-.56H93.27V48.32H59.44Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M76.36,37.31c5.5,0,11,0,16.5,0,.83,0,1.06.24,1,1.05,0,1.9,0,1.9-1.86,1.9H60c-1.14,0-1.14,0-1.14-1.17,0-1.77,0-1.77,1.78-1.77ZM59.44,39.69H93.27V37.87H59.44Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M58.91,66.35V63.52c.34,0,.65-.06,1-.06q16.51,0,33,0c.76,0,1,.18,1,1,0,1.93,0,1.93-1.92,1.93H58.91Zm.53-.53H93.27V64H59.44Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M58.91,45.45V42.62c.34,0,.65-.06,1-.06q16.51,0,33,0c.86,0,1,.29,1,1.07,0,1.84,0,1.84-1.82,1.84H58.91Zm.53-.52H93.27V43.12H59.44Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M76.27,61.14c-5.47,0-10.94,0-16.41,0-.76,0-1-.18-1-1,0-2,0-2,2-2H92.66c1.22,0,1.24,0,1.22,1.19,0,2,.3,1.74-1.73,1.75Zm-16.83-.56H93.27V58.77H59.44Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M58.88,53H92.72c1.16,0,1.17,0,1.17,1.14v1.69c-.39,0-.7,0-1,0q-16.51,0-33,0c-.77,0-1.08-.19-1-1C58.93,54.34,58.88,53.75,58.88,53Zm34.41.55H59.47v1.82H93.29Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M35.75,36.54V64.25a9.42,9.42,0,0,1-4.81-1.72c-.34-.24-.65-.53-1-.75a1.74,1.74,0,0,0-2.28.55,1.28,1.28,0,0,0,.48,1.91,10.23,10.23,0,0,0,2.29.88c1.42.42,2.85.79,4.28,1.14.91.23,1.33.7,1,1.73H7.09V36.54Zm-8,12.24a4.47,4.47,0,0,0-4.56,3.34c-.4,2.27,1.41,4.32,4.07,4.6s5.16-1.55,5.2-3.89S30.35,48.76,27.72,48.78Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M40,64.26c.11.49.19,1,.34,1.45.34,1.09-.12,1.69-1.26,1.54a4.41,4.41,0,0,1-1.41-.46,24.94,24.94,0,0,0-7.1-2.41,7,7,0,0,1-1.66-.59c-.42-.22-.82-.62-.42-1.14s.83-.47,1.3-.06A10.21,10.21,0,0,0,36.34,65c.33,0,.65,0,1,0,1.15-.08,1.12-.07.79-1.13a.81.81,0,0,1,.31-.74C39.48,62.67,40.08,63.09,40,64.26Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M52.81,61.09H40V39H52.81Zm-.3-.36V39.33a7.49,7.49,0,0,0-.75-.06c-3.61,0-7.22,0-10.83,0-.77,0-.92.27-.92,1q0,9.8,0,19.6c0,.71.16,1,.92,1,3.58,0,7.16,0,10.74,0C51.93,60.78,52.19,60.75,52.51,60.73Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M59.44,50.14V48.32H93.27v1.82Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M59.44,39.69V37.87H93.27v1.82Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M59.44,65.82V64H93.27v1.82Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M59.44,44.93V43.12H93.27v1.81Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M59.44,60.58V58.77H93.27v1.81Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M93.29,53.56v1.82H59.47V53.56Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M27.72,48.78c2.63,0,4.75,1.8,4.71,4s-2.49,4.18-5.2,3.89-4.47-2.33-4.07-4.6A4.47,4.47,0,0,1,27.72,48.78Zm0,.56a14.5,14.5,0,0,0-2.27.9A2.82,2.82,0,0,0,24.25,54a3.66,3.66,0,0,0,3.5,2A3.7,3.7,0,0,0,31.31,54a2.77,2.77,0,0,0,.24-1C31.66,51,29.93,49.49,27.67,49.34Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M52.51,60.73c-.32,0-.58.05-.84.05-3.58,0-7.16,0-10.74,0-.76,0-.92-.26-.92-1q0-9.81,0-19.6c0-.71.15-1,.92-1,3.61,0,7.22,0,10.83,0a7.49,7.49,0,0,1,.75.06ZM40.59,39.66v20.7H52.08V39.66Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M27.67,49.34c2.26.15,4,1.69,3.88,3.59a2.77,2.77,0,0,1-.24,1A3.7,3.7,0,0,1,27.75,56a3.66,3.66,0,0,1-3.5-2,2.82,2.82,0,0,1,1.15-3.76A14.5,14.5,0,0,1,27.67,49.34Zm.06,5.58a2.18,2.18,0,1,0,0-4.29,2.18,2.18,0,1,0,0,4.29Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M40.59,39.66H52.08v20.7H40.59ZM44.5,50.52A2.32,2.32,0,0,0,42,52.7a2.37,2.37,0,0,0,2.59,2.1,2.18,2.18,0,1,0-.08-4.28Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M27.73,54.92a2.18,2.18,0,1,1,0-4.29,2.18,2.18,0,1,1,0,4.29Zm0-.73a1.53,1.53,0,0,0,1.67-1.45,1.55,1.55,0,0,0-1.66-1.37,1.44,1.44,0,1,0,0,2.82Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              d="M44.5,50.52a2.18,2.18,0,1,1,.08,4.28A2.37,2.37,0,0,1,42,52.7,2.32,2.32,0,0,1,44.5,50.52Zm0,.73a1.54,1.54,0,0,0-1.69,1.43,1.58,1.58,0,0,0,1.65,1.4,1.63,1.63,0,0,0,1.75-1.4A1.57,1.57,0,0,0,44.54,51.25Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M27.75,54.19a1.44,1.44,0,1,1,0-2.82,1.55,1.55,0,0,1,1.66,1.37A1.53,1.53,0,0,1,27.75,54.19Z"
              transform="translate(-2.33 -32.34)"
            />
            <path
              class="cls-1"
              d="M44.54,51.25a1.57,1.57,0,0,1,1.71,1.43,1.63,1.63,0,0,1-1.75,1.4,1.58,1.58,0,0,1-1.65-1.4A1.54,1.54,0,0,1,44.54,51.25Z"
              transform="translate(-2.33 -32.34)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
