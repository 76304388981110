import React from "react";
const WIDTH = 90;
const DEPTH = 40;
const HEIGHT = 30;

export default {
  name: "firesafety-direction-diagonal-bottom-right",
  prototype: "items",

  info: {
    tag: ["furnishings", "metal"],
    title: "direction diagonal bottom right",
    description: "direction-diagonal-bottom-right",
    image: require("./direction-diagonal-bottom-right.svg"),
    svgIcon: require("./direction-diagonal-bottom-right.svg"),
  },
  properties: {
    scaleX: {
      label: "Width",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
    scaleY: {
      label: "Depth",
      type: "slider",
      min: 50,
      max: 250,
      defaultValue: 120,
    },
  },

  render2D: function (element, layer, scene) {
    let angle = element.rotation + 90;

    return {
      element: (
        <g>
          <defs>
            <style>{`.directionbrcls1{fill:#009f4c;}.directionbrcls2{fill:#fff;}`}</style>
          </defs>
          <g
            transform={`translate(-47, 55) scale(1, -1)`}
            id={`${element.type}-${element.id}`}
            data-name="corner shower"
          >
            <path
              class="directionbrcls1"
              d="M6.34,4.8h86V90.86l-.4,0H6.55c-.13,0-.2,0-.2-.19V5.31C6.35,5.14,6.34,5,6.34,4.8ZM27.11,63.36l0,.08.24.27q5.46,5.45,10.91,10.91a.92.92,0,0,0,.71.3q18.48,0,37,0c.52,0,.52,0,.52-.53V37.5a.92.92,0,0,0-.3-.73l-10.9-10.9L65,25.71a1.27,1.27,0,0,0-.2-.12c0,.19,0,.33,0,.47V54.74c0,.1,0,.2,0,.38-.16-.16-.27-.26-.37-.37L26.74,17.08l-.18-.18-.13-.09L18.29,25s0,0,0,0l0,0v0l0,0,0,0,0,0,0,0,0,0,38,38,0,0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,1,0,0s0,0,0,.06Z"
              transform="translate(-6.23 -4.8)"
            />
            <path
              class="directionbrcls2"
              d="M6.34,4.8c0,.17,0,.34,0,.51V90.65c0,.14.07.19.2.19H92l.4,0s0,.06,0,.06H6.23V4.8Z"
              transform="translate(-6.23 -4.8)"
            />
            <path
              class="directionbrcls2"
              d="M27.11,63.36H56.56s0,0,0-.06a0,0,0,0,0,0,0s0,0,0,0l0,0,0,0,0,0,0,0-38-38,0,0,0,0,0,0,0,0,0,0v0l0,0s0,0,0,0l8.14-8.14.13.09.18.18L64.42,54.75c.1.11.21.21.37.37,0-.18,0-.28,0-.38V26.06c0-.14,0-.28,0-.47a1.27,1.27,0,0,1,.2.12l.17.16,10.9,10.9a.92.92,0,0,1,.3.73V74.39c0,.52,0,.53-.52.53q-18.47,0-36.95,0a.92.92,0,0,1-.71-.3q-5.44-5.46-10.91-10.91l-.24-.27Z"
              transform="translate(-6.23 -4.8)"
            />
          </g>
        </g>
      ),
      transform: {
        width: -WIDTH / 2,
        depth: -DEPTH / 2,
      },
    };
  },

  render3D: function (element, layer, scene) {},
};
